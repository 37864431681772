import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { getEventAttributeValue } from '@/Utility/react';
import { debounce, trim } from 'lodash';
import { getSubscribeLink, getTrialLink } from '@/Utility/subscribe-link';

// selectors
// import { selectSelectedPaths } from '@/Selection/SelectionSlice';
import {
  selectSearchResults,
  selectSearchStatus,
  selectLastIconQuery,
} from '@/Redux/Slices/IconSearchSlice';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectUserIsVerified,
} from '@/Redux/Slices/ShaperHubSlice';

// actions
import UIFeatureAction from '@/Actions/UIFeature';
import IconSearchAction from '@/Actions/IconSearch';
import StartTrialAction from '@/Actions/StartTrial';

// components
import Icon from '@/Styles/Icons/Icon';
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import InfiniteScroll from '@/Components/InfinteScroll';
import Loading from '@/Components/Loading/Loading';
import { selectFeatures } from '@/Redux/Slices/UISlice';
import classNames from 'classnames';
import TranslationText from '../../../TranslationText/TranslationText';
import { useEffect } from 'react';
import { entitlements } from '@/Helpers/Entitlements';

export default function FindArtPanel() {
  const lastSearchQuery = useSelector(selectLastIconQuery);

  // state
  const [search, setSearch] = useState(lastSearchQuery?.query);
  const [page, setPage] = useState(lastSearchQuery?.page || 1);
  const [hasSearched, setHasSearched] = useState();

  // selectors
  const results = useSelector(selectSearchResults);
  const status = useSelector(selectSearchStatus);

  const { isTrial, isSubscriber, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLite = !isTrial && !isSubscriber;
  const userIsVerified = useSelector(selectUserIsVerified);
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);

  // actions
  const iconSearchAction = useAction(IconSearchAction);
  const uiFeatureAction = useAction(UIFeatureAction);
  const startTrialAction = useAction(StartTrialAction);

  // computed
  const hasSearch = search?.length > 0;
  const hasResults = hasSearched && results?.length > 0;
  const isSearching = ['pending'].includes(status);
  const disableSearchMore = isSearching;
  const hasFullFeature = selectFeatures(entitlements.FIND_ART);

  const filteredResults = hasFullFeature
    ? results
    : results.filter((r) => r.license_description === 'public-domain');
  const upgradeResults = results.filter(
    (r) => r.license_description !== 'public-domain'
  );
  const debounceStart = debounce(() => {
    startTrialAction.start();
  }, 1000);

  // handlers
  function onClose() {
    uiFeatureAction.toggleFindArt(false);
  }

  function onInput(str) {
    setPage(1);
    setSearch(trim(str));
  }

  function onSearch(str) {
    const phrase = trim(str);
    if (!phrase) {
      return;
    }

    // request new icons
    iconSearchAction.clear();
    iconSearchAction.search(search, page);
    setHasSearched(true);
  }

  // appends an icon to the view
  function onAddIcon(event) {
    const id = getEventAttributeValue(event, 'data-id');
    const icon = results.find((result) => result.id === id);

    // add it
    iconSearchAction.add(icon, search);

    // also open the properties panel
    uiFeatureAction.toggleEditSelectionProperties(true);

    // TODO: need to figure out better way to close/clear search for different devices
    // if(isMobile){
    onClose();
    // }
  }

  function onFindMore() {
    const nextPage = page + 1;
    setPage(page + 1);

    // search for more
    iconSearchAction.search(search, nextPage, true);
  }

  function openLink() {
    if (isLite || (isSubscriber && isExpired)) {
      if (userIsVerified) {
        debounceStart();
      } else {
        const trialPath = getTrialLink();
        window.open(`${shaperMarketingUrl}/${trialPath}`, '_self');
      }
    } else if (isTrial) {
      const subscribePath = getSubscribeLink();
      window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
    }
  }

  const findArtFormCx = classNames('find-art--form', {
    search: isSearching,
  });

  const findArtResultsCx = classNames('find-art--results', {
    hasResults: filteredResults,
  });

  useEffect(() => {
    if (
      lastSearchQuery !== null &&
      lastSearchQuery.query !== null &&
      lastSearchQuery.query.match(/^ *$/) === null
    ) {
      iconSearchAction.search(search, page);
      setHasSearched(true);
    }
  }, []);

  return (
    <>
      <FloatingPanel
        className='properties-panel--find-art'
        container
        edge='left'
        onClose={onClose}
        hideCloseOnMobile
        hideMobilePullBar
      >
        <div className={findArtFormCx} data-cy='find-art-panel'>
          <div className='find-art--search'>
            <div className='find-art--close-button' onClick={onClose}>
              <Icon icon='close' />
            </div>
            <FloatingPanel.Input
              commitOnBlur={false}
              value={''}
              defaultValue={search}
              onCommit={onSearch}
              onInput={onInput}
              tail={<SearchHint active={hasSearch} onClick={onSearch} />}
              head={<Icon>search</Icon>}
              dataCy='find-art-search'
            />
          </div>

          {hasResults && (
            <InfiniteScroll
              className={findArtResultsCx}
              disabled={disableSearchMore}
              onReachEnd={onFindMore}
            >
              <div className='find-art--results--grid'>
                {filteredResults.map((result) => (
                  <div className='find-art--result--container' key={result.id}>
                    <canvas
                      width={1}
                      height={1}
                      className='find-art--result--sizer'
                    />
                    <div
                      className='find-art--result'
                      data-id={result.id}
                      dangerouslySetInnerHTML={{ __html: result.iconSVG }}
                      onClick={onAddIcon}
                    />
                  </div>
                ))}
                {!hasFullFeature && (
                  <>
                    <div className='find-art--result--container upgrade'>
                      <div className='find-art--cta'>
                        <TranslationText i18nKey='public-domain'>
                          You've reached the end of public domain designs
                          available in Studio Lite. For commercially licensed
                          options, start your free trial.
                        </TranslationText>
                      </div>
                      <div className='find-art--subscribe' onClick={openLink}>
                        <div data-cy='find-art-cta'>
                          {isLite ? (
                            <TranslationText i18nKey='art-start-trial'>
                              Start Trial for More Designs
                            </TranslationText>
                          ) : (
                            <TranslationText i18nKey='art-subscribe'>
                              Subscribe for More Designs
                            </TranslationText>
                          )}
                        </div>
                        <Icon icon='chevron-right' />
                      </div>
                    </div>
                    {upgradeResults.map((result) => (
                      <div
                        className='find-art--result--container'
                        key={result.id}
                      >
                        <canvas
                          width={1}
                          height={1}
                          className='find-art--result--sizer'
                        />
                        <div
                          className='find-art--result upgrade'
                          data-id={result.id}
                          dangerouslySetInnerHTML={{ __html: result.iconSVG }}
                        />
                        <Icon
                          icon='premium'
                          className='find-art--result--premium'
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </InfiniteScroll>
          )}

          {isSearching && (
            <div className='find-art--instructions'>
              <Loading />
            </div>
          )}

          {!isSearching && !hasResults && (
            <div className='find-art--instructions'>
              <TranslationText i18nKey='art-instructions'>
                Type in a search term to find art
              </TranslationText>
            </div>
          )}
        </div>
      </FloatingPanel>
      <div className='find-art--overlay' />
    </>
  );
}

function SearchHint(props) {
  const { active } = props;

  return (
    <div
      className={`find-art--search-hint ${active && 'ready'}`}
      onClick={props.onClick}
    >
      <Icon icon='enter-key' />
      <div>
        <TranslationText i18nKey='search'>Search</TranslationText>
      </div>
    </div>
  );
}
