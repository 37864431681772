import BaseAction from './BaseAction';
import { actions as sherpaContainerActions } from '@/Redux/Slices/SherpaContainerSlice';
export default class UpdateWorkspaceOptionsAction extends BaseAction {
  update(options) {
    const { dispatch } = this;

    // special updates
    if ('displayUnits' in options) {
      dispatch(
        sherpaContainerActions.setDisplayUnits({
          newDisplayUnits: options.displayUnits,
        })
      );
      delete options.displayUnits;
    }
    if ('showCustomAnchor' in options) {
      dispatch(
        sherpaContainerActions.updateCustomAnchorOption(
          options.showCustomAnchor
        )
      );
      delete options.showCustomAnchor;
    }

    // check for any other options
    if (!Object.keys(options).length) {
      return;
    }

    // normal config update
    dispatch(sherpaContainerActions.updateOptions(options));
  }
}
