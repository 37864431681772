import React, { useRef, useState } from 'react';
import { radiansToDegreesFormattedNum } from '@/Geometry/UnitOps';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { degreesToRadians } from '@/Utility/rotation';

// util
import { asFloat } from '@/Utility/sanitize';

// selectors
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import RotateGroupsAction from '@/Actions/RotateGroups';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '../../../TranslationText/TranslationText';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';

export default function RotationEditor() {
  const rotationRef = useRef();
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectionBounds = useSelector(selectSelectionBounds);
  const isMultiSelect = selectedGroups.length > 1;
  const selectionAngleInDegrees = radiansToDegreesFormattedNum(
    selectionBounds.rotation
  );
  const angle = isMultiSelect ? 0 : asFloat(selectionAngleInDegrees);
  const [currentRotation, setCurrentRotation] = useState(angle);
  const [selectedGroup] = selectedGroups;
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // actions
  const rotateAction = useAction(RotateGroupsAction, selectedGroups, {
    x: 0,
    y: 0,
  });

  // handle resize updates
  function onRotate(value) {
    const degrees = asFloat(value);
    const radians = degreesToRadians(degrees);

    // can this be applied
    if (degrees !== currentRotation && !isNaN(radians)) {
      setCurrentRotation(degrees);
      rotateAction.setToRotation(radians);
      rotateAction.resolve();
    }

    // if needed, reset to zero
    if (isMultiSelect) {
      rotationRef.current.textContent = '0';
      setTimeout(() => setCurrentRotation(0));
    }
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='rotation'>
        <TranslationText i18nKey='rotation'>Rotation</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        suffix='°'
        onCommit={onRotate}
        value={angle}
        number
        calculate
        ignoreUnits
        forwardRef={rotationRef}
        disabled={disabled}
      />
    </FloatingPanel.Group>
  );
}
