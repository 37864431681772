import React from 'react';

export default function OverlayElement(props) {
  const { x, y } = props.ui.viewport.canvasToScreen(props.x, props.y);
  const style = {
    top: `${y}px`,
    left: `${x}px`,
  };

  return (
    <div
      style={style}
      {...props}
      className={`ui-layer--overlay-element ${props.className || ''}`}
    >
      {props.children}
    </div>
  );
}
