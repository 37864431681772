import React from 'react';
import { useSelector } from 'react-redux';

// components
import Workspace from './Workspace';
import Button from '@/Components/Button';

// actions
import { useAction } from '@/Actions/useAction.js';
import ClearCanvasAction from '@/Actions/ClearCanvas';
import ClearStateAction from '@/Actions/ClearState';

// redux
import { selectServerEnv } from '@/Redux/Slices/ShaperHubSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';
import { selectIsEditMode } from '@/Redux/Slices/SyncSlice';
import { entitlements } from '@/Helpers/Entitlements';

function LoginMenuContainer(props) {
  // selectors
  const serverEnv = useSelector(selectServerEnv);

  const isEditMode = useSelector(selectIsEditMode);

  // actions
  const clearStateAction = useAction(ClearStateAction);
  const clearCanvasAction = useAction(ClearCanvasAction);

  const syncEnabled = selectFeatures(entitlements.SYNC);

  const clearSavedState = () => {
    if (isEditMode) {
      clearStateAction.clear();
    }
  };

  const clearAllCanvases = () => {
    if (isEditMode) {
      clearCanvasAction.clear();
    }
  };

  function renderDataControls() {
    const isDev = serverEnv === 'development';

    return (
      <section>
        {isDev && (
          <div>
            <Button
              icon='erase'
              className='pill full'
              style={{ marginBottom: '5px' }}
              onClick={clearSavedState}
            >
              Reset All Data
            </Button>
          </div>
        )}

        <div>
          <Button
            icon='erase'
            className='pill warning full'
            onClick={() => clearAllCanvases()}
          >
            Clear Canvas
          </Button>
        </div>
      </section>
    );
  }
  function renderWorkspaceControls() {
    return <Workspace />;
  }

  return (
    <div style={{ backgroundColor: 'PaleGoldenRod', padding: '10px' }}>
      {renderDataControls()}
      {syncEnabled && renderWorkspaceControls()}
    </div>
  );
}

export default LoginMenuContainer;
