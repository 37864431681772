import { immerable } from 'immer';
import { Matrix33, initialMatrix33 } from './Matrix33';
import {
  Point,
  defaultMaxPt,
  defaultMinPt,
  transform as ptTransform,
} from './Point';

/**
 * Returns a generic AABB with x,y of 1,1
 *
 * @returns {AABB} A new AABB with size
 */
export const unitAABB = (): AABB => {
  return new AABB({ size: new Point(1, 1) });
};

/**
 * Function to transform an AABB
 * @param {AABB} aabb - the AABB to be transformed
 * @param {Matrix33} mtx - the matrix containing the transform
 * @returns {AABB} A new transformed AABB
 */
export const transform = (
  aabb: AABB = unitAABB(),
  mtx: Matrix33 = initialMatrix33
): AABB => {
  const points = [] as Point[];
  points.push(ptTransform(aabb.minPoint, mtx));
  points.push(ptTransform(aabb.maxPoint, mtx));
  points.push(ptTransform(new Point(aabb.minPoint.x, aabb.maxPoint.y), mtx));
  points.push(ptTransform(new Point(aabb.maxPoint.x, aabb.minPoint.y), mtx));

  return new AABB({ points });
};

/**
 * Function to merge two AABBs together into one AABB
 * @param {AABB} currentAABB - an AABB to be merged in
 * @param {AABB} newAABB - another AABB to be merged with the currentAABB
 * @returns {AABB} A new AABB of the merged currentAABB and newAABB
 */
export const mergeAABBs = (
  currentAABB: AABB = new AABB(),
  newAABB: AABB = new AABB()
): AABB => {
  const minPoint = new Point(
    Math.min(newAABB.minPoint.x, currentAABB.minPoint.x),
    Math.min(newAABB.minPoint.y, currentAABB.minPoint.y)
  );

  const maxPoint = new Point(
    Math.max(newAABB.maxPoint.x, currentAABB.maxPoint.x),
    Math.max(newAABB.maxPoint.y, currentAABB.maxPoint.y)
  );

  return new AABB({ minPoint, maxPoint });
};

/**
 * Like mergeAABBs but allows an array of AABB to be passed in
 * @param {AABB[]} AABBArray - an array of AABB to be merged into a single AABB
 * @returns {AABB} returns a single AABB from AABBArray
 */
export const mergeAABBArray = (AABBArray: AABB[] = []): AABB => {
  if (AABBArray.length === 0) {
    return new AABB({ points: [] });
  }
  return AABBArray.reduce((accAABB, currentAABB) =>
    mergeAABBs(accAABB, currentAABB)
  );
};

/**
 * Function that returns the size of an AABB
 * @param {AABB} aabb - the AABB that you want to know the size of
 * @returns {Point} returns a point of the size of param aabb
 */
export const getAABBSize = (aabb: AABB): Point => {
  return new Point(
    aabb.maxPoint.x - aabb.minPoint.x,
    aabb.maxPoint.y - aabb.minPoint.y
  );
};

export interface IAABB {
  minPoint?: Point;
  maxPoint?: Point;
  points?: Point[];
  size?: Point;
  position?: Point;
}

export class AABB implements IAABB {
  [immerable] = true;
  minPoint: Point;
  maxPoint: Point;
  size: Point;

  /**
   * Constructor for class AABB
   * @constructor
   * @param {IAABB} config - Configuration object that takes in either points, a size,
   * or a minPoint & maxPoint. Optional parameter for position if AABB is not centered at (0,0)
   * @see IAABB for configuration details
   */
  constructor(config?: IAABB) {
    if (config) {
      if (config.size) {
        if (config.position) {
          this.minPoint = new Point(
            -config.size.x / 2 + config.position.x,
            -config.size.y / 2 + config.position.y
          );
          this.maxPoint = new Point(
            config.size.x / 2 + config.position.x,
            config.size.y / 2 + config.position.y
          );
        } else {
          this.minPoint = new Point(-config.size.x / 2, -config.size.y / 2);
          this.maxPoint = new Point(config.size.x / 2, config.size.y / 2);
        }
      } else if (config.points) {
        if (config.points.length === 0) {
          this.minPoint = new Point(0, 0);
          this.maxPoint = new Point(0, 0);
        } else {
          this.minPoint = config.points.reduce(
            (min: Point, pt: Point) =>
              new Point(
                pt.x < min.x ? pt.x : min.x,
                pt.y < min.y ? pt.y : min.y
              ),
            defaultMinPt
          );
          this.maxPoint = config.points.reduce(
            (max: Point, pt: Point) =>
              new Point(
                pt.x > max.x ? pt.x : max.x,
                pt.y > max.y ? pt.y : max.y
              ),
            defaultMaxPt
          );
        }
      } else {
        this.minPoint =
          config.minPoint ||
          new Point(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        this.maxPoint =
          config.maxPoint ||
          new Point(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
      }
    } else {
      this.minPoint = new Point(
        Number.MAX_SAFE_INTEGER,
        Number.MAX_SAFE_INTEGER
      );
      this.maxPoint = new Point(
        Number.MIN_SAFE_INTEGER,
        Number.MIN_SAFE_INTEGER
      );
    }

    this.size = new Point(
      this.maxPoint.x - this.minPoint.x,
      this.maxPoint.y - this.minPoint.y
    );
  }
}
