import React from 'react';

//selectors
import { useSelector } from 'react-redux';
import { selectUndoState } from '../../Redux/Slices/CanvasSlice';
import { selectIsEditMode } from '@/Redux/Slices/SyncSlice';

//actions
import UndoRedoAction from '../../Actions/UndoRedoAction';

//components
import Icon from '@/Styles/Icons/Icon';
import { useAction } from '../../Actions/useAction';
import TranslationText from '../TranslationText/TranslationText';

export default function UndoRedo() {
  const { canUndo, canRedo } = useSelector(selectUndoState);
  const isEditMode = useSelector(selectIsEditMode);

  const undoRedoAction = useAction(UndoRedoAction);

  const undo = () => {
    if (isEditMode) {
      undoRedoAction.undo();
    }
  };

  const redo = () => {
    if (isEditMode) {
      undoRedoAction.redo();
    }
  };

  return (
    <div className='undo-redo' data-cy='undo-redo'>
      {canUndo && (
        <div className='undo-redo__container' onClick={undo}>
          <div className='undo-redo__container__button'>
            <Icon icon='undo' />
          </div>
          <div className='undo-redo__container__button__text'>
            <TranslationText i18nKey='undo'>Undo</TranslationText>
          </div>
        </div>
      )}
      {canRedo && (
        <div className='undo-redo__container' onClick={redo}>
          <div className='undo-redo__container__button'>
            <Icon icon='redo' />
          </div>
          <div className='undo-redo__container__button__text'>
            <TranslationText i18nKey='redo'>Redo</TranslationText>
          </div>
        </div>
      )}
    </div>
  );
}
