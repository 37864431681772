// import { batch } from 'react-redux';
import BaseAction from './BaseAction';
import { updatePathsCutParams } from '@/CanvasContainer/CanvasActions';

export default class UpdateSvgPathAction extends BaseAction {
  setCutType(selection, cutType) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutType },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setBitDiameter(selection, toolDia) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, toolDia },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setDepth(selection, cutDepth) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutDepth },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }

  setOffset(selection, cutOffset) {
    const idsAndCutParams = selection.map((sel) => ({
      groupId: sel.group.id,
      pathId: sel.path.id,
      cutParams: { ...sel.path.cutParams, cutOffset },
    }));
    this.dispatch(updatePathsCutParams(idsAndCutParams));
  }
}
