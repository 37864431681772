import { transform, doAABBsIntersect } from '@/Geometry/AABBOps';
import { invert } from '@/Geometry/sherpa-svg-generator/Matrix33';
import UIState from '@/UILayer/State/UIState';

import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedPathIds } from '../Redux/Slices/SelectionSlice';
import { uniq } from 'lodash';
import { Point } from '../Geometry/sherpa-svg-generator/Point';
import { AABB } from '../Geometry/sherpa-svg-generator/AABB';

export default class SelectionNetAction {
  constructor(dispatch, useSelector, originX, originY) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    this.groups = useSelector(selectSvgGroupSet);
    this.viewportState = useSelector(selectViewport);
    this.startingSelection = useSelector(selectSelectedPathIds);

    this.x = originX;
    this.y = originY;
  }

  // updates
  update(x, y) {
    const { groups } = this;
    const { screenToCanvasTransform } = this.viewportState;

    //Create AABB corresponding to selection net
    const p0 = new Point(this.x, this.y);
    const p1 = new Point(x, y);

    const netAABBScreenSpace = new AABB({
      minPoint: p0,
      maxPoint: p1,
    });

    //Now transform netAABB to canvas space for easier hit detection
    const netAABBCanvasSpace = transform(
      netAABBScreenSpace,
      screenToCanvasTransform
    );

    // create the selection
    const selection = [];

    // check for overlapped layers
    for (const group of groups) {
      //Does netAABB intersect group AABB? If not, can skip all paths in group.
      if (!doAABBsIntersect(group.transformedAABB, netAABBCanvasSpace)) {
        continue;
      }

      //Because group intersects net, test all paths in group against net
      // OLD - for(const path of group.cutPathSet){
      // NEW
      // group.cutPathSet is deprecated and transformed paths are no longer precalculated.
      // So new approach is to do this hit detection in each group's local coordinate system.

      // First, invert the group TRSMtx to get a transform from canvas to group local space.
      // const canvasToGroupMtx = Matrix33Ops.invert(group.TRSMtx);
      const canvasToGroupMtx = invert(group.TRSMtx);
      // Then, transform the selection net AABB to group local space
      const netAABBGroupSpace = transform(netAABBCanvasSpace, canvasToGroupMtx);

      //Now compare each basePath AABB against the selection net AABB in group local space, not in canvas space
      for (const path of group.basePathSet) {
        if (doAABBsIntersect(path.AABB, netAABBGroupSpace)) {
          //TODO - check downstream for further DOM stuff to optimize out
          selection.push({
            pathId: path.id,
            groupId: group.id,
            type: group.type,
          });
        }
      }
    }

    // calculate the size
    UIState.apply((state) => {
      const { x: left, y: top } = netAABBCanvasSpace.minPoint;
      const { x: right, y: bottom } = netAABBCanvasSpace.maxPoint;
      state.selectionNet = { left, right, top, bottom };
    });

    // return the final selection which includes any groups
    // that were in the initial selection
    return uniq([...selection, ...this.startingSelection]);
  }

  // end
  resolve() {
    UIState.reset();
  }
}
