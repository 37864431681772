import React from 'react';
import { useSelector } from 'react-redux';
import SvgGroup from '@/UILayer/Components/SvgGroup';
import Layer from '@/UILayer/Components/Layer';
import { selectDepthModeSvg } from '@/Redux/Slices/CanvasSlice';
import { createMatrix33 } from '../../Geometry/sherpa-svg-generator/Matrix33';
import { getTypeProperty } from '../../Geometry/sherpa-svg-generator/PathTypes';

// shared layer cache?
let layerCache = [];

// renders a view of
export default function SvgGroups(props) {
  const { ui } = props;
  const renderMode = ui.store.ui;
  const { changedGroupIds, selectedGroupIds } = ui;

  // clear the cache when the UI is just a full
  // re-render of the view
  if (!ui.hasChanges) {
    layerCache = [];
  }

  const groupSort = (a, b) => {
    const typeSort =
      getTypeProperty(b.data?.type, 'selectionOrder') >
      getTypeProperty(a.data?.type, 'selectionOrder')
        ? 1
        : -1;
    const areaSort = b.area - a.area;
    return typeSort || areaSort;
  };

  // create each svg group
  function renderGroups() {
    return ui.groups
      .sort((a, b) => groupSort(a, b))
      .filter((g) => getTypeProperty(g.data?.type, renderMode))
      .map((group) => {
        let layer = layerCache[group.id];

        // checks if the layer has been generated, or if it doesn't exist
        // yet - if needed, the layer is rendered now
        if (!layer || changedGroupIds[group.id]) {
          const extend = props.extendGroup ? props.extendGroup(group) : {};
          layer = (
            <SvgGroup
              selected={selectedGroupIds.includes(group.id)}
              group={group}
              key={`group_${group.id}`}
              {...extend}
            />
          );

          // saves the instance
          // layerCache.current[group.id] = layer;
          layerCache[group.id] = layer;
        }

        return layer;
      });
  }

  //Review mode renders the depth-clipped svg as a single layer, so it uses different rendering pipeline from here down.
  if (renderMode === 'review') {
    //Gets the depth-clipped svg for the entire workspace
    //Note that this is empty unless async review path generator has completed it's work.
    //See use of Loading icon on ReviewUI component
    const depthSvg = useSelector(selectDepthModeSvg);

    return (
      <Layer
        id='all'
        className={`svg-group ${props.className || ''}`}
        x={0}
        y={0}
        rotation={0}
        stretchMatrix={createMatrix33()}
        svgGroup={depthSvg}
      />
    );
  }

  return renderGroups();
}
