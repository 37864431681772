import BaseAction from './BaseAction';
import { updateRotationHandleCursorAngle } from '@/Cursors/RotationHandleCursorHelper';

// utils
import { ROTATION_HANDLES } from '@/Constants/UI';
import {
  translate,
  invert,
  createMatrix33,
  createRotationMtx,
  getRotationAngleFromMtx,
  getDegreesFromMtx,
} from '@/Geometry/sherpa-svg-generator/Matrix33';
import { multiplyMatrix33 } from '../Geometry/sherpa-svg-generator/Matrix33';
import { transform, subtract } from '@/Geometry/PointOps';
import UIState from '@/UILayer/State/UIState';

// selectors
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectSelectionBounds } from '@/Redux/Slices/SelectionSlice';

// actions
import UpdateSvgGroupAction from '@/Actions/UpdateSvgGroup';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';

// probably find a better home later
const RAD_TO_DEG = 180 / Math.PI;

// handles performing a translate for groups
export default class RotateGroupsAction extends BaseAction {
  static isAllowedHandle(handle) {
    return ROTATION_HANDLES.includes(handle);
  }

  constructor(
    dispatch,
    useSelector,
    selection,
    initialPointerScreenPosition,
    handle
  ) {
    super(dispatch, useSelector);

    const viewport = useSelector(selectViewport);
    const selectionBounds = useSelector(selectSelectionBounds);

    const initialInteractionPosition = transform(
      initialPointerScreenPosition,
      viewport.screenToCanvasTransform
    );

    this.selection = selection;

    this.rotationOrigin = selectionBounds.anchor;
    this.startingAngle = Math.atan2(
      initialInteractionPosition.y - this.rotationOrigin.y,
      initialInteractionPosition.x - this.rotationOrigin.x
    );

    this.rotatingAnchor = handle;

    // ROTATION: We need to get the correct anchor point
    // to rotate around
    //JH 7/12/22 - I modified SelectionBoxHelper to account for rotation when computing selectionBounds.anchor
    const rotateAround = { ...selectionBounds.anchor };

    this.selectionGroupCenter = rotateAround;

    this.selectionCenterMtx = translate(
      createMatrix33(),
      this.selectionGroupCenter
    );
    this.invSelectionCenterMtx = invert(this.selectionCenterMtx);

    this.rotationMtx = createMatrix33();
    this.initialRotationMtx = createRotationMtx(selectionBounds.rotation);
    this.startingRotation = selectionBounds.rotation * RAD_TO_DEG;

    this.initialVector = subtract(
      initialInteractionPosition,
      this.selectionGroupCenter
    );
    this.viewport = viewport;

    this.changes = {};
  }

  setToRotation(radians) {
    const { useSelector } = this;
    this.radians = radians;
    this.asAbsolute = this.selection.length === 1;

    const selectionBounds = useSelector(selectSelectionBounds);
    const { rotation: current } = selectionBounds;
    const amount = -(current - radians);
    this.rotateByRadians(amount);
  }

  rotateByRadians(radians) {
    const mtx = createRotationMtx(radians);
    this.rotateByMatrix(mtx, false);
  }

  // applies translations and updated existing layers
  rotateBy(interactionPointScreen, constrainRotation) {
    const { useSelector } = this;

    const viewport = useSelector(selectViewport);

    //Get interaction point in canvas space
    const interactionPointCanvas = transform(
      interactionPointScreen,
      viewport.screenToCanvasTransform
    );
    const currentAngle = Math.atan2(
      interactionPointCanvas.y - this.rotationOrigin.y,
      interactionPointCanvas.x - this.rotationOrigin.x
    );
    let angle = currentAngle - this.startingAngle;
    let degrees = (180 / Math.PI) * angle;

    // check for snapping - compare this to the actively
    // displayed rotation and not relative to how much
    // rotation has been applied
    if (constrainRotation) {
      const activeRotation = degrees + this.startingRotation;

      // calculate offsets
      const offset = Math.abs(activeRotation % 45);
      const invertVal = activeRotation < 0 ? -1 : 1;
      const shift = (offset > 45 / 2 ? 45 - offset : -offset) * invertVal;

      // only shift if within 5 degrees of a 45 degree angle
      if (Math.abs(shift) < 5) {
        degrees += shift;
      }
    }

    const rotationMtx = createRotationMtx((Math.PI / 180) * degrees);
    this.rotateByMatrix(rotationMtx);
  }

  // apply rotation transforms
  rotateByMatrix(rotationMtx, updateDOM = true) {
    const ui =
      updateDOM &&
      UIState.update({
        rotate: true,
        groups: {},
      });

    const { useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById);

    const rotationAngle = getRotationAngleFromMtx(rotationMtx);
    const selectionCenterTransformMtx = multiplyMatrix33(
      this.selectionCenterMtx,
      rotationMtx,
      this.invSelectionCenterMtx
    );

    if (ui && this.selection.length > 1) {
      ui.isMultiSelection = true;
      ui.rotation = rotationAngle;
    }

    this.changes = {};

    // apply changes to each group
    const isSingleSelection = this.selection.length === 1;
    for (const selectedGroup of this.selection) {
      const groupId = selectedGroup.id;
      const reloadedSelection = getGroupById(groupId);
      if (groupId === undefined || groupId === null || this.changes[groupId]) {
        continue;
      }

      //  rotate position around selection center to get position offset
      const rotatedPositionDelta = subtract(
        transform(reloadedSelection.position, selectionCenterTransformMtx),
        reloadedSelection.position
      );

      // notify the UI update, if needed
      if (ui) {
        ui.rotate = rotationAngle;

        ui.translate = {
          x: isSingleSelection ? rotatedPositionDelta.x : 0,
          y: isSingleSelection ? rotatedPositionDelta.y : 0,
        };

        ui.groups[groupId] = {
          rotate: rotationAngle,
          translate: {
            x: rotatedPositionDelta.x,
            y: rotatedPositionDelta.y,
          },
        };

        ui.rotatingAnchor = this.rotatingAnchor;
      }

      // track the delta change
      this.changes[groupId] = {
        deltaPosition: rotatedPositionDelta,
      };
    }

    // update the UI, if needed
    if (ui) {
      ui.apply();

      // make the rotation cursor match
      const startingRotation = getDegreesFromMtx(this.initialRotationMtx);
      const currentRotation = getDegreesFromMtx(selectionCenterTransformMtx);

      updateRotationHandleCursorAngle(currentRotation + startingRotation);
    }

    // save the current rotation mtx
    this.rotationMtx = rotationMtx;
  }

  // creates a manifest of changes
  resolve() {
    const { rotationMtx, changes, radians, asAbsolute } = this;

    // reset rotation, if needed
    // when it's a group, clear the rotation
    if (this.selection.length > 1) {
      updateRotationHandleCursorAngle(0);
    }

    // clear UI changes
    UIState.reset();

    //Angle in radians
    const rotationAngle = getRotationAngleFromMtx(rotationMtx);

    const result = {};

    // apply all changes
    Object.keys(changes).forEach((groupIdKey) => {
      const change = {
        deltaPosition: changes[groupIdKey].deltaPosition,
      };

      if (asAbsolute) {
        change.setRotation = radians;
      } else {
        change.deltaRotation = rotationAngle;
      }

      result[groupIdKey] = change;
    });

    const updateBatch = Object.keys(result).map((groupChangeKey) => ({
      id: groupChangeKey,
      key: 'rotation:relative_position',
      value: result[groupChangeKey],
    }));

    // apply the update
    const update = this.createAction(UpdateSvgGroupAction);
    update.apply(updateBatch);
  }
}
