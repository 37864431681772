import { parseToRgba } from 'color2k';
import { defaultCutType } from '@/defaults';
import { CutParams, CutType } from './sherpa-svg-generator/CutParams';

// SVGConstants from OriginApp
// Note alpha needs to a float between 0 and 1
const ORIGIN_SVG_COLORS = {
  SVG_COLOR_INTERIOR_CUT: [255, 255, 255, 255 / 255], // White (fill)
  SVG_COLOR_EXTERIOR_CUT: [0, 0, 0, 255 / 255], // Black (fill)
  SVG_COLOR_GRAY: [122, 122, 122, 255 / 255], // Gray (stroke) = online / gray (fill) = pocketing
  SVG_COLOR_GUIDE: [0, 0, 255, 255 / 255], // Blue (stroke OR fill)

  // NOT SUPPORTED
  // SVG_COLOR_LEGACY_INTERIOR_CUT : [0,0,255,255],      // Blue (fill)
  // SVG_COLOR_LEGACY_EXTERIOR_CUT : [0,255,0,255],      // Green (fill)
  // SVG_COLOR_LEGACY_ON_LINE_CUT : [0,255,0,255],       // Green (stroke)
  // SVG_COLOR_LEGACY_POCKETING_CUT : [255,0,0,255],     // Red (fill)
  // SVG_COLOR_LEGACY_GUIDE : [255,255,0,255]           // Yellow (stroke OR fill)
};

const OriginColors = new Map();

Object.entries(ORIGIN_SVG_COLORS).forEach(([k, v]) => {
  OriginColors.set(k, v);
});

const MAX_COLOR_MATCH_ERROR = 1;

//This is not a great way compare colors, but it's consistent with Origin
const computeColorSimilarity = (a: number[], b: number[]) =>
  255 * 4 -
  Math.abs(a[0] - b[0]) -
  Math.abs(a[1] - b[1]) -
  Math.abs(a[2] - b[2]) -
  Math.abs(a[3] - b[3]) * 255;

//It seems sensible to limit color matching if error is large
const computeBoundedColorSimilarity = (a: number[], b: number[]) => {
  const similarity = computeColorSimilarity(a, b);
  const errorBound = 255 * 4 * (1 - MAX_COLOR_MATCH_ERROR);
  return similarity >= errorBound ? similarity : 0;
};

const isRgbValue = (v: any) => Number.isInteger(v) && v >= 0 && v <= 255;
const isAlphaValue = (v: any) => !isNaN(v) && v >= 0 && v <= 1.0;

const isRgbaArray = (c: any) => {
  return (
    Array.isArray(c) &&
    c.length === 4 &&
    isRgbValue(c[0]) &&
    isRgbValue(c[1]) &&
    isRgbValue(c[2]) &&
    isAlphaValue(c[3])
  );
};

const toColorArrOrThrow = (c: any) => {
  if (isRgbaArray(c)) {
    return c;
  }

  const cArr = parseToRgba(c);
  if (isRgbaArray(cArr)) {
    return cArr;
  }

  throw new Error(`Cannot convert input ${c} to rgba array`);
};

const isNone = (c: any) => {
  return (
    (typeof c === 'string' || c instanceof String) && c.toLowerCase() === 'none'
  );
};

const compareColors = (a: any, b: any) => {
  if (isNone(a) || isNone(b)) {
    return 0;
  }

  const aColorArr = toColorArrOrThrow(a);
  const bColorArr = toColorArrOrThrow(b);

  return computeBoundedColorSimilarity(aColorArr, bColorArr);
};

const getMostSimilarColorKey = (candidate: any) => {
  const result = [];
  for (const [colorKey, colorVal] of OriginColors) {
    result.push({ name: colorKey, score: compareColors(candidate, colorVal) });
  }
  result.sort((a, b) => b.score - a.score);

  return result[0].score !== 0 ? result[0].name : false;
};

export const getCutEncoding = function (pathNode: Element) {
  const fillColor = getMostSimilarColorKey(
    pathNode.getAttribute('fill') ?? OriginColors.get('SVG_COLOR_EXTERIOR_CUT')
  );
  const strokeColor = getMostSimilarColorKey(
    pathNode.getAttribute('stroke') ??
      OriginColors.get('SVG_COLOR_EXTERIOR_CUT')
  );
  const cutParams = new CutParams();

  // TODO - need to modify bullet parser to pass shaper NS attributes through to usvg to enable depth encoding
  // const shaperDepthAttr = pathNode.getAttribute('shaper:cutDepth');
  // if(shaperDepthAttr !== null){
  //   cutParams.cutDepth = UnitOps.convertUnitNumToMM(shaperDepthAttr, 'm'); //Legacy Fusion plugin defaults to m for depth
  // }
  // const shaperCutType = pathNode.getAttribute('stroke');
  // const shaperBitSizeType = pathNode.getAttribute('stroke');

  let cutType;
  switch (true) {
    //SVG path nodes have a default fill of black, which unfortunately corresponds to the fill color for an exterior cut
    //There does not appear to be a way to query the pathNode to determine if it's fill is a default or specified explicitly.
    //So we have to test for SVG_COLOR_EXTERIOR_CUT after all stroke dependent cutTypes, else we may accidentally ignore the user specified stroke in favor of a default fill type.
    case fillColor === 'SVG_COLOR_GUIDE':
    case strokeColor === 'SVG_COLOR_GUIDE':
      cutType = CutType.GUIDE;
      break;

    case strokeColor === 'SVG_COLOR_GRAY':
      cutType = CutType.ONLINE;
      break;

    case fillColor === 'SVG_COLOR_INTERIOR_CUT':
      cutType = CutType.INSIDE;
      break;

    case fillColor === 'SVG_COLOR_GRAY':
      cutType = CutType.POCKET;
      break;

    case fillColor === 'SVG_COLOR_EXTERIOR_CUT':
      cutType = CutType.OUTSIDE;
      break;

    default:
      cutType = defaultCutType.keyEnum;
  }
  cutParams.cutType = cutType;

  return cutParams;
};
