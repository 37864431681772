import React from 'react';
import Layer from './Layer';

export default function CustomAnchor(props) {
  const { ui } = props;
  const { viewport } = ui;

  function renderCustomAnchor() {
    if (!viewport) {
      return null;
    }

    return (
      <Layer normalizeToViewport className='custom-anchor-layer'>
        <path d={`M 0 -24 V 0 H 14 L 0 -24 Z`} />
      </Layer>
    );
  }

  return <>{renderCustomAnchor()}</>;
}
