/* eslint-disable valid-jsdoc */
import {
  setSequence,
  setSnapshot,
  setStatus,
  updatePendingQueue,
  updateQueue,
} from '../../Redux/Slices/SyncSlice';
import { update } from './../SyncThunks';
import {
  PatchSet,
  validatePatches,
  applyPatches,
  immerToJSONPatches,
  addSliceNameToPatches,
} from './../PatchGenerator';
import { log, logError, sanitizePatches } from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';
import { UnknownAction } from '@reduxjs/toolkit';
import { SyncError } from './../SyncError';
import { rollbackCanvas } from '@/Redux/Slices/CanvasSlice';
import { AppDispatch, RootState } from '@/Redux/store';
import { Snapshot, SyncStatus } from '../SyncConstants';
import { getStatusFromError, isResolvedTask } from '../SyncHelpers';

const rollbackQueue = async (
  dispatch: AppDispatch,
  pendingQueue: PatchSet[],
  queue: PatchSet[],
  originalSnapshot: Snapshot
) => {
  const revertPatchSet = [...pendingQueue, ...queue].reverse();

  logError(`Rolling back queue with length: ${revertPatchSet}`, {
    syncLevel: 'listener',
    revertPatchSet,
  });

  dispatch(setSnapshot(originalSnapshot));
  dispatch(updatePendingQueue([]));
  dispatch(rollbackCanvas(revertPatchSet));
};

/**
 * Queue listener -- listens to the queue to start sending updates
 */
export const addQueueListener = (startListening: Function) => {
  startListening({
    predicate: (_: UnknownAction, currentState: RootState) => {
      const { queue, status } = currentState.sync;

      /**
       * We only care about the queue listener when there is actually
       * something in the queue
       */
      return (
        queue.length > 0 && status !== 'pending' && status !== 'disconnected'
      );
    },
    effect: async (
      _: UnknownAction,
      { dispatch, getState, fork }: SyncListenerApi
    ) => {
      const { sync } = getState();
      const { queue, snapshot, enabled } = sync;
      log('Syncing queue...', { ...context, queue }, 'debug');

      const newQueue = [] as PatchSet[];
      const newPendingQueue = [...queue] as PatchSet[];

      if (newPendingQueue && newPendingQueue.length > 0) {
        dispatch(setStatus('pending'));
        dispatch(updateQueue(newQueue));
        dispatch(updatePendingQueue(newPendingQueue));

        const nextStatusOrErr = await (async (): Promise<
          SyncStatus | SyncError
        > => {
          const patchesToSend = addSliceNameToPatches(newPendingQueue);
          const jsonPatches = immerToJSONPatches(patchesToSend);
          if (!jsonPatches) {
            return new SyncError(
              'bad_patches',
              'listener',
              'Unable to convert immer to JSON patches',
              undefined,
              { jsonPatches, snapshot }
            );
          }

          const valid = validatePatches(snapshot, jsonPatches);
          if (!valid) {
            return new SyncError(
              'bad_patches',
              'listener',
              'Unable to validate patches',
              undefined,
              { snapshot, jsonPatches }
            );
          }

          const newSnapshot = applyPatches(snapshot, jsonPatches);
          dispatch(setSnapshot(newSnapshot));

          if (enabled) {
            log('Sync is enabled, sending update for patches', {
              ...context,
              patchesToSend: sanitizePatches(patchesToSend),
            });

            const task = fork(async () => {
              try {
                const response = await dispatch(update(patchesToSend)).unwrap();
                return response;
              } catch (err) {
                throw err;
              }
            });

            const result = await task.result;
            if (isResolvedTask(result)) {
              const { latestUpdateSequence } = result.value;
              dispatch(setSequence(latestUpdateSequence));
              log(
                `Update was successful, updating workspace to ${latestUpdateSequence}`,
                { ...context }
              );
              dispatch(updatePendingQueue([]));
              return 'edit';
            }

            const { error } = result;

            const { sync: syncAgain } = getState();
            const { queue: queueAgain } = syncAgain;
            await rollbackQueue(
              dispatch,
              newPendingQueue,
              queueAgain,
              snapshot
            );
            logError(
              'There was an error in updating the workspace, had to rollback',
              {
                ...context,
                result,
              }
            );

            return error instanceof SyncError
              ? error
              : new SyncError(
                  'bad_patches',
                  'listener',
                  `There was an error in updating the workspace, rolling back the queue`
                );
          }
          dispatch(updatePendingQueue([]));
          return 'edit';
        })();

        if (nextStatusOrErr instanceof SyncError) {
          dispatch(setStatus(getStatusFromError(nextStatusOrErr)));
          throw nextStatusOrErr;
        } else {
          dispatch(setStatus(nextStatusOrErr));
        }
      }
    },
  });
};
