import { updateSvgGroup } from '@/CanvasContainer/CanvasActions';
import UpdateSelectionAction from './UpdateSelection';
import { useActionWithDispatch } from './useAction';
import { updateActiveSelection } from '../Redux/Slices/SelectionSlice';
import { batch } from 'react-redux';

export default class UpdateSvgGroupAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  apply = (data) => {
    const { dispatch } = this;
    useActionWithDispatch(UpdateSelectionAction, dispatch);
    batch(async () => {
      await dispatch(updateSvgGroup(data));
      await dispatch(updateActiveSelection());
    });
  };
}
