import {
  selectMostRecentlyAddedGroup,
  selectSelectedGroupIds,
  selectSelectedPathIds,
  setSelection,
  replaceSelection,
} from '@/Redux/Slices/SelectionSlice';

import { isString, uniq } from 'lodash';
import { selectSvgGroupSet } from '../Redux/Slices/CanvasSlice';
import BaseAction from './BaseAction';
import { PATH_TYPE } from '../Geometry/sherpa-svg-generator/Path';

function normalizeGroupIds(collection) {
  const ids = collection
    .map((id) => (isString(id?.groupId) ? id.groupId : id))
    .filter((id) => isString(id));
  return uniq(ids);
}

export default class SetSelectionAction extends BaseAction {
  set = (selection, refresh = true) => {
    return this._apply(setSelection(selection), refresh);
  };

  selectAllPaths(refresh = true) {
    const { useSelector } = this;
    const allObjects = useSelector(selectSvgGroupSet);
    const filteredObjects = allObjects.filter(
      (o) => o.type !== PATH_TYPE.REFERENCE
    );

    let selection = [];
    for (const group of filteredObjects) {
      selection = [
        ...selection,
        ...group.basePathSet.map((path) => ({
          pathId: path.id,
          groupId: group.id,
        })),
      ];
    }

    this.set(selection, refresh);
  }

  // selectAllGroups() { }

  // expands out a selection to include other paths in the same
  // group. This only applies to some types
  expandSelection() {
    const { useSelector } = this;
    const groups = useSelector(selectSvgGroupSet);
    const selected = [...this.useSelector(selectSelectedPathIds)];

    // add more types to expand out, if needed
    ['text-insert'].forEach((tool) => {
      for (let i = selected.length; i-- > 0; ) {
        // nothing to do - maybe cleared by
        // merging another group
        if (!selected[i]) {
          selected.splice(i, 1);
          continue;
        }

        // check if this is a type to update
        const { groupId, type } = selected[i];
        const group = groups.find((item) => item.id === groupId);

        // if this is a matching type, then expand out
        // the selection
        if (group.tool.type === tool) {
          // remove all other paths for this group
          for (let j = selected.length; j-- > 0; ) {
            if (selected[j]?.groupId === groupId) {
              selected[j] = null;
            }
          }

          // then append the items to the end of the group
          const include = group.basePathSet.map((item) => ({
            type,
            pathId: item.id,
            groupId,
          }));

          selected.push(...include);
        }
      }
    });

    // make sure the first result is valid
    if (!selected[0]) {
      selected.splice(0, 1);
    }

    // update the selection
    this.dispatch(setSelection(selected));
  }

  merge = (selection, refresh = true) => {
    const current = this.useSelector(selectSelectedGroupIds);
    const ids = normalizeGroupIds([...current, ...selection]);
    return this._apply(setSelection(ids), refresh);
  };

  remove = (selection, refresh = true) => {
    const updated = [...this.useSelector(selectSelectedPathIds)];

    // remove if needed
    for (let i = updated.length; i-- > 0; ) {
      const existing = updated[i];
      const matches =
        selection.findIndex(
          (remove) =>
            existing.groupId === remove || existing.groupId === remove.groupId
        ) > -1;
      if (matches) {
        updated.splice(i, 1);
      }
    }

    return this._apply(setSelection(updated), refresh);
  };

  clear = (refresh = true) => {
    return this._apply(setSelection([]), refresh);
  };

  mostRecent = (refresh = true) => {
    return this._apply(selectMostRecentlyAddedGroup(), refresh);
  };

  // slightly smarter version of selection management -- this will remove or add selection
  // but also account for paths as part of the selection possibilities
  resolve = (update = [], action) => {
    const { useSelector } = this;

    // get the current selection state
    let groups = useSelector(selectSelectedGroupIds);
    let paths = useSelector(selectSelectedPathIds);

    // always remove the selection first
    for (const { pathId, groupId } of update) {
      paths = paths.filter(({ pathId: id }) => id !== pathId);

      // when providing a group, remove it
      if (groupId) {
        paths = paths.filter(({ groupId: id }) => id !== groupId);
        groups = groups.filter((id) => id !== groupId);
      }

      // if only targeting the group as a whole
      if (!pathId) {
        groups = groups.filter((id) => id !== groupId);
      }
    }

    if (action === 'add') {
      const all = useSelector(selectSvgGroupSet);

      // add all items as possible
      for (const { pathId, groupId } of update) {
        // including both a group and path
        if (groupId && pathId) {
          groups = [...groups, groupId];
          paths = [...paths, { groupId, pathId }];
        }

        // with just a group, include all paths
        else if (groupId) {
          const source = all.find((group) => group.id === groupId);
          groups = [...groups, groupId];
          paths = [
            ...paths,
            ...source.basePathSet.map((path) => ({ groupId, pathId: path.id })),
          ];
        }
        // just a path, select the parent group
        else if (pathId) {
          const source = all.find((group) =>
            group.basePathSet.find((path) => path.id === pathId)
          );
          paths = [...paths, { pathId, groupId: source.id }];
          groups = [...groups, source.id];
        }
      }
    }
    this.dispatch(replaceSelection({ groupIds: groups, pathIds: paths }));
    // this.refresh();
  };

  refresh = () => {
    console.log('unused function refresh (remove invocation from callee)');
    // return this.dispatch(updateSelectionBox());
  };

  _apply = (action, refresh) => {
    this.dispatch(action);
    // if (refresh) {
    //   this.refresh();
    // }
  };
}
