import { updateServerSHA } from '@/Redux/Slices/ShaperHubSlice';
import { setLoading } from '../Redux/Slices/SherpaContainerSlice';
import { disconnect, reconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';

export default class IdleAppAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  goIdle = async () => {
    const { dispatch } = this;

    log(`Emitting disconnect and idling workspace`, { syncLevel: 'action' });
    await dispatch(disconnect({ idleApp: true }));
  };

  goActive = async () => {
    const { dispatch } = this;

    log(`Emitting reconnect after idling`, {
      syncLevel: 'action',
    });
    await dispatch(updateServerSHA());
    await dispatch(setLoading(true));
    await dispatch(reconnect());
  };
}
