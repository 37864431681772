import { batch } from 'react-redux';

//selectors
import { selectShaperSubscriptions } from '../Redux/Slices/ShaperHubSlice';
import {
  getShaperSubscriptions,
  startTrial,
} from '../ShaperHub/ShaperHubThunks';

//actions
import BaseAction from './BaseAction';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';

export default class StartTrialAction extends BaseAction {
  start() {
    const { dispatch, useSelector } = this;
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(async () => {
      await dispatch(startTrial());
      await dispatch(getShaperSubscriptions());
      await updateWorkspaceOptions.update({ showApplicationMenu: false });

      const { isTrial } = useSelector(selectShaperSubscriptions);
      if (isTrial) {
        window.history.pushState(null, document.title, '/?success');
      }
    });
  }
}
