import { endSession } from '@/Redux/Slices/ShaperHubSlice';
import { disconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';

export default class UnloadAppAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  unload = async () => {
    const { dispatch } = this;

    log(`Unloading/disconnecting workspace`, { syncLevel: 'action' });
    await dispatch(endSession());
    await dispatch(disconnect());
  };
}
