import React, { useEffect, useRef } from 'react';

export default function AttachToElement(props) {
  const { el } = props;
  const containerRef = useRef();
  const top = /top/i.test(props.side);
  const left = /left/i.test(props.side);
  const right = /right/i.test(props.side);
  const bottom = /bottom/i.test(props.side);

  const horizontal = left ? '0%' : right ? '-100%' : '-50%';
  const vertical = top ? '0%' : bottom ? '-100%' : '-50%';
  const transform = `translate(${horizontal}, ${vertical})`;
  const style = { transform };

  useEffect(() => {
    // match the menu to the position
    function syncPosition() {
      const bounds = props.el.current.getBoundingClientRect();

      const x = left
        ? bounds.right
        : right
        ? bounds.left
        : (bounds.left + bounds.right) * 0.5;

      const y = top
        ? bounds.bottom
        : bottom
        ? bounds.top
        : (bounds.top + bounds.bottom) * 0.5;

      // save the position
      containerRef.current.style.left = `${x}px`;
      containerRef.current.style.top = `${y}px`;
      containerRef.current.style.transform = transform;
    }

    window.addEventListener('resize', syncPosition);
    window.addEventListener('scroll', syncPosition);
    if (el.current) {
      // first setup
      syncPosition();
    }

    return () => {
      window.removeEventListener('resize', syncPosition);
      window.removeEventListener('scroll', syncPosition);
    };
  }, [el]);

  return (
    <div
      className={`attach-to-element ${props.className}`}
      ref={containerRef}
      style={style}
    >
      {props.children}
    </div>
  );
}
