import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { useSwitchMobileView } from '@/Utility/react';
import './Polyfills';

// loads the main scss file
import '@/Styles/Index.scss';

//Visibility and Activity hooks for workspace sync
import { usePageVisibility } from '@/Utility/PageVisibilityHook.js';
import { usePageIdle } from '@/Utility/PageIdleHook.js';

// Views
import SherpaContainer from '@/SherpaContainer/SherpaContainer';

// selectors
import { selectTheme, selectActiveUIState } from '@/Redux/Slices/UISlice';
import { selectHasSelection } from '@/Redux/Slices/SelectionSlice';

// Actions

import { selectUIMode } from '@/Redux/Slices/UISlice';

// actions
import { setMobileMode } from '@/Redux/Slices/UISlice';
import ViewportActions from '@/Actions/Viewport';
import InitializeAppAction from '@/Actions/InitializeApp';
import UnloadAppAction from '@/Actions/UnloadApp';
import IdleAppAction from '@/Actions/IdleApp';

// saved state
import { stateLoaded } from '@/Redux/localStorage';
import { getScreenViewMode } from './Utility/screen';
import UIFeatureAction from './Actions/UIFeature';
import { addAttributeToUser } from './Utility/userflow';
import { getDeviceType } from './Utility/ismobile';
import { selectEnabled } from './Redux/Slices/SyncSlice';

// main app wrapper
function App({ studioUrlParams }) {
  const dispatch = useDispatch();

  const theme = useSelector(selectTheme);
  const mode = useSelector(selectUIMode);
  const hasSelection = useSelector(selectHasSelection);
  const { isShowingTextInsert } = useSelector(selectActiveUIState);
  const syncEnabled = useSelector(selectEnabled);
  const uiAction = useAction(UIFeatureAction);

  // hooks for workspace sync
  const isVisible = usePageVisibility();
  const isIdle = usePageIdle();

  // tracking text insert state - used to determine if a
  // viewport should actually refresh or not. We may need
  // to make this more robust later
  const isShowingTextInsertRef = useRef(isShowingTextInsert);

  // actions
  const initializeAppAction = useAction(InitializeAppAction);
  const unloadAppAction = useAction(UnloadAppAction);
  const idleAppAction = useAction(IdleAppAction);
  const viewportAction = useAction(ViewportActions);

  useSwitchMobileView((view) => {
    dispatch(setMobileMode(view));

    // when switching to desktop, if there's a selection
    // then make sure to open the menu
    if (view === 'desktop' && hasSelection) {
      uiAction.toggleEditSelectionProperties(true);
    }
  });

  // current text showing state is tracked outside of
  // context and handled in response to a layout update
  isShowingTextInsertRef.current = isShowingTextInsert;
  useLayoutEffect(() => {
    const refresh = () => {
      if (isShowingTextInsertRef.current) {
        return;
      }

      viewportAction.refresh();

      //also change device type on userflow
      addAttributeToUser('device_type', getDeviceType(window));
    };

    window.addEventListener('resize', refresh);
    return () => window.removeEventListener('resize', refresh);
  }, []);

  // mount
  useEffect(() => {
    initializeAppAction.init(studioUrlParams);

    //set screenViewMode on init
    dispatch(setMobileMode(getScreenViewMode()));

    // Call this after first render to set localStorage.refreshInProgress to false
    // If local state is corrupted and application crashes on first, this call will never be reached. Refreshing the browser after this will delete the corrupt store and allow the application to load with a blank workspace.
    stateLoaded();
  }, []);

  //Add before unload event handler
  //This will unlock the current workspace
  //Unfortunately, this only works when navigating away from the page (e.g. browser back or entering a new URL)
  //It does not work on browser refresh, and the user will have to wait until the current lock expires
  useEffect(() => {
    const unload = () => {
      if (syncEnabled) {
        unloadAppAction.unload();
      }
      return true;
    };
    window.addEventListener('beforeunload', unload);

    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, []);

  const sherpaWithoutLanding = <SherpaContainer />;

  useEffect(() => {
    //Go idle if idle or hidden, and active if returning from these states. Reducer will handle no-ops
    const { activeToIdle, idleToActive } = isIdle;
    //Disabled sync idle action on visibility change because the loading icon sometimes gets stuck on, even when we're back in edit mode
    //Idle still happens if user is inactive.
    if (activeToIdle) {
      idleAppAction.goIdle();
    }

    if (idleToActive) {
      idleAppAction.goActive();
    }
  }, [isVisible, isIdle]);

  // map all theme names
  const themeCx = (theme || 'default')
    .split(/ +/)
    .map((name) => `theme--${name}`)
    .join(' ');

  return (
    <div className={`App ${themeCx} mode--${mode}`}>
      <div id='react-portal-container' />
      <input id='temporary-input-preselect' />
      {sherpaWithoutLanding}
    </div>
  );
}

export default App;
