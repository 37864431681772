// helpers
import { invert } from '@/Geometry/sherpa-svg-generator/Matrix33';
import { transform } from '@/Geometry/PointOps';
import { isNumber } from 'lodash';

//state
import UIState from '@/UILayer/State/UIState';

// selectors
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectSelectionBounds } from '@/Redux/Slices/SelectionSlice';
import { selectSecretOptions } from '../Redux/Slices/SherpaContainerSlice';

// actions
import UpdateSvgGroupAction from '@/Actions/UpdateSvgGroup';
import BaseAction from './BaseAction';
import { selectSelectedGroups } from '../Redux/Slices/SelectionSlice';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';
import { Point } from '../Geometry/sherpa-svg-generator/Point';

// handles performing a translate for groups
export default class TranslateGroupsAction extends BaseAction {
  constructor(
    dispatch,
    useSelector,
    selection,
    options = { applyTemporaryTranslations: true }
  ) {
    super(dispatch, useSelector);
    // assign props
    this.selectedGroupIds = selection;
    this.options = options;
    this.alignmentHelper = options.alignmentHelper;
    this.x = 0;
    this.y = 0;
  }

  // TODO: this performs the resolution step as well - the other translate
  // function was designed with pixels in mind - We really ought to
  // refactor all of this
  setAbsolute(newX, newY) {
    const { useSelector } = this;
    const selectionBounds = useSelector(selectSelectionBounds);

    const currentX = (selectionBounds.left + selectionBounds.right) * 0.5;
    const currentY = (selectionBounds.top + selectionBounds.bottom) * 0.5;
    const deltaX = !isNumber(newX) ? 0 : newX - currentX;
    const deltaY = !isNumber(newY) ? 0 : newY - currentY;

    // apply if there are actually changes
    if (deltaX || deltaY) {
      // apply the changes
      const batch = this.selectedGroupIds.map((id) => ({
        id,
        key: 'relative_position',
        value: { x: deltaX, y: deltaY },
      }));

      // apply updates
      const update = this.createAction(UpdateSvgGroupAction);
      update.apply(batch);
    }
  }

  // applies translations and updated existing layers
  translateBy(
    dxPixels,
    dyPixels,
    { snapToObjects, snapToGrid, updateUI = true } = {}
  ) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);
    const secretOptions = useSelector(selectSecretOptions) || {};
    const selectedGroups = useSelector(selectSelectedGroups);
    const cancelResolve = selectedGroups.some(
      (sg) => PATH_TYPES[sg.type].selectability === false
    );

    if (!cancelResolve) {
      let targetX = dxPixels;
      let targetY = dyPixels;

      // check for alignment guides
      let guides;
      if (this.alignmentHelper && (snapToObjects || snapToGrid)) {
        const smartSnappingThreshold = secretOptions.smartSnappingThreshold;
        const gridSnappingThreshold = secretOptions.gridSnappingThreshold;
        let snap = this.alignmentHelper.update(dxPixels, dyPixels, {
          snapToObjects,
          snapToGrid,
          gridSnappingThreshold,
          smartSnappingThreshold,
        });
        [targetX, targetY] = snap.applyTo(targetX, targetY);

        // save alignment guides
        guides = snap.guides;
      }

      // convert to canvas units
      const scale = viewport.screenToCanvasTransform[0][0];
      const x = targetX * scale;
      const y = targetY * scale;

      // save the current values
      this.x = targetX;
      this.y = targetY;

      if (updateUI) {
        const ui = UIState.update({
          translate: true,
          groups: {},
          alignmentGuides: guides,
        });

        // include each group
        for (const id of this.selectedGroupIds) {
          ui.groups[id] = {
            translate: { x, y },
          };

          ui.translate = { x, y };
        }

        ui.apply();
      }
    }
  }

  // creates a manifest of changes
  resolve() {
    const { x, y, useSelector } = this;
    const viewport = useSelector(selectViewport);
    const transformVal = viewport.canvasToScreenTransform;

    // check for which groups need the transform
    const changes = {};
    const translate = new Point(x, y);
    for (const selectedGroupId of this.selectedGroupIds) {
      if (changes[selectedGroupId] === undefined) {
        // invert the scale
        const inverse = invert(transformVal);
        inverse[0][2] = 0;
        inverse[1][2] = 0;

        // get the new point
        const point = transform(translate, inverse);
        changes[selectedGroupId] = point;
      }
    }

    // clear UI layer changes
    UIState.reset();

    // apply the changes
    const updateBatch = Object.keys(changes).map((groupChangeKey) => ({
      id: groupChangeKey,
      key: 'relative_position',
      value: changes[groupChangeKey],
    }));

    // apply updates
    const update = this.createAction(UpdateSvgGroupAction);
    update.apply(updateBatch);
  }
}
