import React from 'react';
import TranslationText from '@/Components/TranslationText/TranslationText';

export default function DashboardLauncher() {
  return (
    <div className='application-menu--dashboard-launcher'>
      <div className='application-menu--dashboard-launcher--title'>
        <TranslationText i18nKey='go-to'>Go to</TranslationText>
      </div>
    </div>
  );
}
