import { logoutShaperHub } from '@/ShaperHub/ShaperHubThunks';
import { toggleLoginMenu } from '@/Redux/Slices/UISlice';
import { FEATURE_MODES } from '../Constants/Subscriptions';
import { setFeaturesMode } from '../Redux/Slices/UISlice';
import ClearCanvasAction from './ClearCanvas';
import ModalActions from './Modal';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { setSandboxMode } from '@/Redux/Slices/SyncSlice';
import { disconnect } from '@/Sync/SyncThunks';
import { reset } from '@/Redux/Slices/CanvasSlice';

export default class LogOutAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.clearCanvasAction = new ClearCanvasAction(dispatch);
    this.updateWorkspaceOptions = new UpdateWorkspaceOptionsAction(dispatch);
    this.modalActions = new ModalActions(dispatch);
  }

  logOut = async () => {
    const { dispatch } = this;

    //When logging out, need to revert to sandbox mode.
    //This means the user loses access to the current workspace, because this is controlled by the sync service
    //The use can access this again by logging in and opening the workspaceId
    dispatch(toggleLoginMenu(false));
    dispatch(disconnect());
    this.updateWorkspaceOptions.update({ showApplicationMenu: false });
    await dispatch(logoutShaperHub());
    await dispatch(reset());
    // this.clearCanvasAction.clearCanvasAndHistory();

    await dispatch(setSandboxMode());
    this.modalActions.openSignInModal();
    await dispatch(setFeaturesMode(FEATURE_MODES.DEMO));
  };
}
