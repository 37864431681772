import React from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';

export default function ViewOnlyModal() {
  const modalActions = useAction(ModalActions);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  return (
    <Modal className='view-only-modal'>
      <div className='view-only-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='sync-problem' />
            <TranslationText i18nKey='view-only'>View Only</TranslationText>
          </Modal.Title>
          <div className='view-only-modal--info'>
            <TranslationText i18nKey='view-only-info'>
              You might have another instance of this file open somewhere else.
            </TranslationText>
          </div>
          <div className='view-only-modal--info'>
            <TranslationText i18nKey='view-only-info-2'>
              ‘View only’ instances will automatically update to reflect the
              changes you make in active instances.
            </TranslationText>
          </div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
