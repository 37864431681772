// checks each element within an event to see if
// it matches any selector
// NOTE: Normally I'd use closest, but for some reason React will
// detatch and element before it's had a chance to finish bubbling
// so it's impossible to get all parent refs
Event.prototype.isWithin = function (selector) {
  const path = this.path || this.composedPath();
  for (const item of path) {
    if ('matches' in item && item.matches(selector)) {
      return true;
    }
  }

  return false;
};
