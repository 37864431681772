import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// helpers
import { unitToFormattedDisplayUnit } from '@/Geometry/UnitOps';
import { uniq } from 'lodash';

// selectors
import { selectSelectedPaths } from '@/Redux/Slices/SelectionSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateSvgPathAction from '@/Actions/UpdateSvgPath';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';

// Constants
import { BIT_DIAMETER_PRESETS } from '@/Constants/UI';
import { unitDefaults } from '../../../../defaults';
import TranslationText from '../../../TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';

export default function BitDiameterSelector() {
  // selectors
  const displayUnits = useSelector(selectDisplayUnits);
  const selectedPaths = useSelector(selectSelectedPaths);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const { t, i18n } = useTranslation();

  //TODO - select between imperial or metric tool diameters for the preset values based on the display units and adjust button icons below accordingly

  // actions
  const updateSvgPathOperations = useAction(UpdateSvgPathAction);

  // computed
  const selectedToolDias = selectedPaths.map((sel) =>
    unitToFormattedDisplayUnit(sel.path.cutParams.toolDia, displayUnits)
  );

  // if there are no selected diameters, use the default
  if (!selectedToolDias.length) {
    const result = unitToFormattedDisplayUnit(`0.125in`, displayUnits);
    selectedToolDias.push(result);
  }

  const isMixed = uniq(selectedToolDias).length > 1;
  const bitDiameter = isMixed
    ? i18n.exists('mixed')
      ? t('mixed')
      : 'mixed'
    : toFormattedDisplayUnitValue(selectedToolDias[0], { preformatted: true });

  // presets are stored as mm
  function usePreset(value) {
    updateSvgPathOperations.setBitDiameter(selectedPaths, `${value}mm`);
  }

  // handlers
  function onCommit(input) {
    //ignore characters that aren't numbers (e.g. you can't type '1in' or '5mm' to override display units right now)
    const inputFloat = parseFloat(input);
    if (!isNaN(inputFloat)) {
      //Tool diameter is stored as a string with in or mm appended at the end. "####in" or "####mm"
      updateSvgPathOperations.setBitDiameter(
        selectedPaths,
        `${inputFloat}${displayUnits}`
      );
    }
  }

  function renderPresets() {
    const useMM = /mm/i.test(displayUnits);

    return BIT_DIAMETER_PRESETS.map((preset, idx) => {
      const { d, mm, engrave, in: inch } = preset;
      const value = useMM ? mm : d ? unitDefaults.metric / d : inch;

      let label = useMM ? mm : d ? `1&frasl;${d}` : inch;
      label = label.toString().replace(/^0/, '');

      // determine what to show
      const attrs = { value, onClick: usePreset };
      if (engrave) {
        attrs.icon = `bit-diameter-${label.replace(/^\./, '')}`;
        label = null;
      } else {
        label = <div dangerouslySetInnerHTML={{ __html: label }} />;
      }

      // show the button
      return (
        <FloatingPanel.Button key={idx} {...attrs}>
          {label}
        </FloatingPanel.Button>
      );
    });
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='bit-diameter'>
        <TranslationText i18nKey='bit-diameter'>Bit Diameter</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        calculate
        value={bitDiameter}
        onCommit={onCommit}
        suffix={displayUnits}
        precision={4}
      />

      <FloatingPanel.ButtonSet>{renderPresets()}</FloatingPanel.ButtonSet>
    </FloatingPanel.Group>
  );
}
