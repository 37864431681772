import React from 'react';

let SVG_CACHE = {};

// resets the cache
export function clearCache() {
  SVG_CACHE = {};
}

// gets a shape from the document
export function getSvgShape(extractAction, id, ...params) {
  let shape = SVG_CACHE[id];

  // if not yet cached, generate it now
  if (!shape) {
    const extracted = extractAction(id, ...params);

    // the preview couldn't be located (possibly a new shape
    // so it will be skipped for now)
    if (!extracted) {
      return null;
    }

    // try to make the preview
    const { el, markup } = extracted;
    const bounds = el.getBBox();
    const expandBy = 0.33;
    const viewBox = `${bounds.x - expandBy} ${bounds.y - expandBy} ${
      bounds.width + expandBy * 2
    } ${bounds.height + expandBy * 2}`;

    // TEMP: remove rotation and transforms for now
    // to fix issue with Selection Editor
    // const rotation = 0;
    // const scale = 1;

    // console.log('extr', extracted)

    // // find the rotation
    // const matrix = el.getCTM();
    // const rotation = Math.atan2(-matrix.b, matrix.d) * -(180 / Math.PI);

    // const markup =

    // // adjust scaling to make sure it fits
    // let scale = 1 - (45 / Math.abs((rotation + 45) % 45)) * 0.05;
    // if (!isFinite(scale)) {
    //   scale = 1;
    // }

    // create the shape
    shape = (
      <svg viewBox={viewBox}>
        <g
          dangerouslySetInnerHTML={{ __html: markup }}
          // transform={`scale(${scale}) rotate(${rotation})`}
        />
      </svg>
    );

    SVG_CACHE[id] = shape;
  }

  return shape;
}

export function extractSvgGroup(id) {
  const viewport = document.body.querySelector('svg.ui-viewport');
  const el = viewport.querySelector(id);
  if (!el) {
    return null;
  }

  let cloned = [];
  for (const path of el.querySelectorAll('.pathGroup')) {
    cloned.push(path.outerHTML);
  }

  for (const path of el.querySelectorAll('.pathGroup2')) {
    cloned.push(path.outerHTML);
  }

  // cloned.reverse();
  const markup = cloned.join('\n');

  return { el, markup };
}

export function extractPath(id) {
  const el = document.body.querySelector(id);
  const markup = el.outerHTML;
  return { el, markup };
}
