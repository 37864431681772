import { isNumber } from './numeric';

export default function pluralize(parts, ...args) {
  return parts.reduce((prev, current, index) => {
    const arg = args[index - 1];

    // nothing to change
    if (!isNumber(arg)) {
      return `${prev}${arg}${current}`;
    }

    // get the word to pluralize
    const [leadingSpaces] = current.match(/ +/);
    let [word, ...rest] = current.substr(leadingSpaces.length).split(/ /);

    // override scenarios
    // if (word === '???') {
    //   word = '???s';
    // } else if

    // simplified pluralization
    if (arg !== 1) {
      if (/(s|ss|sh|ch|x|z)$/i.test(word)) {
        word += 'es';
      } else {
        word += 's';
      }
    }

    // merge as required
    if (rest) {
      word += ` ${rest.join(' ')}`;
    }

    // merge back together
    return `${prev}${arg}${leadingSpaces}${word}`;
  });
}
