import { SerializedError } from '@reduxjs/toolkit';
import { logError } from './SyncLog';

export type SyncLayer = 'api' | 'listener' | 'thunk' | 'action';

export type ErrorTypes = Error | SerializedError | string;

export type SyncErrors =
  | 'sequence_taken'
  | 'network_error'
  | 'object_not_found'
  | 'forbidden'
  | 'unknown_workspace'
  | 'generic_error'
  | 'bad_patches'
  | 'invalid-version'
  | 'immer';

export class SyncError extends Error {
  private type: SyncErrors;
  private layer: SyncLayer;
  private msg: string;
  private originalError?: ErrorTypes;
  private context?: object;

  constructor(
    type: SyncErrors,
    layer: SyncLayer,
    message: string,
    error?: ErrorTypes,
    context?: object
  ) {
    super(type);
    this.type = type;
    this.layer = layer;
    this.msg = message;
    if (error) {
      this.originalError = error;
    }
    if (context) {
      this.context = context;
    }
    Object.setPrototypeOf(this, SyncError.prototype);
    const errorMsg = `Sync error ${type}: ${this.msg}`;
    const errorContext = {
      errorType: this.type,
      ...this.context,
    };
    if (this.originalError) {
      logError(errorMsg, errorContext, this.originalError);
    } else {
      logError(errorMsg, errorContext);
    }
  }

  getMessage() {
    return `Error ${this.type} in ${this.layer}: ${this.msg}`;
  }

  get() {
    return this;
  }
}
