import * as classList from '@/Utility/classList';

class CursorComponent {
  constructor() {
    this.mode = 'default';
  }

  replaceCursor(mode) {
    let cursor = mode;

    // if a mode object was passed
    if (cursor?.mode) {
      cursor = cursor.mode;
    }

    if (typeof cursor !== 'string') {
      // eslint-disable-next-line
      cursor = 'default';
    }

    this.mode = cursor;
    classList.replace(document.body, 'cursor', this.mode || 'default');
  }

  setCursorMode(newMode = 'default') {
    if (this.mode === newMode) {
      return;
    }

    this.mode = newMode;
    this.replaceCursor(newMode);
  }

  clearCursorMode() {
    this.setCursorMode();
  }
}

const cursorComponent = new CursorComponent();

export { cursorComponent as default };
