import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export default function InputField(props) {
  const inputRef = useRef();
  const [value, setValue] = useState(props.value);

  const inputMode = props.inputMode ?? 'decimal';
  const type = props.type ?? 'text';

  // gets the value from the input
  function getValue(event) {
    let { value: newValue } = event.target;
    if (props.onBeforeUpdate) {
      newValue = props.onBeforeUpdate(newValue);
    }

    return newValue;
  }

  function applyTo(event, func) {
    const updated = getValue(event);
    if (func) {
      func(updated);
    }
    setValue(updated);
  }

  function onFocus() {
    if (props.selectAllOnFocus) {
      // wait a moment for the click event to end
      setTimeout(() => inputRef.current.select());
    }
  }

  function onKeyUp(event) {
    if (props.onCommit && event.keyCode === 13) {
      applyTo(event, props.onCommit);
    }

    event.stopPropagation();
  }

  // handle on input
  const onInput = (event) => applyTo(event, props.onInput);
  const onChange = (event) => applyTo(event, props.onChange);
  const onBlur = (event) => applyTo(event, props.onBlur);

  // watch for changes
  useEffect(() => setValue(props.value), [props.value, props.forceReset]);

  const inputCx = classNames(`component--input-field`, {
    'with-icon': props.icon,
    'with-unit': props.unit,
    mini: /m|mini/i.test(props.size),
    small: /s|small/i.test(props.size),
    large: /l|large/i.test(props.size),
    full: /f|full/i.test(props.size),
  });

  return (
    <div className={inputCx}>
      {props.icon && (
        <div className='component--input-field--icon'>{props.icon}</div>
      )}
      <input
        ref={inputRef}
        inputMode={inputMode}
        value={value}
        type={type}
        placeholder={props.placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onInput={onInput}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
      />
      {props.unit && (
        <div className='component--input-field--unit'>{props.unit}</div>
      )}
    </div>
  );
}
