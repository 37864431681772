import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toDegrees } from '@/Helpers/utils';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';

export default function Layer(props) {
  const nspf =
    props.nspf ?? useSelector(selectNonScalingPixelFactor, shallowEqual);

  // check props
  let {
    rotation,
    degrees,
    x,
    y,
    stretchMatrix,
    scale,
    scaleX,
    scaleY,
    children,
    className,
    id,
    svgGroup,
  } = props;
  const normalizeScale = 'normalizeToViewport' in props;
  const hasX = !isNaN(x);
  const hasY = !isNaN(y);
  const hasScaleX = !isNaN(scaleX) && scaleX !== 1;
  const hasScaleY = !isNaN(scaleY) && scaleY !== 1;
  const hasScale = (hasScaleX && hasScaleY) || !isNaN(scale);
  const hasRotation = !isNaN(rotation);
  const hasStretchMatrix = !!stretchMatrix;
  let hasDegrees = !isNaN(degrees);

  let layer =
    svgGroup !== undefined ? (
      <g dangerouslySetInnerHTML={{ __html: svgGroup }} />
    ) : (
      children
    );

  // get the rotation, if any
  if (hasRotation) {
    degrees = toDegrees(rotation);
    hasDegrees = true;
  }

  // check for scaling
  if (hasScale || normalizeScale) {
    if (isNaN(scale)) {
      scale = 1;
    }

    if (isNaN(scaleX)) {
      scaleX = scale;
    }

    if (isNaN(scaleY)) {
      scaleY = scale;
    }

    // update the scaling
    if (normalizeScale) {
      scaleX *= nspf;
      scaleY *= nspf;
    }

    // display the content
    layer = <g transform={`scale(${scaleX}, ${scaleY})`}>{layer}</g>;
  }

  // display the content
  if (hasStretchMatrix) {
    const [a, c, e] = stretchMatrix[0];
    const [b, d, f] = stretchMatrix[1];
    const apply = [a, b, c, d, e, f].join(',');

    layer = (
      <g id='stretch-mtx' transform={`matrix(${apply})`}>
        {layer}
      </g>
    );
  }

  // apply the rotation
  if (hasDegrees) {
    layer = (
      <g id='rotate' transform={`rotate(${degrees})`}>
        {layer}
      </g>
    );
  }

  // check for coordinates
  if (hasX || hasY) {
    layer = (
      <g id='translate' transform={`translate(${x || 0}, ${y || 0})`}>
        {layer}
      </g>
    );
  }

  if (className) {
    if (id) {
      layer = (
        <g data-cy='layer' id={`layer-${id}`} className={className}>
          {layer}
        </g>
      );
    } else {
      layer = (
        <g id={`layer-${id}`} className={className}>
          {layer}
        </g>
      );
    }
  }

  // displays the layer
  return layer;
}
