import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { unitToMMNum } from '@/Geometry/UnitOps';

// util
import { asFloat } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import ResizeGroupsAction from '@/Actions/ResizeGroups';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';
import { selectToFormattedDisplayUnitValue } from '@/Redux/Slices/SherpaContainerSlice';
import { xor } from 'lodash';
import MirrorAction from '@/Actions/Mirror';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';
import classNames from 'classnames';

export default function SizeEditor(props) {
  const mirror = useAction(MirrorAction);
  const selectedGroups = useSelector(selectSelectedGroups);
  const displayUnits = useSelector(selectDisplayUnits);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const selectionBounds = useSelector(selectSelectionBounds);
  const { t, i18n } = useTranslation();
  const [selectedGroup] = selectedGroups;

  // state
  const [lockAspectRatio, setLockAspectRatio] = useState();
  const [currentSelection, setCurrentSelection] = useState([]);

  // actions
  const resizeAction = useAction(
    ResizeGroupsAction,
    selectedGroups,
    'centroid'
  );

  // computed
  const displayWidth = toFormattedDisplayUnitValue(selectionBounds.width);
  const displayHeight = toFormattedDisplayUnitValue(selectionBounds.height);
  const widthTranslation = i18n.exists('width') ? t('width') : 'W';
  const heightTranslation = i18n.exists('height') ? t('height') : 'H';
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // handle resize updates
  function onResize({ width, height }) {
    const newWidth = !isNaN(width) ? unitToMMNum(width, displayUnits) : null;
    const newHeight = !isNaN(height) ? unitToMMNum(height, displayUnits) : null;

    let scaleX = newWidth / selectionBounds.width;
    let scaleY = newHeight / selectionBounds.height;

    if (!width) {
      scaleX = 1;
    }

    if (!height) {
      scaleY = 1;
    }

    // ensure that when locking aspect ratio, the other side is
    // scaled as expected
    if (height && lockAspectRatio) {
      scaleX =
        (selectionBounds.width * Math.abs(scaleY)) / selectionBounds.width;
    } else if (width && lockAspectRatio) {
      scaleY =
        (selectionBounds.height * Math.abs(scaleX)) / selectionBounds.height;
    }

    if (isNaN(scaleX) || !isFinite(scaleX)) {
      scaleX = 1;
    }

    if (isNaN(scaleY) || !isFinite(scaleY)) {
      scaleY = 1;
    }

    resizeAction.resizeByScale(scaleX, scaleY, lockAspectRatio);
    resizeAction.resolve();

    // negative resize
    if (width < 0) {
      mirror.mirrorAnchor(selectedGroups, { horizontal: true });
    }

    if (height < 0) {
      mirror.mirrorAnchor(selectedGroups, { vertical: true });
    }
  }

  // setup some handlers for resizing
  const onSetWidth = (input) => onResize({ width: asFloat(input) });
  const onSetHeight = (input) => onResize({ height: asFloat(input) });
  const onToggleAspectRatio = () => {
    if (!disabled) {
      setLockAspectRatio(!lockAspectRatio);
    }
  };

  // make sure to update the lock toggle if the selection changes
  useEffect(() => {
    const ids = props.selectedGroups.map((item) => item.id);
    const diff = xor(ids, currentSelection);

    // if the selection has changed
    if (diff.length > 0) {
      setLockAspectRatio(props.lockAspectRatio);
      setCurrentSelection(ids);
    }
  }, [props.lockAspectRatio, props.selectedGroups]);

  const aspectRatioCx = classNames('properties-panel--aspect-ratio', {
    active: lockAspectRatio,
    disabled: disabled,
  });

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='size'>
        <TranslationText i18nKey='size'>Size</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        calculate
        prefix={widthTranslation}
        onCommit={onSetWidth}
        suffix={displayUnits}
        number
        value={displayWidth}
        onAfterCommit={({ target }) => {
          target.textContent = target.textContent.replace(/^-/, '');
        }}
        disabled={disabled}
      />
      <FloatingPanel.Input
        calculate
        prefix={heightTranslation}
        onCommit={onSetHeight}
        suffix={displayUnits}
        number
        value={displayHeight}
        onAfterCommit={({ target }) => {
          target.textContent = target.textContent.replace(/^-/, '');
        }}
        disabled={disabled}
      />
      <div className={aspectRatioCx} onClick={onToggleAspectRatio}>
        <Icon
          icon={lockAspectRatio ? 'aspect-ratio' : 'aspect-ratio-disabled'}
        />
      </div>
    </FloatingPanel.Group>
  );
}
