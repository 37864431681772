import { SupportedBrowser } from '@shapertools/supported-browsers';
import minimumSupportedBrowsers from '@/supportedBrowsers.js';
import * as Sentry from '@sentry/react';
import { BrowserObject } from '@/@types/shaper-types';

export const handleUnsupportedBrowser = (
  supportedBrowser: SupportedBrowser
) => {
  const browserName = supportedBrowser.result.browser.name;
  const browserNumber = (minimumSupportedBrowsers as BrowserObject)[
    browserName
  ];

  if (!browserNumber) {
    // send a sentry error if the browser is not supported but isn't flagged as having minimum requirements
    Sentry.withScope(() => {
      Sentry.setContext('Browser Info', supportedBrowser);
      Sentry.captureException(
        new Error(`Unknown incompatible browser for user`)
      );
    });
  } else {
    const versionNumber = Number(
      supportedBrowser.result?.browser?.major?.split('.')[0]
    );
    if (!isNaN(versionNumber) && versionNumber >= browserNumber) {
      // send a sentry error if the browser is not supported BUT meets minimum requirements
      Sentry.withScope(() => {
        Sentry.setContext('Browser Info', supportedBrowser);
        Sentry.captureException(
          new Error(
            `Unknown incompatible browser for user, despite meeting minimum requirements`
          )
        );
      });
    }
  }
};
