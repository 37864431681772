export const MAX_ZOOM_LEVEL = 5;
export const MIN_SCALE_MULTIPLE = 0.1;
export const MAX_SCALE_MULTIPLE = 10.0;
export const SCALE_INCREMENT = 0.1;
export const MINIMUM_DRAG_THRESHOLD_DISTANCE = 5;
export const MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY = 15;
export const BOUNDING_BOX_HANDLE_DISTANCE =
  MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY;
export const TOUCH_AMPLIFY_MODIFIER = 2.5;
export const NUDGE_BONUS = 10;
export const SECONDARY_ACTION_ACTIVATION_TIME_MS = 500;
export const SELECTION_NET_STROKE_WIDTH = 2;
export const GROUP_PATH_STROKE_WIDTH = 2;
export const PATH_STROKE_DASH_LENGTH = 5;
export const SHAPE_BUILDER_FILL_ACTIVATION_TIME = 100;

export const RESIZE_HANDLE_DISTANCE = 5;
export const ROTATION_HANDLE_DISTANCE = 25;
export const PLAN_MODE_MINIMUM_STROKE_DETECTION_WIDTH = 2;
export const EDGE_HIT_DETECTION_EXPANSION = 24;

export const SIZE_TO_HIDE_BOUNDING_BOX_HANDLE = 56;
export const SIZE_TO_INVERT_HIDDEN_BOUNDING_BOX_HANDLE = 40;

export const SELECTION_EDITOR_HOVER_STATE_THRESHOLD = 400;

export const FIT_TO_VIEW_PADDING = 0.15;

export const MOUSE_DOWN_LONG_PRESS_TIMING = 1000;
export const TOUCH_START_LONG_PRESS_TIMING = 750;

// key codes - Browser support for keyCode is deprecated. Use code below instead
// export const LEFT_ARROW = 37;
// export const UP_ARROW = 38;
// export const RIGHT_ARROW = 39;
// export const DOWN_ARROW = 40;
// export const SPACE_BAR = 32;
// export const SHIFT_KEY = 16;
// export const DELETE_KEY = 46;
// export const BACKSPACE_KEY = 8;
// export const ESC_KEY = 27;

export const LEFT_ARROW = { code: 'ArrowLeft', key: 'ArrowLeft' };
export const UP_ARROW = { code: 'ArrowUp', key: 'ArrowUp' };
export const RIGHT_ARROW = { code: 'ArrowRight', key: 'ArrowRight' };
export const DOWN_ARROW = { code: 'ArrowDown', key: 'ArrowDown' };
export const SPACE_BAR = { code: 'Space', key: ' ' };
export const SHIFT_LEFT_KEY = { code: 'ShiftLeft', key: 'Shift' };
export const SHIFT_RIGHT_KEY = { code: 'ShiftRight', key: 'Shift' };
export const DELETE_KEY = { code: 'Delete', key: 'Delete' };
export const BACKSPACE_KEY = { code: 'Backspace', key: 'Backspace' };
export const ESC_KEY = { code: 'Escape', key: 'Escape' };
export const ENTER_KEY = { code: 'Enter', key: 'Enter' };
export const DUPLICATE_KEY = { code: 'KeyD', key: 'd' };
export const SELECT_ALL_LC = { code: 'a', key: 'a' };
export const SELECT_ALL_UC = { code: 'a', key: 'A' };

export const UNDO_KEY_LC = { code: 'z', key: 'z' };
export const UNDO_KEY_UC = { code: 'z', key: 'Z' };
export const REDO_LEGACY_KEY_LC = { code: 'y', key: 'y' };
export const REDO_LEGACY_KEY_UC = { code: 'y', key: 'Y' };
export const REDO_KEY_LC = { code: 'z', key: 'z' };
export const REDO_KEY_UC = { code: 'z', key: 'Z' };

export const FIT_TO_VIEW_KEY = { code: 'o', key: 'o' };

export const SHAPE_TYPE_ROUNDED_RECTANGLE = 'rounded_rectangle';
export const SHAPE_TYPE_CIRCLE = 'circle';
export const SHAPE_TYPE_POLYGON = 'polygon';

export const TOOL_TYPE_NONE = 'none';
export const TOOL_TYPE_ICON = 'icon';
export const TOOL_TYPE_ROUNDED_RECTANGLE = SHAPE_TYPE_ROUNDED_RECTANGLE;
export const TOOL_TYPE_CIRCLE = SHAPE_TYPE_CIRCLE;
export const TOOL_TYPE_POLYGON = SHAPE_TYPE_POLYGON;
export const TOOL_TYPE_TEXT = 'text-insert';

// types of shapes that can be created as tools
export const GENERATED_SHAPE_TYPES = [
  SHAPE_TYPE_CIRCLE,
  SHAPE_TYPE_ROUNDED_RECTANGLE,
  SHAPE_TYPE_POLYGON,
];

export const SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE = [
  SHAPE_TYPE_ROUNDED_RECTANGLE,
];

export const TOOL_TYPES = [
  TOOL_TYPE_ROUNDED_RECTANGLE,
  TOOL_TYPE_CIRCLE,
  TOOL_TYPE_POLYGON,
  TOOL_TYPE_TEXT,
];

export const GROUP_ANCHORS = [
  'tl',
  'tm',
  'tr',
  'lm',
  'center',
  'rm',
  'bl',
  'bm',
  'br',
];

export const BOUNDING_BOX_HANDLES = [
  'tr',
  'bl',
  'tl',
  'br',
  'tm',
  'bm',
  'rm',
  'lm',
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const RESIZE_HANDLES = ['tr', 'bl', 'tl', 'br', 'tm', 'bm', 'rm', 'lm'];

export const ROTATION_HANDLES = [
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const BOUNDING_BOX_HANDLE_RESIZE_DIRECTION = [
  'resize-ne',
  'resize-ne',
  'resize-sw',
  'resize-sw',
  'resize-ns',
  'resize-ns',
  'resize-ew',
  'resize-ew',
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const CUT_DEPTH_PRESETS = [
  { mm: 2, d: 16 },
  { mm: 5, d: 8 },
  { mm: 8, n: 3, d: 16 },
  { mm: 10, d: 4 },
  { mm: 12, n: 3, d: 8 },
  { mm: 15, d: 2 },
];

export const OFFSET_PRESETS = [
  { in: 0.001, mm: 0.02 },
  { in: 0.005, mm: 0.1 },
  { in: 0.01, mm: 0.5 },
  { d: 32, mm: 1 },
  { d: 16, mm: 2 },
  { d: 8, mm: 5 },
];

export const BIT_DIAMETER_PRESETS = [
  { in: 0.508, mm: 0.5, engrave: true },
  { d: 8, mm: 3 },
  { d: 4, mm: 6 },
];
