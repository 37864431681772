import React from 'react';
import classNames from 'classnames';

// button types
import MaterialButton from '@material-ui/core/Button';
import MaterialIconButton from '@material-ui/core/IconButton';

// styling
import { isString } from 'lodash';
import Icon from '@/Styles/Icons/Icon';
import { Tooltip } from '@/Components/Tooltip/Tooltip';

export default function Button(props) {
  // adjust incoming props
  let icon = props.endIcon || props.startIcon || props.icon;
  const { size, children, active, disabled, className, startIcon } = props;
  const config = { ...props };

  // remove Material UI specific props
  delete config.className;
  delete config.size;
  delete config.active;

  // resolve icons as needed
  if (isString(icon)) {
    icon = <Icon icon={icon} />;
  }

  // check for prop values
  const isEmpty = !children;
  const hasIcon = !!(icon || startIcon);
  const onlyIcon = hasIcon && isEmpty;
  const isActive = !!active;
  const isDisabled = !!disabled;

  // no children content
  if (isEmpty && hasIcon) {
    config.children = icon || startIcon;
  }
  // has content, like label, and an icon
  else if (!isEmpty && icon) {
    config['endIcon' in props ? 'endIcon' : 'startIcon'] = icon;
    delete config.icon;
  }

  // determine styles
  const buttonCx = classNames(
    'component__button',
    size,
    {
      'icon-only': onlyIcon,
      disabled: isDisabled,
      empty: isEmpty,
      active: isActive,
    },
    className
  );

  //Added component='span' to enable child file <input/>
  const display = !onlyIcon ? (
    <MaterialButton
      component='span'
      tabIndex={-1}
      className={buttonCx}
      {...config}
    />
  ) : (
    <MaterialIconButton tabIndex={-1} className={buttonCx} {...config} />
  );

  return Tooltip.wrap(props.tooltip, props.side, display);
}
