import React from 'react';
import Button from '@/Components/Button';
import SadBrowser from './sadBrowser2x.svg';

// loads the main scss file
import './BrowserError.scss';

function BrowserError() {
  return (
    <div className='browser-error'>
      <div className='browser-error--img-container'>
        <img src={SadBrowser} alt='Sad Browser' />
      </div>
      <div className='browser-error--column-gap' />
      <div className='browser-error--text-container'>
        <div className='browser-error--header-text'>
          This browser is not supported by Studio
        </div>
        <Button
          style={{
            height: '40px',
            marginTop: '24px',
            marginBottom: '16px',
            boxSizing: 'borderBox',
          }}
          icon='erase'
          className='browser-error--link-button pill error full'
          onClick={() =>
            window.open(
              'https://support.shapertools.com/hc/articles/8445650593819-Browser-Compatibility'
            )
          }
        >
          SEE SUPPORTED BROWSERS
        </Button>
        <div className='browser-error--body-text'>
          The browser you’re using is out of date and cannot run Studio. To use
          Studio, update this browser or try a different browser.
        </div>
      </div>
    </div>
  );
}

export default BrowserError;
