import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import SelectionNetInteraction from '@/InteractionsManager/Interactions/SelectionNet';
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
// import PanModeTranslateViewportInteraction from '@/InteractionsManager/Interactions/PanModeTranslateViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import TransformGroupsInteraction from '@/InteractionsManager/Interactions/TransformGroups';

// default actions
import ActivateAnchorEditInteraction from './Interactions/ActivateAnchorEdit';
import KeyboardShortcutsInteraction from './Interactions/KeyboardShortcuts';
import EmptyInteraction from './Interactions/Empty';
import PanAndZoomViewportInteraction from '../../Interactions/PanAndZoomViewport';
import TextEdit from './Interactions/TextEdit';

export default class DefaultMode extends InteractionMode {
  theme = 'default';

  getInteractionHandlers() {
    return [
      // PanModeTranslateViewportInteraction,
      SelectionNetInteraction,
      TranslateViewportInteraction,
      ActivateAnchorEditInteraction,
      KeyboardShortcutsInteraction,
      TransformGroupsInteraction,
      ZoomViewportInteraction,
      PanAndZoomViewportInteraction,
      EmptyInteraction,
      TextEdit,
    ];
  }
}
