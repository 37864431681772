import React from 'react';
import classNames from 'classnames';

// components
import Icon from '@/Styles/Icons/Icon';

export default function Toggle(props) {
  const children = React.Children.toArray(props.children);
  const values = children.map((child) => child.props.value);
  const index = children.findIndex(
    (child) => child.props.value === props.value
  );
  const active = index === 0;
  const side = active ? 'left' : 'right';

  // handle toggling
  function onToggle() {
    props.onToggle(!active ? values[0] : values[1]);
  }

  const toggleCx = classNames('mode-menu--toggle', side);

  return (
    <div className={toggleCx} onClick={onToggle}>
      {props.children}
    </div>
  );
}

Toggle.Item = function (props) {
  return (
    <div className='mode-menu--toggle-item'>
      {props.icon ? <Icon>{props.icon}</Icon> : props.children}
    </div>
  );
};
