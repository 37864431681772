//Singletons
import svgCache, { SvgCache } from './SvgCache';
import { ClipperOpsWorkerProxy } from '@/Geometry/ClipperOps';
import {
  selectSvgGroupSet,
  selectSvgGroupTs,
} from '@/Redux/Slices/CanvasSlice';
import { Store } from '@reduxjs/toolkit';
import { SvgGroup } from './sherpa-svg-generator/SvgGroup';
import { BasePath } from './sherpa-svg-generator/BasePath';
import { getId, transform } from './BasePathOps';
import { multiplyMatrix33 } from './sherpa-svg-generator/Matrix33';
import {
  getDepthClippedReviewPaths,
  getReviewPathDataAsync,
  getReviewPathSvg,
} from './CutPreviewOps';
import { RootState } from '@/Redux/store';

/*
  //Depth clipped SVG processing
  //getReviewPathCache calling both clipperOffset and depthClippedReviewPath calling booleans is very slow, so this has been rewritten to use clipper in a webworker with promises

  New store listener checks timestamp of cached depthClipped and max of basePaths cached.  If depth clipped is out of date, 
  *  dispatch setReviewLoader(true)
  *  kill current worker and initialize new one
  *  delete pending promises from prior calls to the listener
  *  run getDepthClippedSvg - This runs async, using promises to perform all clipperOps. Does not need to be a thunk, because cache is not in redux store
  *  when depthClippedSvg isc complete, svgCache is updated with new data to make it accessible via a selector to the <ReviewUI><SvgGroups></ReviewUI> React components
  *  dispatch setReviewLoader(false) so ReviewUI clears the loading icon and renders SvgGroups component. SvgGroups component retrieves depthClippedSvg via a Redux selector
*/

class ReviewPathGenerator {
  pendingPromises: {
    [key: number]: any;
  } = {};
  pendingTs = 0;
  nextId = 0;
  store?: Store;
  svgCache: SvgCache;
  clipperOpsHandler: any;
  busyReduxAction: string;

  constructor(cache: SvgCache, clipperOpsHandler: any) {
    this.svgCache = cache;
    this.clipperOpsHandler = clipperOpsHandler;
    this.busyReduxAction = '';
  }

  getPromiseId = () => this.nextId++;

  unsubscribeStore = () => {};

  unsubscribe() {
    this.unsubscribeStore();
  }

  setStore(store: Store) {
    this.store = store;

    //Need to explicitly bind this to update supervisor when passing class method to another function.
    this.unsubscribeStore = this.store.subscribe(
      this.updateReviewPaths.bind(this)
    );
  }

  //Placeholders - Replace with selector functions to access svgGroup and ts from current state
  selectSvgGroups = (state: RootState): SvgGroup[] => [];
  selectSvgGroupTs = (state: RootState): number[] => [];

  //Replace with selector function to access depth clipped reviewPath ts from current svgCache
  selectDepthSvgTs() {
    return (
      this.svgCache.getCachedPathTs('depthClippedPaths', 'all') ??
      Number.NEGATIVE_INFINITY
    );
  }

  getMaxGroupTs(state: any) {
    //Sum all group Ts to get unique depth clipped Ts. Because timestamps are monotonically increasing, any change in number or undo state of a group will give a different ts
    const groupTimestamps = this.selectSvgGroupTs(state);
    const timestamps = groupTimestamps.length === 0 ? [0] : groupTimestamps;

    return timestamps.reduce((acc, ts) => acc + ts);
  }

  updateReviewPathsAndDepthClippedSvg(mostRecentGroupTs: number) {
    const depthSvgTs = this.selectDepthSvgTs();
    return (
      depthSvgTs !== mostRecentGroupTs && this.pendingTs !== mostRecentGroupTs
    );
  }

  resetClipperWorker() {
    //Kill the worker and reload
    this.clipperOpsHandler.terminateAndReset();
    //Discard all pending promises, because the worker will never return invoke these
    this.pendingPromises = {};
  }

  getReviewPathCache(svgGroup: SvgGroup, basePath: BasePath) {
    const bpId = getId(basePath) as string;

    //Some and ideally most of the review paths are unchanged, so we can save time by only regenerating the ones we need
    const reviewPathGenFcn = () => {
      // console.log(`Updating review path data ${bpId}`);
      const { pathData: toolPathData } = this.svgCache.getToolPathCache(
        svgGroup,
        basePath
      );
      const { cutParams } = basePath;
      const { translateMtx, rotateMtx, tool } = svgGroup;

      //Review paths are combined into a depth-sorted and depth-segmented SVG, so need to rotate and translate these paths before combining with paths from other groups. Scaling has already been performed above.

      const TRMtx = multiplyMatrix33(translateMtx, rotateMtx);

      const TRPathData = transform(toolPathData, TRMtx) as BasePath[];

      //Strangely simplification has minimal impact, so skip for now
      // const simplifiedPathData = BasePathOps.simplifyPath(TRPathData, 0.01);

      // const reviewPathData = CutPathPreviewOps.getReviewPathData(simplifiedPathData, cutParams);

      // Return a promise from the worker handler
      return getReviewPathDataAsync(TRPathData, cutParams, tool).then(
        (reviewPathData) => {
          return reviewPathData.length === 0
            ? { pathData: [] }
            : { pathData: reviewPathData };
        }
      );
    };

    return this.svgCache.getCachedPathPromise(
      'reviewPaths',
      bpId,
      svgGroup.generatedTs,
      reviewPathGenFcn
    );
  }

  getReviewPathData(svgGroup: SvgGroup, basePath: BasePath) {
    return this.getReviewPathCache(svgGroup, basePath).then(
      (cacheLine: any) => cacheLine.pathData
    );
  }

  getDepthClippedSvg = async function (
    this: ReviewPathGenerator,
    svgGroupSet: SvgGroup[],
    timestamp: number
  ) {
    const reviewPathsDataPromises = [];
    for (const svgGroup of svgGroupSet) {
      for (const basePath of svgGroup.basePathSet) {
        const rpPromise = this.getReviewPathData(svgGroup, basePath);
        reviewPathsDataPromises.push(rpPromise);
        this.pendingPromises[this.getPromiseId()] = rpPromise;
      }
    }

    return Promise.all(reviewPathsDataPromises)
      .then((reviewPathsArr) => {
        const reviewPaths = reviewPathsArr.flat();
        return getDepthClippedReviewPaths(reviewPaths);
      })
      .then((pathData) => {
        const pathSvg = pathData.map((pd: any) =>
          getReviewPathSvg('depth-clipped-group', pd.id, pd.cutParams, [pd])
        );
        //Update the cache directly
        this.svgCache.setCachedPath('depthClippedPaths', 'all', timestamp, {
          pathData,
          pathSvg,
        });
      });
  };

  updateReviewPaths() {
    const state = this.store?.getState();
    const maxGroupTs = this.getMaxGroupTs(state);
    if (this.updateReviewPathsAndDepthClippedSvg(maxGroupTs)) {
      //Don't cancel current worker unless groups get updated after current maxTs
      //So save pendingTs to use in updateReviewPathsAndDepthClippedSvg
      this.pendingTs = maxGroupTs;

      //if any clipper jobs are pending, kill them and clear the promises
      this.resetClipperWorker();

      const nextId = this.getPromiseId();
      const depthClippedPromise = this.getDepthClippedSvg(
        this.selectSvgGroups(state),
        maxGroupTs
      ).then(() => {
        //Clear review loader
        this.store?.dispatch({ type: this.busyReduxAction, payload: false });
      });

      this.pendingPromises[nextId] = depthClippedPromise;
      //Set review loader
      this.store?.dispatch({ type: this.busyReduxAction, payload: true });
    }
  }
}

//Create singleton and config with related singletons and selectors
//Note reviewPathGenerator.busyReduxAction is set by the store with the name of the redux action to call to set and clear the reviewPathLoading icon
//This is done there to avoid circular import dependency

const reviewPathGenerator = new ReviewPathGenerator(
  svgCache,
  ClipperOpsWorkerProxy
);
//Supporting singletons
// reviewPathGenerator.svgCache = svgCache;
// reviewPathGenerator.clipperOpsHandler = ClipperOpsWorkerProxy;

//Selectors for accessing values from redux store used by the listener
reviewPathGenerator.selectSvgGroups = selectSvgGroupSet;
reviewPathGenerator.selectSvgGroupTs = selectSvgGroupTs;

//Now export this singleton to the store, so that it can be subscribed to store changes
export { reviewPathGenerator as default };
