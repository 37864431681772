import React from 'react';
import {
  mmToUnitFormattedNum,
  convertNumBetweenUnits,
} from '@/Geometry/UnitOps';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// util
import { asInteger } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import UpdateToolParamsAction from '@/Actions/UpdateToolParams';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';

export default function PolygonEditor() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const displayUnits = useSelector(selectDisplayUnits);
  const selectionBounds = useSelector(selectSelectionBounds);
  const [selectedGroup] = selectedGroups;

  // actions
  const updateToolsAction = useAction(UpdateToolParamsAction, selectedGroups);

  // computed
  const currentPoints = selectedGroup.tool?.params?.points || 0;
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // handle resize updates
  function onChange(newPoints) {
    const points = asInteger(newPoints, 10);

    // update
    if (isNumber(points)) {
      // TODO: not sure why, but we need to provide width and height in the units
      // currently selected to update the radius -- probably should remove that
      const width = mmToUnitFormattedNum(selectionBounds.width, displayUnits);
      const height = mmToUnitFormattedNum(selectionBounds.height, displayUnits);
      let circumradius = selectedGroup.tool?.params?.circumradius;

      // circumradius on the polygon tool params never seems to update to mm when
      // changing units so this does the conversion when in mm
      if (selectedGroup.tool?.params?.units !== displayUnits) {
        circumradius = convertNumBetweenUnits(
          selectedGroup.tool?.params?.circumradius,
          selectedGroup.tool?.params?.units,
          displayUnits
        );
      }

      updateToolsAction.apply('polygon', {
        points,
        inset: 0,
        width,
        height,
        circumradius,
        units: displayUnits,
      });
      updateToolsAction.resolve();
    }
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='sides'>
        <TranslationText i18nKey='sides'>Sides</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        onCommit={onChange}
        int
        value={currentPoints}
        min={3}
        calculate
        disabled={disabled}
        ignoreUnits
      />
    </FloatingPanel.Group>
  );
}
