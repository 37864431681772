import {
  createAction,
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import svgCache from '@/Geometry/SvgCache';
import { getCanvasSVG } from '@/Geometry/CanvasOps';
import { saveSvg } from '@/Utility/downloadBlob.js';

import { isArray, kebabCase, trim } from 'lodash';

import { CANVAS_DATA_VERSION } from '@/defaults';

import {
  selectIsDefaultMode,
  selectIsPlanMode,
  selectIsReviewMode,
} from '@/Redux/Slices/UISlice';
import { Canvas } from '@/Geometry/sherpa-svg-generator/Canvas';
import { getTypeProperty } from '@/Geometry/sherpa-svg-generator/PathTypes';
import { PatchSet } from '@/Sync/PatchGenerator';
import { RootState } from '@/Redux/store';
import { SvgGroup } from '@/Geometry/sherpa-svg-generator/SvgGroup';
import { applyPatches } from 'immer';

export interface CanvasState {
  version: string;
  canvas: Canvas;
  undo: {
    past: PatchSet[];
    future: PatchSet[];
  };
}

/* **************************************************************

IMPORTANT IMPORTANT IMPORTANT IMPORTANT IMPORTANT IMPORTANT

Any changes in the data schema for this slice MUST include a version bump and migration functions in migrateCanvasStore.

Otherwise, old workspaces cannot be loaded and may even become corrupted, leading to customer data loss. 

*****************************************************************
*/
export const canvasInitialState: CanvasState = {
  version: CANVAS_DATA_VERSION,
  canvas: new Canvas(),
  undo: {
    past: [],
    future: [],
  },
};

export const exportCanvasSvg = createAction('canvas/exportCanvasSvg');

export const slice = createSlice({
  name: 'canvas',
  initialState: canvasInitialState,
  reducers: {
    setCanvasState: (
      state,
      action: PayloadAction<CanvasState | null | undefined>
    ) => {
      return action.payload ?? state;
    },

    reset: () => {
      return canvasInitialState;
    },

    clearHistory: (state) => {
      state.undo = {
        past: [],
        future: [],
      };
    },

    rollbackCanvas: (state, action) => {
      try {
        return action.payload.reduce(
          (nextState: CanvasState, patchSet: PatchSet) =>
            applyPatches(nextState, patchSet.inversePatches),
          state
        );
      } catch (err) {
        console.error(err);
        return null;
      }
    },
  },
});

export const setCanvasFromSyncSnapshot = createAction<
  CanvasState | null | undefined
>('canvas/setCanvasFromSyncSnapshot');

export const { reset, clearHistory, setCanvasState, rollbackCanvas } =
  slice.actions;

export const middleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (isAction(action) && action.type && action.type.includes('canvas/')) {
      const store = getState();
      const { shaperHub, canvas } = store;
      if (exportCanvasSvg.match(action)) {
        const canvasSvgStr = getCanvasSVG(canvas.canvas);
        const name = trim(
          `${kebabCase(shaperHub.workspace?.name || 'Untitled')}.svg`
        );
        saveSvg(canvasSvgStr, /^\.svg/.test(name) ? 'Untitled.svg' : name);
      }
    }

    next(action);
  };

// utility functions/selectors
export const getGroupById = (state: RootState, groupId: string) => {
  const svgGroupSet = state.canvas?.canvas?.svgGroupSet;
  for (const group of svgGroupSet) {
    if (group.id === groupId) {
      return group;
    }
  }
  return null;
};

export const getPathById = (
  state: RootState,
  providedPathId: string,
  providedGroupId: string
) => {
  const pathId = isArray(providedPathId) ? providedPathId[0] : providedPathId;
  const groupId = isArray(providedGroupId)
    ? providedGroupId[0]
    : providedGroupId;

  const group = getGroupById(state, groupId);
  if (!group) {
    return null;
  }

  for (const path of group.basePathSet) {
    if (path.id === pathId) {
      return { path, group };
    }
  }
};

export const selectSvgGroupSet = (state: RootState) => {
  const mode = state.ui.mode;
  const svgGroupSetFilteredByMode = state.canvas?.canvas?.svgGroupSet.filter(
    (s: SvgGroup) => getTypeProperty(s.type, mode)
  );

  return svgGroupSetFilteredByMode;
};
export const selectSvgGroupTs = (state: RootState) =>
  selectSvgGroupSet(state).map((sg: SvgGroup) => sg.generatedTs);
export const selectCanvas = (state: RootState) => state.canvas?.canvas;
export const selectGetPathById =
  (state: RootState) => (pathId: string, groupId: string) =>
    getPathById(state, pathId, groupId);
export const selectGetGroupById = (state: RootState) => (groupId: string) =>
  getGroupById(state, groupId);
export const selectMostRecentlyAddedId = (state: RootState) => {
  const selectedSvgGroupSet = selectSvgGroupSet(state);
  return selectedSvgGroupSet[selectedSvgGroupSet.length - 1]?.id;
};
export const selectSvgGroupString = (group: SvgGroup) => (state: RootState) => {
  // Flags for new UI modes
  const isDefaultMode = selectIsDefaultMode(state);
  const isPlanMode = selectIsPlanMode(state);
  const isReviewMode = selectIsReviewMode(state);
  const { useCutPaths } = state.sherpaContainer.options;

  //Base paths are the paths of the original svgGroup as imported
  //Tool paths are the paths the cutting tool travels. For inside and outside cuts, these are offset from the basePaths
  //In Plan Mode, preview paths are the paths of material removed by the tool. These are the "wide" paths showing the result of cutting along a toolpath with a bit of a given size.
  //Review mode shows a fake 3D view of the cut material. Review paths are the paths to display in this mode. Review paths have line boundaries to show shadows and highlights, a CSS class for depth value, and overlapping paths are split by depth.
  const showBasePaths = isDefaultMode || isPlanMode; //Last condition is legacy
  const showToolPaths = isPlanMode;
  const showPlanPreviewPaths = isPlanMode;
  const showReviewPaths = isReviewMode;
  const showCutPaths = !!useCutPaths;

  return svgCache.getSvgGroupDisplaySvg(group, {
    showBasePaths,
    showToolPaths,
    showPlanPreviewPaths,
    showReviewPaths,
    showCutPaths,
  });
};

export const selectDepthModeSvg = (state: RootState) => {
  const isReviewMode = selectIsReviewMode(state);
  if (!isReviewMode) {
    throw new Error('Cannot render depth mode svg outside of review mode');
  }

  const svgGroupSet = selectSvgGroupSet(state);

  const depthClippedSvg = svgCache.getDepthClippedSvg(svgGroupSet);
  if (isArray(depthClippedSvg)) {
    return depthClippedSvg.join(' ');
  }
  return depthClippedSvg;
};

export const selectUndoState = (state: RootState) => ({
  canUndo: !!state.canvas.undo.past?.length,
  canRedo: !!state.canvas.undo.future?.length,
});

export const utils = {
  getPathById,
  getGroupById,
};

export const actions = slice.actions;
export default slice.reducer;
