import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';
import UIFeatureAction from '@/Actions/UIFeature';
import UIModeAction from '@/Actions/UIMode';
import { selectActiveUIState } from '@/Redux/Slices/UISlice';
import { closeHelpMenu } from '../../../../Utility/userflow';

export default class EmptyInteraction extends BaseInteraction {
  interactionId = 'Empty Interaction';

  onPointerUp() {
    // if text is present, ignore this interaction
    const { isShowingTextInsert } = this.useSelector(selectActiveUIState);
    if (isShowingTextInsert) {
      return;
    }

    // we need to ensure some menus always close
    const features = this.createAction(UIFeatureAction);
    features.toggleFindArt(false);
    features.toggleFileImport(false);
    features.toggleInsertShape(false);

    // hide the modes menu
    const modes = this.createAction(UIModeAction);
    modes.toggleOptionsMenu(false);
    closeHelpMenu();
  }
}
