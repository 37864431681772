import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TranslationText(props) {
  const { i18nKey, children, count } = props;

  const { t, ready, i18n } = useTranslation();

  const isEmpty = (str) => !str || !str.trim();
  const existsAndValid = ready
    ? (count ? i18n.exists(i18nKey, { count: count }) : i18n.exists(i18nKey)) &&
      !isEmpty(t(i18nKey))
    : false;

  return (
    <>
      {ready && i18nKey && existsAndValid
        ? count !== null
          ? t(i18nKey, { count: count })
          : t(i18nKey)
        : children
        ? children
        : ''}
    </>
  );
}
