import React from 'react';

//actions
import { useAction } from '@/Actions/useAction.js';
import ModalActions from '@/Actions/Modal';

//selectors
import { useDispatch } from 'react-redux';

//components
import Modal from '../Modal';
import Icon from '@/Styles/Icons/Icon';
import LoginForm from '../../LoginMenuContainer/LoginForm';
import { menuLinks, MODAL_TRIGGER_TYPES, signupURL } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';
import { createAccountAction } from '../../../Constants/Analytics';

export default function SignInModal() {
  const modalActions = useAction(ModalActions);
  const dispatch = useDispatch();

  const createAccount = async () => {
    await dispatch(createAccountAction);
    window.open(signupURL(), '_self');
  };

  return (
    <Modal className='sign-in-modal' dataCy='sign-in-modal'>
      <Modal.Panel position='left'>
        <Modal.Content>
          <div className='sign-in-modal--left--content-icon'>
            <Icon icon='shaper' />
          </div>
          <Modal.Title>
            <TranslationText i18nKey='sign-in-text'>
              Sign In to Shaper
            </TranslationText>
          </Modal.Title>
          <div className='sign-in-modal--left--content-form'>
            <LoginForm />
          </div>
          <div className='sign-in-modal--left--content-helper-text'>
            <span className='link' onClick={createAccount}>
              <TranslationText i18nKey='create-account'>
                No account? Create account
              </TranslationText>
            </span>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Modal.Button
            className='sign-in-modal--left--content-demo-button'
            onClick={() =>
              modalActions.closeModal(undefined, MODAL_TRIGGER_TYPES.TRY_DEMO)
            }
          >
            <span className='sign-in-modal--demo-button-text'>
              <TranslationText i18nKey='try-demo'>Try the Demo</TranslationText>
              <Icon icon='arrow-right' />
            </span>
          </Modal.Button>
          <a className='sign-in-modal--pricing-text' href={menuLinks.PRICING}>
            <TranslationText i18nKey='learn-more-login'>
              Learn more about pricing &amp; features
            </TranslationText>
          </a>
        </Modal.Footer>
      </Modal.Panel>
      <Modal.Panel position='right'>
        <Modal.Button
          className='sign-in-modal--demo-button'
          onClick={() =>
            modalActions.closeModal(undefined, MODAL_TRIGGER_TYPES.TRY_DEMO)
          }
        >
          <span
            className='sign-in-modal--demo-button-text'
            data-cy='try-demo-button'
          >
            <TranslationText i18nKey='try-demo'>Try the Demo</TranslationText>
            <Icon icon='arrow-right' />
          </span>
        </Modal.Button>
      </Modal.Panel>
    </Modal>
  );
}
