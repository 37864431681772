import { batch } from 'react-redux';
import { clearShapeBuilderFragments } from '@/Redux/Slices/ShapeBuilderSlice';
import { clearCanvas } from '@/CanvasContainer/CanvasActions';
import { clearHistory } from '@/Redux/Slices/CanvasSlice';

import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { setDocumentName } from '@/Redux/Slices/SherpaContainerSlice';
import { toggleLoginMenu } from '@/Redux/Slices/UISlice';
import { resetViewport } from '@/Redux/Slices/ViewportSlice';
import { defaultDocumentName } from '@/defaults';
import UIModeAction from './UIMode';
import { useActionWithDispatch } from './useAction';
import BaseAction from './BaseAction';

export default class ClearCanvasAction extends BaseAction {
  clear = (undoable = true) => {
    const { dispatch } = this;
    const uiModeAction = useActionWithDispatch(UIModeAction, dispatch);

    batch(() => {
      dispatch(clearCanvas({ undoable }));
      dispatch(clearSelection());
      dispatch(clearShapeBuilderFragments());
      uiModeAction.toDefault();
      dispatch(resetViewport());
      dispatch(setDocumentName(defaultDocumentName));
      dispatch(toggleLoginMenu(false));
    });
  };

  clearCanvasAndHistory = (undoable) => {
    batch(() => {
      this.clear(undoable);
      this.dispatch(clearHistory());
    });
  };
}
