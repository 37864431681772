import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectionBounds } from '@/Redux/Slices/SelectionSlice';

// create base 64 encoded versions of each cursor that can be rotated
import ResizeCursor from './Resize.svg?raw';
import RotateCursor from './Rotate.svg?raw';

const DELIMETER = '%ROTATION%';
// tracking icons
const icons = {};
let ready = false;

export async function setupIcon(key, source) {
  try {
    let svg = source;

    // this is a little hacky, but insert a delimeter so we can
    // add transform logic to this svg element later
    svg = svg.replace(/<g>/, `<g transform='rotate(${DELIMETER},12,12)' >`);

    // encode to a usable string
    svg = svg.replace(/"/g, "'");
    svg = svg.replace(/\t/g, ' ');
    svg = svg.replace(/\n/g, ' ');
    svg = svg.replace(/#/g, '%23');
    svg = svg.replace(/</g, '%3C');
    svg = svg.replace(/>/g, '%3E');
    svg = svg.replace(/E *%/g, 'E%');
    svg = svg.replace(/(^ *| *$)/g, '');

    // create the css value
    const css = `url("data:image/svg+xml,${svg}")`;

    // split at the delimeter so we can just join the segments
    // together to update as opposed to string replace
    const segments = css.split(DELIMETER);

    // save the icon segments
    icons[key] = segments;
  } catch {
    icons[key] = [];
  }
}

// request icons
(async () => {
  await setupIcon('resize', ResizeCursor);
  await setupIcon('rotate', RotateCursor);
  ready = true;
})();

// pre-encoded SVG for the rotation handle
const generateCss = (rotation) => `
  .cursor--rotate * {
    cursor: ${icons.rotate.join(rotation % 360)} 10 10, auto !important;
  }

  .cursor--rotate_tl * {
    cursor: ${icons.rotate.join((rotation % 360) - 45)} 10 10, auto !important;
  }

  .cursor--rotate_tr * {
    cursor: ${icons.rotate.join((rotation % 360) + 45)} 10 10, auto !important;
  }

  .cursor--rotate_br * {
    cursor: ${icons.rotate.join((rotation % 360) + 135)} 10 10, auto !important;
  }

  .cursor--rotate_bl * {
    cursor: ${icons.rotate.join((rotation % 360) - 135)} 10 10, auto !important;
  }

  .cursor--resize-ne *,
  .cursor--bl *,
  .cursor--tr * {
    cursor: ${icons.resize.join(rotation % 360)} 10 10, auto !important;
  }

  .cursor--resize-sw *,
  .cursor--tl *,
  .cursor--br * {
    cursor: ${icons.resize.join((rotation - 90) % 360)} 10 10, auto !important;
  }

  .cursor--resize-ns *,
  .cursor--tm *,
  .cursor--bm * {
    cursor: ${icons.resize.join((rotation - 45) % 360)} 10 10, auto !important;
  }

  .cursor--resize-ew *,
  .cursor--lm *,
  .cursor--rm * {
    cursor: ${icons.resize.join((rotation + 45) % 360)} 10 10, auto !important;
  }
`;

const ROTATION_HELPER_ID = 'rotation-cursor-helper';

// keeps track of the
let ref;

// changes the style for the rotation helper
export function updateRotationHandleCursorAngle(rotation = 0) {
  ref = ref || document.getElementById(ROTATION_HELPER_ID);
  const deg = Math.round((rotation % 360) / 10) * 10;
  ref.innerText = generateCss(deg);
}

export default function RotationHandleCursorHelper() {
  const bounds = useSelector(selectSelectionBounds);
  const rotation = (180 / Math.PI) * (bounds.rotation || 0);

  if (!ready) {
    return null;
  }

  return <style id={ROTATION_HELPER_ID}>{generateCss(rotation)}</style>;
}
