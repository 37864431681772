import { cancelEvent } from '@/Utility/events';

export function isEnterKey(event) {
  return event.keyCode === 13;
}

export function isEscKey(event) {
  return event.keyCode === 27;
}

export function isDeleteKey(event) {
  return event.keyCode === 46;
}

export function isBackspaceKey(event) {
  return event.keyCode === 8;
}

export function isArrowKey(event) {
  return (
    event.keyCode === 37 ||
    event.keyCode === 38 ||
    event.keyCode === 39 ||
    event.keyCode === 40
  );
}

export function isUpArrow(event) {
  return event.keyCode === 37;
}

export function isRightArrow(event) {
  return event.keyCode === 38;
}

export function isDownArrow(event) {
  return event.keyCode === 39;
}

export function isLeftArrow(event) {
  return event.keyCode === 40;
}

export function handleKey(event, props) {
  for (const [check, handler] of [
    [isEnterKey, 'enter'],
    [isEscKey, 'esc'],
    [isDeleteKey, 'delete'],
    [isBackspaceKey, 'backspace'],
    [isLeftArrow, 'left'],
    [isRightArrow, 'right'],
    [isUpArrow, 'up'],
    [isDownArrow, 'down'],
  ]) {
    // if matches
    if (handler in props && check(event)) {
      props[handler](event);
      break;
    }
  }

  // check for event handling
  if (props.capture) {
    cancelEvent(event);
  }
}
