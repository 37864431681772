import { uniqBy } from 'lodash';
import BaseInteraction from './BaseInteraction';

// actions
import SelectionNetAction from '@/Actions/SelectionNet';
import SetCursorAction from '@/Actions/SetCursor';
import SetSelectionAction from '@/Actions/SetSelection';

// helpers
import { isSameSelection } from '@/Helpers/Selection';

// consts
import { SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY } from '@/Constants/UI';
import { PATH_TYPE } from '../../Geometry/sherpa-svg-generator/Path';

const toFullId = (item) => `${item.groupId}::${item.pathId}`;

export default class SelectionNetInteraction extends BaseInteraction {
  interactionId = 'Selection Net';

  setActive(active, longPress = false) {
    super.setActive(active);

    // replace the cursor, as needed
    const cursor = this.createAction(SetCursorAction);
    if (this.isActive || longPress) {
      cursor.toMultiSelect();
    } else {
      cursor.toDefault();
    }
  }

  onEveryWindowExit() {
    this.onActivePointerMoveEnd();
  }

  onEveryKeyDown(event) {
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      this.longPress = true;
    }
  }

  onEveryKeyUp(event) {
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      this.longPress = false;
    }
  }

  onLongPressActivate(event, manager) {
    const over = this.getGroupsAt({ ...event.center, multiSelection: true });

    // only activate if in blank canvas space
    if (!over.length) {
      this.longPress = true;
      this.setActive(true, true);
    }
  }

  onLongPressRelease() {
    setTimeout(this.clearSelectionNet);
  }

  onPointerMoveStart() {
    if (this.longPress) {
      this.setActive();
    }
  }

  onActivePointerMove(event, manager) {
    if (!this.action) {
      // create the selection net action
      this.setActive();
      const originX = event.center.x;
      const originY = event.center.y;
      this.action = this.createAction(SelectionNetAction, originX, originY);
    }

    // handle updates
    const over = this.action.update(event.center.x, event.center.y);

    let selection = uniqBy([...over], toFullId);

    if (this.longPress) {
      if (this.type) {
        if (selection.some((s) => s.type === PATH_TYPE.DESIGN)) {
          this.type = PATH_TYPE.DESIGN;
        }
        const newSelectedGroups = selection.filter((s) => s.type === this.type);
        const groupIds = selection.map((s) => s.groupId);
        const test = newSelectedGroups.filter((f) =>
          groupIds.includes(f.groupId)
        );
        selection = test;
      } else {
        this.type = selection[0]?.type;
      }
    }

    // check if the selection is different or there is no previousSelection
    if (!isSameSelection(this.previousSelection, selection)) {
      const select = this.createAction(SetSelectionAction);
      select.set(selection);

      // updates the menu state
      this.refreshMenuState({
        reason: 'selection',
        selection,
      });
    }

    // always refresh the hovering
    this.refreshHoverState(event.center);

    this.previousSelection = selection;
    manager.isDragging = true;
  }

  clearSelectionNet = () => {
    this.pathType = null;
    delete this.action;
    this.onActivePointerMoveEnd();
  };

  // ui interactions
  onActivePointerMoveEnd() {
    this.setActive(false);

    // always clear alt activation -- require another
    // long press to start again
    this.longPress = false;
    this.type = null;

    // needs to resolve selection
    if (this.action) {
      this.action.resolve();
    }

    // clear
    this.action = null;
  }

  onEveryPointerUp() {
    setTimeout(this.clearSelectionNet);
  }
}
