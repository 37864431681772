import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';

// components
import Grid from '@/UILayer/Components/Grid';
import BoundingBox from '@/UILayer/Components/BoundingBox';
import SelectionNet from '@/UILayer/Components/SelectionNet';
import WorkspaceOrigin from '@/UILayer/Components/WorkspaceOrigin';
import AlignmentGuides from '@/UILayer/Components/AlignmentGuides';
import WorkspaceOriginLabels from '@/UILayer/Components/WorkspaceOriginLabel';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import CustomAnchor from '../../Components/CustomAnchor';
import { defaultSecretOptions } from '../../../defaults';
import { selectSecretOptions } from '../../../Redux/Slices/SherpaContainerSlice';
import { entitlements } from '@/Helpers/Entitlements';

export default function DefaultUI(props) {
  const { ui, selectedGroup } = props;
  const options = useSelector(selectOptions);
  const { hasBounds, hasAlignmentGuides, hasSelectionNet } = ui;

  const hasPositioning = selectFeatures(entitlements.PRECISION_POSITION);
  const hasGrid = selectFeatures(entitlements.GRID_VISIBILITY);
  const secretOptions = useSelector(selectSecretOptions);

  // computed
  let { showPositionLabels, showGrid, usePositioning, showCustomAnchor } =
    options;
  if (!usePositioning) {
    showPositionLabels = showGrid = showCustomAnchor = false;
  }

  const {
    guideUnselectedPathWidth,
    guideUnselectedPathColor,
    guideSelectedPathWidth,
    guideSelectedPathColor,
    objectUnselectedPathColor,
    objectUnselectedPathWidth,
    objectSelectedPathColor,
    objectSelectedPathWidth,
  } = {
    ...defaultSecretOptions,
    ...secretOptions,
  };

  const styles = `
      .ui-layer .svg-group.reference.selected .pathGroup2 path {
        stroke: #E27718;
        stroke-width: 12px;
        stroke-opacity: 0.12;
        vector-effect: non-scaling-stroke;
      }
      .ui-layer .svg-group.reference.selected path {
        stroke: #${guideSelectedPathColor};
        stroke-width: ${guideSelectedPathWidth}px;
      }
      .ui-layer .svg-group.reference path {
        stroke: #${guideUnselectedPathColor};
        stroke-width: ${guideUnselectedPathWidth}px;
      }
      .ui-layer .svg-group.design.selected path
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design .pathGroup.selected > .basePath
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design path {
        stroke: #${objectUnselectedPathColor};
        stroke-width: ${objectUnselectedPathWidth}px;
      }`;

  return (
    <div className='ui-layer'>
      <style type='text/css'>{styles}</style>
      <div className='ui-layer--overlay'>
        {hasPositioning && showPositionLabels && hasBounds && (
          <WorkspaceOriginLabels
            ui={ui}
            boundingBox={ui.boundingBox}
            selectedGroups={selectedGroup}
          />
        )}
      </div>

      <Viewport ui={ui}>
        {hasGrid && showGrid && <Grid viewport={ui.viewport} />}
        {hasPositioning && usePositioning && (
          <WorkspaceOrigin
            {...props}
            selection={ui.boundingBox}
            hideGuides={!showPositionLabels}
          />
        )}
        {hasPositioning && showCustomAnchor && (
          <CustomAnchor {...props} viewport={ui.viewport} />
        )}
        {<SvgGroups ui={ui} />}
        {hasAlignmentGuides && (
          <AlignmentGuides
            {...props}
            viewport={ui.viewport}
            guides={ui.alignmentGuides}
          />
        )}
        {hasBounds && <BoundingBox {...props} {...ui.boundingBox} />}
        {hasSelectionNet && <SelectionNet {...ui.selectionNet} />}
      </Viewport>
    </div>
  );
}
