import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useSwitchMobileView } from '@/Utility/react';
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';
import * as cssVariables from '@/Helpers/GlobalCssVariablesHelper';

// Single objects in plan mode Selection Editor should be grouped together as a single group
// does not include single selection of a shape within a group

// tap and hold will deselect an object instead of releasing the hover

// components
import Icon from '@/Styles/Icons/Icon';

// sub components
import Input from './Components/Input';
import Label from './Components/Label';
import Group from './Components/Group';
import Dropdown from './Components/Dropdown';
import Separator from './Components/Separator';
import Gap from './Components/Gap';
import ButtonSet from './Components/ButtonSet';
import Button from './Components/Button';
import PopOut from './Components/PopOut';
import New from '../Badge/New';
import TranslationText from '../TranslationText/TranslationText';

// the range for scaling the menu
const SCALING_RANGE = 0.85;
const CLOSE_THRESHOLD = 0.7;

// main properties panel container
export default function FloatingPanel(props) {
  const selectedPathIds = useSelector(selectSelectedPathIds);
  const containerRef = useRef();
  const contentRef = useRef();
  const touchRef = useRef();
  const menuRef = useRef();

  // props
  const hideCloseOnMobile = 'hideCloseOnMobile' in props;
  const hideMobilePullBar = 'hideMobilePullBar' in props;

  // state
  const [active, setActive] = useState(1);
  const [focused, setFocused] = useState(false);

  function matchMenuToScaling() {
    const percent = touchRef.current?.percent || 0;

    // tracking the sliding menu
    let expansion = 0;
    let shift = 0;

    // check if there are any bounds that can be used
    const bounds = menuRef.current?.getBoundingClientRect() || {};
    let { top = 'unset', bottom = 'unset' } = bounds;
    if (!isNaN(top)) {
      expansion = `${window.innerHeight - top}px`;
      shift = `${0 | (percent * 100)}%`;
      top = `${window.innerHeight - top}px`;
      bottom = `${bottom}px`;
    }

    // update the known props
    cssVariables.assign({
      'properties-panel--anchor-top': top,
      'properties-panel--anchor-bottom': bottom,
      'properties-panel--expansion': expansion,
      'properties-panel--shift': shift,
    });
  }

  // reset the view
  function resetMenuExpansionTracking() {
    touchRef.current = null;

    setTimeout(matchMenuToScaling, 100);
  }

  function onBeginTouchExpandTracking(event) {
    touchRef.current = { y: event.nativeEvent?.touches?.[0]?.pageY };
  }

  // handle closing
  function onClose() {
    props.onClose?.();
    resetMenuExpansionTracking();
  }

  // make sure to adapt when items are changed
  useEffect(resetMenuExpansionTracking, [selectedPathIds]);

  // handle watching for touch events on mobile
  useEffect(() => {
    function onTrackExpansion(event) {
      if (!touchRef.current) {
        return;
      }

      const { innerHeight: baseline } = window;
      const range = baseline - touchRef.current?.y;
      const at = baseline - event.touches?.[0]?.pageY;
      touchRef.current.percent = Math.min(
        SCALING_RANGE,
        Math.max(0, (1 - at / range) * SCALING_RANGE)
      );
      matchMenuToScaling();
    }

    // check for behaviors
    function onEndTrackExpansion() {
      if (touchRef.current?.percent > CLOSE_THRESHOLD) {
        onClose();
      } else {
        resetMenuExpansionTracking();
      }
    }

    // default sizing
    setTimeout(resetMenuExpansionTracking);

    // track the touch event normally
    window.addEventListener('touchmove', onTrackExpansion);
    window.addEventListener('touchend', onEndTrackExpansion);
    window.addEventListener('touchcancel', onEndTrackExpansion);

    // clean up
    return () => {
      window.removeEventListener('touchmove', onTrackExpansion);
      window.removeEventListener('touchend', onEndTrackExpansion);
      window.removeEventListener('touchcancel', onEndTrackExpansion);

      // always reset
      menuRef.current = null;
      onEndTrackExpansion();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resetMenuExpansionTracking);
    return () =>
      window.removeEventListener('resize', resetMenuExpansionTracking);
  }, []);

  useLayoutEffect(() => {
    // this has no special layout rules
    if ('container' in props) {
      return;
    }

    // This is an usual block of code, but what it's doing it creating
    // relationships between labels and UI elements automatically
    // this is so that the CSS side of things can switch between mobile
    // and desktop without any javascript
    //
    // essentially - each label gets a group ID, and that ID is assigned to all
    // following non-labels as the "parent" property. There is some additional
    // code checking for when labels and inputs are focused so the mobile
    // view can know when to toggle certain arrangements
    let group = 0;
    let separator;
    const dispose = [];
    const nodes = [...contentRef.current.childNodes];
    nodes.forEach((node) => {
      // labels act as tabs
      if (/--label/.test(node.className)) {
        ++group;
        node.setAttribute('group', group);

        // wire up this group
        const id = group;
        const activate = () => setActive(id);
        node.addEventListener('click', activate);

        // add a clean up function
        dispose.push(() => node.removeEventListener('click', activate));
      }
      // it's something else
      else if (!/path-type/.test(node.className)) {
        node.setAttribute('parent', group);

        // add the separator, if needed
        if (!separator) {
          separator = document.createElement('div');
          separator.setAttribute('separator', true);
          node.parentNode.insertBefore(separator, node);

          // remove the separator, as needed
          dispose.push(() => separator.remove());
        }

        // check for input fields
        const input = node.querySelector('[contenteditable]');
        if (input) {
          // waits a moment so blurs activate first
          const focus = () => setFocused(true);
          const blur = () => setFocused(false);

          if (input) {
            input.addEventListener('blur', blur);
            input.addEventListener('focus', focus);
          }

          dispose.push(() => {
            input.removeEventListener('focus', focus);
            input.removeEventListener('blur', blur);
          });
        }
      }
    });

    // clean up action
    return () => dispose.forEach((action) => action());
  });

  // when switching views, force a reset
  useSwitchMobileView(() => {
    setActive(1);
  });

  const hasCloseHandler = !!props.onClose;
  const panelCx = classNames(
    'properties-panel',
    props.className,
    `properties-panel--edge--${props.edge} group--${active}`,
    'hideHeadersOnDesktop' in props && 'mobile-only-headers',
    hideCloseOnMobile && 'hide-close-on-mobile',
    { focused }
  );

  const closeCx = classNames('properties-panel--close', {
    // floating: floatingCloseButton,
    // inside: !floatingCloseButton
  });

  return (
    <>
      <div className={panelCx} role='menu' data-cy={props.dataCy} ref={menuRef}>
        {hasCloseHandler && !hideMobilePullBar && (
          <div
            className='properties-panel--handle'
            onTouchStart={onBeginTouchExpandTracking}
          />
        )}

        {!!(props.title || props.subtitle) && (
          <div className='properties-panel--header'>
            {!!props.title && (
              <>
                <div className='properties-panel--title'>
                  <TranslationText i18nKey={props.i18nTitleKey}>
                    {props.title}
                  </TranslationText>

                  {!!props.isNew && (
                    <div className='properties-panel--new'>
                      <New />
                    </div>
                  )}
                </div>
              </>
            )}

            {!!props.title && !!props.subtitle && (
              <div className='properties-panel--subtitle'>
                <TranslationText i18nKey={props.i18nSubtitleKey}>
                  {props.subtitle}
                </TranslationText>
              </div>
            )}
          </div>
        )}

        <div ref={containerRef} className='properties-panel--container'>
          {hasCloseHandler && (
            <div className={closeCx} onClick={onClose}>
              <Icon icon='chevron-down' />
            </div>
          )}

          <div
            className={`properties-panel--content ${props.className}--content`}
            ref={contentRef}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

// expose property types
FloatingPanel.Input = Input;
FloatingPanel.Label = Label;
FloatingPanel.Separator = Separator;
FloatingPanel.Gap = Gap;
FloatingPanel.Dropdown = Dropdown;
FloatingPanel.Group = Group;
FloatingPanel.Button = Button;
FloatingPanel.ButtonSet = ButtonSet;
FloatingPanel.PopOut = PopOut;
FloatingPanel.DetachedButton = ({ children }) => {
  return <div className='properties-panel--detached-button'>{children}</div>;
};
