import { SYNC_IDLE_TIMEOUT } from '@/Sync/SyncConstants';
import { useEffect, useState, useRef } from 'react';

const defaultIdle = { activeToIdle: false, idleToActive: false };

export function usePageIdle() {
  const [isIdle, _setIsIdle] = useState(false);

  //Accessing isIdle state hook in an event handler will give an out of date value, as it references the value of the hook at the time the event handler is attached.
  //So we need to use a ref hook instead, and access via ref.current in the handler.
  const isIdleRef = useRef(isIdle);

  //Use ref instead of useState because I don't want to trigger rerender when timer ref is updated
  const idleTransitions = useRef(defaultIdle);
  const timerRef = useRef(undefined);

  const setIsIdle = (val) => {
    isIdleRef.current = val;
    _setIsIdle(val);
  };

  const computeTransitions = (is, was) => {
    const activeToIdle = is && !was;
    const idleToActive = !is && was;
    idleTransitions.current = { activeToIdle, idleToActive };
  };

  const changeIdle = (val) => {
    computeTransitions(val, isIdleRef.current);
    setIsIdle(val);
  };

  const idleTimeoutCB = () => {
    changeIdle(true);
  };

  const resetTimer = (evt) => {
    if (evt !== undefined) {
      if (timerRef.current !== undefined) {
        clearTimeout(timerRef.current);
      }
      if (isIdleRef.current) {
        changeIdle(false);
      }
      timerRef.current = setTimeout(idleTimeoutCB, SYNC_IDLE_TIMEOUT);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown', resetTimer, false);
    document.addEventListener('keypress', resetTimer, false);
    document.addEventListener('touchmove', resetTimer, false);

    resetTimer();

    return () => {
      document.addEventListener('mousemove', resetTimer);
      document.addEventListener('mousedown', resetTimer);
      document.addEventListener('keypress', resetTimer);
      document.addEventListener('touchmove', resetTimer);
    };
  }, []);

  return idleTransitions.current;
}
