/* eslint-disable valid-jsdoc */
import { setStatus } from '../../Redux/Slices/SyncSlice';
import { clearAlert, setAlert } from '@/Redux/Slices/ShaperHubSlice';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { log } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { PayloadAction } from '@reduxjs/toolkit';
import { SyncStatus } from '../SyncConstants';

export const addStatusListener = (startListening: Function) => {
  startListening({
    actionCreator: setStatus,
    effect: (
      action: PayloadAction<SyncStatus>,
      { dispatch, cancelActiveListeners }: SyncListenerApi
    ) => {
      // debounce
      cancelActiveListeners();

      const { payload } = action;
      log(`Setting status: ${payload}`, { ...context, action }, 'debug');

      switch (payload) {
        case 'edit':
        case 'disconnected':
          dispatch(clearAlert());
          break;

        case 'out-of-date':
          dispatch(
            setAlert({
              msg: 'Design out of date',
              i18nKey: 'design-out-date',
              type: ALERT_TYPES.OUT_OF_SYNC,
              modal: MODAL_TYPES.SYNC_MODAL,
              className: 'out-of-sync-alert',
              position: 'top',
              modalIcon: 'question-mark-circle-inverse',
            })
          );
          break;
        case 'error':
          dispatch(
            setAlert({
              msg: 'Offline',
              i18nKey: 'offline',
              type: ALERT_TYPES.ERROR,
              icon: 'offline',
              modal: MODAL_TYPES.OFFLINE_MODAL,
            })
          );
          break;
        case 'invalid-workspace':
          dispatch(
            setAlert({
              msg: 'Cannot open workspace',
              i18nKey: 'fail-open',
              type: ALERT_TYPES.ERROR,
              icon: 'offline',
              modal: MODAL_TYPES.OFFLINE_MODAL,
            })
          );
          break;
        case 'invalid-version':
          dispatch(
            setAlert({
              msg: 'Workspace Version is Not Compatible',
              type: ALERT_TYPES.ERROR,
              icon: 'offline',
              modal: MODAL_TYPES.INVALID_MODAL,
            })
          );
          break;
        default:
          break;
      }
    },
  });
};
