import React, { useEffect } from 'react';

//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';
import { ALERT_TYPES, MODAL_TYPES, miscLinks } from '@/defaults';
import AlertAction from '@/Actions/Alert';

export default function ReferenceModal() {
  const modalActions = useAction(ModalActions);
  const alertAction = useAction(AlertAction);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  const goToUrl = () => {
    window.open(miscLinks.REFERENCE_HELP, '_self');
  };

  useEffect(() => {
    alertAction.clear();

    return () => {
      alertAction.set({
        msg: 'Object set to Reference and locked',
        i18nKey: 'set-to-reference',
        type: ALERT_TYPES.DEFAULT,
        modal: MODAL_TYPES.REFERENCE_MODAL,
        className: 'guide-alert',
        position: 'top',
        icon: 'path-type-lock',
        modalIcon: 'circle-warning',
        duration: 5000,
      });
    };
  }, []);

  return (
    <Modal className='reference-modal'>
      <div className='reference-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='path-type-lock' />
            <TranslationText i18nKey='reference'>Reference</TranslationText>
          </Modal.Title>
          <div className='reference-modal--info'>
            <div className='reference-modal--info--text'>
              <TranslationText i18nKey='reference-info-1'>
                Objects set to “Reference” will be locked (non-editable),
                excluded from lasso selections, and will not be visible in other
                modes or on Origin.
              </TranslationText>
            </div>
            <div className='reference-modal--info--text'>
              <TranslationText i18nKey='reference-info-2'>
                Use this feature to more easily draw over your traces or
                reference designs and keep your working designs out of your cut
                files.
              </TranslationText>
            </div>
            <div
              className='reference-modal--info--learn-more'
              onClick={goToUrl}
            >
              <TranslationText i18nKey='learn-more'>Learn more</TranslationText>
              <Icon icon='chevron-right' />
            </div>
          </div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
