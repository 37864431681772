import React from 'react';

//components
import FloatingPanel from './Components/FloatingPanel';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/Redux/Slices/ShaperHubSlice';
import { setI18nLanguage } from '@/i18n';
import { setDeveloperSettings } from '@/Utility/developer-settings';

export default function SecretMenuPanel() {
  const { locale } = useSelector(selectLocale);
  const gridOptions = [
    {
      label: 'Grid Snapping Threshold (px)',
      type: 'number',
      i18nKey: 'grid-snapping',
      prop: 'gridSnappingThreshold',
    },
    {
      label: 'Smart Snapping Threshold (px)',
      type: 'number',
      i18nKey: 'smart-snapping',
      prop: 'smartSnappingThreshold',
    },
    {
      label: 'Snapping Stroke',
      type: 'number',
      prop: 'guideLineWidth',
      prop2: 'guideLineColor',
      hasColor2: true,
    },
  ];

  const guideOptions = [
    {
      label: 'Guide Unselected Path',
      type: 'number',
      prop: 'guideUnselectedPathWidth',
      prop2: 'guideUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Unselected Hover Path',
      type: 'number',
      prop: 'guideUnselectedHoverPathWidth',
      prop2: 'guideUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Glow Path',
      type: 'number',
      prop: 'guideGlowPathWidth',
      prop2: 'guideGlowPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Path',
      type: 'number',
      prop: 'guideSelectedPathWidth',
      prop2: 'guideSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Hover Path',
      type: 'number',
      prop: 'guideSelectedHoverPathWidth',
      prop2: 'guideSelectedHoverPathColor',
      hasColor2: true,
    },
  ];

  const objectOptions = [
    {
      label: 'Object Unselected Path',
      type: 'number',
      prop: 'objectUnselectedPathWidth',
      prop2: 'objectUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Unselected Hover Path',
      type: 'number',
      prop: 'objectUnselectedHoverPathWidth',
      prop2: 'objectUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Path',
      type: 'number',
      prop: 'objectSelectedPathWidth',
      prop2: 'objectSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Hover Path',
      type: 'number',
      prop: 'objectSelectedHoverPathWidth',
      prop2: 'objectSelectedHoverPathColor',
      hasColor2: true,
    },
  ];

  const positionOptions = [
    {
      label: 'Position Display Stroke',
      i18nKey: 'position-display-color',
      prop: 'positionStrokeWidth',
      prop2: 'positionColor',
      hasColor2: true,
    },
    {
      label: 'Position Display Dash Width (px)',
      type: 'number',
      i18nKey: 'position-dash-width',
      prop: 'positionDashWidth',
    },
    {
      label: 'Position Display Gap Width (px)',
      type: 'number',
      i18nKey: 'position-display-gap',
      prop: 'positionGapWidth',
    },
  ];

  const otherOptions = [
    {
      label: 'Display Workspace Sync Options',
      type: 'toggle',
      i18nKey: 'display-workspace-sync',
      prop: 'showWorkspaceSettings',
    },
    {
      label: 'Language',
      type: 'dropdown',
      prop: 'language',
      value: locale || 'en',
      onChange: (value) => {
        const newLanguage = value.target.value;
        if (newLanguage !== locale) {
          setDeveloperSettings('locale', newLanguage);
          setI18nLanguage(newLanguage);
        }
      },
    },
  ];

  return (
    <div className='secret-menu-panel--content'>
      {gridOptions.map((opt, index) => (
        <FloatingPanel.Group key={index} {...opt} />
      ))}

      <FloatingPanel.Separator />

      {guideOptions.map((opt, index) => (
        <FloatingPanel.Group key={gridOptions.length + index} {...opt} />
      ))}

      <FloatingPanel.Separator />

      {objectOptions.map((opt, index) => (
        <FloatingPanel.Group
          key={gridOptions.length + guideOptions.length + index}
          {...opt}
        />
      ))}

      <FloatingPanel.Separator />

      {positionOptions.map((opt, index) => (
        <FloatingPanel.Group
          key={
            gridOptions.length +
            guideOptions.length +
            objectOptions.length +
            index
          }
          {...opt}
        />
      ))}

      <FloatingPanel.Separator />

      {otherOptions.map((opt, index) => (
        <FloatingPanel.Group
          key={
            gridOptions.length +
            guideOptions.length +
            objectOptions.length +
            positionOptions.length +
            index
          }
          {...opt}
        />
      ))}
    </div>
  );
}
