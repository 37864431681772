export function getSubscribeLink() {
  const encodedReturnLocation = encodeURIComponent(
    window.location.href + '?success'
  );
  return `subscribe/?backTo=${encodedReturnLocation}`;
}

export function getTrialLink() {
  const encodedReturnLocation = encodeURIComponent(
    window.location.href + '?success'
  );
  return `start-trial?backTo=${encodedReturnLocation}`;
}
