/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

//selectors
import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
} from '@/Redux/Slices/ShaperHubSlice';

//actions
import ModalActions from '@/Actions/Modal';
import { useAction } from '@/Actions/useAction';

//Components
import Icon from '@/Styles/Icons/Icon';
import { getSubscribeLink } from '@/Utility/subscribe-link';
import TranslationText from '@/Components/TranslationText/TranslationText';
import classNames from 'classnames';

export default function UpgradeSubscription() {
  const { isTrial, isSubscriber, daysLeft, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLite = !isSubscriber && !isTrial;
  const validTrial = isTrial && !isExpired;
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);

  const modalActions = useAction(ModalActions);

  function openSubscribeLink() {
    const subscribePath = getSubscribeLink();
    window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
  }

  function openModal() {
    modalActions.openEducationalModal();
  }

  const explanationCx = classNames(
    'application-menu--upgrade-subscription--explanation',
    {
      'center-text': validTrial,
    }
  );

  return (
    <div className='application-menu--upgrade-subscription'>
      <div className={explanationCx}>
        {isLite && !isTrial && (
          <TranslationText i18nKey='studio-lite-cta-with-trial'>
            You are using Studio Lite. To access more fonts, icons, and use the
            Shapeshifter tool, start a trial or begin your subscription.
          </TranslationText>
        )}
        {isTrial && !validTrial && (
          <TranslationText i18nKey='studio-lite-cta'>
            You are using Studio Lite. To access more fonts, icons, and use the
            Shapeshifter tool, begin your subscription.
          </TranslationText>
        )}
        {validTrial && (
          <TranslationText i18nKey='days-left' count={daysLeft}>
            You have {daysLeft} days left in your trial
          </TranslationText>
        )}
      </div>

      <div className='application-menu--upgrade-subscription--cta'>
        {(isLite || (isSubscriber && isExpired)) && (
          <a onClick={openModal} data-cy='unlock-studio-cta'>
            <Icon icon='lock' />
            <TranslationText i18nKey='unlock-studio'>
              Unlock Studio
            </TranslationText>
          </a>
        )}
        {isTrial && !validTrial && (
          <a onClick={openSubscribeLink}>
            <TranslationText i18nKey='start-subscribing'>
              Start Subscribing
            </TranslationText>
          </a>
        )}
        {validTrial && (
          <a onClick={openModal}>
            <TranslationText i18nKey='subscribe'>Subscribe</TranslationText>
          </a>
        )}
      </div>
    </div>
  );
}
