import { setSvgGroupAnchor } from '@/CanvasContainer/CanvasActions';
import {
  setTemporaryAnchor as setTemporaryAnchorAction,
  clearTemporaryAnchor as clearTemporaryAnchorAction,
} from '@/Redux/Slices/SelectionSlice';
import SetSelectionAction from '@/Actions/SetSelection';
import BaseAction from './BaseAction';

export default class SetSvgGroupAnchorAction extends BaseAction {
  // verify the selected anchor
  static normalizeAnchor(anchor) {
    return ['center', 'centroid'].includes(anchor) ? null : anchor;
  }

  setTemporaryAnchor(anchor) {
    const { dispatch } = this;

    const updateTo = SetSvgGroupAnchorAction.normalizeAnchor(anchor);
    dispatch(setTemporaryAnchorAction(updateTo));
  }

  clearTemporaryAnchor() {
    const { dispatch } = this;
    dispatch(clearTemporaryAnchorAction());
  }

  static getOppositeAnchorRelativeToAxis(anchor, axis) {
    return ['h', 'horizontal'].includes(axis)
      ? anchor.replace(/(l|r)/gi, (side) => (side === 'l' ? 'r' : 'l'))
      : anchor.replace(/(t|b)/gi, (side) => (side === 't' ? 'b' : 't'));
  }

  async setAnchor(groupIds, anchor) {
    const { dispatch } = this;

    // replaces the selected anchor
    const updateTo = SetSvgGroupAnchorAction.normalizeAnchor(anchor);
    await dispatch(
      setSvgGroupAnchor({
        ids: groupIds,
        anchor: updateTo,
      })
    );

    // must update the selection so it's aware
    // of the new anchor
    const selection = this.createAction(SetSelectionAction);
    selection.refresh();
  }
}
