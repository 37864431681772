document.querySelector('[global-css-variables]')?.remove();

const cssVariables = document.createElement('style');
cssVariables.setAttribute('global-css-variables', '');
document.body.appendChild(cssVariables);

let variables: Record<string, string> = {};

export function assign(set: Record<string, string>) {
  variables = { ...variables, ...set };

  cssVariables.innerHTML = `:root {
    ${Object.keys(variables)
      .map((key) => `--${key}: ${variables[key]};`)
      .join('\n')}
  }`;
}
