import { batch } from 'react-redux';
import { duplicateSelectedGroups } from '@/CanvasContainer/CanvasActions';
import { selectMostRecentlyAddedGroup } from '@/Redux/Slices/SelectionSlice';
import BaseAction from './BaseAction';

export default class DuplicateGroupsAction extends BaseAction {
  duplicate(groupIds, offset = { x: 5, y: 5 }) {
    const { dispatch } = this;
    batch(async () => {
      await dispatch(duplicateSelectedGroups({ groupIds, offset }));
      await dispatch(
        selectMostRecentlyAddedGroup({ duplicateCount: groupIds.length })
      );
    });
  }
}
