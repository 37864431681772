/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//selectors
import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectLocale,
  selectUserIsVerified,
} from '@/Redux/Slices/ShaperHubSlice';

//components
import Modal from '../Modal';
import Icon from '@/Styles/Icons/Icon';

//helpers
import { getSubscribeLink, getTrialLink } from '@/Utility/subscribe-link';
import StartTrialAction from '@/Actions/StartTrial';
import { localePricing, menuLinks } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';
import { debounce } from 'lodash';

export default function EducationalModal() {
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const userIsVerified = useSelector(selectUserIsVerified);
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);
  const isLite = !isSubscriber && !isTrial;
  const validTrial = isTrial && !isExpired;
  const { locale, currency } = useSelector(selectLocale);

  const modalActions = useAction(ModalActions);
  const startTrialAction = useAction(StartTrialAction);

  const debounceStart = debounce(() => {
    startTrialAction.start();
  }, 1000);

  const closeModal = () => {
    modalActions.closeModal();
  };

  const openSubscribeLink = () => {
    const subscribePath = getSubscribeLink();
    window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
  };

  const openLink = () => {
    if (isLite || (isSubscriber && isExpired)) {
      if (userIsVerified) {
        debounceStart();
      } else {
        const trialPath = getTrialLink();
        window.open(`${shaperMarketingUrl}/${trialPath}`, '_self');
      }
    } else if (isTrial) {
      openSubscribeLink();
    }
  };

  const subscriptionPricing = localePricing[currency].toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <Modal className='educational-modal'>
      <Modal.Panel position='left'>
        <Modal.Content>
          <Modal.Title>
            {(isLite || validTrial) && (
              <TranslationText i18nKey='do-more'>
                Do More With Studio
              </TranslationText>
            )}
            {!validTrial && isTrial && (
              <TranslationText i18nKey='free-trial-ended'>
                Oh no, your free trial has ended!
              </TranslationText>
            )}
            {isSubscriber && isExpired && (
              <TranslationText i18nKey='subscription-ended'>
                Your subscription has ended
              </TranslationText>
            )}
          </Modal.Title>
          <Modal.Button onClick={openLink}>
            {isLite ? (
              <TranslationText i18nKey='start-free-trial'>
                Start a free trial
              </TranslationText>
            ) : (
              <>
                <TranslationText i18nKey='subscribe'>Subscribe</TranslationText>
                {` (${subscriptionPricing}/YR)`}
              </>
            )}
          </Modal.Button>
          {isLite && (
            <div className='educational-modal--left--content--title-subheader'>
              <a onClick={openSubscribeLink}>
                <TranslationText i18nKey='or-subscribe'>
                  or subscribe
                </TranslationText>
              </a>
            </div>
          )}
          <div className='educational-modal--left--content--info-text'>
            {isLite && (
              <TranslationText i18nKey='try-full-version'>
                With an account, try the full version completely free for 14
                days. We won't ask for payment info and you won't be billed.
              </TranslationText>
            )}
            {isTrial && (
              <>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='access-designs'>
                    Access millions of pre-made designs
                  </TranslationText>
                </div>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='shapeshifter-explanation'>
                    Fast results with Shapeshifter
                  </TranslationText>
                </div>
                <div className='educational-modal--left--content--info-terms'>
                  <TranslationText i18nKey='sync-to-origin'>
                    Sync to Origin from your phone or computer
                  </TranslationText>
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <a href={menuLinks.LEARN_MORE}>
            <TranslationText i18nKey='learn-more-modal'>
              Learn More about Studio
            </TranslationText>
          </a>
        </Modal.Footer>
      </Modal.Panel>
      <Modal.Panel position='right'>
        <div className='educational-modal--close-icon'>
          <Icon icon='close' onClick={closeModal} />
        </div>
        <div className='educational-modal--learn-more'>
          <a
            href={menuLinks.LEARN_MORE}
            className='educational-modal--learn-more-text'
          >
            <TranslationText i18nKey='learn-more'>Learn More</TranslationText>
          </a>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
