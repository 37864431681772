import { getId, transform } from './BasePathOps';
import {
  getToolPathSvgFromPathData,
  getToolPathSvgData,
  getPreviewPathSvgFromPathData,
} from './CutPreviewOps';
import { createScaledBasePathSvg } from './SvgGroupOps';
import { BasePath, generatePathId } from './sherpa-svg-generator/BasePath';
import { PATH_TYPE } from './sherpa-svg-generator/Path';
import {
  createCutPathSvg,
  createSvgImageGroup,
} from './sherpa-svg-generator/SvgGenerator';
import { SvgGroup } from './sherpa-svg-generator/SvgGroup';

//TODO - delete cache entries when paths are deleted
interface SvgStringCache {
  scaledBasePaths: {};
  toolPaths: {};
  reviewPaths: {};
  depthClippedPaths: {};
  [key: string]: any;
}
export class SvgCache {
  svgStringCache: SvgStringCache = {
    scaledBasePaths: {},
    toolPaths: {},
    reviewPaths: {},
    depthClippedPaths: {},
  };

  // Generates or reuses svg paths for scaledBasePath and toolPath, reviewPath
  // pathTypeKey is the type of path (scaledBasePaths, toolPaths, reviewPaths)
  // pathId is the id, not index, of the path in svgGroup.basePathSet
  // timestamp is the ts when the svgGroup was last updated
  // pathGenFcn will create an updated version of the pathData and/or pathSvg if cached version doesn't exist or is out of date
  getCachedPath(
    pathTypeKey: string,
    pathId: string,
    timestamp: number,
    pathGenFcn: Function
  ) {
    // check if already cached
    let cacheLine = this.svgStringCache[pathTypeKey][pathId];

    // Generate cacheline if it doesn't exist or timestamp is out of date.
    // Because of undo/redo, cacheLine.Ts may be ahead of group timestamp
    if (!cacheLine || cacheLine?.generatedTs !== timestamp) {
      const { pathData, pathSvg } = pathGenFcn();
      cacheLine = {
        generatedTs: timestamp,
        pathData, //Optionally store pathData in addition to svg. This is used to accelerate generation of toolpaths from the previously cached scaledBasePath
        pathSvg,
      };

      // save the cacheline to the cache
      this.svgStringCache[pathTypeKey][pathId] = cacheLine;
    }

    // return the cacheline
    return cacheLine;
  }

  //Promisfied version for cases where pathGenFcn is in web-worker or async
  getCachedPathPromise(
    pathTypeKey: string,
    pathId: string,
    timestamp: number,
    pathGenFcn: Function
  ) {
    // check if already cached
    let cacheLine = this.svgStringCache[pathTypeKey][pathId];

    // Generate cacheline if it doesn't exist or timestamp is out of date.
    // Because of undo/redo, cacheLine.Ts may be ahead of group timestamp
    if (!cacheLine || cacheLine?.generatedTs !== timestamp) {
      return pathGenFcn().then((cacheData: object) => {
        cacheLine = { generatedTs: timestamp, ...cacheData };
        // save the cacheline to the cache
        this.svgStringCache[pathTypeKey][pathId] = cacheLine;
        return cacheLine;
      });
    }

    // return the cacheline now if present and valid
    return Promise.resolve(cacheLine);
  }

  getCachedPathTs(pathTypeKey: string, pathId: string) {
    return this.svgStringCache[pathTypeKey]?.[pathId]?.generatedTs;
  }

  setCachedPath(
    pathTypeKey: string,
    pathId: string,
    timestamp: number,
    cacheLine: object
  ) {
    this.svgStringCache[pathTypeKey][pathId] = {
      ...cacheLine,
      generatedTs: timestamp,
    };
  }

  getScaledBasePathCache(svgGroup: SvgGroup, basePath: BasePath) {
    const bpId = getId(basePath) as string;
    const pathGenFcn = () => {
      //TODO - in theory we could use basePath.sourceSvg.svg if it exists, but we need to be able to account for sourceTransform and also apply arbitrary css/svg attributes to this. Consider this if we ever rewrite the renderer.
      // if(basePath?.sourceSvg?.svg.length > 0){
      // const processPathSvg = SvgGroupOps.createScaledPostProcessPathSvg(svgGroup.id, bpId, postProcessPath);

      //PostprocessedPaths are already scaled, but basePaths are not. Otherwise, these cases could be merged.
      if (svgGroup?.postProcessPathSet?.length > 0) {
        return {
          pathData: basePath,
          pathSvg: createScaledBasePathSvg(svgGroup.id, bpId, basePath),
        };
      }

      const scaledPathData = transform(
        basePath,
        svgGroup.stretchMtx
      ) as BasePath;
      const pathSvg = createScaledBasePathSvg(
        svgGroup.id,
        bpId,
        scaledPathData
      );
      return { pathData: scaledPathData, pathSvg };
    };

    return this.getCachedPath(
      'scaledBasePaths',
      bpId,
      svgGroup.generatedTs,
      pathGenFcn
    );
  }

  getScaledBasePathSvg(svgGroup: SvgGroup, basePath: BasePath) {
    const { pathSvg } = this.getScaledBasePathCache(svgGroup, basePath);
    return pathSvg;
  }

  getScaledBasePathData(svgGroup: SvgGroup, basePath: BasePath) {
    const { pathData } = this.getScaledBasePathCache(svgGroup, basePath);
    return pathData;
  }

  //Returns cached toolpath and preview paths for Plan Mode Only
  getToolPathCache(svgGroup: SvgGroup, basePath: BasePath) {
    const bpId = getId(basePath) as string;

    const toolPathGenFcn = () => {
      const renderedPath = (
        svgGroup?.postProcessPathSet?.length > 0
          ? svgGroup.postProcessPathSet.find((p) => p.id === bpId)
          : basePath
      ) as BasePath;
      const scaledBasePathData = this.getScaledBasePathData(
        svgGroup,
        renderedPath
      );

      const { pathSvgData, pathData } = getToolPathSvgData(scaledBasePathData);
      const toolPathSvg = getToolPathSvgFromPathData(
        svgGroup.id,
        bpId,
        renderedPath.cutParams,
        pathSvgData
      );
      const previewPathSvg = getPreviewPathSvgFromPathData(
        svgGroup.id,
        bpId,
        renderedPath.cutParams,
        pathSvgData
      );
      return { pathSvg: { toolPathSvg, previewPathSvg }, pathData };
    };

    return this.getCachedPath(
      'toolPaths',
      bpId,
      svgGroup.generatedTs,
      toolPathGenFcn
    );
  }

  getToolPathSvg(svgGroup: SvgGroup, basePath: BasePath) {
    return this.getToolPathCache(svgGroup, basePath).pathSvg;
  }

  getDepthClippedSvg(svgGroupSet: SvgGroup[]) {
    const groupTimestamps = svgGroupSet.map((sg) => sg.generatedTs);
    const timestamps = groupTimestamps.length === 0 ? [0] : groupTimestamps;
    const maxGroupTs = timestamps.reduce((acc, ts) => acc + ts);

    return this.getCachedPath(
      'depthClippedPaths',
      'all',
      maxGroupTs,
      // If cacheLine is undefined or out of date, return empty data and wait for the reviewPathLoader to be cleared to try again
      () => ({ pathData: [], pathSvg: '' })
    ).pathSvg;
  }

  getSvgGroupDisplaySvg(svgGroup: SvgGroup, pathSelections: any) {
    const {
      showToolPaths,
      showPlanPreviewPaths,
      showBasePaths,
      showReviewPaths,
      showCutPaths,
    } = pathSelections;
    const pathGroupsSvg = [];

    const basePathSet =
      svgGroup?.postProcessPathSet?.length > 0
        ? svgGroup.postProcessPathSet
        : svgGroup.basePathSet;

    for (const basePath of basePathSet) {
      const pathSvg = [];
      const cutParams = basePath.cutParams;
      if (showBasePaths) {
        pathSvg.push(this.getScaledBasePathSvg(svgGroup, basePath));
      }

      if (showToolPaths || showPlanPreviewPaths) {
        const { toolPathSvg, previewPathSvg } = this.getToolPathSvg(
          svgGroup,
          basePath
        );

        if (showToolPaths) {
          pathSvg.push(toolPathSvg);
        }

        if (showPlanPreviewPaths) {
          //duplicate toolWidth to display pocket pattern properly
          if (cutParams.cutType === 'pocket') {
            const previewPathSvgPattern = previewPathSvg.replaceAll(
              /oolWidth/g,
              'oolWidthPattern'
            );
            pathSvg.push(previewPathSvgPattern);
          }

          pathSvg.push(previewPathSvg);
        }
      }

      const pathGroupGroupId = generatePathId(
        svgGroup.id,
        getId(basePath) as string,
        'pathGroup'
      );

      const pathGroupGroupId2 = generatePathId(
        svgGroup.id,
        getId(basePath) as string,
        'pathGroup2'
      );

      const closed = basePath.outerPath
        ? basePath.outerPath.closed
        : basePath.closed;
      const pathGroupCSS = ['pathGroup', closed ? 'closed' : 'open'];

      if (showPlanPreviewPaths) {
        pathGroupCSS.push(`cut-type-${cutParams.cutType}`);
        if (showCutPaths) {
          pathGroupCSS.push(`has-cut-paths`);
        }
        pathSvg.unshift(pathSvg.pop());
      }

      // We should look at refactoring the CSS to eliminate this superfluous group
      const pathGroupSvg = createCutPathSvg(
        pathSvg.join('\n'),
        pathGroupCSS,
        pathGroupGroupId
      );

      pathGroupsSvg.push(pathGroupSvg);

      if (svgGroup.type === PATH_TYPE.REFERENCE) {
        const pathGroupSvgDupe = createCutPathSvg(
          pathSvg.join('\n'),
          ['pathGroup2'],
          pathGroupGroupId2
        );

        pathGroupsSvg.push(pathGroupSvgDupe);
      }
    }

    const cssClasses = !(showPlanPreviewPaths && showReviewPaths)
      ? [svgGroup.type?.toLowerCase() || PATH_TYPE.DESIGN.toLowerCase()]
      : [];
    return createSvgImageGroup(
      pathGroupsSvg.join('\n'),
      cssClasses,
      svgGroup.id,
      '',
      svgGroup.transformedAABB
    );
  }
}

const svgCache = new SvgCache();
export { svgCache as default };
