//Determine list from https://browsersl.ist/#q=%3E0.2%25+in+US%2Cnot+dead%2Cnot+op_mini+all%2Cnot+opera+%3E+0%2Cnot+samsung+%3E+0%2Cnot+and_uc+%3E+0%2Cnot+and_qq+%3E+0%2Cnot+android+%3E+0
//Run package.json script to generate regex
/*
  Example user strings to test, possible future unit test
  Old iOS:
  Mozilla/5.0 (iPad; CPU OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1

  iOS 15.7:
  Mozilla/5.0 (iPad; CPU OS 15_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6,2 Mobile/15E148 Safari/604.1

  iOS 15.7 ipad pro desktop mode: 
  Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6,2 Safari/605.1.15

  Samsung:
  Mozilla/5.0 (Linux; Android 12; Pixel 4a) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/18.0 Chrome/99.0.4844.88 Mobile Safari/537.36
*/
const browserList = {
  'Chrome Mobile': 104,
  'Firefox Mobile': 101,
  Safari: 13,
  Chrome: 101,
  Firefox: 102,
  'Microsoft Edge': 103,
  Electron: 20,
};

export default browserList;
