/* eslint-disable valid-jsdoc */
import { isAnyOf, isRejected, PayloadAction } from '@reduxjs/toolkit';

import { setSnapshot, updateQueue } from '../../Redux/Slices/SyncSlice';
import {
  addSVGGeometry,
  addSimplePolygonGeometry,
  deleteSvgGroup,
  addSvgGroups,
  updateSvgGroup,
  deleteGroupIdsAndAddSimplePolygonGeometry,
  undoCanvas,
  redoCanvas,
  clearCanvas,
  updatePathsCutParams,
  duplicateSelectedGroups,
  setSvgGroupAnchor,
  setCanvasCustomAnchor,
  updateVersion,
  getActionLookup,
} from '@/CanvasContainer/CanvasActions';
import { generatePatchAndHistory } from './../PatchGenerator';
import { setCanvasState } from '@/Redux/Slices/CanvasSlice';
import { log, sanitizeCanvasState, sanitizePatchState } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { SyncError } from '../SyncError';

/**
 * A immer listener to create patches and add them to the queue after any of the
 * canvas actions completes. Canvas action has a state mutator function that helps to generate the patches.
 * If sync is enabled, the patches will be sent as an update, otherwise it will only be saved in local storage
 */
export const addCanvasActionListener = (startListening: Function) => {
  startListening({
    matcher: isAnyOf(
      addSVGGeometry,
      addSimplePolygonGeometry,
      deleteSvgGroup,
      addSvgGroups,
      updateSvgGroup,
      deleteGroupIdsAndAddSimplePolygonGeometry,
      undoCanvas,
      redoCanvas,
      clearCanvas,
      updatePathsCutParams,
      duplicateSelectedGroups,
      setSvgGroupAnchor,
      setCanvasCustomAnchor,
      updateVersion
    ),
    effect: async (
      action: PayloadAction,
      { getState, dispatch }: SyncListenerApi
    ) => {
      if (isRejected(action)) {
        throw new SyncError(
          'immer',
          'listener',
          `An error occurred in ${action.type}: `,
          action.error
        );
      }

      const { canvas } = getState();
      const actionType = action.type.substring(action.type.indexOf('/') + 1);

      // to find the function that is actually being called by `action` (i.e. `canvas/addSVGGeometry`)
      // look in `CanvasActions.ts`
      const createAction = getActionLookup(actionType);
      const createActionFcn = createAction(action);
      const { stateMutator, addUndoPatches, manualPatchGenerator, payloadLog } =
        createActionFcn;

      log(
        `Immer canvas action listener: queueing patches for action: ${action.type}`,
        {
          ...context,
          payload: payloadLog ? payloadLog(action.payload) : undefined,
        },
        'debug'
      );

      const [patches, nextState] = generatePatchAndHistory(
        canvas,
        stateMutator,
        addUndoPatches,
        manualPatchGenerator
      );

      log(
        `Patches and next state for workspace`,
        {
          ...context,
          patches: sanitizePatchState(patches),
          nextState: sanitizeCanvasState(nextState),
        },
        'debug'
      );

      const { sync } = getState();
      const { queue, enabled } = sync;
      if (enabled) {
        const newQueue = [...queue, patches];
        log('Sync is enabled: queuing updates...', {
          ...context,
          newQueueLength: newQueue.length,
        });
        dispatch(updateQueue(newQueue));
      }
      dispatch(setCanvasState(nextState));
      dispatch(setSnapshot({ value: nextState }));
    },
  });
};
