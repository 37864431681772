import { prepareInputPreselect } from '@/Helpers/DOM';
import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { toggleTextInsert } from '@/Redux/Slices/UISlice';

export default class ToggleTextEditorModeAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  // insert mode
  insert = () => {
    const { dispatch } = this;
    prepareInputPreselect();

    dispatch(toggleTextInsert(true));
    dispatch(clearSelection());
  };

  exit = () => {
    const { dispatch } = this;

    dispatch(toggleTextInsert(false));
  };

  // shows the edit mode
  edit = (textParams) => {
    const { dispatch } = this;

    dispatch(
      toggleTextInsert({
        active: true,
        textParams,
      })
    );
  };
}
