export default class UpdateTextAction {
  constructor(selectedGroups) {
    this.selectedGroups = selectedGroups;
  }

  setText = (text) => {
    this.text = text;
  };

  setFontName = (name) => {
    this.fontDisplayName = name;
  };

  setFontStyle = (style) => {
    this.fontDisplayStyle = style;
  };

  // performs a general update
  apply(params) {
    [
      ['text', this.setText],
      ['fontName', this.setFontName],
      ['fontStyle', this.setFontStyle],
    ].forEach(([key, updater]) => {
      if (key in params) {
        updater(params[key]);
      }
    });
  }

  // creates a change set for
  resolve() {
    const changes = {};
    const { fontDisplayName, fontDisplayStyle, text } = this;

    for (const group of this.selectedGroups) {
      const id = group.id;

      // create required params
      changes[id] = {
        textParams: {
          fontDisplayName:
            fontDisplayName || group.tool?.params?.fontDisplayName,
          fontDisplayStyle:
            fontDisplayStyle || group.tool?.params?.fontDisplayName,
          text: text || group.tool?.params?.text,
        },
      };
    }

    return changes;
  }
}
