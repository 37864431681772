import UIState from '@/UILayer/State/UIState';

// common state management
export default class DesignState extends UIState {
  // prepare relevant state
  init() {
    const { overrides, selectedGroupIds } = this;

    // predefine a few things
    this.isSingleSelection = selectedGroupIds.length === 1;
    this.isMultiSelection = selectedGroupIds.length > 1;
    this.hasSelection = selectedGroupIds.length >= 1;

    // interactions
    this.isResizing = !!overrides?.resize;
    this.isRotating = !!overrides?.rotate;
    this.isTranslating = !!overrides?.translate;

    // define required data
    this.defineViewport();
    this.defineSelectionNet();
    this.defineGroups();
    this.defineBoundingBox();
    this.defineAlignmentGuides();
  }
}
