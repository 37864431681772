import React from 'react';

const EVENT_MAPPINGS = {
  mousedown: 'onMouseDown',
  mouseup: 'onMouseUp',
  click: 'onClick',
  touchstart: 'onTouchStart',
  touchend: 'onTouchEnd',
  pointerup: 'onPointerUp',
  pointerdown: 'onPointerDown',
};

const EVENTS = Object.keys(EVENT_MAPPINGS);

export default class InteractionCapture extends React.PureComponent {
  static refs = 0;

  containerRef = React.createRef();

  // all attached events
  events = {};

  // setup the component
  componentDidMount() {
    const { containerRef, events } = this;
    this.identity = `ref_${++InteractionCapture.refs}`;

    // attach events
    EVENTS.forEach((event) => {
      const handler = (e) => {
        const isCapture = containerRef.current === e.target;

        // if the target is itself, check for a capture event
        if (isCapture) {
          e.preventDefault();
          e.stopPropagation();
          this.invoke(event, e);
        }
      };

      // handle capture events
      events[event] = handler;
      containerRef.current.addEventListener(event, handler);
    });
  }

  // teardown the component
  componentWillUnmount() {
    const { events, containerRef } = this;
    for (const id in events) {
      if (events.hasOwnProperty(id)) {
        containerRef.current.removeEventListener(id, events[id]);
      }
    }
  }

  // invokes a method, if found
  invoke(name, event) {
    const invoke = this.props[EVENT_MAPPINGS[name]];
    if (invoke) {
      invoke(event);
    }
  }

  render() {
    const { identity, containerRef, props } = this;

    return (
      <div
        ref={containerRef}
        className={`${identity} ${props.className || ''}`}
      >
        {this.props.children}
      </div>
    );
  }
}
