// localState.js

// Use cases -
// persist some/all state after reload

// How do we clear the workspace? After saving or sending to shaperhub?

// Use localStorage, don't need a DB.

// redux-persist seems too complicated for our uses, just serialize and throttle state changes to localStorage and retrieve on reload.

//Semaphore to handle state versioning and prevent infinite loop if state load causes crash
import axios from 'axios';
import { CANVAS_DATA_VERSION, apiTimeouts } from '@/defaults';

const thisAxios = axios.create({
  timeout: apiTimeouts.localStorage,
});

const getServerSHA = async () =>
  await thisAxios
    .get('/api/serversha')
    .then((response) => response.data?.server_sha);

const loadKey = (stateKey, storageSrv = localStorage) => {
  try {
    const serializedState = storageSrv.getItem(stateKey);
    if (serializedState === null) {
      return undefined;
    }
    const savedState = JSON.parse(serializedState);
    return savedState;
  } catch (err) {
    return undefined;
  }
};

const saveKey = (stateKey, state, storageSrv = localStorage) => {
  try {
    const serializedState = JSON.stringify(state);
    storageSrv.setItem(stateKey, serializedState);
  } catch {
    // ignore write errors
  }
};

const loadLocalKey = (stateKey) => {
  return loadKey(stateKey, localStorage);
};

const loadSessionKey = (stateKey) => {
  return loadKey(stateKey, sessionStorage);
};

const saveLocalKey = (stateKey, state) => {
  return saveKey(stateKey, state, localStorage);
};

const saveSessionKey = (stateKey, state) => {
  return saveKey(stateKey, state, sessionStorage);
};

//When running multiple tabs at the same time mixes up data, so disable localStorage when testing sync.
const ENABLE_DEMO_STORAGE = true;
const DEMO_WORKSPACE_KEY = 'demoWorkspace';

export const clearDemoStorage = () => {
  localStorage.removeItem(DEMO_WORKSPACE_KEY);
  sessionStorage.removeItem(DEMO_WORKSPACE_KEY);
};

//Returns a promise for either the saved sherpa state validated against the current server commit SHA or undefined
export const loadSherpaState = async () => {
  //First get semaphone state, if true, then return undefined. Otherwise, update refresh flag
  if (!ENABLE_DEMO_STORAGE) {
    return undefined;
  }

  // eslint-disable-next-line no-unreachable
  const lastRefreshFlag = loadSessionKey('refreshInProgress') === true;

  if (lastRefreshFlag === true) {
    clearDemoStorage();
    return undefined;
  }

  //TODO - demo workspace should only be stored for a short period, so maybe automatically purge if stale or version is out of date.

  //Check for saved state in sessionStorage first, then localstorage
  //State is only placed in localStorage when creating a new account so not to interfere with multiple tabs
  let savedState = loadSessionKey(DEMO_WORKSPACE_KEY);
  if (savedState === undefined) {
    savedState = loadLocalKey(DEMO_WORKSPACE_KEY);
  }

  if (savedState === undefined) {
    return undefined;
  }

  //set state.shaperHub.shaperHubInitialized = false to force refresh on load
  if (savedState?.shaperHub?.shaperHubInitialized === true) {
    savedState.shaperHub.shaperHubInitialized = false;
  }

  //TODO - Add data migration when loading localStorage state
  if (savedState?.canvas?.version !== CANVAS_DATA_VERSION) {
    return undefined;
  }

  saveSessionKey('refreshInProgress', true);

  return getServerSHA().then((currentServerSHA) => {
    if (currentServerSHA === savedState?.shaperHub?.serverSHA) {
      return savedState;
    }
    clearDemoStorage();
    return undefined;
  });
};

export const saveSherpaSessionState = (state) => {
  // console.log('saving state');
  if (ENABLE_DEMO_STORAGE) {
    saveSessionKey(DEMO_WORKSPACE_KEY, state);
  }
};

export const checkBrowserForSavedState = () => {
  return (
    Object.keys(sessionStorage).includes(DEMO_WORKSPACE_KEY) ||
    Object.keys(localStorage).includes(DEMO_WORKSPACE_KEY)
  );
};

export const saveSherpaLocalState = (state) => {
  // console.log('saving state');
  if (ENABLE_DEMO_STORAGE) {
    saveLocalKey(DEMO_WORKSPACE_KEY, state);
  }
};

export const stateLoaded = () => {
  saveSessionKey('refreshInProgress', false);
};
