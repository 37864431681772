import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSecretOptions } from '../../Redux/Slices/SherpaContainerSlice';
import { defaultSecretOptions } from '../../defaults';

let stateHelper;
export function register(groupId, ...rest) {
  let [pathId, options] = rest;

  if (typeof pathId !== 'string') {
    options = pathId;
    pathId = undefined;
  }

  stateHelper({ groupId, pathId, options });
}

export function clear() {
  stateHelper({});
}

export default function HoverStateHelper() {
  const [styles, setStyles] = useState();
  const [{ groupId, pathId, options = {} }, setHoverState] = useState({});
  const secretOptions = useSelector(selectSecretOptions);
  stateHelper = setHoverState;

  // handle clearing events
  useEffect(() => {
    const clearState = () => stateHelper({});

    window.addEventListener('blur', clearState);
    return () => {
      window.removeEventListener('blur', clearState);
    };
  }, []);

  // when the target changes, update the css used
  useEffect(() => {
    const css = renderState({ groupId, pathId, options, secretOptions })
      ?.filter((item) => !!item)
      .join('\n\n');
    setStyles(css);
  }, [groupId, pathId]);

  // nothing to render
  if (!styles) {
    return null;
  }

  // helpers
  return <style type='text/css'>{styles}</style>;
}

function renderState({ groupId, pathId, options, secretOptions }) {
  return pathId
    ? renderPathCss(groupId, pathId, options, secretOptions)
    : groupId
    ? renderGroupCss(groupId, options, secretOptions)
    : null;
}

function renderGroupCss(groupId, { isTouch }, secretOptions) {
  const viewportLayer = `.mode--default .ui-viewport #layer-${groupId}`;
  const viewportDesignPath = `${viewportLayer}.design path`;
  const viewportUnselectedDesignPath = `${viewportLayer}.design .pathGroup.closed`;
  const viewportReferencePath = `${viewportLayer}.reference .pathGroup path`;
  const viewportReferenceSelectedPath = `${viewportLayer}.reference.selected .pathGroup path`;
  const viewportReferenceBackgroundPath = `${viewportLayer}.reference .pathGroup2 path`;
  const viewportSelectedPath = `${viewportLayer}.design.selected path`;
  const viewportReferenceSelectedBackgroundPath = `${viewportLayer}.reference.selected .pathGroup2 path`;
  const viewportSelectedPathClosed = `${viewportLayer}.design.selected .closed path`;
  const selectionEditorTile = `.selection-editor #selection-editor--sg-${groupId}`;
  const selectionEditorDesignTile = `${selectionEditorTile}.Design`;
  const selectionEditorGuideTile = `${selectionEditorTile}.Reference`;
  const selectionEditorPath = `${selectionEditorTile} path`;

  const {
    guideUnselectedHoverPathWidth,
    guideUnselectedHoverPathColor,
    objectUnselectedHoverPathColor,
    objectUnselectedHoverPathWidth,
    objectSelectedHoverPathColor,
    objectSelectedHoverPathWidth,
    guideSelectedPathColor,
  } = {
    ...defaultSecretOptions,
    ...secretOptions,
  };

  return [
    // prevent mouse events in the viewport (I don't remember why)
    `${viewportLayer} {
      pointer-events: auto !important;

    }`,

    // the design path within the viewport
    !isTouch &&
      `${viewportDesignPath} {
      stroke: #${objectUnselectedHoverPathColor};
      stroke-width: ${objectUnselectedHoverPathWidth}px;

    }`,

    `${viewportUnselectedDesignPath} {
      fill: var(--unselected-object-hover-fill);
    }`,

    // the guide path within the viewport
    !isTouch &&
      `${viewportReferencePath} {
      stroke: #EBB52E;
      stroke-width: 1.5px;
    }`,

    !isTouch &&
      `${viewportReferenceSelectedPath} {
        stroke: #E27718;
        stroke-width: 2px;
      }`,

    !isTouch &&
      `${viewportReferenceBackgroundPath} {
        stroke: #${guideUnselectedHoverPathColor};
        stroke-width: ${guideUnselectedHoverPathWidth}px;
        stroke-opacity: 0.12;
        vector-effect: non-scaling-stroke;
    }`,

    // the path within the viewport when selected
    `${viewportSelectedPath} {
      stroke: #${objectSelectedHoverPathColor};
      stroke-width: ${objectSelectedHoverPathWidth}px;
    }`,

    `${viewportReferenceSelectedBackgroundPath} {
      stroke: #${guideSelectedPathColor};
      stroke-opacity: .20 !important;
    }`,

    // only closed paths within the viewport when selected will get a fill
    `${viewportSelectedPathClosed} {
      fill: var(--hover-state--viewport-group--selected-hover--fill);
    }`,

    // the path within the selection editor
    `${selectionEditorPath} {
      stroke-width: 1.5px;
    }`,

    // the selection editor box itself - for regular objects
    `${selectionEditorDesignTile} {
      box-shadow: inset 0 0 0 2px #${objectUnselectedHoverPathColor};
    }`,

    // the selection editor box itself - for guides
    `${selectionEditorGuideTile} {
      box-shadow: inset 0 0 0 2px #${guideSelectedPathColor};
    }`,
  ];
}

function renderPathCss(groupId, pathId, { isTouch }, secretOptions) {
  const id = `sg-${groupId}-pg-${pathId}`;
  const selectionEditorTile = `.selection-editor #selection-editor--${id}`;
  const viewportCutPath = `.mode--plan .ui-viewport .pathGroup:not(.cut-type-guide) #cutPreviewToolWidth-${id}`;
  const viewportCutPreviewOnline = `.mode--plan .ui-viewport .pathGroup:not(.cut-type-guide) #cutPreviewOnline-${id}`;
  const basePreviewPath = `.mode--plan .ui-viewport .pathGroup #basePath-${id}`;
  const basePreviewSelectedPath = `.mode--plan .ui-viewport .pathGroup.selected #basePath-${id}`;
  const selectedViewportPath = `.mode--plan .ui-viewport .pathGroup.selected #basePath-${id}`;
  const selectedViewportToolWidth = `.mode--plan .ui-viewport #pathGroup-${id}.selected:not(.cut-type-pocket):not(.cut-type-guide) #cutPreviewToolWidth-${id}`;
  const selectedViewportPocket2ToolWidth = `.mode--plan .ui-viewport #pathGroup-${id}.selected.cut-type-pocket #cutPreviewToolWidth-${id}`;
  const selectedViewportPocketToolWidth = `.mode--plan .ui-viewport #cutPreviewToolWidthPattern-${id}`;

  return [
    `${selectionEditorTile} {
      box-shadow: inset 0 0 0 var(--hover-state--selection-editor--path-tile--hover-stroke-width) var(--hover-state--selection-editor--path-tile--hover-stroke);
    }`,

    `${viewportCutPath} {
      visibility: visible;
    }`,

    `${viewportCutPreviewOnline} {
      visibility: visible;
    }`,

    !isTouch &&
      `${basePreviewPath} {
      stroke-width: 3.5px;
      stroke: var(--hover-state--viewport-group--plan--unselected-hover--stroke);
    }`,

    !isTouch &&
      `${basePreviewSelectedPath} {
      stroke-width: var(--hover-state--viewport-path--basePath--hover-stroke-width);
      stroke: #1BE09E;
    }`,

    `${selectedViewportPath} {
      stroke-width: 4px !important;
    }`,

    `${selectedViewportToolWidth} {
      stroke: var(--hover-state--viewport-path--tool-width--selected-hover-stroke) !important;
    }`,

    `${selectedViewportPocketToolWidth} {
      visibility: visible !important;
    }`,

    `${selectedViewportPocket2ToolWidth} {
      fill: #1BE09E !important;
      stroke: #1BE09E !important;
      opacity: 0.5 !important;
    }`,
  ];
}
