import React from 'react';

// components
import { ModeOptionHeader } from '../ModeOptionMenu';
import UnitSelector from '../Components/UnitSelector';

export default function PlanModeOptionsMenu() {
  return (
    <>
      <UnitSelector />

      <ModeOptionHeader.Section
        title='Cut Path Display'
        icon='cut-paths'
        prop='useCutPaths'
        i18nKey='cut-paths'
      />
    </>
  );
}
