import Bounds from '@/Utility/bounds';
import { unitToMMNum } from '@/Geometry/UnitOps';
import estimateArea from '../../../Helpers/AreaDetection';

const NONE = Object.freeze({});

export default class SvgGroup {
  // creates a new Group instance
  constructor(ui, data, overrides = NONE) {
    this.data = data;
    this.ui = ui;
    this.overrides = overrides;

    // prepare the group
    this._init(ui, data, overrides);
  }

  getAABB() {
    const { left, right, top, bottom, cx, cy } = this;
    return { left, right, top, bottom, cy, cx };
  }

  get area() {
    return estimateArea(this.data);
  }

  get cutDepth() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams = this.data.basePathSet[0].cutParams;
    const { cutDepth } = cutParams || {};
    return unitToMMNum(cutDepth, 'in');
  }

  get bitDiameter() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams = this.data.basePathSet[0].cutParams;
    const { toolDia } = cutParams || {};
    return unitToMMNum(toolDia, 'in');
  }

  get cutOffset() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const [cutParams] = this.data.cutParams;
    const { cutOffset } = cutParams || {};
    return unitToMMNum(cutOffset, 'in');
  }

  get cutType() {
    // I'm not sure why svgGroup needs the first path's cutParams, but here you go
    const cutParams = this.data.basePathSet[0].cutParams;
    const { cutType } = cutParams || {};
    return cutType;
  }

  _init(ui, data, overrides) {
    // gather initial data
    let { rotation = 0, tool, anchor = 'center' } = data;
    let { x, y } = data.position;
    let { minPoint: min, maxPoint: max } = data.unrotatedAABB;

    // aabb info
    const aabb = {
      left: data.transformedAABB.minPoint.x,
      right: data.transformedAABB.maxPoint.x,
      top: data.transformedAABB.minPoint.y,
      bottom: data.transformedAABB.maxPoint.y,
    };

    // check for transform overrides
    const { translate, resize, rotate, data: dataOverride } = overrides;

    // defaults
    let width = max.x - min.x;
    let height = max.y - min.y;
    let scale;
    let stretchMatrix;

    // apply offsets
    if (translate) {
      x += translate.x;
      y += translate.y;

      aabb.left += translate.x;
      aabb.right += translate.x;
      aabb.top += translate.y;
      aabb.bottom += translate.y;
    }

    // apply resizing stretchMatrix
    if (resize) {
      [width, height] = [
        width * resize[0][0] + height * resize[0][1],
        height * resize[1][1] + width * resize[1][0],
      ];

      // TODO: aabb

      // shape scaling is required
      stretchMatrix = resize;
    }

    // check for rotation changes
    if (!isNaN(rotate)) {
      rotation += rotate;
    }

    // get the default bounding box
    const hw = width * 0.5;
    const hh = height * 0.5;
    const right = x + hw;
    const bottom = y + hh;
    const left = x - hw;
    const top = y - hh;

    // update with group info
    Object.assign(this, {
      width,
      height,
      left,
      right,
      top,
      bottom,

      // centers
      cx: (left + right) * 0.5,
      cy: (top + bottom) * 0.5,

      // current rotation
      rotation,

      // other props
      tool,
      scale,
      anchor,

      // is selected or not
      selected: data.selected,

      // required info
      id: data.id,

      // allow temporary matrices
      stretchMatrix,

      // include the original reference
      overrides,
      data,
      ui,

      ...(dataOverride && {
        id: dataOverride.id,
        data: dataOverride,
      }),
    });
  }

  get aabb() {
    return this.rotation ? Bounds(this) : this;
  }
}
