import React, { useEffect } from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';
import AlertAction from '@/Actions/Alert';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';

export default function InvalidRenameModal() {
  const modalActions = useAction(ModalActions);
  const alertAction = useAction(AlertAction);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  useEffect(() => {
    setTimeout(() => alertAction.clear(), 0);

    return () => {
      alertAction.set({
        msg: 'Invalid character',
        i18nKey: 'invalid-character',
        type: ALERT_TYPES.ERROR_DISMISSIBLE,
        modal: MODAL_TYPES.INVALID_CHARACTERS_MODAL,
        icon: 'alert-warning',
        duration: 2500,
      });
    };
  }, []);

  return (
    <Modal className='invalid-rename-modal'>
      <div className='invalid-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='alert-warning' />
            Invalid Character
          </Modal.Title>
          <div className='invalid-modal--info'>
            <TranslationText i18nkey='invalid-rename-modal-info'>
              Your rename action failed. Try a new name without any of the
              following characters:
            </TranslationText>
          </div>
          <div className='invalid-modal--text'>{'\\ / : ? * " < > |'}</div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
