import React from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectLoggedIn,
} from '@/Redux/Slices/ShaperHubSlice';

import logo from './logo.svg';
import TranslationText from '../../TranslationText/TranslationText';

export default function Logo() {
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLoggedIn = useSelector(selectLoggedIn);
  const isLite = (!isSubscriber && !isTrial) || isExpired;
  const validTrial = isTrial && !isExpired;

  const logoCx = classNames('application-menu--logo', {
    lite: isLite,
    demo: !isLite && !isLoggedIn,
  });

  return (
    <div className={logoCx}>
      <div className='application-menu--logo--image'>
        <img src={logo} alt='studio application menu logo' />
      </div>
      {isLoggedIn && isLite && (
        <div className='application-menu--logo--lite'>Lite</div>
      )}
      {!isLoggedIn && (
        <div className='application-menu--logo--demo' data-cy='demo-badge'>
          <TranslationText i18nKey='demo'>Demo</TranslationText>
        </div>
      )}
      {validTrial && !isSubscriber && (
        <div className='application-menu--logo--lite'>
          <TranslationText i18nKey='trial-badge'>Trial</TranslationText>
        </div>
      )}
    </div>
  );
}
