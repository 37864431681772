import { Helper } from 'dxf';
import { SvgOps } from './SvgParser';
import { getSizeAndCenterFromAABB } from './AABBOps';

const unitConstants = [
  { name: 'unitless', dxfId: 0, mmConversion: 1 },
  { name: 'in', dxfId: 1, mmConversion: 25.4 },
  { name: 'ft', dxfId: 2, mmConversion: 12 * 25.4 },
  { name: 'mi', dxfId: 3, mmConversion: 5280 * 12 * 25.4 },
  { name: 'mm', dxfId: 4, mmConversion: 1 },
  { name: 'cm', dxfId: 5, mmConversion: 10 },
  { name: 'm', dxfId: 6, mmConversion: 1000 },
  { name: 'km', dxfId: 7, mmConversion: 1000 * 1000 },
  { name: 'microinches', dxfId: 8, mmConversion: 25.4 / 1000000 },
  { name: 'mils', dxfId: 9, mmConversion: 25.4 / 1000 },
  { name: 'yards', dxfId: 10, mmConversion: 36 * 25.4 },
  { name: 'angstroms', dxfId: 11, mmConversion: 1e-7 },
  { name: 'nanometers', dxfId: 12, mmConversion: 1e-6 },
  { name: 'microns', dxfId: 13, mmConversion: 1e-3 },
  { name: 'decimeters', dxfId: 14, mmConversion: 100 },
  { name: 'decameters', dxfId: 15, mmConversion: 10000 },
  { name: 'hectometers', dxfId: 16, mmConversion: 1e5 },
  { name: 'gigameters', dxfId: 17, mmConversion: 1e12 },
  { name: 'astronomical units', dxfId: 18, mmConversion: 1.496e14 },
  { name: 'light years', dxfId: 19, mmConversion: 9.461e18 },
  { name: 'parsecs', dxfId: 19, mmConversion: 3.086e19 },
];

const unitlessUnit = unitConstants.find((u) => u.name === 'unitless');

const svgUnits = ['in', 'cm', 'mm'];

function getDxfUnitsFromHeader(dxfHeader: any) {
  const { insUnits } = dxfHeader;

  if (insUnits === undefined) {
    return unitlessUnit;
  }

  const units = unitConstants.find((u) => u.dxfId === insUnits);

  if (units === undefined) {
    return unitlessUnit;
  }
  return units;
}

function validateSvg(svg: string, unit: any) {
  const parser = new DOMParser();
  const svgDom = parser.parseFromString(svg, 'application/xml');

  //Get all svg root nodes -- should just be one, but multiple are technically legal
  const svgRootNodes = svgDom.getElementsByTagName('svg');

  //Dxf parser may return 0 size svg, which causes parser to crash
  const svgRoot = SvgOps.getSvgRootNode(svgDom);
  if (svgRoot) {
    const svgViewBox = SvgOps.getSvgViewBox(svgRoot);
    const { size } = getSizeAndCenterFromAABB(svgViewBox);

    if (size.x === 0 && size.y === 0) {
      throw new Error('DXF to SVG conversion results in empty SVG');
    }

    if (!!unit && unit.name !== 'unitless') {
      let svgUnit = unit;
      Array.from(svgRootNodes).forEach((svgNode) => {
        // For each root node, extract viewbox values
        let viewBox = svgNode.getAttribute('viewBox')?.split(' ');
        if (viewBox) {
          const isSvgUnit = svgUnits.includes(unit.name);
          if (!isSvgUnit) {
            // if unit is not legal svg unit:
            // multiply viewbox values by conversion factor
            // add scaling transform around all children of root node
            const { mmConversion } = unit;
            viewBox = viewBox.map((v) =>
              (parseFloat(v) * mmConversion).toString()
            );

            const xmlns = 'http://www.w3.org/2000/svg';
            const unitConversionGroup = svgDom.createElementNS(xmlns, 'g');
            unitConversionGroup.setAttribute(
              'transform',
              `scale(${mmConversion})`
            );

            svgNode.childNodes.forEach((c) => {
              unitConversionGroup.appendChild(c);
            });
            svgNode.appendChild(unitConversionGroup);
            svgUnit = unitConstants.find((u) => u.name === 'mm');
          }

          // set new vb and w, h values in root node
          const [width, height] = viewBox.slice(-2);
          svgNode.setAttribute('width', width + svgUnit.name);
          svgNode.setAttribute('height', height + svgUnit.name);
          svgNode.setAttribute('viewBox', viewBox.join(' '));
        }
      });
    }
  }

  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgDom);
}

export const convertDxfToSvg = function (rawDxf: string) {
  try {
    const helper = new Helper(rawDxf);
    if (helper && helper.parsed) {
      const units = getDxfUnitsFromHeader(helper.parsed.header);
      const svg = helper.toSVG();
      return validateSvg(svg, units);
    }
  } catch (err) {
    throw new Error(`Error processing DXF: ${err}`);
  }
};
