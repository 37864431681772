/* eslint-disable valid-jsdoc */
import { create, open, reconnect } from './../SyncThunks';
import { log } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { UnknownAction } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/store';
import InitializeAppAction from '@/Actions/InitializeApp';

/**
 * Reconnect listener that is only attached when the application idles
 * and disconnects a workspace
 */
export const reconnectListener = {
  predicate: (action: UnknownAction, currentState: RootState) => {
    const { enabled } = currentState.sync;
    return enabled && reconnect.match(action);
  },
  effect: async (
    _: UnknownAction,
    { dispatch, getState, unsubscribe }: SyncListenerApi
  ) => {
    log(
      'Reconnecting...',
      {
        ...context,
      },
      'debug'
    );

    const store = getState();
    const { reconnectId } = store.sync;
    if (reconnectId) {
      log(`Reconnecting workspace ${reconnectId}`, {
        ...context,
      });
      dispatch(
        open({
          workspaceId: reconnectId,
        })
      );

      /**
       * Detach the listener so that it is only used once
       */
    } else {
      log(
        'Unable to reconnect because there is no reconnect id... Will open most recent or create a new one',
        {
          ...context,
        }
      );
      const initializeAction = new InitializeAppAction(dispatch);
      const mostRecentWorkspaceId =
        await initializeAction.getMostRecentActiveWorkspaceId();
      if (mostRecentWorkspaceId) {
        dispatch(
          open({
            workspaceId: mostRecentWorkspaceId,
          })
        );
      } else {
        dispatch(create({}));
      }

      unsubscribe();
    }
  },
};
