import React from 'react';
import Icon from '@/Styles/Icons/Icon';

export default function Button(props) {
  // handles the click event
  function onClick(event) {
    props.onClick(props.value, event);
  }

  return (
    <div
      className={`properties-panel--button ${props.className || ''}`}
      data-cy={props.dataCy}
      onClick={onClick}
    >
      {props.icon && <Icon icon={props.icon} />}
      {props.children}
    </div>
  );
}
