import ScaleViewportAction from '@/Actions/ScaleViewport';
import BaseInteraction from './BaseInteraction';
import PanAndZoomViewportAction from '@/Actions/PanAndZoomViewport';

export default class PanAndZoomViewportInteraction extends BaseInteraction {
  interactionId = 'Pan and Zoom';

  onPinchStart() {
    const viewport = this.getViewport();
    this.priorScale = viewport.scale;
    this.action = this.createAction(PanAndZoomViewportAction);
    this.setActive(true);
  }

  onActivePinchMove(event) {
    if (this.priorScale === undefined) {
      const viewport = this.getViewport();
      this.priorScale = viewport.scale;
    }

    const zoomDiff = event.movement[0];
    const zoomDifference = 1 - zoomDiff;
    let scale = this.priorScale + zoomDifference;

    const { event: originalEvent } = event;
    const pos = { x: originalEvent.clientX, y: originalEvent.clientY };
    let scale2 = scale;
    this.scaleViewport(scale2, pos);

    // if (this.action) {
    //   this.action.panBy(-event.clientDelta.x, -event.clientDelta.y);
    // }
  }

  onPinchEnd() {
    this.setActive(false);

    // this.action?.resolve();
  }

  onEveryWindowExit() {
    this.setActive(false);

    this.action?.resolve();
  }

  scaleViewport(scale, point) {
    const action = this.createAction(ScaleViewportAction);
    action.scaleByAbsolute(scale, point);
  }
}
