import { clearSelection } from '@/Redux/Slices/SelectionSlice';
import { toggleShapeCreator } from '@/Redux/Slices/UISlice';

export default class ToggleShapeCreatorModeAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  activate = () => {
    const { dispatch } = this;

    dispatch(toggleShapeCreator(true));
    dispatch(clearSelection());
  };

  deactivate = () => {
    const { dispatch } = this;

    dispatch(toggleShapeCreator(false));
  };
}
