import SvgPathBuilder from './SvgPathBuilder';
import { transform } from '@/Geometry/PathOps';
import { invert } from '@/Geometry/sherpa-svg-generator/Matrix33';
import { getAABBSize } from '@/Geometry/AABBOps';
import { Point } from '@/Geometry/sherpa-svg-generator/Point';
import { Path } from '@/Geometry/sherpa-svg-generator/Path';
import { multiplyMatrix33 } from '@/Geometry/sherpa-svg-generator/Matrix33';

const TAU = Math.PI * 2;

//In theory, viewbox can be 1:1 with actual units. However, usvg parser tessellates curves with default 0.1 pixel precision. This leads to faceted arcs and splines if we don't upscale the viewbox and its contents
const viewBoxScale = 100;

function svgWrapper({ width, height, units, innerSvg } = {}) {
  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="${
    (-width / 2) * viewBoxScale
  } ${(-height / 2) * viewBoxScale} ${width * viewBoxScale} ${
    height * viewBoxScale
  }" width="${width}${units}" height="${height}${units}" >${innerSvg}</svg>`;
}

function createEllipse(options) {
  const { width, height, units } = options;
  const hw = (width / 2) * viewBoxScale;
  const hh = (height / 2) * viewBoxScale;

  const innerSvg = `<ellipse cx="0" cy="0" rx="${hw}" ry="${hh}"/>`;
  return { svg: svgWrapper({ width, height, units, innerSvg }) };
}

// generates a rectangle shape
function createRectangle(options) {
  const { width, height, units } = options;
  const scaledWidth = width * viewBoxScale;
  const scaledHeight = height * viewBoxScale;

  const innerSvg = `<rect x="${-scaledWidth / 2}" y="${
    -scaledHeight / 2
  }" width="${scaledWidth}" height="${scaledHeight}"/>`;
  return { svg: svgWrapper({ width, height, units, innerSvg }) };
}

function createPolygon(options) {
  let { circumradius, points, units } = options;

  const width = 2 * circumradius;
  const height = 2 * circumradius;
  const scaledCircumradius = circumradius * viewBoxScale;

  const angleIncrement = TAU / points;
  points = parseInt(points, 10);

  // generate the path
  const instructions = new SvgPathBuilder();

  // start by creating each point around the outside of the center
  // One side of the polygon should align with x-axis
  let vertexAngle = Math.PI / 2 - angleIncrement / 2;

  //Hugo - For a polygon with N sides, it's better to generate N points and then close the path with the SVG 'Z' command than to generate N+1 points (where the first and last points are the same). In this second case, there can be numerical rounding errors that leave a small gap between the start and end points.
  for (let i = 0; i < points; i++) {
    const isFirst = i === 0;

    // calculate the point
    const x = Math.cos(vertexAngle) * scaledCircumradius;
    const y = Math.sin(vertexAngle) * scaledCircumradius;

    // save the point and values
    instructions.add(isFirst ? 'M' : 'L', x, y);

    //HUGO - let's talk when we enable inset. There's probably an easier way to compute this.
    // check for the inset point
    // if (inset) {
    //   // get the interpolated midpoint
    //   const nvertexAngle = vertexAngle + angleIncrement;
    //   const cvertexAngle = vertexAngle + hp + TAU;
    //   const nx = Math.cos(nvertexAngle) * hw;
    //   const ny = Math.sin(nvertexAngle) * hh;
    //   const mx = mid(x, nx);
    //   const my = mid(y, ny);

    //   // calculate the distance to work with
    //   // from the middle back to the center
    //   const radian = Math.sqrt(mx * mx + my * my);

    //   // then calculate the inset point to use
    //   const cx = Math.cos(cvertexAngle) * (radian * inset);
    //   const cy = Math.sin(cvertexAngle) * (radian * inset);
    //   const ix = mx - cx;
    //   const iy = my - cy;

    //   // add the inset line
    //   instructions.add('L', ix, iy);
    // }

    // check for outside rounding
    // if (outside_rounding) {
    // }

    vertexAngle += angleIncrement;
  }
  //Tell the path builder that this is a closed path
  instructions.closePath();

  const innerSvg = instructions.generate();
  return { svg: svgWrapper({ width, height, units, innerSvg }) };
}

//Moved to tessellation phase
// const= options;
// const inRadius = diameter/2;
// const nPoints = 256; //Should be multiple of 8 to have flat edges aligned with 0, 45, 90, 135 deg, ... etc
// const interiorAngle = 2*Math.PI / nPoints;
// const circumradius = inRadius/Math.cos(interiorAngle/2);

// return createPolygon({...options, circumradius, points: nPoints});

//Because of numerical precision issues with Shapeshifter, we approximate circles as n-gons (e.g. 256-gons).
export function createAlignedNGonForCircle(diameter, eps = 0.01) {
  const radius = diameter / 2;
  const maxPolyAngle = 2 * Math.acos(1 - eps / radius);
  const minPolySides = Math.ceil((2 * Math.PI) / maxPolyAngle);

  //Make nGon with 8*N sides, where 8N >= minPolySides
  const sides = Math.ceil(minPolySides / 8) * 8;
  const angle = (2 * Math.PI) / sides;

  //Also, we slightly oversize the n-gon by using the circle diamater as the indiameter, rather then the polygon's circumdiameter. This gives an error of about .0001 in / in
  const circumRad = radius / Math.cos(angle / 2);

  // Generate polyline by walking circle
  // Use phase offset to align faces with vertical and horizontal axes
  const phaseOffset = -angle / 2;
  const pathPts = [];
  for (let i = 0; i < sides; i++) {
    pathPts.push(
      new Point(
        circumRad * Math.cos(i * angle + phaseOffset),
        circumRad * Math.sin(i * angle + phaseOffset)
      )
    );
  }

  return new Path({ points: pathPts, closed: true });
}

function createCircle(options) {
  const { diameter } = options;
  return createEllipse({
    ...options,
    type: 'ellipse',
    width: diameter,
    height: diameter,
  });
}

//TODO - refactor this to output everything but polygons as SVG shape elements. Polygon has to be path to handle rounded corners.

// handles preparing to generate shapes
export function createSvg(options) {
  //HUGO - The SVG parser assumes a default 96 pixels/in -- this matches the CSS definition of a pixel
  //So if SVG units are missing, then 96 (not 100) is the correct scaling factor for pixels to inches.

  // const defaultSvgPixelsPerIn = 96;
  // const adjusted = {
  //   ...options,
  //   diameter: parseFloat(options.diameter) * defaultSvgPixelsPerIn,
  //   width: parseFloat(options.width) * defaultSvgPixelsPerIn,
  //   height: parseFloat(options.height) * defaultSvgPixelsPerIn,
  //   points: parseInt(options.points, 10) || 0,
  //   radius: parseFloat(options.radius) || 0
  // };

  switch (options.type) {
    case 'circle':
      return createCircle(options);
    case 'ellipse':
      return createEllipse(options);
    case 'rectangle':
      return createRectangle(options);
    case 'rounded_rectangle':
      return createRectangle(options);
    case 'polygon':
      return createPolygon(options);
    default:
      throw new Error('Invalid shape type');
  }
}

export function getShearedRectAABB({ width, height }, shearMtx, rotateMtx) {
  const w2 = width / 2;
  const h2 = height / 2;
  const rectPts = [
    { x: -w2, y: -h2 },
    { x: w2, y: -h2 },
    { x: w2, y: h2 },
    { x: -w2, y: h2 },
  ].map((p) => new Point(p.x, p.y));

  const rectPath = new Path({ closed: true, points: rectPts });
  //Shear the path, then rotate to shape's local frame
  const RSMatrix = multiplyMatrix33(invert(rotateMtx), shearMtx);
  const shearedRect = transform(rectPath, RSMatrix);

  return getAABBSize(shearedRect.AABB);
}
