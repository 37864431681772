import { selectTemporaryAnchor } from '@/Redux/Slices/SelectionSlice';

import BaseAction from './BaseAction';
import ResizeGroupsAction from './ResizeGroups';
import RotateGroupsAction from './RotateGroups';
import SetSvgGroupAnchorAction from './SetSvgGroupAnchor';
import { batch } from 'react-redux';

export default class MirrorAction extends BaseAction {
  mirror(groups, { horizontal, vertical }) {
    if (!groups.length) {
      return;
    }

    batch(() => {
      const anchor = this.getCurrentAnchor(groups);

      // check the anchor combination
      const isVerticalAnchor = ['rm', 'lm'].includes(anchor);
      const isHorizontalAnchor = ['tm', 'bm'].includes(anchor);
      const isMidAnchor =
        (horizontal && isHorizontalAnchor) || (vertical && isVerticalAnchor);

      // check if this should use the anchor when determining
      // the resize change
      let useAnchor;
      if (!isMidAnchor) {
        useAnchor = anchor;
      }

      // perform the resize first
      const resize = this.createAction(ResizeGroupsAction, groups, useAnchor);
      resize.resizeByScale(horizontal ? -1 : 1, vertical ? -1 : 1);
      resize.resolve();

      // fix the anchor
      if (!isMidAnchor) {
        this.mirrorAnchor(groups, { horizontal, vertical });
      }

      // update each rotation
      if (groups.length === 1) {
        const [group] = groups;
        const rotate = this.createAction(RotateGroupsAction, [group]);
        const delta = -(group.rotation - -group.rotation);
        rotate.rotateByRadians(delta);
        rotate.resolve();
      }
    });
  }

  getCurrentAnchor(groups) {
    const temporaryAnchor = this.useSelector(selectTemporaryAnchor);
    return (
      (groups.length > 1 ? temporaryAnchor : groups[0]?.anchor) || 'centroid'
    );
  }

  mirrorAnchor(groups, { horizontal, vertical }) {
    const anchor = this.getCurrentAnchor(groups);

    // can't mirror center
    if (['center', 'centroid'].includes(anchor) || !(horizontal || vertical)) {
      return;
    }

    // figure out the anchor direction
    const anchorTo = this.createAction(SetSvgGroupAnchorAction);
    const opposite = SetSvgGroupAnchorAction.getOppositeAnchorRelativeToAxis(
      anchor,
      horizontal ? 'h' : 'v'
    );

    // fix appropriately
    if (groups.length > 1) {
      anchorTo.setTemporaryAnchor(opposite);
    } else if (groups.length === 1) {
      anchorTo.setAnchor(
        groups.map((group) => group.id),
        opposite
      );
      anchorTo.setAnchor([groups[0].id], opposite);
    }
  }
}
