import BaseAction from './BaseAction';
import AddGeometryAction from '@/Actions/AddGeometry';
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';

import {
  clearSearchResults,
  newIconSearch,
} from '@/Redux/Slices/IconSearchSlice';

export default class IconSearchAction extends BaseAction {
  search(query, page = 1, append = false) {
    this.previousQuery = query;
    this.dispatch(newIconSearch({ query, page, append }));
  }

  clear() {
    this.dispatch(clearSearchResults());
  }

  add(icon, query = this.previousQuery) {
    const { id: iconId, iconURI: uriSVG, iconSVG: rawSVG } = icon;
    const params = { iconId, query };
    const addGeometryAction = this.createAction(AddGeometryAction);
    const viewbox = this.useSelector(selectSVGViewbox);
    const position = getPositionFromSvgViewbox(viewbox);

    // add to the view
    addGeometryAction.addSvg(
      'icon',
      { rawSVG, uriSVG, tool: { type: 'icon', params } },
      position
    );
  }
}
