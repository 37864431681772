import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { trim } from 'lodash';

import { defaultFont, createTextSvg, getFonts } from '@/Helpers/TextCreator';
import { SvgOps } from '@/Geometry/SvgParser';

import InteractionCapture from '@/Components/InteractionCapture';
import TextInsertFooterMenu from '../FooterMenu/Menus/TextInsert';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { selectFeatures } from '@/Redux/Slices/UISlice';
import AlertAction from '@/Actions/Alert';
import { useAction } from '@/Actions/useAction';
import { ALERT_TYPES } from '@/defaults';
import { entitlements } from '@/Helpers/Entitlements';

export function TextEditor(props) {
  const isEditing = !!props.textParams;
  const inputRef = useRef();
  const [characterCount, setCharacterCount] = useState();
  const alertAction = useAction(AlertAction);

  const hasAllFonts = selectFeatures(entitlements.TEXT);
  const defaultFonts = getFonts(hasAllFonts);

  const text = props.textParams?.text ?? '';
  const fontDisplayName =
    props.textParams?.fontDisplayName || defaultFont.fontDisplayName;
  const fontSelection =
    props.textParams?.fontSelection || defaultFont.fontSelection;
  const forceOpenPaths =
    props.textParams?.fontSelection?.forceOpenPaths || false;

  function getSelectedCharacterCount() {
    let selected;
    if (window.getSelection) {
      selected = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      selected = document.selection.createRange()?.text;
    }
    return selected?.length || 0;
  }

  function refocusInput(e) {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function onBeginInteraction() {
    const chars = getSelectedCharacterCount();
    setCharacterCount(chars);
  }

  function onVoidClick() {
    const chars = getSelectedCharacterCount();
    if (chars !== characterCount) {
      return;
    }

    onCancel();
  }

  function onCancel() {
    props.onCancel();
  }

  function onCommit() {
    const current = trim(inputRef.current.value);

    // require text before inserting
    if (!current.length) {
      return;
    }

    const [fontToUse, styleToUse] = (() => {
      if (!SvgOps.isTextSupportedByFont(current, fontSelection.fontFamily)) {
        // The current font doesn't support the text the user entered. See if we can find another font that does

        const firstCompatibleFont = defaultFonts.find((font) =>
          SvgOps.isTextSupportedByFont(current, font.fontStyles[0].fontFamily)
        );
        if (firstCompatibleFont) {
          alertAction.set({
            msg: 'Font changed to support all characters',
            i18nKey: 'fallback-font-applied',
            type: ALERT_TYPES.DEFAULT_DISMISSIBLE,
            icon: 'font',
            className: 'fallback-font-applied-alert',
            duration: 10000,
          });

          return [
            firstCompatibleFont.fontDisplayName,
            firstCompatibleFont.fontStyles[0],
          ];
        }

        const fonts = SvgOps.getFontsUsedToShapeText(
          current,
          fontSelection.fontFamily
        );
        if (fonts.length > 0) {
          alertAction.set({
            msg: 'Multiple fonts applied to support all characters',
            i18nKey: 'multiple-fallback-fonts-applied',
            type: ALERT_TYPES.DEFAULT_DISMISSIBLE,
            icon: 'font',
            className: 'fallback-font-applied-alert',
            duration: 10000,
          });

          const firstFallbackFont = defaultFonts.find(
            (font) => font.fontStyles[0].fontFamily === fonts[0]
          );
          return [
            firstFallbackFont.fontDisplayName,
            firstFallbackFont.fontStyles[0],
          ];
        }
      }

      // Use whatever was already selected
      return [fontDisplayName, fontSelection];
    })();

    const svg = createTextSvg(current, fontToUse, styleToUse, forceOpenPaths);
    if (props.onCommit) {
      props.onCommit(svg, isEditing);
    }
  }

  // check for the enter key
  function onKeyDown(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (/enter/i.test(e.key)) {
      e.preventDefault();
      return onCommit();
    }

    if (/escape/i.test(e.key)) {
      e.preventDefault();
      return onCancel();
    }
  }

  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current.value = text;
  }, []);

  const placeholderI18nKey = isEditing ? 'edit-text' : 'insert-text';
  const placeholder = props.i18n.exists(placeholderI18nKey)
    ? props.t(placeholderI18nKey)
    : isEditing
    ? 'Edit Text'
    : 'Insert Text';
  const textEditorCx = classNames('component__text-editor', 'text-editor', {
    'hide-placeholder': (text || '').length > 0,
  });

  return (
    <>
      <div className='text-edit-ui-header' data-cy='text-edit-ui'>
        <Icon icon='edit-text' />
        <div>
          <TranslationText i18nKey='edit-text'>Text Edit</TranslationText>
        </div>
      </div>
      <TextInsertFooterMenu onCommit={onCommit} onClose={onCancel} />
      <InteractionCapture
        className={textEditorCx}
        onPointerUp={onVoidClick}
        onPointerDown={onBeginInteraction}
      >
        <div
          className='component__text-editor__editor'
          onPointerUp={refocusInput}
        >
          <input
            className='component__text-editor__input'
            placeholder={placeholder}
            onPointerDown={onBeginInteraction}
            onKeyDown={onKeyDown}
            ref={inputRef}
            data-cy='add-text-input'
          />
        </div>
      </InteractionCapture>
    </>
  );
}
