import React from 'react';

import { useSelector } from 'react-redux';
import { getSubscribeLink } from '@/Utility/subscribe-link';
import { selectShaperMarketingUrl } from '@/Redux/Slices/ShaperHubSlice';

import Icon from '@/Styles/Icons/Icon';
import TranslationText from '../../TranslationText/TranslationText';

export default function UpgradeSubscription() {
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);

  function openSubscribeLink() {
    const subscribePath = getSubscribeLink();
    window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
  }

  return (
    <div className='properties-panel--upgrade' data-cy='add-text-upgrade-cta'>
      <div className='properties-panel--upgrade-info'>
        <TranslationText i18nKey='additional-fonts'>
          additional fonts for subscribers:
        </TranslationText>
      </div>
      <div
        className='properties-panel--upgrade-button'
        onClick={openSubscribeLink}
      >
        <div>
          <TranslationText i18nKey='subscribe'>Subscribe</TranslationText>
        </div>
        <Icon icon='arrow-right' />
      </div>
    </div>
  );
}
