import React from 'react';

// components
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';

// helpers
import classNames from 'classnames';

export default function Tab(props) {
  const { label, icon, selected: isSelected, i18nKey, onClick } = props;

  const tabCx = classNames('toggle-button--tab', {
    selected: isSelected,
  });

  const iconCx = classNames('toggle-button--tab--icon', {
    selected: isSelected,
  });

  const textCx = classNames('toggle-button--tab--text', {
    selected: isSelected,
  });

  return (
    <div className={tabCx} onClick={onClick}>
      <Icon className={iconCx} icon={icon} />
      <div className={textCx}>
        <TranslationText i18nKey={i18nKey}>{label}</TranslationText>
      </div>
    </div>
  );
}
