import React from 'react';

export default function Pattern() {
  return (
    <defs>
      <pattern
        id='plan-mode_pocket_white'
        viewBox='-7 -7 38 38'
        width='22%'
        height='25%'
      >
        <path d='M26.0586 11.9412L30.9998 7' stroke='#FDFDFE' />
        <path d='M26.0588 26.0588L31 31' stroke='#FDFDFE' />
        <path d='M7 30.9999L11.9412 26.0587' stroke='#FDFDFE' />
        <path d='M7.00023 7.00012L11.9414 11.9413' stroke='#FDFDFE' />
      </pattern>
    </defs>
  );
}
