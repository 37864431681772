import {
  createAction,
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';

import { getGroupById, getPathById } from '@/Redux/Slices/CanvasSlice';
import {
  PATH_TYPES,
  getTypeProperty,
} from '@/Geometry/sherpa-svg-generator/PathTypes';
import { PATH_TYPE } from '@/Geometry/sherpa-svg-generator/Path';
import { RootState } from '../store';
import { SvgGroup } from '@/Geometry/sherpa-svg-generator/SvgGroup';
import {
  doRefreshPathSelection,
  doSetSelection,
  doUpdateActiveSelection,
  doSelectMostRecentlyAddedGroup,
} from '@/Selection/SelectionHelpers';
import { Handle } from '@/@types/shaper-types';

const getSelectionBounds = function (
  selectedGroupIds: string[],
  svgGroupSet: SvgGroup[],
  temporaryAnchor: Handle | null
) {
  const selectedGroups = selectedGroupIds
    .map((gId) => svgGroupSet.find((sg) => sg.id === gId))
    .filter((sg) => !!sg); //filter for valid items
  return SelectionBoxOps.getSelectionBounds(selectedGroups, temporaryAnchor);
};

const getSelectionAnchorPosition = function (
  state: RootState,
  selectedGroupIds: string[],
  svgGroupSet: SvgGroup[]
) {
  const selectedGroups = selectedGroupIds.map((gId) =>
    svgGroupSet.find((sg) => sg.id === gId)
  );
  const [group] = selectedGroups;
  if (group) {
    const isSingleSelection = !!selectedGroups.length;
    const anchor = isSingleSelection
      ? group.anchor
      : state.selection.temporaryAnchor;
    const rotation = isSingleSelection ? group.rotation : 0;
    const bounds = SelectionBoxOps.getSelectionBounds(selectedGroups, anchor);
    return SelectionBoxOps.getAnchoredPosition(
      bounds,
      bounds.centroidPosition,
      rotation,
      anchor
    );
  }
};

type GroupIds = string[];
type PathIds = { groupId: string; pathId: string }[];

export type SelectionState = {
  groupIds: GroupIds;
  pathIds: PathIds;
  temporaryAnchor: Handle | null;
};

export const initialState: SelectionState = {
  groupIds: [],
  pathIds: [], //{groupId, pathId}
  temporaryAnchor: null,
};

export type GroupAndPathId = { groupId: string; pathId: string };

export const setSelection = createAction<PathIds>('selection/setSelection');
export const updateActiveSelection = createAction(
  'selection/updateActiveSelection'
);
export const refreshPathSelection = createAction(
  'selection/refreshPathSelection'
);
export const selectMostRecentlyAddedGroup = createAction<{
  duplicateCount?: number;
}>('selection/selectMostRecentlyAddedGroup');

export const slice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    // replaces selections assuming the logic for using
    // correct selections has been performed elsewhere
    replaceSelection: (
      state,
      action: PayloadAction<{ groupIds: GroupIds; pathIds: PathIds }>
    ) => {
      const { groupIds, pathIds } = action.payload;
      state.groupIds = groupIds;
      state.pathIds = pathIds;
      state.temporaryAnchor = null;
    },

    setTemporaryAnchor: (state, action: PayloadAction<Handle>) => {
      state.temporaryAnchor = action.payload;
    },

    clearTemporaryAnchor: (state) => {
      state.temporaryAnchor = null;
    },

    // clear all selections
    clearSelection: (state) => {
      state.groupIds = [];
      state.pathIds = [];
      state.temporaryAnchor = null;
    },
  },
});

export const {
  replaceSelection,
  clearSelection,
  setTemporaryAnchor,
  clearTemporaryAnchor,
} = slice.actions;

export const middleware: Middleware = (store) => (next) => (action) => {
  if (isAction(action) && action.type && action.type.includes('selection/')) {
    const { canvas, ui, selection } = store.getState();
    const dispatch = store.dispatch;

    const mode = ui.mode;
    if (setSelection.match(action)) {
      const { groupIds, pathIds } = doSetSelection(action.payload, canvas);
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (updateActiveSelection.match(action)) {
      const { groupIds, pathIds } = doUpdateActiveSelection(
        selection,
        canvas,
        mode
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (refreshPathSelection.match(action)) {
      const { groupIds, pathIds } = doRefreshPathSelection(
        selection,
        canvas,
        mode
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (selectMostRecentlyAddedGroup.match(action)) {
      const { groupIds, pathIds } = doSelectMostRecentlyAddedGroup(
        action.payload,
        canvas
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
  }

  // perform the update
  next(action);
};

export const selectHasSelection = (state: RootState) => {
  const mode = `${state.ui.mode}`;
  return (
    state.selection.groupIds
      .map((gId) => getGroupById(state, gId))
      .filter((g) => getTypeProperty(g?.type || PATH_TYPE.DESIGN, mode))
      .length > 0
  );
};
export const selectSelectedGroupIds = (state: RootState) =>
  state.selection.groupIds;
export const selectSelectedPathIds = (state: RootState) =>
  state.selection.pathIds;
export const selectSelectionType = (state: RootState) => {
  if (state.selection.groupIds && state.selection.groupIds.length > 0) {
    const firstSelection = state.selection.groupIds
      .map((gId) => getGroupById(state, gId))
      .at(0);
    if (firstSelection) {
      return PATH_TYPES[firstSelection.type || PATH_TYPE.DESIGN];
    }
  }
  return null;
};

export const selectSelectedGroups = (state: RootState) =>
  state.selection.groupIds
    .map((gId) => getGroupById(state, gId))
    .filter((g) => g !== null)
    .filter((g) => getTypeProperty(g?.type || PATH_TYPE.DESIGN, state.ui.mode));

export const selectSelectedPaths = (state: RootState) =>
  state.selection.pathIds
    .map((idPair) => getPathById(state, idPair.pathId, idPair.groupId))
    .filter((g) => g && g.path !== null);

export const selectSelectionBounds = (state: RootState) =>
  getSelectionBounds(
    state.selection.groupIds,
    state.canvas.canvas.svgGroupSet,
    state.selection.temporaryAnchor
  );
export const selectSelectionBoundsWithGroupOverrides =
  (state: RootState) => (overrides: object) =>
    getSelectionBounds(
      state.selection.groupIds,
      Object.values({
        ...state.canvas.canvas.svgGroupSet.reduce(
          (obj: any, group: any) => ({ ...obj, [group.id]: group }),
          {}
        ),
        ...overrides,
      }),
      state.selection.temporaryAnchor
    );
export const selectSelectionAnchorPosition = (state: RootState) =>
  getSelectionAnchorPosition(
    state,
    state.selection.groupIds,
    state.canvas.canvas.svgGroupSet
  );
export const selectTemporaryAnchor = (state: RootState) =>
  state.selection.temporaryAnchor;
export const selectActiveAnchor = (state: RootState) => {
  const isSingleSelection = state.selection?.groupIds?.length === 1;
  const defaultAnchor = 'center';

  // if this is a single selected object and has an anchor
  if (isSingleSelection) {
    const group = getGroupById(state, state.selection.groupIds[0]);
    return group?.anchor || defaultAnchor;
  }

  return state.selection.temporaryAnchor || defaultAnchor;
};

export const actions = slice.actions;
export default slice.reducer;
