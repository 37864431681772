import React from 'react';
import classNames from 'classnames';

export default function Switch(props) {
  function onToggle() {
    props.onToggle(!props.active);
  }

  const switchCx = classNames('mode-menu--switch', {
    active: !!props.active,
  });

  return <div className={switchCx} onClick={onToggle} />;
}
