//Polyfill for Array.prototype.flat
//Not supported in Jest
if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'flat', {
    value: function (depth = 1) {
      return this.reduce(function (flat, toFlatten) {
        return flat.concat(
          Array.isArray(toFlatten) && depth > 1
            ? toFlatten.flat(depth - 1)
            : toFlatten
        );
      }, []);
    },
    configurable: true,
  });
}

// Applies fn(A,B) for each aSet, bSet.
// After each iteration, aSet may grow, shrink, or stay the same size
// Returns final set
// function fn returns a single value, an array of multiple values, or undefined if no value
// final set is flattened and undefined values removed

export const AMutBMap = function (aSet, bSet, fn) {
  let nextSet = aSet;
  for (const b of bSet) {
    nextSet = nextSet
      .map((n) => fn(n, b))
      .flat()
      .filter((n) => n !== undefined);
  }
  return nextSet;
};

// Applies fn(A,B) for each aSet, bSet.
// After each iteration, both aSet and bSet may grow, shrink, or stay the same size
// Returns last mut aSet, bSet
// function fn returns a pair of values {aNext:, bNext}, each of which may be a single value, an array of multiple values, or undefined if no value
// fn should return b if b unchanged
// final set is flattened and undefined values removed

export const AMutBMutMap = function (aSet, bSet, fn) {
  let nextASet = aSet;
  let nextBSet = [];
  //aSet may be mutated by each element of b. b may or may not be mutated by fn()
  for (const b of bSet) {
    const { nextASetStacked, nextB } = nextASet.map((nA) => fn(nA, b));
    //Clean up nextASet
    nextASet = nextASetStacked.flat().filter((nA) => nA !== undefined);
    nextBSet.push(...nextB);
  }
  return { nextASet, nextBSet };
};
