import { PATH_TYPE } from './Path';

interface IPathType {
  type?: PATH_TYPE;
  icon?: string;
  propertyEditingDisabled?: boolean;
  selectionOrder?: number;
  selectability?: boolean;
  contextual?: boolean;
  default?: boolean;
  plan?: boolean;
  review?: boolean;
  snapping?: boolean;
  shapeshifter?: boolean;
  anchorSelection?: boolean;
  canExport?: boolean;
  [key: string]: any; // eslint-disable-line no-undef
}

class PathType {
  type: PATH_TYPE;
  icon: string;
  propertyEditingDisabled: boolean;
  selectionOrder: number;
  selectability: boolean;
  contextual: boolean;
  default: boolean;
  plan: boolean;
  review: boolean;
  snapping: boolean;
  shapeshifter: boolean;
  'anchor-selection': boolean;
  'shape-builder': boolean;
  canExport: boolean;
  [key: string]: any; // eslint-disable-line no-undef

  constructor(config: IPathType) {
    this.type = config.type || PATH_TYPE.DESIGN;
    this.icon = config.icon || 'path-design';
    this.propertyEditingDisabled = config.propertyEditingDisabled || false;
    this.selectionOrder = config.selectionOrder || 0;
    this.selectability = config.selectability ?? true;
    this.contextual = config.contextual ?? true;
    this.default = config.default ?? true;
    this.plan = config.plan ?? true;
    this.review = config.review ?? true;
    this.snapping = config.snapping ?? true;
    this.shapeshifter = config.shapeshifter ?? true;
    this['anchor-selection'] = config.anchorSelection ?? true;
    this['shape-builder'] = config.shapeBuilder ?? true;
    this.canExport = config.canExport ?? true;
  }
}

const design = new PathType({
  type: PATH_TYPE.DESIGN,
  icon: 'path-design',
  propertyEditingDisabled: false,
  selectionOrder: 0,
  selectability: true,
  contextual: true,
  default: true,
  plan: true,
  review: true,
  snapping: true,
  shapeshifter: true,
});
const reference = new PathType({
  type: PATH_TYPE.REFERENCE,
  icon: 'path-reference',
  propertyEditingDisabled: true,
  selectionOrder: 3,
  selectability: false,
  contextual: false,
  default: true,
  plan: false,
  review: false,
  snapping: false,
  shapeshifter: false,
  anchorSelection: false,
  canExport: false,
});

const PATH_TYPES = {
  [PATH_TYPE.DESIGN]: design,
  [PATH_TYPE.REFERENCE]: reference,
};

const getTypeProperty = (type: PATH_TYPE, property: string) => {
  const pathType = PATH_TYPES[type];
  return pathType ? pathType[property] : false;
};

export { PATH_TYPES, getTypeProperty };
