//Geometry classes for sherpa
//For compatibility with redux, geometry is represented as plain objects without added methods

//Factory classes create and update geometry classes, returning plain objects

//Redux toolkit includes Immer, so we can modify input classes safely and library will handle immutability.

//All internal dims are in mm

import { create, all } from 'mathjs';

const config = { matrix: 'Array' };
const math = create(all, config);

//Create svg specific units px, pt, etc
math.createUnit('px', 96 / 25.4 + ' mm');
math.createUnit('pc', (1 / 6) * 25.4 + ' mm');

//pt is defined as pint, so need to use point
math.createUnit('point', (1 / 72) * 25.4 + ' mm');

// already exist in mathjs
// math.createUnit('in', `${(25.4 * 1)} mm`);
// math.createUnit('ft', `${(25.4 * 12)} mm`);
// math.createUnit('m', `${1000} mm`);

// additional units
math.createUnit('y', `${25.4 * 12 * 3} mm`);
math.createUnit('cm', `${10} mm`);
math.createUnit('km', `${1000000} mm`);

export { math as default };
