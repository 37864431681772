import 'react-app-polyfill/stable';

//IMPORTANT - POLYFILL IMPORT MUST BE FIRST IMPORT
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import userflow from 'userflow.js';

import { setStore } from './Actions/useAction';

import App from './App';
import BrowserError from '@/Components/BrowserError/BrowserError';
import MaintenancePage from './Components/MaintenancePage/MaintenancePage';
import minimumSupportedBrowsers from '@/supportedBrowsers.js';
import { isSupportedBrowser } from '@shapertools/supported-browsers';

import './index.css';
import './i18n';

import { init } from './Sync/SyncLog';
import setupStore from './Redux/store';
import { loadSherpaState, saveSherpaSessionState } from './Redux/localStorage';
import reviewPathGenerator from './Geometry/DepthClippedSvg';
import { SET_REVIEW_PATH_LOADER } from './Redux/Slices/UISlice';
import { throttle } from 'lodash';
import { handleUnsupportedBrowser } from './Utility/unsupported-browser';

//ClipperLib logs errors using browser alert FFS, so override alert prototype to throw an error instead.
window.alert = (msg) => {
  throw new Error(msg);
};

//Used to load workspace from link
function getStudioURLParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  //Reset history
  history.pushState(null, '', window.location.pathname);

  //workspaceId, newWorkspace, and blobId are mutually exclusive
  const workspaceId = urlSearchParams.get('workspaceId') ?? null;
  const blobId = urlSearchParams.get('blobId') ?? null;
  if (workspaceId) {
    const duplicate = urlSearchParams.get('duplicate') ?? null;
    if (duplicate) {
      return { workspaceId, duplicate, blobId };
    }
    return { workspaceId, blobId };
  }

  const newWorkspace = !!(urlSearchParams.get('newWorkspace') ?? null);
  if (newWorkspace) {
    const pathParam = urlSearchParams.get('path');
    return {
      newWorkspace,
      ...(pathParam && {
        path: pathParam,
      }),
    };
  }

  if (blobId) {
    return { blobId };
  }

  const success = urlSearchParams.get('success') ?? null;
  if (success !== null) {
    //will be an empty string, need to explicitly check for null
    history.pushState(null, document.title, '/?success');
  }

  return {};
}

function renderApplication(store, studioUrlParams) {
  // render the view
  ReactDOM.render(
    <Provider store={store}>{rootComponent(studioUrlParams)}</Provider>,
    document.getElementById('root')
  );
}

let rootComponent;
(async () => {
  const studioUrlParams = getStudioURLParams();

  const supportedBrowser = isSupportedBrowser([], minimumSupportedBrowsers);

  rootComponent = (() => {
    if (import.meta.env.VITE_ENABLE_MAINTENANCE_PAGE === 'true') {
      return () => <MaintenancePage />;
    } else if (supportedBrowser.isSupported) {
      return (studioUrlParam) => <App studioUrlParams={studioUrlParam} />;
    }
    return () => <BrowserError />;
  })();

  const preloadedState = await loadSherpaState();
  const store = setupStore({
    environment: import.meta.env.MODE,
    preloadedState,
  });

  reviewPathGenerator.setStore(store);
  reviewPathGenerator.busyReduxAction = SET_REVIEW_PATH_LOADER;

  if ('__playwright__' in window && window.__playwright__) {
    window.__store__ = store;
  }

  store.subscribe(
    throttle(() => {
      const state = store.getState();

      if (
        state.sherpaContainer.sherpaInitialized &&
        !state.shaperHub.loggedIn
      ) {
        saveSherpaSessionState(state);
      }
    }, 1000)
  );

  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      normalizeDepth: 10,
      environment: import.meta.env.VITE_SENTRY_ENV || 'development',
      ignoreErrors: [
        'Uncaught ReferenceError: ClipperLib is not defined',
        'Could not load Userflow.js',
        'ResizeObserver loop limit exceeded',
        'UserflowError',
        "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        /extensions/,
        /^chrome:\/\//,
        /^safari-extension:\/\//,
        'CredentialsInvalid',
        'TypeError:',
        'Transaction aborted',
        '{}',
      ],
    });
  }

  init(import.meta.env.VITE_LOGGLY_TOKEN);

  if (supportedBrowser && !supportedBrowser.isSupported) {
    handleUnsupportedBrowser(supportedBrowser);
  }

  userflow.init(import.meta.env.VITE_USERFLOW_KEY);
  userflow.on('flowEnded', (e) => {
    history.pushState(null, '', window.location.pathname);
  });

  setStore(store);

  renderApplication(store, studioUrlParams);
})();
