export const getDeveloperSettings = (key: string) => {
  const developerSettings = JSON.parse(
    localStorage.getItem('devSettings') ?? '{}'
  );
  if (developerSettings) {
    const setting = developerSettings[key];
    return setting;
  }
  return null;
};

export const setDeveloperSettings = (key: string, value: any) => {
  const developerSettings = localStorage.getItem('devSettings') || '{}';
  const devSettings = JSON.parse(developerSettings);

  localStorage.setItem(
    'devSettings',
    JSON.stringify({
      ...devSettings,
      [key]: value,
    })
  );
};
