import React from 'react';

export default function SelectionNet(props) {
  return (
    <rect
      className='selection-net'
      x={props.left}
      y={props.top}
      width={props.right - props.left}
      height={props.bottom - props.top}
    />
  );
}
