const LOG_CLIPPER_PERFORMANCE = false;

function performanceLogSync(fcn: Function, msg: string) {
  const start = performance.now();
  const result = fcn();
  if (LOG_CLIPPER_PERFORMANCE) {
    console.log(`${msg} ${performance.now() - start}`);
  }
  return result;
}

function performanceLogAsync(fcn: Function, msg: string) {
  const start = performance.now();
  return fcn().then((result: any) => {
    if (LOG_CLIPPER_PERFORMANCE) {
      console.log(`${msg} ${performance.now() - start}`);
    }
    return result;
  });
}

function difference(obj1: object, obj2: object) {
  const diffInFields = Object.entries(obj2).filter(
    ([field, obj2Value]) =>
      JSON.stringify(obj1[field as keyof object]) !== JSON.stringify(obj2Value)
  );
  return diffInFields;
}

const removeAttrFromObject = <O extends object, A extends keyof O>(
  object: O,
  attr: A
): Omit<O, A> => {
  const newObject = { ...object };

  if (attr in newObject) {
    delete newObject[attr];
  }

  return newObject;
};

export {
  performanceLogAsync,
  performanceLogSync,
  difference,
  removeAttrFromObject,
};
