import { batch } from 'react-redux';
import { updateShaperHubWorkspace } from '@/Redux/Slices/ShaperHubSlice';
import { selectWorkspaceInformation } from '@/Redux/Slices/ShaperHubSlice';
import BaseAction from './BaseAction';
import { toggleRename } from '@/Redux/Slices/UISlice';
import { getShaperHubExternalItem } from '../ShaperHub/ShaperHubThunks';

export default class RenameWorkspaceAction extends BaseAction {
  constructor(dispatch, useSelector) {
    super(dispatch, useSelector);
    this.dispatch = dispatch;
  }

  renameWorkspace = async (newName) => {
    const workspace = this.useSelector(selectWorkspaceInformation);
    const patch = {
      name: workspace.name,
      newName,
      path: workspace.path,
    };

    return batch(async () => {
      const update = await this.dispatch(updateShaperHubWorkspace(patch));
      if (update.error) {
        return update.error.message;
      }
      this.dispatch(getShaperHubExternalItem(workspace.externalItemId));
      this.dispatch(toggleRename(false));
      return 0;
    });
  };
}
