type XmlNs = {
  name: string;
  URI: string;
};

const shaperNs: XmlNs = {
  name: 'shaper',
  URI: 'http://www.shapertools.com/namespaces/shaper',
};

// All currently valid XML attributes in the shaper NS
type ShaperSvgXml = {
  importOptions?: string;
  svgType?: string;
  cutType?: string;
  cutDepth?: string;
  cutOffset?: string;
  toolDia?: string;
};

// Crude attempt to enforce type checking at runtime
const ShaperSvgXmlAttributes: (keyof ShaperSvgXml)[] = [
  'importOptions',
  'svgType',
  'cutType',
  'cutDepth',
  'cutOffset',
  'toolDia',
];

/* eslint-disable no-unused-vars */
export enum ImportOptions {
  ViewBoxOriginIsGridOrigin = 'vb-origin-is-grid-origin',
}
/* eslint-enable no-unused-vars */

function extractShaperXmlAttributes(svgStr: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svgStr, 'image/svg+xml');
  const rootElement = xmlDoc.documentElement;

  const attributesInNamespace: Partial<ShaperSvgXml> = {};
  for (let attr of rootElement.attributes) {
    if (attr.namespaceURI === shaperNs.URI) {
      if (
        ShaperSvgXmlAttributes.includes(attr.localName as keyof ShaperSvgXml)
      ) {
        attributesInNamespace[attr.localName as keyof ShaperSvgXml] =
          attr.value;
      }
    }
  }
  return attributesInNamespace;
}

export default extractShaperXmlAttributes;
