import * as React from 'react';
import classNames from 'classnames';

import { Tooltip } from '@/Components/Tooltip/Tooltip';

export default function Toggle(props) {
  function onToggleSwitch() {
    if (props.disabled) {
      return;
    }

    props.onToggle();
  }

  const toggleCx = classNames('component__toggle-switch', {
    'is-active': props.active,
    'is-disabled': props.disabled,
    [`component__toggle-switch__${props.size}`]: props.size,
  });

  const toggle = (
    <div className={toggleCx} onClick={onToggleSwitch}>
      {props.children && (
        <div className='component__toggle-switch__label'>{props.children}</div>
      )}
      <div className='component__toggle-switch__switch' />
    </div>
  );

  return Tooltip.wrap(props.tooltip, props.side, toggle);
}
