import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Components
import Icon from '@/Styles/Icons/Icon';
import SecretMenuPanel from './SecretMenuPanel';

//selectors
import {
  toggleSecretMenu,
  selectIsShowingSecretMenu,
} from '@/Redux/Slices/UISlice';

export default function SecretMenu() {
  const dispatch = useDispatch();

  const isShowingSecretMenu = useSelector(selectIsShowingSecretMenu);

  // activates an action
  const onToggleSecretMenu = () => {
    dispatch(toggleSecretMenu(!isShowingSecretMenu));
  };

  const icon = isShowingSecretMenu ? 'close' : 'eye';
  const iconCx = `secret-menu--item-icon ${icon}`;

  return (
    <div className='secret-menu'>
      <div className='secret-menu--launcher' onClick={onToggleSecretMenu}>
        <div className={iconCx}>
          <Icon icon={icon} />
        </div>
      </div>
      <div className='secret-menu-panel'>
        {isShowingSecretMenu && <SecretMenuPanel />}
      </div>
    </div>
  );
}
