import { CutType } from './CutParams';

/**
 *
 * @param {CutType} cutType - {@link CutType} enum value that helps define CSS attributes for display/exporting purposes
 * @returns {object[]} returns an array of CSS Properties formatted as `{name: '', value: ''}`
 *
 * @also
 * `Anchor` is defined here for exporting purposes. Should not be expected as an actual Cut Type
 */
const getSvgPathAttrs = (cutType: CutType) => {
  switch (cutType) {
    case CutType.ONLINE:
      return [
        { name: 'stroke', value: '#7F7F7F' },
        { name: 'fill', value: 'none' },
        { name: 'stroke-width', value: '0.1' },
      ];
    case CutType.GUIDE:
      return [
        { name: 'fill', value: '#0068FF' },
        { name: 'stroke', value: '#0068FF' },
        { name: 'stroke-width', value: '0.1' },
      ];

    case CutType.INSIDE:
      return [
        { name: 'stroke', value: '#000000' },
        { name: 'stroke-width', value: '0.1' },
        { name: 'fill', value: '#FFFFFF' },
      ];

    case CutType.POCKET:
      return [{ name: 'fill', value: '#7F7F7F' }];
    case CutType.OUTSIDE:
      return [
        { name: 'stroke-width', value: '0.1' },
        { name: 'fill', value: '#000000' },
      ];
    case CutType.ANCHOR:
      return [{ name: 'fill', value: '#FF0000' }];
    default:
      throw Error('Unknown cut type');
  }
};

export default getSvgPathAttrs;
