import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import SelectAnchorInteraction from './Interactions/SelectAnchor';

export default class AnchorSelectionMode extends InteractionMode {
  theme = 'default anchor-selector';

  getInteractionHandlers() {
    return [
      SelectAnchorInteraction,
      TranslateViewportInteraction,
      ZoomViewportInteraction,
    ];
  }
}
