import BaseAction from './BaseAction';
import ApplySvgChangeSetAction from '@/Actions/ApplySvgChangeSet';
import SetSelectionAction from '@/Actions/SetSelection';

export default class UpdateToolParamsAction extends BaseAction {
  constructor(dispatch, useSelector, selectedGroups) {
    super();

    this.dispatch = dispatch;
    this.selectedGroups = selectedGroups;
  }

  pendingChanges = {};

  apply(target, params) {
    this.pendingChanges[target] = params;
  }

  async resolve() {
    const changes = {};

    // apply to all selected groups, but limit
    // to the target types
    for (const group of this.selectedGroups) {
      const { id, tool } = group;
      const apply = this.pendingChanges[tool?.type];

      // nothing was found
      if (!apply) {
        console.error(
          `No shape params of type "${
            tool?.type || 'none'
          }" found for group ${id}`
        );
        continue;
      }

      // save the change set
      changes[id] = {
        toolParams: {
          ...tool.params,
          ...apply,
        },
      };
    }

    // apply updates
    const changeSet = this.createAction(ApplySvgChangeSetAction);
    await changeSet.apply(changes);

    // update the selection
    const selection = this.createAction(SetSelectionAction);
    selection.refresh();
  }
}
