import React, { useEffect, useRef, useState } from 'react';
import { useAction } from '@/Actions/useAction';

// utils
import { trim } from 'lodash';
import { cancelEvent } from '@/Utility/events';
import { ALERT_TYPES, menuLinks } from '../../defaults';
import classNames from 'classnames';

// actions
import ImportGeometryAction from '@/Actions/ImportGeometry';
import UIFeatureAction from '@/Actions/UIFeature';
import FileSystemAction from '@/Actions/FileSystem';
import AlertAction from '@/Actions/Alert';

//selectors
import { useSelector } from 'react-redux';
import { selectScreenViewMode } from '@/Redux/Slices/ViewportSlice';
import { selectFeatures } from '../../Redux/Slices/UISlice';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';

// components
import Icon from '@/Styles/Icons/Icon';
import FloatingPanel from '../FloatingPanel/FloatingPanel';
import ProjectImport from './ProjectImport';
import TranslationText from '../TranslationText/TranslationText';
import { entitlements } from '@/Helpers/Entitlements';

export default function FileImportContainer() {
  const targetRef = useRef();
  const importGeometryAction = useAction(ImportGeometryAction);
  const uiFeatureAction = useAction(UIFeatureAction);
  const fileSystemAction = useAction(FileSystemAction);
  const alertAction = useAction(AlertAction);

  const [showMyProjects, setShowMyProjects] = useState(false);

  const viewMode = useSelector(selectScreenViewMode);
  const isMobile = viewMode === 'mobile';
  const workspaceId = useSelector(selectWorkspaceId);

  const hasImport = selectFeatures(entitlements.IMPORT) || false;

  function onClose() {
    uiFeatureAction.toggleFileImport(false);
  }

  async function processFile(file) {
    // get the file type being
    const { name } = file;
    let [type] = name.toLowerCase().match(/\.[^.]*$/);
    type = trim(type).substr(1);

    // read the file contents
    return new Promise((resolve, reject) => {
      // try and read the contents
      const reader = new FileReader();
      reader.onload = (event) => {
        const { result: content } = event.target;

        // not supported
        if (!['svg', 'dxf'].includes(type)) {
          reject(type);
        }

        // notify this worked
        resolve({ name, type, content });
      };
      reader.readAsText(file);
    });
  }

  async function dispatchFileImport(file) {
    try {
      // eslint-disable-next-line no-use-before-define
      const imported = await processFile(file);
      switch (imported.type) {
        case 'svg':
          importGeometryAction.importSVG(imported.content);
          break;
        case 'dxf':
          importGeometryAction.importDXF(imported.content);
          break;
        default:
          alertAction.set({
            msg: 'Unsupported File Type',
            i18nKey: 'import-unsupported',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
          });
          throw new Error('Unsupported file type?');
      }
    } catch (ex) {
      console.log('unsupported', ex);
      alertAction.set({
        msg: 'Import Failed',
        i18nKey: 'import-failed',
        type: ALERT_TYPES.ERROR_DISMISSIBLE,
      });
    }
  }

  // simple handling of incoming files
  async function onDropFile(event) {
    cancelEvent(event);

    // process each file
    for (const file of event.dataTransfer.files) {
      await dispatchFileImport(file);
    }
  }

  // must stop drag over events
  function onDragOver(event) {
    cancelEvent(event);
  }

  function openFileBrowser() {
    if (hasImport) {
      document.getElementById('file-input').click();
    }
  }

  function importFromProjects() {
    if (hasImport) {
      setShowMyProjects(!showMyProjects);
      fileSystemAction.toggleMyProjects();
    }
  }

  function importFromTrace() {
    window.open(menuLinks.TRACE + `/?workspaceId=${workspaceId}`, '_self');
  }

  // mount
  useEffect(() => {
    targetRef.current?.addEventListener('dragover', onDragOver);
    targetRef.current?.addEventListener('drop', onDropFile);

    return () => {
      targetRef.current?.removeEventListener('dragover', onDragOver);
      targetRef.current?.removeEventListener('drop', onDropFile);
    };
  }, []);

  const mobileOptionCx = classNames('file-import--mobile--option', {
    disabled: !hasImport,
  });

  return (
    <>
      <FloatingPanel
        className={showMyProjects ? 'project-import' : 'file-import'}
        container
        edge='left'
        floatingCloseButton
        onClose={onClose}
      >
        {showMyProjects ? (
          <ProjectImport />
        ) : (
          <>
            <div className='file-import--close-button' onClick={onClose}>
              <Icon icon='close' />
            </div>
            <div className='file-import--header'>
              <TranslationText i18nKey='import'>Import</TranslationText>
            </div>
            {isMobile ? (
              <>
                <div className='file-import--mobile'>
                  <div className='file-import--mobile--header'>From:</div>
                  <div className='file-import--mobile--content'>
                    <div
                      className='file-import--mobile--option'
                      onClick={importFromTrace}
                    >
                      <Icon icon='trace' />
                      <div className='file-import--mobile--option-text'>
                        Trace
                      </div>
                    </div>
                    <div
                      className={mobileOptionCx}
                      onClick={openFileBrowser}
                      data-cy='file-import-select-file-mobile'
                    >
                      <Icon icon='devices' />
                      <div className='file-import--mobile--option-text'>
                        <TranslationText i18nKey='this-device'>
                          This Device
                        </TranslationText>
                        <input
                          type='file'
                          id='file-input'
                          style={{ display: 'none' }}
                          onChange={(evt) =>
                            dispatchFileImport(evt.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={mobileOptionCx}
                      onClick={importFromProjects}
                    >
                      <Icon icon='projects' />
                      <div className='file-import--mobile--option-text'>
                        <TranslationText i18nKey='my-projects'>
                          My Files
                        </TranslationText>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='file-import--drop-target' ref={targetRef}>
                  <div className='file-import--drop-target--icon'>
                    <Icon>import-file</Icon>
                  </div>
                  <div className='file-import--drop-target--instructions'>
                    <TranslationText i18nKey='drag-drop'>
                      Drag and drop here
                    </TranslationText>
                  </div>
                  <div className='file-import--drop-target--requirements'>
                    DXF, SVG, 20MB max
                  </div>
                  <div className='file-import--drop-target--select-file'>
                    <div
                      className='file-import--select-file'
                      onClick={openFileBrowser}
                    >
                      <TranslationText i18nKey='select-file'>
                        Select File
                      </TranslationText>
                      <input
                        type='file'
                        id='file-input'
                        style={{ display: 'none' }}
                        onChange={(evt) =>
                          dispatchFileImport(evt.target.files[0])
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='file-import--additional'>
                  <TranslationText i18nKey='or'>Or</TranslationText>
                </div>
                <div
                  className='file-import--additional-options'
                  onClick={importFromProjects}
                >
                  <div className='file-import--select-projects'>
                    <Icon icon='projects' />
                    <div>
                      <TranslationText i18nKey='my-projects'>
                        My Files
                      </TranslationText>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </FloatingPanel>
      <div className='file-import--overlay' />
    </>
  );
}
