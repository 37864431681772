import BoundingBox from './Helpers/BoundingBox';
import SvgGroup from './Helpers/SvgGroup';
import Viewport from './Helpers/Viewport';

import {
  selectSelectedGroupIds,
  selectSelectedPathIds,
  selectSelectionBoundsWithGroupOverrides,
} from '@/Redux/Slices/SelectionSlice';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';

import { useSelector } from '@/Actions/useAction';
import { isNumber } from 'lodash';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';

const NONE = Object.freeze({});

// handles tracking UI state
export default class UIState {
  // set the initial time
  static lastUpdate = Date.now();

  // the temporary values for updating
  static intermediate = NONE;

  static setListener = (listener) => {
    UIState.listener = listener;
  };

  // triggers a render
  static render = () => {
    UIState.lastUpdate = Date.now();
    (UIState.listener || (() => {}))(UIState.lastUpdate);
  };

  // allows an action to apply changes immediately
  static apply = (action) => {
    const update = UIState.update();
    action(update);
    update.apply();
  };

  // creates an update object for a series of changes
  static update = (initialState = {}) => {
    const update = { ...UIState.intermediate, ...initialState };

    update.apply = () => {
      delete update.apply;
      UIState.intermediate = update;
      UIState.render();
    };

    return update;
  };

  // resets the intermediate layer and renders again
  static reset = (silent) => {
    UIState.intermediate = NONE;

    // render again
    if (!silent) {
      UIState.render();
    }
  };

  // creates a new instance of a UI state
  static create(Mode, store) {
    const state = new Mode(store, UIState.intermediate);
    state.init();
    return state;
  }

  // tracking groups and selections
  groups = [];
  selectedGroups = [];
  changedGroupIds = {};

  constructor(store, overrides) {
    this.store = store;
    this.overrides = overrides;
    this.hasChanges = overrides !== NONE;
    this.useSelector = useSelector;
  }

  get uiMode() {
    return this.store?.ui;
  }

  get hasBounds() {
    return !!this.boundingBox;
  }

  get hasAlignmentGuides() {
    return !!this.alignmentGuides;
  }

  get hasSelectionNet() {
    return !!this.selectionNet;
  }

  get selectionBounds() {
    return this.useSelector(selectSelectionBoundsWithGroupOverrides)(
      Object.values(this.overrides.groups || {}).reduce(
        (obj, group) => ({
          ...obj,
          ...(group.data && {
            [group.data.id]: group.data,
          }),
        }),
        {}
      )
    );
  }

  get selectedGroupIds() {
    return this.useSelector(selectSelectedGroupIds);
  }

  get selectedPathIds() {
    return this.useSelector(selectSelectedPathIds);
  }

  get svgGroups() {
    return this.useSelector(selectSvgGroupSet);
  }

  applyOverridesToPoint(...args) {
    const points = [];

    // check the args provided
    for (let arg of args) {
      if (isNumber(arg)) {
        points.push(arg);
      } else if (arg && 'x' in arg && 'y' in arg) {
        points.push(arg.x, arg.y);
      }
    }

    // get the coordinates to use
    let [x, y] = points;

    // check for override changes
    if (this.overrides && !this.overrides?.rotate && !this.overrides?.resize) {
      x += this.overrides?.translate?.x || 0;
      y += this.overrides?.translate?.y || 0;
    }

    return { x, y };
  }

  // creates a viewport from state
  defineViewport() {
    this.viewport = new Viewport(this, this.overrides);
  }

  defineSelectionNet() {
    this.selectionNet = this.overrides.selectionNet;
  }

  defineAlignmentGuides() {
    this.alignmentGuides = this.overrides.alignmentGuides;
  }

  defineBoundingBox() {
    const { selectionBounds, hasSelection, overrides } = this;

    // only bounding box when selected
    if (!(selectionBounds && hasSelection)) {
      return;
    }

    // save a bounding box
    this.boundingBox = new BoundingBox(this, overrides);
  }

  defineGroups() {
    const { overrides, selectedGroupIds, svgGroups } = this;

    this.selectionType = (() => {
      const selected = svgGroups.find((g) =>
        selectedGroupIds.length > 0 ? g.id === selectedGroupIds[0] : null
      );
      if (selected) {
        return PATH_TYPES[selected?.type];
      }
      return PATH_TYPES.Design;
    })();

    for (const data of svgGroups) {
      const override = overrides.groups && overrides.groups[data.id];
      const group = new SvgGroup(this, data, override);
      this.changedGroupIds[group.id] = override;
      this.groups.push(group);

      // check if selected
      if (selectedGroupIds.includes(group.id)) {
        this.selectedGroups.push(group);
      }
    }
  }
}
