export function cancelEvent(event) {
  if (event.stopPropagation) {
    event.stopPropagation();
  }

  if (event.stopImmediatePropagation) {
    event.stopImmediatePropagation();
  }

  if (event.nativeEvent?.stopImmediatePropagation) {
    event.nativeEvent.stopImmediatePropagation();
  }

  if (event.preventDefault) {
    event.preventDefault();
  }
}
