import * as _ from 'lodash';

// checks if this object is a standard selection
export function isSelection(a) {
  return _.isNumber(a?.pathId) && _.isNumber(a?.groupId);
}

// creates a standard selection object
// TODO - deprecate this once we have a consistent selection object
export function toSelection(path) {
  const pathId = _.isNumber(path.path) ? path.path : path.id;
  const groupId = _.isNumber(path.parent?.id)
    ? path.parent.id
    : _.isNumber(path.parent)
    ? path.parent
    : _.isNumber(path.group?.id)
    ? path.group.id
    : _.isNumber(path.group)
    ? path.group
    : null;

  return { pathId, groupId, path: path, group: path.group }; //TODO - deprecate path and group properties
}

function compareIds(a, b) {
  // const aIds = toSelection(a);
  // const bIds = toSelection(b);
  return a.pathId === b.pathId && a.groupId === b.groupId;
}

// converts paths into a standard group/path selection
export function createSelection(selections) {
  return _(selections).map(toSelection).compact().value();
}

// selects a path
export function selectSvgGroupPath(selection, canvas, mapPathToGroup = false) {
  for (const group of canvas.svgGroupSet) {
    // nothing to check here
    if (!group.id !== selection.group) {
      continue;
    }

    // check each path
    for (const path of group.cutPathSet) {
      if (path.id === selection.path) {
        return mapPathToGroup ? { ...path, group } : path;
      }
    }
  }
}

// selects an array of paths
export function selectAllSvgGroupPaths(
  selections,
  canvas,
  mapPathToGroup = false
) {
  return _(selections)
    .map((selection) => selectSvgGroupPath(selection, canvas, mapPathToGroup))
    .compact()
    .value();
}

// checks if the selection is the same as before
export function isSameSelection(a, b) {
  const result = _.xorWith(a, b, compareIds);
  return result.length === 0;
}

// gets the unique group IDs from a selection
export function getGroupIds(a) {
  return _(a)
    .map((item) => item.group)
    .uniq();
}
