import React from 'react';
import Icon from '@/Styles/Icons/Icon';

export default function Label(props) {
  return (
    <>
      <div className='footer-menu--label'>
        {props.icon && <Icon>{props.icon}</Icon>}
        {props.children}
      </div>

      {/*
      This is a silly extra element to allow breaking up the title
      when on mobile. It might be possible with CSS alone, but at the 
      moment, nothing was working so I used this to get the feature done
      we can revisit this later
    */}
      <div className='footer-menu--break' />
    </>
  );
}
