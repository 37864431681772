import { setScaleBy, setScaleByAbsolute } from '@/Redux/Slices/ViewportSlice';

export default class ScaleViewportAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  get screenCenter() {
    return {
      x: window.innerWidth * 0.5,
      y: window.innerHeight * 0.5,
    };
  }

  scaleBy(scale, scaleOriginScreen = this.screenCenter) {
    const { dispatch } = this;

    dispatch(
      setScaleBy({
        scale,
        scaleOriginScreen,
      })
    );
  }

  scaleByAbsolute(scale, scaleOriginScreen = this.screenCenter) {
    const { dispatch } = this;

    dispatch(
      setScaleByAbsolute({
        scale,
        scaleOriginScreen,
      })
    );
  }
}
