import { AABB, transform as AABBTransform, getAABBSize } from './AABB';
import { BasePath } from './BasePath';
import { Matrix33, initialMatrix33, multiplyMatrix33 } from './Matrix33';
import { PATH_TYPE, Path } from './Path';
import { Point, transform as ptTransform } from './Point';
import { v4 as uuidv4 } from 'uuid';
import { getAABB } from './BasePath';
import { createRotationMtx, createStretchMtx } from './Matrix33';
import { immerable } from 'immer';

export const defaultSvgGroupSize = {
  metric: 25,
  imperial: 25.4,
};

/**
 * @enum {Shape} - predefined shapes Circle, Ellipse, Rectangle, Rounded Rectangle, Polygon, and Unknown
 */
/* eslint-disable no-unused-vars */
export enum Shape {
  CIRCLE = 'circle',
  ELLIPSE = 'ellipse',
  RECTANGLE = 'rectangle',
  ROUNDED_RECT = 'rounded_rectangle',
  POLYGON = 'polygon',
  TEXT = 'text-insert',
  UNKNOWN = '',
}
/* eslint-enable no-unused-vars */

/**
 * @type ToolParams
 * @property {number} diameter - diameter of bit
 * @property {string} units - units as represented by `in` or `mm`
 * @property {number} width - width of the {@link Shape}
 * @property {number} height - height of the {@link Shape}
 * @property {number} points - points of the {@link Shape}, specifically used for Polygon
 * @property {number} inset - inset of the {@link Shape}
 * @property {number} radius - radius of the {@link Shape}, specifically used for Rounded Rectangles
 * @property {number} circumradius - circumradius of the {@link Shape}, specifically used for Polygon
 * @property {string} postProcess - post process to be applied to the {@link Shape}, right now, 'round' is the only post process that applies
 */
export interface ToolParams {
  diameter?: number;
  units: string;
  width: number;
  height: number;
  points?: number;
  inset?: number;
  radius?: number;
  circumradius?: number;
  postProcess?: string;
  fontDisplayName?: string;
  fontDisplayStyle?: string;
  forceOpenPaths?: boolean;
  text?: string;
}

/**
 * @type {Tool} - Class to define the {@link Shape} and {@link ToolParams} of a {@link SvgGroup}
 * @property {Shape} type - See implementation details in {@link Shape}
 * @property {ToolParams} params - See implementation details in {@link ToolParams}
 */
export class Tool {
  [immerable] = true;
  type: Shape;
  params: ToolParams;

  constructor(type?: Shape, params?: ToolParams) {
    this.type = type ?? Shape.UNKNOWN;
    if (params) {
      this.params = { ...params };
    } else {
      switch (this.type) {
        case Shape.CIRCLE:
          this.params = { diameter: 1, units: 'in', width: 1, height: 1 };
          break;
        case Shape.ELLIPSE:
          this.params = { width: 1, height: 0.5, units: 'in' };
          break;
        case Shape.RECTANGLE:
          this.params = { width: 1, height: 1, units: 'in' };
          break;
        case Shape.ROUNDED_RECT:
          this.params = {
            radius: 0.2,
            width: 1,
            height: 1,
            units: 'in',
            postProcess: 'round',
          };
          break;
        case Shape.POLYGON:
          this.params = {
            circumradius: 0.5,
            points: 5,
            inset: 0,
            units: 'in',
            width: 1,
            height: 1,
          };
          break;
        case Shape.UNKNOWN:
        default:
          this.params = { width: 1, height: 1, units: 'in' };
          break;
      }
    }
  }
}

/**
 * @type {ISvgGroup} - Wrapper interface for passing in configuration values for an SvgGroup. See implementation of {@link SvgGroup} for more information
 */
export interface ISvgGroup {
  id?: string;
  type?: PATH_TYPE;
  position?: Point;
  rotation?: number;
  basePathSet?: BasePath[];
  postProcessPathSet?: BasePath[];
  origin?: Point;
  displaySize?: Point;
  tool?: Tool;
  stretchMtx?: Matrix33;
  rotateMtx?: Matrix33;
  translateMtx?: Matrix33;
  TRSMtx?: Matrix33;
  anchor?: string;
  transformedAABB?: AABB;
  baseAABB?: AABB;
  unrotatedAABB?: AABB;
  svgStr?: string;
  repairPaths?: boolean;
  useGroupSize?: boolean;
  generatedTs?: number;
  [key: string]: any;
}

/**
 * A function to get the scale of the svg as a {@link Point}
 * @param {AABB} baseAABB - the AABB without any transforms applied
 * @param {Point} displaySize - the display size of the svg represented as a {@link Point}
 * @returns {Point} the resulting scale of the svg as represented as a {@link Point} with x indicating width and y indicating height
 */
export function getSvgGroupScale(baseAABB: AABB, displaySize: Point) {
  const baseSize = getAABBSize(baseAABB);
  return new Point(displaySize.x / baseSize.x, displaySize.y / baseSize.y);
}

function basePathTransform(
  basePath: BasePath | BasePath[],
  mtx: Matrix33 = initialMatrix33
): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp) => basePathTransform(bp, mtx)).flat();
  }
  const params = {} as BasePath;
  if (basePath.outerPath) {
    params.outerPath = new Path({
      closed: basePath.outerPath.closed,
      points: basePath.outerPath.points.map((pt) => ptTransform(pt, mtx)),
    });
    params.AABB = basePath.outerPath.AABB;
  }
  if (basePath.holePaths) {
    params.holePaths = basePath.holePaths.map(
      (hp) =>
        new Path({
          closed: hp.closed,
          points: hp.points.map((pt) => ptTransform(pt, mtx)),
        })
    );
  }
  if (basePath.points) {
    params.points = basePath.points.map((p) => ptTransform(p, mtx));
    params.AABB = new AABB({ points: params.points });
  }

  return new BasePath({
    ...basePath,
    ...params,
    closed: basePath.closed,
  });
}

/**
 * @type {SvgGroup} - a class that holds all of the properties of an SvgGroup which is used to hold BasePaths and it's relevant transforms and params
 * @property {string} id - unique, generated UUID
 * @property {PATH_TYPE} type - see implementation details in {@link PATH_TYPE}
 * @property {Point} position - position of the SvgGroup as defined as a {@link Point}
 * @property {number} rotation - angle of the rotation
 * @property {BasePath[]} basePathSet - array of {@link BasePath} that represents the Paths in the SvgGroup
 * @property {BasePath[]} postProcessPathSet - similar to `basePathSet` but has the `postProcess` applied as defined in {@link Shape}, under `postProcess`
 * @property {Point} origin - origin of the SvgGroup as defined as a {@link Point}
 * @property {Point} displaySize - size of the SvgGroup as defined as a {@link Point} (x being the width, y being the height)
 * @property {Tool} tool - see implementation details in {@link Tool}
 * @property {Matrix33} stretchMtx - {@link Matrix33} representation of the stretch values
 * @property {Matrix33} rotateMtx - {@link Matrix33} representation of the rotation values
 * @property {Matrix33} translateMtx - {@link Matrix33} representation of the translation values
 * @property {Matrix33} TRSMtx - a combined {@link Matrix33} that contains the translate, rotation, stretch values, applied in that order (T * R * S)
 * @property {string} anchor - position of the anchor (tr, br, tl, bl, tm, bm, lm, rm)
 * @property {AABB} transformedAABB - the {@link AABB} after all the transforms have been applied
 * @property {AABB} baseAABB - the {@link AABB} WITHOUT any transforms applied
 * @property {AABB} unrotatedAABB - the {@link AABB} after the translation and stretch {@link Matrix33} has been applied
 * @property {number} generatedTs - a timestamp to represent the last time the SvgGroup was updated. Particularly useful for creating review paths
 */
export class SvgGroup {
  [immerable] = true;
  id: string;
  type: PATH_TYPE;
  position: Point;
  rotation: number;
  basePathSet: BasePath[];
  postProcessPathSet: BasePath[];
  origin?: Point;
  displaySize: Point;
  tool: Tool;
  stretchMtx: Matrix33;
  rotateMtx: Matrix33;
  translateMtx: Matrix33;
  TRSMtx: Matrix33;
  anchor: string;
  transformedAABB: AABB;
  baseAABB: AABB;
  unrotatedAABB: AABB;
  generatedTs: number;
  [key: string]: any; // eslint-disable-line no-undef

  constructor(opts?: ISvgGroup) {
    this.id = uuidv4();
    if (opts) {
      this.type = opts.type || PATH_TYPE.DESIGN;
      this.position = opts.position || new Point();
      this.rotation = opts.rotation || 0.0;
      this.basePathSet = opts.basePathSet || [];
      this.postProcessPathSet = opts.postProcessPathSet || [];
      this.displaySize =
        opts.displaySize ||
        new Point(defaultSvgGroupSize.imperial, defaultSvgGroupSize.imperial);
      this.tool = opts.tool || new Tool();
      this.stretchMtx = opts.stretchMtx || createStretchMtx();
      this.anchor = opts.anchor || 'center';
      if (opts.origin) {
        this.origin = opts.origin;
      }
      this.transformedAABB = opts.transformedAABB || new AABB();
      this.baseAABB = opts.baseAABB || new AABB();
      // if (opts.repairPaths !== undefined) {
      // }
      // if (opts.useGroupSize !== undefined) {
      // }
      this.unrotatedAABB = opts.unrotatedAABB || new AABB();
    } else {
      this.type = PATH_TYPE.DESIGN;
      this.id = uuidv4();
      this.position = new Point();
      this.rotation = 0.0;
      this.basePathSet = [];
      this.postProcessPathSet = [];
      this.displaySize = new Point(
        defaultSvgGroupSize.imperial,
        defaultSvgGroupSize.imperial
      );
      this.tool = new Tool();
      this.stretchMtx = [
        [1, 0, 0],
        [1, 0, 0],
        [0, 0, 1],
      ];
      this.anchor = 'center';
      this.transformedAABB = new AABB();
      this.baseAABB = new AABB();
      this.unrotatedAABB = new AABB();
    }

    const baseCutPaths = this.basePathSet;
    const baseAABB = getAABB(baseCutPaths);

    const scale = (() => {
      if (this.stretchMtx === undefined) {
        return getSvgGroupScale(baseAABB, this.displaySize);
      }
      return new Point(this.stretchMtx[0][0], this.stretchMtx[1][1]);
    })();

    const cloneStretchMtx = (() => {
      if (this.stretchMtx === undefined) {
        return [
          [scale.x, 0, 0],
          [0, scale.y, 0],
          [0, 0, 1],
        ] as Matrix33;
      }
      return this.stretchMtx;
    })();

    const rotateMtx = createRotationMtx(this.rotation);
    this.translateMtx = (() => {
      const tMtx = [
        [1, 0, this.position.x],
        [0, 1, this.position.y],
        [0, 0, 1],
      ] as Matrix33;
      if (this.origin) {
        return [
          [tMtx[0][0], tMtx[0][1], this.origin.x],
          [tMtx[1][0], tMtx[1][1], this.origin.y],
          [tMtx[2][0], tMtx[2][1], tMtx[2][2]],
        ] as Matrix33;
      }
      return tMtx;
    })();

    const TRSMtx = multiplyMatrix33(
      this.translateMtx,
      rotateMtx,
      cloneStretchMtx
    );

    const stretchBaseAABB = getAABB(
      basePathTransform(baseCutPaths, cloneStretchMtx)
    );

    const TRSPathSet = basePathTransform(baseCutPaths, TRSMtx);

    const transformedAABB = getAABB(TRSPathSet);

    this.rotateMtx = rotateMtx;
    this.stretchMtx = cloneStretchMtx;
    this.TRSMtx = TRSMtx;
    this.unrotatedAABB = AABBTransform(stretchBaseAABB, this.translateMtx);
    this.transformedAABB = transformedAABB;
    this.baseAABB = baseAABB;

    /*
			Timestamp for caching cut path and svg path generation
			Ts assigned on per-group basis, not per path.
			Ts is updated when group is created, when group is scaled, or when cutParams are changed
		*/
    const generatedTs = Date.now();
    this.generatedTs = generatedTs;

    // TODO find a way to move Round Path into sherpa-svg-generator so that we can apply post process there
    // applyPostProcess(this);
  }
}
