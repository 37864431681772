import React from 'react';

//selectors
import { useSelector } from 'react-redux';
import { selectSecretOptions } from '../../../Redux/Slices/SherpaContainerSlice';

//components
import Input from './Input';
import Label from './Label';

export default function Group({
  label,
  type,
  prop,
  type2,
  prop2,
  hasColor,
  hasColor2,
  value,
  onChange,
}) {
  const secretOptions = useSelector(selectSecretOptions);

  const attrs = {
    type,
    prop,
    hasColor,
    value,
    onChange,
  };

  const attrs2 = {
    type: type2,
    prop: prop2,
    hasColor: hasColor2,
    className: 'secondProp',
  };

  return (
    <div className='secret-menu-panel--group'>
      <Label>{label}</Label>
      <Input value={value ? value : secretOptions[prop]} {...attrs} />
      {prop2 ? (
        <Input value={secretOptions[prop2]} {...attrs2} />
      ) : (
        <div className='secret-menu-panel--empty' />
      )}
    </div>
  );
}
