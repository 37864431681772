import { Point } from './Point';
import math from './mathjs';

const inv = math.inv;

/**
 * @type {Matrix33} - creates a new type named `Matrix33` which is a 3x3 Matrix
 */
export type Matrix33 = [
  [number, number, number],
  [number, number, number],
  [number, number, number]
];

export const initialMatrix33 = [
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1],
] as Matrix33;

/**
 * Clones a {@link Matrix33}
 * @param {Matrix33} mtx - matrix to clone
 * @returns {Matrix33} a cloned Matrix333
 */
export function clone(mtx: Matrix33): Matrix33 {
  return [[...mtx[0]], [...mtx[1]], [...mtx[2]]];
}

/**
 * Utility function to convert a {@link Matrix33} to a string to be used in the DOM or on export
 * @param {Matrix33?} mtx - an optional matrix that is to be converted to a string
 * @returns {string} a string representation of a transforms Matrix of form {@link Matrix33}
 */
export const getSVGTransformParams = (mtx = initialMatrix33) => {
  return `matrix(${mtx[0][0]} ${mtx[1][0]} ${mtx[0][1]} ${mtx[1][1]} ${mtx[0][2]} ${mtx[1][2]})`;
};

/**
 * A utility function to multiple two {@link Matrix33}
 * @param {Matrix33} a - one of the {@link Matrix33} to be multiplied
 * @param {Matrix33} b - the other {@link Matrix33} to be multiplied
 * @returns {Matrix33} returns the product of a*b in a single {@link Matrix33}
 */
export const fast33Multiply = (a: Matrix33, b: Matrix33): Matrix33 => {
  return [
    [
      a[0][0] * b[0][0] + a[0][1] * b[1][0] + a[0][2] * b[2][0],
      a[0][0] * b[0][1] + a[0][1] * b[1][1] + a[0][2] * b[2][1],
      a[0][0] * b[0][2] + a[0][1] * b[1][2] + a[0][2] * b[2][2],
    ],
    [
      a[1][0] * b[0][0] + a[1][1] * b[1][0] + a[1][2] * b[2][0],
      a[1][0] * b[0][1] + a[1][1] * b[1][1] + a[1][2] * b[2][1],
      a[1][0] * b[0][2] + a[1][1] * b[1][2] + a[1][2] * b[2][2],
    ],
    [
      a[2][0] * b[0][0] + a[2][1] * b[1][0] + a[2][2] * b[2][0],
      a[2][0] * b[0][1] + a[2][1] * b[1][1] + a[2][2] * b[2][1],
      a[2][0] * b[0][2] + a[2][1] * b[1][2] + a[2][2] * b[2][2],
    ],
  ];
};

/**
 * A wrapper function around {@link fast33Multiply} to multiply multiple {@link Matrix33}
 * @param {Matrix33[]} matrixArgs - an array of {@link Matrix33}
 * @returns {Matrix33} returns the product of `matrixArgs` as a single {@link MAtrix33}
 */
export const multiplyMatrix33 = (...matrixArgs: Matrix33[]): Matrix33 => {
  return matrixArgs.reduce((previous: Matrix33, current: Matrix33) =>
    fast33Multiply(previous, current)
  );
};

export function createMatrix33(arr?: Matrix33): Matrix33 {
  if (arr) {
    return JSON.parse(JSON.stringify(arr));
  }
  return initialMatrix33;
}

export function invert(mtx: Matrix33 = initialMatrix33): Matrix33 {
  const matrixToInvert = createMatrix33(mtx);
  return inv(matrixToInvert);
}

export function translate(mtx: Matrix33, pt: Point): Matrix33 {
  const newMtx = clone(mtx);
  newMtx[0][2] = pt.x;
  newMtx[1][2] = pt.y;
  return newMtx;
}

export function scale(mtx: Matrix33, pt: Point): Matrix33 {
  const newMtx = clone(mtx);
  newMtx[0][0] = pt.x;
  newMtx[1][1] = pt.y;
  return newMtx;
}

export function scaleAndTranslate(
  mtx: Matrix33,
  scalePt: Point,
  translatePt: Point
): Matrix33 {
  const m1 = scale(mtx, scalePt);
  return translate(m1, translatePt);
}

export function getDegreesFromMtx(mtx: Matrix33): number {
  return -((180 / Math.PI) * Math.atan2(mtx[0][1], mtx[0][0]));
}

export function getRotationAngleFromMtx(mtx: Matrix33): number {
  //Use atan to get angle from -Pi..Pi
  return Math.atan2(mtx[1][0], mtx[1][1]);
}

export function createScaleMtx(scaleX: number, scaleY?: number): Matrix33 {
  if (!scaleY) {
    return [
      [scaleX, 0, 0],
      [0, scaleX, 0],
      [0, 0, 1],
    ];
  }

  return [
    [scaleX, 0, 0],
    [0, scaleY, 0],
    [0, 0, 1],
  ];
}

export function createStretchMtx(
  scaleX: number = 1,
  scaleY: number = 1,
  shearX: number = 0,
  shearY: number = 0
): Matrix33 {
  return [
    [scaleX, shearX, 0],
    [shearY, scaleY, 0],
    [0, 0, 1],
  ];
}

export function createTranslateMtx(position: Point = new Point()): Matrix33 {
  return [
    [1, 0, position.x],
    [0, 1, position.y],
    [0, 0, 1],
  ];
}

export function createRotationMtx(
  rotation: number,
  rotationPoint: Point = new Point()
) {
  return math.multiply(
    createTranslateMtx(rotationPoint),
    [
      [math.cos(rotation), -math.sin(rotation), 0],
      [math.sin(rotation), math.cos(rotation), 0],
      [0, 0, 1],
    ],
    createTranslateMtx(new Point(-rotationPoint.x, -rotationPoint.y))
  );
}

export function getMtx33FromSVGMatrix({
  a = 1,
  b = 0,
  c = 0,
  d = 1,
  e = 0,
  f = 0,
}): Matrix33 {
  return [
    [a, c, e],
    [b, d, f],
    [0, 0, 1],
  ];
}

export function getMtx33FromSVGMatrixArgs(
  a = 1,
  b = 0,
  c = 0,
  d = 1,
  e = 0,
  f = 0
) {
  return [
    [a, c, e],
    [b, d, f],
    [0, 0, 1],
  ];
}
