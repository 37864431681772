import React from 'react';
import { cancelEvent } from '@/Utility/events';

export default function ClickShield(props) {
  function onClick(event) {
    cancelEvent(event);
    props.onClick();
  }

  function onCapture(event) {
    cancelEvent(event);
  }

  // not actively capturing
  if (props.disabled) {
    return null;
  }

  return (
    <div className={`click-capture ${props.className}`} onClick={onClick}>
      {props.children && <div onClick={onCapture}>{props.children}</div>}
    </div>
  );
}
