import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Snapshot, SyncStatus } from '../../Sync/SyncConstants';
import { PatchSet } from '../../Sync/PatchGenerator';
import { CanvasState } from '@/Redux/Slices/CanvasSlice';
import { Workspace } from '@/@types/shaper-types';
import { RootState } from '@/Redux/store';
import { log } from '@/Sync/SyncLog';

interface SyncState {
  syncUrl?: string;
  enabled: boolean;
  status: SyncStatus;
  workspace: Workspace | null;
  reconnectId?: string | null;
  passedWorkspaceId?: string | null;
  queue: PatchSet[];
  pendingQueue: PatchSet[];
  snapshot: Snapshot;
}

export const initialSyncState: SyncState = {
  enabled: false,
  status: 'disconnected',
  workspace: null,
  queue: [],
  pendingQueue: [],
  snapshot: {
    canvas: null,
  },
};

export interface SnapshotUpdate {
  slice?: string;
  value: CanvasState | null;
}

const context = {
  syncLevel: 'slice',
};

export const syncSlice = createSlice({
  name: 'sync',
  initialState: initialSyncState,
  reducers: {
    setEnabled: (state, action: PayloadAction<boolean>) => {
      log(`Setting sync to enabled as ${action.payload}`, { ...context });
      state.enabled = action.payload;
    },
    setWorkspaceIdFromUrlParam: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.passedWorkspaceId = action.payload;
    },
    setSandboxMode: (state) => {
      log('Setting to sandbox mode', { ...context });
      state.enabled = false;
      state.workspace = null;
      state.status = 'edit';
    },
    setSyncUrl: (state, action: PayloadAction<string>) => {
      state.syncUrl = action.payload;
    },
    setWorkspace: (state, action: PayloadAction<Workspace | null>) => {
      state.workspace = action.payload;
    },
    updateQueue: (state, action: PayloadAction<PatchSet[]>) => {
      state.queue = action.payload;
    },
    updatePendingQueue: (state, action: PayloadAction<PatchSet[]>) => {
      state.pendingQueue = action.payload;
    },
    setSnapshot: (state, action: PayloadAction<SnapshotUpdate | Snapshot>) => {
      if ('slice' in action.payload || 'value' in action.payload) {
        const { slice, value } = action.payload;
        state.snapshot[(slice as keyof Snapshot) ?? 'canvas'] = value;
      } else {
        state.snapshot = action.payload;
      }
    },
    setSequence: (state, action: PayloadAction<number>) => {
      if (state.workspace) {
        state.workspace.latestUpdateSequence = action.payload;
      }
    },
    setStatus: (state, action: PayloadAction<SyncStatus>) => {
      state.status = action.payload;
    },
    setReconnectId: (state, action: PayloadAction<string>) => {
      state.reconnectId = action.payload;
    },
    removeReconnectId: (state) => {
      const { reconnectId, ...restOfState } = state;
      return restOfState;
    },
  },
});

export const {
  setEnabled,
  setWorkspaceIdFromUrlParam,
  setSandboxMode,
  setSyncUrl,
  setWorkspace,
  updateQueue,
  updatePendingQueue,
  setSnapshot,
  setSequence,
  setStatus,
  setReconnectId,
  removeReconnectId,
} = syncSlice.actions;

export const selectIsEditMode = (state: RootState) =>
  state.sync.status === 'edit' || state.sync.status === 'pending';

export const selectWorkspace = (state: RootState) => state.sync.workspace;
export const selectWorkspaceId = (state: RootState) => state.sync.workspace?.id;
export const selectSnapshot = (state: RootState) => state.sync.snapshot;
export const selectWorkspaceIdFromUrlParam = (state: RootState) =>
  state.sync.passedWorkspaceId;
export const selectDuplicateId = (state: RootState) => '';
export const selectEnabled = (state: RootState) => state.sync.enabled;

export const { reducer } = syncSlice;
