import { useDispatch } from 'react-redux';

let store;
export function setStore(source) {
  store = source;
}

export function useSelector(selector) {
  const state = store.getState();
  return selector(state);
}

/*
 * @template T
 * @param {new() => T} Type
 * @returns {T}
 */
export function useAction(Type, ...args) {
  const dispatch = useDispatch();
  return new Type(dispatch, useSelector, ...args);
}

/*
 * @template T
 * @param {new() => T} Type
 * @returns {T}
 */
export function useActionWithDispatch(
  Type,
  dispatch,
  useSelectorForAction = useSelector,
  ...args
) {
  return new Type(dispatch, useSelectorForAction, ...args);
}
