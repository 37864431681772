import BaseAction from './BaseAction';
import { useActionWithDispatch } from './useAction';

// selectors
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';

// helpers
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { createSvg } from '@/Helpers/ShapeCreator';

// actions
import AddGeometryAction from '@/Actions/AddGeometry';
import { trackEvent } from '../Utility/userflow';
import { Tool } from '../Geometry/sherpa-svg-generator/SvgGroup';

export default class BuildBasicShapeAction extends BaseAction {
  build(type, insertAt) {
    const { dispatch, useSelector } = this;

    // create the svg content
    // const params = SHAPE_TYPES_PARAMS[type];
    const tool = new Tool(type);
    const { svg } = createSvg({ type, ...tool.params });

    // determine where to add
    let position = insertAt;
    if (!position) {
      const viewbox = useSelector(selectSVGViewbox);
      position = getPositionFromSvgViewbox(viewbox);
    }

    // build the svg
    const build = useActionWithDispatch(AddGeometryAction, dispatch);
    const shape = { rawSVG: svg, tool: tool };
    build.addSvg('icon', shape, position);

    trackEvent('shape_added', { shape_added: true });
  }
}
