import { ClipperOps, ClipperPath, clipperScale } from './ClipperOps';
import { transform as ptTransform, comparePoints } from './PointOps';
import { AABB } from './sherpa-svg-generator/AABB';
import { Matrix33 } from './sherpa-svg-generator/Matrix33';
import { Path } from './sherpa-svg-generator/Path';
import { Point } from './sherpa-svg-generator/Point';
import { v4 as uuidv4 } from 'uuid';
import inside from 'point-in-polygon-hao';

function sanitize(path: Path): Object {
  return { ...path, points: path.points?.slice(0, 10) };
}
function getAABB(path: Path): AABB {
  return path.AABB;
}
function transform(path: Path, mtx: Matrix33): Path {
  return new Path({
    closed: path.closed,
    points: path.points.map((pt) => ptTransform(pt, mtx)),
  });
}

function createPathAndTestClosed(points: Point[]): Path {
  const p0 = points[0];
  const p1 = points[points.length - 1];
  //If first and last path points are the same or "close enough", discard the last point and set the closed flag to true
  //However, you cannot close a path unless it has at least 4 points, (e.g. A -- B -- C -- (A + eps))
  //In theory, you only need 3 points to form a closed path that is a line doubling back on itself, but that path would enclose zero area and should be treated as a line segment.

  const closed = points.length >= 4 && comparePoints(p0, p1);

  const pathPoints = closed ? points.slice(0, -1) : points;

  return new Path({ closed, points: pathPoints });
}

function clone(path: Path): Path {
  return new Path({ ...path, id: uuidv4() });
}

function signedArea(path: Path): number {
  if (path.closed === false) {
    return 0;
  }
  //create segments for closed paths
  return (
    path.points
      .map((pt, idx, ptArr) => {
        let nextPt = idx < ptArr.length - 1 ? ptArr[idx + 1] : ptArr[0];
        return { p0: pt, p1: nextPt };
      })
      //then compute accumulated signed area
      .reduce(
        (acc, seg) => acc + (seg.p0.x * seg.p1.y - seg.p1.x * seg.p0.y),
        0
      )
  );
}

function reversePath(path: Path) {
  const htaP = clone(path);
  htaP.points.reverse();
  return htaP;
}

function pointsInsidePath(path: Path, points: Point[]): boolean {
  //Convert path to array format and add copy of closing point
  //Returns true if inside, 0 if on edge, and false if outside
  //NB: A polygon is an array paths, each path an array of points, and each point an array of values. Need to wrap pathArray in array to make it a polygon.

  //Add pathArray to path to speed up multiple tests

  let pathArray: number[][];
  if (path.pathArray !== undefined) {
    pathArray = path.pathArray;
  } else {
    pathArray = path.points.map((p) => [p.x, p.y]);
    pathArray.push(pathArray[0].slice());
    path.pathArray = pathArray;
  }

  return points.some((p) => {
    const result = inside([p.x, p.y], [pathArray]);
    return result === true || result === 0;
  });
}

function shaperPathToScaledClipperPath(shaperPath: Path): ClipperPath {
  return new ClipperPath(
    shaperPath.closed,
    shaperPath.points.map((pt) => ({
      X: Math.round(pt.x * clipperScale),
      Y: Math.round(pt.y * clipperScale),
    }))
  );
}

function clipperPathToShaperPath(clipperPath: ClipperPath): Path {
  return new Path({
    closed: clipperPath.closed,
    points: clipperPath.points.map(
      (pt) => new Point(pt.X / clipperScale, pt.Y / clipperScale)
    ),
  });
}

function simplifyPolygonPath(path: Path) {
  return ClipperOps.simplifyClipperPolygon(
    shaperPathToScaledClipperPath(path)
  ).map((sp: ClipperPath) => clipperPathToShaperPath(sp));
}

export {
  sanitize,
  getAABB,
  transform,
  createPathAndTestClosed,
  clone,
  signedArea,
  reversePath,
  pointsInsidePath,
  shaperPathToScaledClipperPath,
  clipperPathToShaperPath,
  simplifyPolygonPath,
};
