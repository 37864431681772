import { useSelector } from 'react-redux';
import * as classList from '@/Utility/classList';

// selectors
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  Middleware,
  createAction,
  isAction,
} from '@reduxjs/toolkit';

// helpers
import { isBoolean } from 'lodash';
import * as dev from '@/development';

import { getShaperSubscriptions, getUser } from '@/ShaperHub/ShaperHubThunks';
import type {
  Entitlements,
  Features,
  Mode,
  SubscriptionTier,
  Theme,
  User,
} from '@/@types/shaper-types';
import { AppThunkDispatch, RootState } from '../store';
import { FontStyle } from '@/Helpers/FontHelper';
import { enableDebugging } from '@/Sync/SyncLog';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { setI18nLanguage } from '@/i18n';
import { entitlements, getFeatureList } from '@/Helpers/Entitlements';
import { getFeatureFlags as getFeatureFlagsHelper } from '@/Helpers/FeatureFlags';

export type FeatureFlags = {
  [key: string]: boolean;
};

export interface FeatureList {
  features?: {
    // eslint-disable-next-line no-unused-vars
    [tier in SubscriptionTier]: Entitlements[] | [];
  };
  allFeatures?: Entitlements;
}

export type TextParams = {
  text: string;
  fontDisplayName: string;
  fontSelection: FontStyle | null;
  forceOpenPaths?: boolean;
};

export interface UIState {
  status: string;
  mode: Mode;
  theme: Theme;
  mobile: 'mobile' | 'desktop';
  featureList: FeatureList;
  featureMode: SubscriptionTier;
  activities: string[];
  featuresShowing: Partial<Features>;
  reviewModeLoading: boolean;
  showProgressIndicator: boolean;
  textParams: TextParams | null;
  featureFlags: FeatureFlags;
  modeOptionsMenuExpanded: boolean;
}

// checks for an assigned value or toggle
function identifyToggle(
  value: boolean | { active: boolean },
  current?: boolean
) {
  const active = isBoolean(value)
    ? value
    : isBoolean(value?.active)
    ? value.active
    : false;

  // if an explicit assignment
  // otherwise, reverse the toggle
  return active === true || active === false ? active : !current;
}

const removeFeaturesFromState = function (
  featuresShowingState: Partial<Features>
) {
  Object.keys(featuresShowingState).forEach((k) => {
    featuresShowingState[k] = false;
  });
};

const removeFeaturesButOneFromState = function (
  featuresShowingState: Partial<Features>,
  visibleFeatureKey: string
) {
  if (featuresShowingState[visibleFeatureKey] === true) {
    removeFeaturesFromState(featuresShowingState);
    // eslint-disable-next-line no-param-reassign
    featuresShowingState[visibleFeatureKey] = true;
  }
};

export const setProgressIndicator = createAction<boolean>(
  'ui/setProgressIndicator'
);

let hasFeature = function (ui: UIState, feature: string) {
  // for local development, activate all features
  if (dev.useAllFeatures()) {
    return true;
  }
  const { features } = getFeatureList();
  const featuresByMode = features ? features[ui.featureMode] : [];
  return featuresByMode.includes(feature) ?? false;
};

export const selectFeaturesByMode = createSelector(
  [(state) => state.ui, (state, feature) => feature],
  (ui, feature) => hasFeature(ui, feature)
);

export const selectFeatures = (feature: string) => {
  return useSelector((state) => selectFeaturesByMode(state, feature));
};

const hasFeatureFlag = function (ui: UIState, flagName: string) {
  const flags = ui.featureFlags;
  return flags[flagName] ?? false;
};

export const selectFeaturesByFlag = createSelector(
  [(state) => state.ui, (_, flagName) => flagName],
  (ui, flagName) => hasFeatureFlag(ui, flagName)
);

export const selectFeatureFlag = (flagName: string) => {
  return useSelector((state) => selectFeaturesByFlag(state, flagName));
};

export const getFeatureFlags = createAsyncThunk(
  'ui/getFeatureFlags',
  async (user: User) => getFeatureFlagsHelper(user._id, user.email)
);

export const initialState: UIState = {
  status: 'idle',
  mode: 'default',
  mobile: 'desktop',
  theme: 'default',
  featureList: {},
  featureMode: 'demo',
  activities: [],
  featuresShowing: {
    isShowingSelectionEditor: false,
    isShowingLoginMenu: false,
    isShapeBuilderMode: false,
    isShowingIconSearch: false,
    isShowingFileImport: false,
    isShowingShapeCreator: false,
    isShowingTrace: false,
    isShowingEditSelectionProperties: false,
    isShowingSecretMenu: false,
    isShowingHelpMenu: false,
    isShowingMoveMenu: false,
    isShowingOpenMenu: false,
    isShowingRename: false,
  },
  reviewModeLoading: false,
  showProgressIndicator: false,
  textParams: null,
  featureFlags: {},
  modeOptionsMenuExpanded: false,
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetUI: (state) => {
      state.featuresShowing = {};
    },

    setMobileMode: (state, action: PayloadAction<'mobile' | 'desktop'>) => {
      state.mobile = action.payload;
    },

    // changes the active mode for the app
    setTheme: (state, action: PayloadAction<Theme>) => {
      // only replace the theme if one is provided, otherwise it
      // will keep the prior theme. Used when an InteractionMode doesn't
      // have it's own theme, or should continue using the existing theme
      // if for whatever reason there's no known theme or provided theme
      // just fall back to default
      state.theme = action.payload || state.theme || 'default';
    },

    // changes the active mode for the app
    setUIMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
    },

    toggleUIModeOptionsMenu: (state, action: PayloadAction<boolean>) => {
      state.modeOptionsMenuExpanded = action.payload === true;
    },

    // changes the active mode for the app
    setUIActivity: (state, action: PayloadAction<string>) => {
      if (state.activities.includes(action.payload)) {
        return;
      }

      state.activities = [...state.activities, action.payload];
      classList.add(document.body, 'ui-activity', action.payload);
    },

    // changes the active mode for the app
    clearUIActivity: (state, action: PayloadAction<string>) => {
      state.activities = state.activities.filter(
        (activity) => activity !== action.payload
      );
      classList.remove(document.body, 'ui-activity', action.payload);
    },

    // changes the active mode for the app
    clearAllUIActivities: (state) => {
      classList.clear(document.body, 'ui-activity');
      state.activities = [];
    },

    toggleSelectionEditor: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingSelectionEditor = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingSelectionEditor
      );
      removeFeaturesButOneFromState(
        state.featuresShowing,
        'isShowingSelectionEditor'
      );
    },

    toggleIconSearch: (state, action: PayloadAction<boolean>) => {
      if (hasFeature(state, entitlements.FIND_ART_LITE)) {
        state.featuresShowing.isShowingIconSearch = identifyToggle(
          action.payload,
          state.featuresShowing.isShowingIconSearch
        );
        removeFeaturesButOneFromState(
          state.featuresShowing,
          'isShowingIconSearch'
        );
      }
    },

    hideAllFeatures: (state) => {
      removeFeaturesFromState(state.featuresShowing);
    },
    toggleTextInsert: (
      state,
      action: PayloadAction<Boolean | { textParams: TextParams }>
    ) => {
      if (hasFeature(state, entitlements.TEXT_LITE)) {
        if (isBoolean(action.payload)) {
          state.mode = action.payload ? 'text-editor' : 'default';
          state.textParams = null;
        } else {
          state.textParams =
            'textParams' in action.payload ? action.payload.textParams : null;
          state.mode = 'text-editor';
        }
      }
    },

    toggleFileImport: (state, action: PayloadAction<boolean>) => {
      const isMobile = state.mobile === 'mobile';
      if ((!isMobile && hasFeature(state, entitlements.IMPORT)) || isMobile) {
        state.featuresShowing.isShowingFileImport = identifyToggle(
          action.payload,
          state.featuresShowing.isShowingFileImport
        );
        removeFeaturesButOneFromState(
          state.featuresShowing,
          'isShowingFileImport'
        );
      }
    },

    toggleShapeCreator: (state, action: PayloadAction<boolean>) => {
      if (hasFeature(state, entitlements.SHAPE_BUILDER)) {
        state.featuresShowing.isShowingShapeCreator = identifyToggle(
          action.payload,
          state.featuresShowing.isShowingShapeCreator
        );
        removeFeaturesButOneFromState(
          state.featuresShowing,
          'isShowingShapeCreator'
        );
      }
    },
    toggleLoginMenu: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingLoginMenu = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingLoginMenu
      );
      removeFeaturesButOneFromState(state.featuresShowing, 'isShowingLogin');
    },

    toggleEditSelectionProperties: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingEditSelectionProperties = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingEditSelectionProperties
      );
      removeFeaturesButOneFromState(
        state.featuresShowing,
        'isShowingEditSelectionProperties'
      );
    },

    toggleSecretMenu: (state, action: PayloadAction<boolean>) => {
      if (hasFeature(state, entitlements.SECRET_MENU)) {
        state.featuresShowing.isShowingSecretMenu = identifyToggle(
          action.payload,
          state.featuresShowing.isShowingSecretMenu
        );
        removeFeaturesButOneFromState(
          state.featuresShowing,
          'isShowingSecretMenu'
        );
      }
    },

    toggleHelpMenu: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingHelpMenu = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingHelpMenu
      );
      removeFeaturesButOneFromState(state.featuresShowing, 'isShowingHelpMenu');
    },

    toggleMoveMenu: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingMoveMenu = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingMoveMenu
      );
      removeFeaturesButOneFromState(state.featuresShowing, 'isShowingMoveMenu');
    },

    toggleOpenMenu: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingOpenMenu = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingOpenMenu
      );
      removeFeaturesButOneFromState(state.featuresShowing, 'isShowingOpenMenu');
    },

    toggleRename: (state, action: PayloadAction<boolean>) => {
      state.featuresShowing.isShowingRename = identifyToggle(
        action.payload,
        state.featuresShowing.isShowingRename
      );
      removeFeaturesButOneFromState(state.featuresShowing, 'isShowingRename');
    },

    setFeaturesMode: (state, action) => {
      state.featureMode = action.payload;
    },

    setReviewModeLoading: (state, action: PayloadAction<boolean>) => {
      state.reviewModeLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setProgressIndicator,
        (state, action: PayloadAction<boolean>) => {
          state.showProgressIndicator = action.payload;
        }
      )
      .addCase(
        getShaperSubscriptions.fulfilled,
        (state, action: PayloadAction<{ featureMode: SubscriptionTier }>) => {
          state.featureMode = action.payload.featureMode;
        }
      )
      .addCase(getShaperSubscriptions.rejected, (state) => {
        state.featureMode = 'demo';
      })
      .addCase(
        getFeatureFlags.fulfilled,
        (state, action: PayloadAction<{ [key: string]: boolean }>) => {
          state.featureFlags = action.payload;
          if (state.featureFlags['studio-debug-logs']) {
            enableDebugging();
          }
        }
      )
      .addCase(getFeatureFlags.rejected, (state) => {
        state.featureFlags = {};
      });
  },
});

export const {
  setMobileMode,
  setUIMode,
  setUIActivity,
  clearUIActivity,
  clearAllUIActivities,
  hideAllFeatures,
  toggleLoginMenu,
  toggleIconSearch,
  toggleSelectionEditor,
  toggleFileImport,
  toggleTextInsert,
  toggleShapeCreator,
  toggleEditSelectionProperties,
  resetUI,
  toggleUIModeOptionsMenu,
  toggleSecretMenu,
  toggleHelpMenu,
  toggleMoveMenu,
  toggleOpenMenu,
  toggleRename,
  setFeaturesMode,
  setReviewModeLoading,
} = slice.actions;

export const SET_REVIEW_PATH_LOADER = 'set-review-path-loader';

export const middleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    if (
      isAction(action) &&
      action.type === SET_REVIEW_PATH_LOADER &&
      'payload' in action
    ) {
      dispatch({
        type: 'ui/setReviewModeLoading',
        payload: action.payload,
      });
    }
    if (getUser.fulfilled.match(action)) {
      const user: User = action.payload;
      (dispatch as AppThunkDispatch)(getFeatureFlags(user));
    }
    if (getFeatureFlags.fulfilled.match(action)) {
      const featureFlags = action.payload;
      const { shaperHub, ui } = getState();
      const { locale } = shaperHub;

      if (featureFlags && hasFeatureFlag(ui, 'studio-developer-access')) {
        const devLocale = getDeveloperSettings('locale');
        setI18nLanguage(devLocale || locale.locale);
      }
    }

    next(action);
  };

export const selectUIMode = (state: RootState) => state.ui.mode;
export const selectUIModeOptionsMenuExpanded = (state: RootState) =>
  state.ui.modeOptionsMenuExpanded;
export const selectFeatureList = (state: RootState) => getFeatureList();
export const selectIsShowingRename = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingRename;
export const selectIsShowingMoveMenu = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingMoveMenu;
export const selectIsShowingOpenMenu = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingOpenMenu;
export const selectIsShowingHelpMenu = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingHelpMenu;
export const selectIsShowingSecretMenu = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingSecretMenu;
export const selectIsShowingEditSelectionProperties = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingEditSelectionProperties;
export const selectIsShowingLoginMenu = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingLoginMenu;
export const selectIsShowingIconSearch = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingIconSearch;
export const selectIsShowingFileImport = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingFileImport;
export const selectIsShowingTrace = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingTrace;
export const selectIsShowingAnchorSelector = (state: RootState) =>
  state.ui.mode === 'anchor-selection';
export const selectIsShowingShapeBuilder = (state: RootState) =>
  state.ui.mode === 'shape-builder';
export const selectIsShowingShapeCreator = (state: RootState) =>
  !!state.ui.featuresShowing.isShowingShapeCreator;
export const selectIsShowingTextInsert = (state: RootState) =>
  state.ui.mode === 'text-editor';
export const selectIsShowingProgress = (state: RootState) =>
  !!state.ui.showProgressIndicator;
export const selectIsShowingImportMode = (state: RootState) =>
  state.ui.mode === 'import';
export const selectIsPlanMode = (state: RootState) => state.ui.mode === 'plan';
export const selectIsReviewMode = (state: RootState) =>
  state.ui.mode === 'review';
export const selectIsDesignMode = (state: RootState) =>
  state.ui.mode === 'default';
export const selectIsDefaultMode = (state: RootState) =>
  !(selectIsPlanMode(state) || selectIsReviewMode(state));
export const selectTextParams = (state: RootState) => state.ui.textParams;
export const selectTheme = (state: RootState) => state.ui.theme;
export const selectIsMobile = (state: RootState) =>
  state.ui.mobile === 'mobile';
export const selectActiveUIState = (state: RootState) =>
  state.ui.featuresShowing;
export const selectFeatureMode = (state: RootState) => state.ui.featureMode;
export const selectReviewModeLoading = (state: RootState) =>
  state.ui.reviewModeLoading;
export const selectFeatureFlags = (state: RootState) => state.ui.featureFlags;

export const actions = slice.actions;

export default slice.reducer;
