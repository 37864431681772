import React from 'react';
import { useAction } from '@/Actions/useAction';

// actions
import BuildBasicShapeAction from '@/Actions/BuildBasicShape';
import UIFeatureAction from '@/Actions/UIFeature';

// components
import CanvasActionMenu from '../CanvasActionMenu';

// types of shapes to insert
const SHAPE_TYPES = [
  {
    id: 'circle',
    label: 'Circle',
    icon: 'basic-shape-circle',
    i18nKey: 'circle',
  },
  {
    id: 'ellipse',
    label: 'Ellipse',
    icon: 'basic-shape-ellipse',
    i18nKey: 'ellipse',
  },
  {
    id: 'rectangle',
    label: 'Rectangle',
    icon: 'basic-shape-rectangle',
    i18nKey: 'rectangle',
  },
  {
    id: 'rounded_rectangle',
    label: 'Rounded Rectangle',
    icon: 'basic-shape-rounded-rectangle',
    i18nKey: 'rounded-rectangle',
  },
  {
    id: 'polygon',
    label: 'Polygon',
    icon: 'basic-shape-polygon',
    i18nKey: 'polygon',
  },
];

export default function BasicShapeCreatorActionMenu(props) {
  const buildBasicShape = useAction(BuildBasicShapeAction);
  const uiFeature = useAction(UIFeatureAction);

  // handles requesting a new shape to add
  function onSelectAction(shape) {
    buildBasicShape.build(shape.id);
    uiFeature.toggleEditSelectionProperties(true);
  }

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      edge={props.edge}
      className='shape-creator-menu'
      actions={SHAPE_TYPES}
    />
  );
}
