import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { getScreenViewMode } from './screen';

export function getPortalContainer() {
  return document.getElementById('react-portal-container');
}

// preferred root container for portals
export function createPortal(content, key) {
  const container = getPortalContainer();
  return ReactDOM.createPortal(content, container, key);
}

export function getReactComponentChildren(obj) {
  return obj.children instanceof Array
    ? obj.children
    : obj.children !== undefined && obj.children !== null
    ? [obj.children]
    : [];
}

// finds the nearest attribute matching for an event target
export function getEventAttributeValue(event, attr) {
  const source = event.target?.closest(`[${attr}]`);
  return source?.getAttribute(attr);
}

// a hook used to capture when switching to a mobile view
export function useSwitchMobileView(action) {
  const [mode, setMode] = useState(getScreenViewMode());

  // check for changes
  useEffect(() => {
    const check = () => {
      const current = getScreenViewMode();
      if (current !== mode) {
        setMode(current);
        action?.(current);
      }
    };

    window.addEventListener('resize', check);
    return () => window.removeEventListener('resize', check);
  });

  return mode;
}

export function extractDropdownList(props) {
  const all = [];
  const options = [];
  const values = [];

  // flatten into a single list
  const children = React.Children.toArray(props.children);
  for (const child of children) {
    if (child.type.isDropdownGroup) {
      all.push(child);

      const items = React.Children.toArray(child.props.children);
      all.push(...items);
      options.push(...items);
      values.push(...items.map((item) => item.props.value));
    } else {
      all.push(child);
      options.push(child);
      values.push(child.props.value);
    }
  }

  return { options, values, items: all };
}
