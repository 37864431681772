import React, { useLayoutEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// selectors
import { selectSvgGroupString } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';

// components
import Layer from './Layer';
import { PATH_TYPE } from '../../Geometry/sherpa-svg-generator/Path';
import { selectUIMode } from '../../Redux/Slices/UISlice';

export default function SvgGroup(props) {
  const { group, selected } = props;
  const selectedPathIds = useSelector(selectSelectedPathIds);
  const svg = useSelector(selectSvgGroupString(group.data), shallowEqual);
  const mode = useSelector(selectUIMode);
  const pathType =
    group.data?.type?.toLowerCase() || PATH_TYPE.DESIGN.toLowerCase();

  // TODO: this is not ideal, but find the selected paths
  // inside this layer and mark them as selected - manipulate
  // the DOM to avoid rendering a new string when selection changes
  // consider moving the ID generation to somewhere else
  function updatePathSelections() {
    const selection = selectedPathIds.map(
      (item) => `pathGroup-sg-${item.groupId}-pg-${item.pathId}`
    );
    const groupSelection = [...new Set(selectedPathIds.map((i) => i.groupId))];
    document.querySelectorAll(`#sg-${group.id} .pathGroup`).forEach((el) => {
      const action = (() => {
        if (mode === 'default') {
          return groupSelection.some((g) => el.id.includes(g))
            ? 'add'
            : 'remove';
        }
        return selection.includes(el.id) ? 'add' : 'remove';
      })();
      el.classList[action]('selected');
    });
  }

  // refresh when the UI changes
  useLayoutEffect(updatePathSelections, [selectedPathIds, group]);

  return (
    <Layer
      id={group.id}
      className={`svg-group ${selected ? 'selected' : ''} ${pathType} ${
        props.className || ''
      }`}
      x={group.cx}
      y={group.cy}
      rotation={group.rotation}
      stretchMatrix={group.stretchMatrix}
      svgGroup={svg}
    />
  );
}
