import { TaskRejected, TaskResolved, TaskResult } from '@reduxjs/toolkit';
import { Value } from 'classnames';
import { SyncError } from './SyncError';
import { SyncStatus } from './SyncConstants';

export const sequenceUpdateRange = (
  snapshotNumber: number,
  updateNumber: number
) => {
  return Array.from(
    { length: updateNumber - snapshotNumber },
    (_, index) => index + snapshotNumber + 1
  );
};

export function isRejectedTask(task: TaskResult<Value>): task is TaskRejected {
  return (task as TaskRejected).error !== undefined;
}

export function isResolvedTask<T>(
  task: TaskResult<T>
): task is TaskResolved<T> {
  return (task as TaskResolved<T>).value !== undefined && task.status === 'ok';
}

export const getStatusFromError = (error: SyncError): SyncStatus => {
  switch (error.message) {
    case 'sequence_taken':
      return 'out-of-date';
    case 'object_not_found':
    case 'unknown_workspace':
      return 'invalid-workspace';
    case 'invalid-version':
      return 'invalid-version';
    default:
      return 'error';
  }
};
