/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

//Components
import Icon from '@/Styles/Icons/Icon';
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';
import CreateAccountActions from '@/Actions/CreateAccount';
import { menuLinks } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';

export default function DemoMode() {
  const modalActions = useAction(ModalActions);
  const createAccountActions = useAction(CreateAccountActions);

  function disableDemo() {
    modalActions.openSignInModal();
  }

  function openCreateAccountLink() {
    createAccountActions.createAccount();
  }

  return (
    <div className='application-menu--demo'>
      <div className='application-menu--demo--explanation'>
        <TranslationText i18nKey='demo-version'>
          This is the demo version of Shaper Studio. Create an account or log in
          to save, download designs, and access more features.
        </TranslationText>
      </div>

      <div
        className='application-menu--demo--button'
        onClick={openCreateAccountLink}
      >
        <Icon icon='person' />
        <span>
          <TranslationText i18nKey='create-account-2'>
            Create an Account
          </TranslationText>
        </span>
      </div>

      <div className='application-menu--demo--login'>
        <a onClick={disableDemo}>
          <TranslationText i18nKey='have-account'>
            Have an account? Log in
          </TranslationText>
        </a>
      </div>

      <div className='application-menu--demo--learn-more'>
        <a href={menuLinks.LEARN_MORE}>
          <TranslationText i18nKey='learn-more-menu'>
            Learn more about studio
          </TranslationText>
        </a>
      </div>
    </div>
  );
}
