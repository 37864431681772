import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

//interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';

export default class DisabledMode extends InteractionMode {
  getInteractionHandlers() {
    return [ZoomViewportInteraction, TranslateViewportInteraction];
  }
}
