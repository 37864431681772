import { MODAL_TRIGGER_TYPES } from '../defaults';

/*
 * Events for the Facebook/Meta Pixel
 *
 * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 */
class MetaPixelSingleton {
  /*
   * Since the Meta Pixel is loaded async via Tag Manager
   * we need to get it each time we want to use it
   */
  send(event, payload) {
    const metaPixel = window.fbq;

    if (!metaPixel) {
      return; // User may not have given consent for Meta Pixel
    }

    metaPixel('track', event, payload);
  }

  sendLeadEvent() {
    this.send('Lead', {
      content_category: 'Studio Subscription',
      content_name: 'Demo Mode',
    });
  }

  sendStartTrialEvent(currency) {
    this.send('StartTrial', { currency, value: 0 });
  }
}

const metaPixelInstance = new MetaPixelSingleton();

/*
 * Events for Google Analytics 3 (a.k.a. Universal Analytics)
 *
 * https://support.google.com/analytics/answer/1033068#zippy=%2Cin-this-article
 */
class GoogleAnalyticsSingleton {
  /*
   * Since Google Analytics is loaded async via Tag Manager
   * we need to get it each time we want to use it
   */
  send(eventType, payload) {
    const ga = window.ga;

    if (!ga) {
      return; // User may not have given consent for Google Analytics
    }

    ga('send', eventType, payload);
  }

  sendPageView() {
    this.send('pageview', window.location.origin);
  }

  sendLeadEvent() {
    this.send('event', {
      eventCategory: 'Studio Subscription',
      eventAction: 'Demo Mode',
    });
  }

  sendStartTrialEvent(currency) {
    this.send('event', {
      eventCategory: 'Studio Subscription',
      eventAction: 'Start Trial',
      eventLabel: currency,
      eventValue: 0,
    });
  }
}

const googleAnalyticsInstance = new GoogleAnalyticsSingleton();

/*
 * Events sent to Google Tag Manager that can be used as triggers
 *
 * https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */
class TagManagerDataLayerSingleton {
  send(payload) {
    const dataLayer = window.dataLayer;

    if (!dataLayer) {
      return; // dataLayer may not have been loaded
    }

    dataLayer.push(payload);
  }

  sendStartTrialEvent() {
    this.send({ event: 'subscription-start-trial' });
  }
}

const tagManagerDataLayerInstance = new TagManagerDataLayerSingleton();

class ConversionTrackingAPISingleton {
  constructor() {
    this.metaPixel = metaPixelInstance;
    this.googleAnalytics = googleAnalyticsInstance;
    this.tagManagerDataLayer = tagManagerDataLayerInstance;
  }

  trackPageView() {
    this.googleAnalytics.sendPageView();
  }

  trackDemoMode() {
    this.metaPixel.sendLeadEvent();
    this.googleAnalytics.sendLeadEvent();
  }

  trackStartTrial(currency) {
    this.metaPixel.sendStartTrialEvent(currency);
    this.googleAnalytics.sendStartTrialEvent(currency);
    this.tagManagerDataLayer.sendStartTrialEvent();
  }
}

const DISABLE_CONVERSION_TRACKING = false;

const conversionTrackingAPI = new ConversionTrackingAPISingleton();

const conversionTrackingMiddleware = (storeAPI) => (next) => (action) => {
  if (DISABLE_CONVERSION_TRACKING) {
    return next(action);
  }

  switch (action.type) {
    case 'sherpaContainer/setSherpaInitialized': {
      conversionTrackingAPI.trackPageView();
      break;
    }
    case 'sherpaContainer/setModal': {
      if (action.meta.trigger === MODAL_TRIGGER_TYPES.TRY_DEMO) {
        conversionTrackingAPI.trackDemoMode();
      }
      break;
    }
    case 'shaperhub/startTrial/fulfilled': {
      const state = storeAPI.getState();

      conversionTrackingAPI.trackStartTrial(state.shaperHub.locale.currency);
      break;
    }
    default: {
      break;
    }
  }

  return next(action);
};

export default conversionTrackingMiddleware;
