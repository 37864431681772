import getCutTypeSvgPathAttributes from './CutTypeSvgPathAttributes';
import getCutParamsXmlAttributes from './CutParamsXmlAttributes';

import { BasePath, generatePathId } from './BasePath';
import { Matrix33, getSVGTransformParams, multiplyMatrix33 } from './Matrix33';
import { AABB, mergeAABBs } from './AABB';
import { Path } from './Path';
import { Point } from './Point';
import { Canvas } from './Canvas';
import { SvgGroup } from './SvgGroup';
import { CutParams, CutType } from './CutParams';
import { getTypeProperty } from './PathTypes';

interface PathAttribute {
  name: string;
  value: string;
}

const defaultPathAttrs = [
  { name: 'fill', value: 'none' },
  { name: 'stroke', value: 'black' },
  { name: 'stroke-width', value: '0.1px' },
] as PathAttribute[];

/* SVG display hierarchy - Updated 3/10/22
/For display svg only, export svg is less complicated
<svg 
  width/height = viewport size in pixels
  viewbox = viewport TL corner and width/height in canvas units (mm) //Viewbox values are changed to pan and zoom canvas
>
  <defs>
    <pattern id="gridPattern" grid pattern/>
  </defs>
  <rect fill="url(#gridPattern_0)" width="100%" height="100%"></rect> //grid background is filled rect covering canvas div
  
  <g class="svg-group ">
    <g id="translate" transform="translate(x,y)"> //group position
      <g id="rotate" transform="rotate(0)"> // group rotation
        <g id="stretch-tmp"> //Stretch matrix added here during interactive resizes only. When committed, scaling and shear get baked into the paths.
          <g id="sg-1">
            <g id="pathGroup-sg-1-pg-0" class="pathGroup cut-type-online">
              <path id="cutPreviewToolWidth-sg-1-pg-0" class="toolWidth" d="..."></path>
              <path id="cutPreviewOnline-sg-1-pg-0" class="cutPath online" d="..."></path>
              <path id="basePath-sg-1-pg-0" class="basePath" d="..."></path>
            </g>
            <rect class="svg-group-container" width="25.399999999999995" height="25.4" x="-12.699999999999998" y="-12.7"></rect>
          </g>
        </g>
      </g>
    </g>
  </g>

  <g class="bounding-box" transform="translate(12.307092559835633, -18.001368502040076)">
    ...
  </g>

</svg>
*/

const createSvgPathWithCSSClasses = (
  path: BasePath,
  pathId: string,
  cssClasses: string[]
) => {
  return createSvgPathWithCSSAndAttributes(path, pathId, cssClasses, []);
};

const createSvgPathWithAttributes = (
  path: BasePath,
  pathId: string,
  attrs: PathAttribute[] = defaultPathAttrs,
  useSourceTransform = null
) => {
  return createSvgPathWithCSSAndAttributes(
    path,
    pathId,
    [],
    attrs,
    useSourceTransform
  );
};

const createSvgPathDataPrefix = () => 'd = "';

const createSvgPathDataSuffix = () => '"';

const getTransformSvgPathAttributes = (
  useSourceTransform: boolean | null,
  stretchMtx: Matrix33 | null
) => {
  if (useSourceTransform && stretchMtx) {
    return [
      {
        name: 'transform',
        value: getSVGTransformParams(stretchMtx),
      },
    ];
  }
  return [];
};

const createSvgPathFromStrsWithCSSAndAttributes = (
  pathData: string | string[],
  basePathsId: string,
  cssClasses: string[],
  attrs: PathAttribute[]
) => {
  const cssStr = cssClasses.length > 0 ? `class="${cssClasses.join(' ')}"` : '';
  const attrStr =
    attrs.length > 0
      ? attrs.map((a) => `${a.name}="${a.value}"`).join(' ')
      : '';

  let pathStr = `<path id="${basePathsId}" ${cssStr} ${attrStr} `;

  pathStr += createSvgPathDataPrefix();

  if (Array.isArray(pathData)) {
    pathStr += pathData.join(' ');
  } else {
    pathStr += pathData;
  }

  pathStr += createSvgPathDataSuffix();
  pathStr += ' />';

  return pathStr;
};

const createSvgPathWithCSSAndAttributes = (
  basePaths: BasePath,
  basePathsId: string,
  cssClasses: string[],
  attrs: PathAttribute[],
  useSourceTransform = null
) => {
  const useSourceSvg =
    useSourceTransform === true || useSourceTransform === false;

  const cssStr = cssClasses.length > 0 ? `class="${cssClasses.join(' ')}"` : '';
  const attrStr =
    attrs.length > 0
      ? attrs.map((a) => `${a.name}="${a.value}"`).join(' ')
      : '';

  let pathStr = `<path id="${basePathsId}" ${cssStr} ${attrStr} `;

  pathStr += createSvgPathDataPrefix();

  if (!basePaths.outerPath) {
    pathStr += createSvgPathDataFromPath(basePaths, useSourceSvg);
  } else {
    pathStr += createSvgPathDataFromSimplePolygon(basePaths, useSourceSvg);
  }
  pathStr += createSvgPathDataSuffix();
  pathStr += ' />';

  if (useSourceTransform && basePaths.sourceSvg) {
    //If using sourceSvg, need to scale sourceSvg.svg by svgGroup.stretchMtx * sourceSvg.sourceTransform
    //This is done before if all paths have the same transform. There currently isn't any case where this won't be true, but it could come up later with further shapeshifter improvements.
    //If including sourceSvg and group does not have common path source svg transforms, then need to wrap each path in its own sourceTransform. This is not implemented yet due to the impact it would have on the display svg structure.
    // const pathTransform = AbstractMatrix33.multiplyMatrix33(svgGroup.TRSMtx, pathTransforms[0])
    // pathStr = `<g transform="${AbstractMatrix33.getSVGTransformParams(basePaths.sourceSvg.sourceTransform)}">${pathStr}</g>`;
    throw new Error('Path transforms not implemented for sourceSvg');
  }

  return pathStr;
};

const createSvgImageGroup = (
  innerSvgStr: string = '',
  cssClasses: string[] = [],
  id: string = '',
  transformStr: string = '',
  bounds?: AABB
) => {
  const cssClassesStr =
    cssClasses.length > 0 ? `class="${cssClasses.join(' ')}"` : '';

  const idStr = id !== '' ? `id="sg-${id}"` : '';

  // if requesting a bounding box, append it
  if (bounds) {
    // eslint-disable-next-line no-param-reassign
    innerSvgStr += createSvgGroupContainer(bounds);
  }

  return `<g ${idStr} ${cssClassesStr} ${transformStr}>\n  ${innerSvgStr}\n</g>`;
};

const createSvgImage = (
  innerSvgStr: string = '',
  svgAABB: AABB = new AABB(),
  nestedSvg: boolean = false,
  namespaces: object = {}
) => {
  const svgSize = svgAABB.size;

  const sizeStr = nestedSvg
    ? ''
    : `width="${svgSize.x}mm" height="${svgSize.y}mm"`;
  const namespacesDefs = Object.entries(namespaces)
    .map(([nsId, nsUrl]) => `xmlns:${nsId}="${nsUrl}"`)
    .join(' ');

  return `<svg xmlns="http://www.w3.org/2000/svg" ${namespacesDefs} ${sizeStr} viewBox="${svgAABB.minPoint.x} ${svgAABB.minPoint.y} ${svgSize.x} ${svgSize.y}"> ${innerSvgStr} </svg>`;
};

const createSvgPathDataFromPath = (
  path: Path,
  useSourceSvg: boolean | null = null
) => {
  //Use sourceSvg if possible
  if (useSourceSvg && path.sourceSvg && typeof path.sourceSvg === 'object') {
    return path.sourceSvg.svg;
  }

  let pathStr = '';

  for (const pathPtIdx in path.points) {
    //First point
    if (pathPtIdx === '0') {
      pathStr += `M ${path.points[pathPtIdx].x} ${path.points[pathPtIdx].y} L`;
    } else {
      pathStr += ` ${path.points[pathPtIdx].x} ${path.points[pathPtIdx].y}`;
    }
  }

  //Terminate path
  if (path.closed) {
    pathStr += ' Z ';
  }

  return pathStr;
};

const createSvgPathDataFromSimplePolygon = (
  simplePolygon: BasePath,
  useSourceSvg: boolean | null = null
) => {
  const pathStr = [
    simplePolygon.outerPath
      ? createSvgPathDataFromPath(simplePolygon.outerPath, useSourceSvg)
      : '',
  ];

  simplePolygon.holePaths?.forEach((hp) => {
    pathStr.push(createSvgPathDataFromPath(hp, useSourceSvg));
  });

  return pathStr.join(' ');
};

const createBoundingBoxRect = (size: Point, origin: Point) => {
  const offsetX = -(size.x / 2) + origin.x;
  const offsetY = -(size.y / 2) + origin.y;
  return `<rect class="bounding-box" width="${size.x}px" height="${size.y}px" x="${offsetX}px" y="${offsetY}px" />`;
};

const createCutPathSvg = (
  innerSvgStr: string,
  cssClasses: string[],
  id: string
) => {
  const cssClassesStr =
    cssClasses.length > 0 ? `class="${cssClasses.join(' ')}"` : '';

  const idStr = id ? `id="${id}"` : '';

  return `<g ${idStr} ${cssClassesStr}>${innerSvgStr}</g>`;
};

const createSvgGroupContainer = (bounds: AABB) => {
  const width = bounds.maxPoint.x - bounds.minPoint.x;
  const height = bounds.maxPoint.y - bounds.minPoint.y;
  return `<rect class="svg-group-container" width="${width}" height="${height}" x="-${
    width / 2
  }" y="-${height / 2}" />`;
};

const createSvgUri = (svgString: string) => {
  return `data:image/svg+xml;base64,${btoa(
    unescape(encodeURIComponent(svgString))
  )}`;
};

const createCutPathExportSvg = (
  cutPath: BasePath,
  svgId: string,
  stretchMtx: Matrix33 | null,
  useSourceTransform: boolean | null
) => {
  const svgAttrs = getCutTypeSvgPathAttributes(cutPath.cutParams.cutType);
  const transformSvgAttrs = getTransformSvgPathAttributes(
    useSourceTransform,
    stretchMtx
  );
  const xmlAttrs = getCutParamsXmlAttributes(cutPath.cutParams);
  const pathId = generatePathId(svgId, cutPath.id);

  return createSvgPathWithAttributes(
    cutPath,
    pathId,
    [...svgAttrs, ...transformSvgAttrs, ...xmlAttrs] as PathAttribute[],
    null
  );
};

const createSvgGroupSvg = (svgGroup: SvgGroup) => {
  const transformStr = `transform="${getSVGTransformParams(
    multiplyMatrix33(svgGroup.translateMtx, svgGroup.rotateMtx)
  )}"`;

  const cutPaths = svgGroup.basePathSet;
  const svgPathsStr = cutPaths
    .map((cp) => {
      const postCp = svgGroup.postProcessPathSet?.find((p) => cp.id === p.id);
      if (postCp !== undefined) {
        return createCutPathExportSvg(postCp, svgGroup.id, null, false);
      }
      return createCutPathExportSvg(cp, svgGroup.id, svgGroup.stretchMtx, true);
    })
    .join('\n');

  return createSvgImageGroup(svgPathsStr, [], svgGroup.id, transformStr);
};

const createCanvasSvg = (canvas: Canvas) => {
  let newCanvas = new Canvas(canvas);

  if (newCanvas.showCustomAnchor) {
    // TODO: we don't need to apply post process since there isn't any rounding on an anchor point
    const anchorSvgGroup = new SvgGroup({
      repairPaths: false,
      useGroupSize: true,
      position: new Point(1.852, -3.175),
      basePathSet: [
        new BasePath({
          cutParams: new CutParams({ cutType: CutType.ANCHOR }),
          points: [
            new Point(-1.852, -3.175),
            new Point(-1.852, 3.175),
            new Point(1.852, 3.175),
            new Point(-1.852, -3.175),
          ],
          closed: true,
        }),
      ],
    });
    newCanvas.svgGroupSet.push(anchorSvgGroup);
    newCanvas.AABB = mergeAABBs(newCanvas.AABB, anchorSvgGroup.transformedAABB);
  }

  let svgGroupsSvgStr = newCanvas.svgGroupSet
    .filter((sg) => getTypeProperty(sg.type, 'canExport'))
    .map((sg) => createSvgGroupSvg(sg))
    .join('\n');
  return createSvgImage(svgGroupsSvgStr, newCanvas.AABB, false, {
    shaper: 'http://www.shapertools.com/namespaces/shaper',
  });
};

export {
  createSvgPathFromStrsWithCSSAndAttributes,
  createSvgPathWithCSSClasses,
  createSvgPathWithAttributes,
  createSvgPathDataPrefix,
  createSvgPathDataSuffix,
  getTransformSvgPathAttributes,
  createSvgPathWithCSSAndAttributes,
  createSvgImageGroup,
  createSvgImage,
  createSvgPathDataFromPath,
  createSvgPathDataFromSimplePolygon,
  createBoundingBoxRect,
  createCutPathSvg,
  createSvgGroupContainer,
  createSvgUri,
  createCutPathExportSvg,
  createSvgGroupSvg,
  createCanvasSvg,
};
