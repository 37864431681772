import { Tool, SvgGroup } from './sherpa-svg-generator/SvgGroup';
import { AABB } from './sherpa-svg-generator/AABB';
import { CutParams } from './sherpa-svg-generator/CutParams';
import { Point } from './sherpa-svg-generator/Point';
import { BasePath } from './sherpa-svg-generator/BasePath';
import { createCanvasSvg } from './sherpa-svg-generator/SvgGenerator';
import { mergeAABBs, clone as AABBclone, getAABBSize } from './AABBOps';
import { getAABB } from './BasePathOps';
import { Canvas } from './sherpa-svg-generator/Canvas';
import {
  createSvgGroupFromSvg,
  updateKeyAndValue,
  updateCutParams,
  updateSvgGroupTransform,
  updateTs,
  clone as svgGroupClone,
  applyPostProcess,
} from './SvgGroupOps';

export interface UpdatePaths {
  groupId: string;
  pathId: string;
  cutParams: CutParams;
}

function clone(canvas: Canvas): Canvas {
  const newCanvas = new Canvas();
  newCanvas.svgGroupSet = [...canvas.svgGroupSet];
  newCanvas.AABB = AABBclone(canvas.AABB);
  newCanvas.showCustomAnchor = canvas.showCustomAnchor;
  return newCanvas;
}

function updateCanvasAABB(canvas: Canvas): AABB {
  return canvas.svgGroupSet.reduce(
    (cumBB, sg: SvgGroup) => mergeAABBs(sg.transformedAABB, cumBB),
    new AABB()
  );
}

function createSvgGroupOnCanvasFromSvg(
  canvas: Canvas,
  useGroupSize: boolean,
  position: Point,
  svgStr: string,
  tool?: Tool,
  origin?: Point
): Canvas {
  const svgGroup = createSvgGroupFromSvg({
    useGroupSize,
    position,
    origin,
    svgStr,
    tool,
  });

  canvas.svgGroupSet.push(svgGroup);
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function createSvgGroupOnCanvasFromSimplePolygon(
  canvas: Canvas,
  position: Point,
  simplePolygon: BasePath[],
  tool: Tool
): Canvas {
  let svgGroup = new SvgGroup({
    basePathSet: simplePolygon,
    position,
    tool,
    displaySize: getAABBSize(getAABB(simplePolygon)),
  });
  svgGroup = applyPostProcess(svgGroup);

  canvas.svgGroupSet.push(svgGroup);

  canvas.AABB = updateCanvasAABB(canvas);

  return canvas;
}

function deleteSvgGroup(
  canvas: Canvas,
  svgGroupId: string | { id: string | number }
): Canvas {
  const id = typeof svgGroupId === 'object' ? svgGroupId.id : svgGroupId;
  canvas.svgGroupSet = [
    ...canvas.svgGroupSet.filter((group) => group.id !== id),
  ];

  canvas.AABB = updateCanvasAABB(canvas);

  return canvas;
}

function updateSvgGroup(
  canvas: Canvas,
  svgGroupId: string,
  svgGroupKey: string,
  svgGroupValue: any
): Canvas {
  const thisSvgGroup = canvas.svgGroupSet.find((sg) => sg.id === svgGroupId);

  if (thisSvgGroup !== undefined) {
    updateKeyAndValue({
      svgGroup: thisSvgGroup,
      key: svgGroupKey,
      value: svgGroupValue,
    });
  }

  //Refresh pathgroup and canvas BB after size, position, SVG content update
  canvas.AABB = updateCanvasAABB(canvas);

  return canvas;
}

function createAnchorSvgGroupOnCanvas(canvas: Canvas): Canvas {
  let svgGroup = new SvgGroup({
    repairPaths: false,
    useGroupSize: true,
    position: new Point(1.8520833333333333, -3.175),
    svgStr: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -24 14 24">
        <path d="m 0 -24 v 24 h 14 l -14 -24 z" fill="#FF0000"/>
      </svg>`,
    tool: new Tool(),
  });
  svgGroup = applyPostProcess(svgGroup);
  canvas.svgGroupSet.push(svgGroup);

  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function addSvgGroups(canvas: Canvas, svgGroups: SvgGroup[]): Canvas {
  canvas.svgGroupSet.push(...svgGroups);
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function updatePathsCutParams(
  canvas: Canvas,
  pathsUpdate: UpdatePaths[]
): Canvas {
  //Updating cutParams triggers refresh of canvas AABB.
  //If there are a large number of paths being updated, it is better to update all the paths first, and then refresh AABB only at the end.

  //Track groups that are updated
  const dirtyGroupIds: {
    [key: string]: boolean;
  } = {};
  pathsUpdate.forEach((pu) => {
    const thisSvgGroup = canvas.svgGroupSet.find((g) => g.id === pu.groupId);
    if (thisSvgGroup === undefined) {
      throw new Error('SvgGroup Id not found');
    }

    dirtyGroupIds[pu.groupId] = true;
    // thisSvgGroup.updateCutParams()
    updateCutParams(thisSvgGroup, pu.pathId, pu.cutParams);
  });

  Object.keys(dirtyGroupIds).forEach((gId) => {
    const thisSvgGroup = canvas.svgGroupSet.find((g) => g.id === gId);
    if (thisSvgGroup) {
      updateSvgGroupTransform(thisSvgGroup);
      updateTs(thisSvgGroup);
    }
  });

  //Finally, update canvas AABB once
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function getCanvasSVG(canvas: Canvas): string {
  return createCanvasSvg(canvas);
}

function duplicateGroupsById(
  canvas: Canvas,
  groupIds: string[],
  offset: Point = new Point(5, 5)
): {
  canvas: Canvas;
  groupIds: string[];
} {
  const duplicatedGroupIds: string[] = [];
  const resultingCanvas = groupIds.reduce((canvasAcc, gId) => {
    const srcGroup = canvasAcc.svgGroupSet.find((g) => g.id === gId);
    if (srcGroup) {
      const targetGroup = svgGroupClone(srcGroup);
      duplicatedGroupIds.push(targetGroup.id);

      canvasAcc.svgGroupSet.push(targetGroup);

      return updateSvgGroup(
        canvasAcc,
        targetGroup.id,
        'relative_position',
        offset
      ) as Canvas;
    }
    return canvasAcc;
  }, canvas);

  return { canvas: resultingCanvas, groupIds: duplicatedGroupIds };
}

export {
  clone,
  updateCanvasAABB,
  createSvgGroupOnCanvasFromSvg,
  createSvgGroupOnCanvasFromSimplePolygon,
  deleteSvgGroup,
  updateSvgGroup,
  createAnchorSvgGroupOnCanvas,
  addSvgGroups,
  updatePathsCutParams,
  getCanvasSVG,
  duplicateGroupsById,
};
