export function add(el, type, state) {
  el.classList.add(`${type}--${state}`);
}

export function remove(el, type, state) {
  el.classList.remove(`${type}--${state}`);
}

export function clear(el, type) {
  const prefix = `${type}--`;
  const chars = prefix.length;

  // filtered
  const keep = [];
  el.classList.forEach((item) => {
    if (item.substr(0, chars) !== prefix) {
      keep.push(item);
    }
  });

  // replace the new list
  el.className = '';
  if (keep.length) {
    el.classList.add(...keep);
  }
}

export function replace(el, type, state) {
  clear(el, type);
  add(el, type, state);
}
