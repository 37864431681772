import { immerable } from 'immer';
import { AABB } from './AABB';
import { SvgGroup } from './SvgGroup';

/**
 * @type {Canvas} - canvas element that is used in the CanvasSlice `canvas` property
 * @property {SvgGroup[]} svgGroupSet
 * @property {AABB} AABB -
 * @property {boolean} showCustomAnchor -
 */
export class Canvas {
  [immerable]? = true;
  svgGroupSet: SvgGroup[];
  AABB: AABB;
  showCustomAnchor: boolean;

  /**
   * Creates a new Canvas with either an empty SvgGroupSet, unit AABB, and showCustomAnchor defaulted to `false`
   * OR a cloned copy of an existing Canvas
   * @constructor
   * @param {Canvas?} canvas - an optional Canvas Object to copy if wanting to clone another `canvas`
   */
  constructor(canvas?: Canvas) {
    if (canvas) {
      this.svgGroupSet = JSON.parse(JSON.stringify(canvas.svgGroupSet));
      this.AABB = new AABB({
        minPoint: canvas.AABB.minPoint,
        maxPoint: canvas.AABB.maxPoint,
      });
      this.showCustomAnchor = canvas.showCustomAnchor;
    } else {
      this.svgGroupSet = [];
      this.AABB = new AABB();
      this.showCustomAnchor = false;
    }
  }
}
