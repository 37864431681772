import { setSelection as setSelectionAction } from '@/Redux/Slices/SelectionSlice';

export default class UpdateSelectionAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  setSelection = (selection) => {
    const { dispatch } = this;
    dispatch(setSelectionAction(selection));
  };

  refresh = () => {};
}
