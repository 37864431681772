import { useEffect, useState, useRef } from 'react';

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

const defaultVis = { visToHidden: false, hiddenToVis: false };

export function usePageVisibility() {
  const [transitionCount, setTransitionCount] = useState(0);

  const [isVisible, _setIsVisible] = useState(getIsDocumentHidden());

  //Accessing isVisible state hook in an event handler will give an out of date value, as it references the value of the hook at the time the event handler is attached.
  //So we need to use a ref hook instead, and access via ref.current in the handler.
  const isVisibleRef = useRef(isVisible);

  //Use ref instead of useState because I don't want to trigger rerender when timer ref is updated
  const visTransitions = useRef(defaultVis);

  const setIsVisible = (val) => {
    isVisibleRef.current = val;
    _setIsVisible(val);
  };

  const computeTransitions = (is, was) => {
    const visToHidden = was && !is;
    const hiddenToVis = !was && is;
    visTransitions.current = { visToHidden, hiddenToVis };
  };

  const clearTransitions = () => {
    visTransitions.current = defaultVis;
  };

  const onVisibilityChange = () => {
    computeTransitions(getIsDocumentHidden(), isVisibleRef.current);
    setIsVisible(getIsDocumentHidden());
    setTransitionCount(1);
  };

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  if (transitionCount === 1) {
    setTransitionCount(0);
    clearTransitions();
  }

  return visTransitions.current;
}
