import React from 'react';
import TranslationText from '../TranslationText/TranslationText';

export default function New() {
  return (
    <div className='badge badge--new'>
      <TranslationText i18nKey='new'>New</TranslationText>
    </div>
  );
}
