import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';

import TranslateGroupsAction from '@/Actions/TranslateGroups.js';
import UndoRedoAction from '@/Actions/UndoRedoAction.js';
import DeleteGroupsAction from '@/Actions/DeleteGroups.js';
import DuplicateGroupsAction from '@/Actions/DuplicateGroups.js';
import SetSelectionAction from '@/Actions/SetSelection.js';

import {
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
  DOWN_ARROW,
  DELETE_KEY,
  ESC_KEY,
  BACKSPACE_KEY,
  DUPLICATE_KEY,
  UNDO_KEY_LC,
  UNDO_KEY_UC,
  REDO_KEY_LC,
  REDO_KEY_UC,
  REDO_LEGACY_KEY_LC,
  REDO_LEGACY_KEY_UC,
  SHIFT_LEFT_KEY,
  SHIFT_RIGHT_KEY,
  SELECT_ALL_LC,
  SELECT_ALL_UC,
  FIT_TO_VIEW_KEY,
} from '@/Constants/UI';
import SetCursorAction from '../../../../Actions/SetCursor';
import { addAttributeToUser } from '../../../../Utility/userflow';
import ViewportActions from '@/Actions/Viewport';

export default class KeyboardShortcutsInteraction extends BaseInteraction {
  interactionId = 'Keyboard Shortcuts';

  onKeyDown(event) {
    const hasModifier = event.ctrlKey || event.metaKey;
    const hasShift = event.shiftKey;

    addAttributeToUser('key_pressed', event.key);
    if (this.isKey(event, DUPLICATE_KEY)) {
      this.duplicateSelection();
      return event.cancel();
    }
    // for backwards compatibility for anyone that discovered 'Y' was redo
    else if (this.isKey(event, REDO_LEGACY_KEY_LC, REDO_LEGACY_KEY_UC)) {
      this.redo();
      return event.cancel();
    }
    // ctrl + shift + z or
    else if (
      hasModifier &&
      hasShift &&
      this.isKey(event, REDO_KEY_LC, REDO_KEY_UC)
    ) {
      this.redo();
      return event.cancel();
    } else if (hasModifier && this.isKey(event, UNDO_KEY_LC, UNDO_KEY_UC)) {
      this.undo();
      return event.cancel();
    } else if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);
      cursorAction.toMultiSelect();
    } else if (this.isKey(event, SELECT_ALL_LC, SELECT_ALL_UC)) {
      this.selectAllGroups();
      return event.cancel();
    } else if (this.isKey(event, FIT_TO_VIEW_KEY)) {
      const selectedGroups = this.getSelectedGroups();
      const source = [];
      for (const group of selectedGroups) {
        source.push(
          ...group.basePathSet.map((path) => ({
            group: group,
            path: path,
          }))
        );
      }

      const viewportAction = this.createAction(ViewportActions);
      viewportAction.centerTo(source);
      return event.cancel();
    }
  }

  onKeyUp(event) {
    addAttributeToUser('key_pressed', null);
    if (this.isKey(event, DELETE_KEY, BACKSPACE_KEY)) {
      this.deleteSelection();
      return event.cancel();
    } else if (this.isKey(event, ESC_KEY)) {
      this.clearSelection();
      return event.cancel();
    }
    if (this.isKey(event, LEFT_ARROW.code)) {
      this.nudge(-1, 0, event.shiftKey);
      return event.cancel();
    }
    if (this.isKey(event, RIGHT_ARROW.code)) {
      this.nudge(1, 0, event.shiftKey);
      return event.cancel();
    }
    if (this.isKey(event, UP_ARROW.code)) {
      this.nudge(0, -1, event.shiftKey);
      return event.cancel();
    }
    if (this.isKey(event, DOWN_ARROW.code)) {
      this.nudge(0, 1, event.shiftKey);
      return event.cancel();
    } else if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);
      cursorAction.toDefault();
    }
  }

  duplicateSelection() {
    const action = this.createAction(DuplicateGroupsAction);
    const ids = this.getDesignSelectedGroupIds();
    if (ids.length > 0) {
      action.duplicate(ids, { x: 5, y: 5 });
    }
  }

  deleteSelection() {
    const ids = this.getDesignSelectedGroupIds();
    const action = this.createAction(DeleteGroupsAction);
    action.delete(ids);
  }

  undo() {
    const action = this.createAction(UndoRedoAction);
    action.undo();
  }

  redo() {
    const action = this.createAction(UndoRedoAction);
    action.redo();
  }

  clearSelection() {
    const action = this.createAction(SetSelectionAction);
    action.clear();
  }

  nudge(x, y, extendDistance) {
    const selected = this.getDesignSelectedGroupIds();
    if (!selected.length) {
      return;
    }

    // perform the nudge
    const action = this.createAction(TranslateGroupsAction, selected);
    const bonus = extendDistance ? 10 : 1;
    action.translateBy(x * bonus, y * bonus, { updateUI: false });
    action.resolve();
  }
}
