import React from 'react';
import { useSelector } from 'react-redux';
import * as ui from '@/Redux/Slices/UISlice';
import { useAction } from '@/Actions/useAction';

// actions
import UIFeatureAction from '@/Actions/UIFeature';
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import PathSelector from './PathSelector';
import GroupSelector from './GroupSelector';

export default function SelectionEditorPanel() {
  const isPlanMode = useSelector(ui.selectIsPlanMode);
  const ItemSelector = isPlanMode ? PathSelector : GroupSelector;
  const uiAction = useAction(UIFeatureAction);
  const updateWorkspaceOptionsAction = useAction(UpdateWorkspaceOptionsAction);

  // leaves the editor mode
  function onExitSelectionEditorMode() {
    uiAction.toggleSelectionEditor(false);
    updateWorkspaceOptionsAction.update({ panelOpenOnDefault: false });
    uiAction.toggleEditSelectionProperties(false);
  }

  return (
    <FloatingPanel
      edge='right'
      container
      className='selection-editor'
      title='Manage Selection'
      i18nTitleKey='manage-selection'
      subtitle='Tap to deselect, tap and hold to highlight'
      i18nSubtitleKey='tap-to-deselect'
      onClose={onExitSelectionEditorMode}
    >
      <div className='selection-editor--selection'>
        <div className='selection-editor--selection-items scrollable'>
          <ItemSelector />
        </div>
      </div>
    </FloatingPanel>
  );
}
