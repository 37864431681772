// @ts-nocheck
import { pathJoinTolerance } from '@/defaults';
import {
  clonePath,
  closePath,
  joinPaths as basePathJoinPaths,
  pointsInsidePath,
} from './BasePathOps';
import { comparePoints, distance } from './PointOps';
import {
  getPathSegments,
  segmentsParallel,
  joinAdjacentSegments,
  segmentsIntersect,
} from './SegmentOps';
import { doAABBsIntersect } from './AABBOps';
import { reversePath } from './PathOps';
import { compareCutParams } from './CutParamsOps';
import { Path } from './sherpa-svg-generator/Path';
import { Point } from './sherpa-svg-generator/Point';
import { BasePath } from './sherpa-svg-generator/BasePath';

const JOIN_SPEC_TYPES = {
  JOIN_NONE: 'none',
  JOIN_END_START: 'end_start',
  JOIN_END_END: 'end_end',
  JOIN_START_START: 'start_start',
  JOIN_START_END: 'start_end',
};

/**
 * Closes all base paths where start and end points are less than threshold set in PathOps.closePath
 * @param {BasePath[]} paths base paths to close
 * @returns {BasePath[]}  cloned closed paths
 */
function closePaths(paths: BasePath[]) {
  return paths.map((p) => closePath(p));
}

/**
 * Joins all base paths where start or end points are less than threshold set in pathJoinTolerance
 * @param {BasePath[]} paths base paths to join
 * @returns {BasePath[]}  new cloned paths with cloned, joined paths
 */
function joinPaths(paths: BasePath[]) {
  const resultSet = [];
  let workingSet = [];

  //Build initial sets. Closed paths cannot be joined. All open paths start in the working set
  paths.forEach((p) => {
    if (p.closed) {
      resultSet.push(clonePath(p));
    } else {
      workingSet.push(p);
    }
  });

  const computeJoinSpec = (pathA: BasePath, pathB: BasePath) => {
    const startA = pathA.points[0];
    const startB = pathB.points[0];
    const endA = pathA.points.slice(-1)[0];
    const endB = pathB.points.slice(-1)[0];

    //end_start
    if (distance(endA, startB) < pathJoinTolerance) {
      return JOIN_SPEC_TYPES.JOIN_END_START;
    }

    //end_end
    if (distance(endA, endB) < pathJoinTolerance) {
      return JOIN_SPEC_TYPES.JOIN_END_END;
    }

    //start_end
    if (distance(startA, endB) < pathJoinTolerance) {
      return JOIN_SPEC_TYPES.JOIN_START_END;
    }

    //start_start
    if (distance(startA, startB) < pathJoinTolerance) {
      return JOIN_SPEC_TYPES.JOIN_START_START;
    }

    return JOIN_SPEC_TYPES.JOIN_NONE;
  };

  const mapJoinSpecToPath = (
    js: string,
    selectedPath: BasePath,
    workingPath: BasePath
  ) => {
    switch (js) {
      case JOIN_SPEC_TYPES.JOIN_NONE:
        return workingPath;

      case JOIN_SPEC_TYPES.JOIN_END_START:
        return joinPathPair(selectedPath, workingPath);

      case JOIN_SPEC_TYPES.JOIN_END_END:
        return joinPathPair(selectedPath, reversePath(workingPath));

      case JOIN_SPEC_TYPES.JOIN_START_END:
        return joinPathPair(workingPath, selectedPath);

      case JOIN_SPEC_TYPES.JOIN_START_START:
        return joinPathPair(reversePath(selectedPath), workingPath);

      default:
        throw new Error('Unknown joinSpec value');
    }
  };

  /*
      *** Set transformation pattern

      Given S = [A, B, C, D, ...]

      let thisPath = S.shift() = A
      let results = []

      compare thisPath with S => joinInfo

      joinInfo = [A|B, A|C, A|D] = each element can be none | end_start | end_end | start_start | start_end
    */

  while (workingSet.length > 0) {
    const selectedPath = workingSet.pop();
    const joinSpec = workingSet.map((path) =>
      computeJoinSpec(selectedPath, path)
    );

    const selectedPathComplete =
      joinSpec.every((js) => js === JOIN_SPEC_TYPES.JOIN_NONE) ||
      joinSpec.length === 0;

    if (selectedPathComplete) {
      resultSet.push(clonePath(selectedPath));
    } else {
      //I can only join the selected path to at most two paths, but to avoid Y-junctions, I really should only join the selected path to the first path connecting with either end of the path. Then repeat the test again with the newly joined path to pick up the next joining path, assuming it was not a Y-junction

      const firstJoinSpecIdx = joinSpec.findIndex(
        (js) => js !== JOIN_SPEC_TYPES.JOIN_NONE
      );
      const firstJoinSpec = joinSpec[firstJoinSpecIdx];
      const firstJoinPath = workingSet[firstJoinSpecIdx];
      //Remove firstJoinPath from workingSet
      workingSet.splice(firstJoinSpecIdx, 1);

      //Join selectedPath and firstJoinPath
      const newJoinedPath = mapJoinSpecToPath(
        firstJoinSpec,
        selectedPath,
        firstJoinPath
      );

      //Add any newly closed paths to results
      if (newJoinedPath.closed) {
        resultSet.push(newJoinedPath);
      } else {
        //newJoinedPath is still open, so put newJoinedPath back in the queue for another pass to see if it connects with anything else
        workingSet.push(newJoinedPath);
      }
    }
  }

  return resultSet;
}

function removeDuplicatePaths(paths: BasePath[]): BasePath[] {
  // First, clean all paths, then test if equivalent
  let currentPathSet = paths.map((p) =>
    closePath(p.points && p.points.length > 0 ? removeCollinearPoints(p) : p)
  );
  let uniquePathSet = [];

  while (currentPathSet.length > 0) {
    const thisPath = currentPathSet.pop();
    currentPathSet = currentPathSet.filter(
      (p) => !areCleanedPathsEquivalent(p, thisPath)
    );
    uniquePathSet.push(thisPath);
  }
  return uniquePathSet;
}

//Returns new path with cloned points from pathA and pathB
//points = [...pathA.points, ...pathB.points] -- note that last point of A and first point of B are both included
//Checks if joined path is closed as well
function joinPathPair(pathA: Path, pathB: Path, cleanTolerance = 0.007) {
  return basePathJoinPaths(pathA, pathB, cleanTolerance);
}

//Checks if pathA is equivalent to pathB
//Handles circular shifts for closed paths

function areCleanedPathsEquivalent(cleanA, cleanB) {
  if (!compareCutParams(cleanA.cutParams, cleanB.cutParams)) {
    return false;
  }
  //First find pathA.point[0] in pathB
  //if miss, return false
  //otherwise,
  //close both paths
  //Then start at found index and check each sequential point,

  //Try to close paths and remove collinear points first, so that we're not matching a redundant point in A with pathB
  // Cleaning moved up to reduce computation time
  // const cleanA = PathOps.closePath(this.removeCollinearPoints(pathA));
  // const cleanB = PathOps.closePath(this.removeCollinearPoints(pathB));

  // const cleanA = pathA;
  // const cleanB = pathB;

  if (
    cleanA.closed !== cleanB.closed ||
    cleanA.points.length !== cleanB.points.length
  ) {
    return false;
  }

  const firstAinB = cleanB.points.map((p: Point) =>
    comparePoints(p, cleanA.points[0])
  );
  if (firstAinB.every((r: boolean) => r === false)) {
    return false;
  }

  //For each possible match, test the sequence of points
  let equivalentResult = false;

  function pointIdx(count: number, startIdx: number, pathLength: number) {
    const idx = startIdx + count;
    return idx < pathLength ? idx : idx - pathLength;
  }

  for (let matchIdx = 0; matchIdx < firstAinB.length; matchIdx++) {
    const match = firstAinB[matchIdx];
    if (match === false) {
      continue;
    }

    const len = cleanA.points.length;
    for (let count = 1; count < len; count++) {
      const aIdx = pointIdx(count, 0, len);
      const bIdx = pointIdx(count, matchIdx, len);
      if (comparePoints(cleanA.points[aIdx], cleanB.points[bIdx])) {
        if (count === len - 1) {
          equivalentResult = true;
        }
        continue;
      } else {
        break;
      }
    }
    if (equivalentResult) {
      break;
    }
  }
  return equivalentResult;
}

function removeCollinearPoints(path: BasePath) {
  const { closed, ...rest } = path;

  //Build initial set of all segments, from 0 to l, including loop-around if closed
  const initialSegments = getPathSegments(path);
  if (initialSegments.length === 1) {
    return path;
  }

  let segments = initialSegments;
  const resultSegments = [];

  while (segments.length > 1) {
    /*
        get first seg
        check first seg for parallel with next seg
        if parallel, combine and add to front of next set to keep testing against further adjacent segments, otherwise, add to result set
        and add all unchecked segs to next set
      */

    const thisSeg = segments.shift();
    const nextSeg = segments.shift();

    //We know these segments are connected, because they are derived from adjacent points
    //Therefore, they are collinear if they are parallel

    const collinear = segmentsParallel(thisSeg, nextSeg);

    if (collinear) {
      //If collinear, keep joinedSeg in queue and test against next segment
      const joinedSeg = joinAdjacentSegments(thisSeg, nextSeg);
      if (joinedSeg.length === 1) {
        segments = [...joinedSeg, ...segments];
        continue;
      }
    }

    //Not collinear OR collinear, but couldn't join due to doubling back
    resultSegments.push(thisSeg);
    segments = [nextSeg, ...segments];
  }

  //We still have one segment left in the queue
  //If path is closed, we need to check the last seg against first seg
  if (closed && resultSegments.length > 0) {
    const thisSeg = segments[0];
    const nextSeg = resultSegments.shift();

    const collinear = segmentsParallel(thisSeg, nextSeg);

    const joinedSeg = joinAdjacentSegments(thisSeg, nextSeg);
    if (collinear && joinedSeg.length === 1) {
      resultSegments.unshift(joinedSeg[0]);
    } else {
      //Put nextSeg back at front of results
      resultSegments.unshift(nextSeg);
      //And add last segment to end of results
      resultSegments.push(thisSeg);
      segments = [nextSeg, ...segments];
    }
  } else {
    //If not closed, just add it to the results
    resultSegments.push(segments[0]);
  }

  const cleanPoints = resultSegments.map((seg) => seg[0]);
  if (!closed) {
    cleanPoints.push(resultSegments[resultSegments.length - 1][1]);
  }
  return clonePath({ ...rest, closed, points: cleanPoints });
}

/*
    Current grouping algorithm uses the following rules:
    1. If two paths (open or closed), intersect, they are part of the same group.
    2. If any path (open or closed) is inside a closed path, they are part of the same group.

    There is an edge case of intersecting open or closed paths enclosing another path. This will be missed by the above rules. To catch this:
    3. See https://stackoverflow.com/questions/14333341/checking-if-a-set-of-lines-make-a-closed-object-in-contour
  */

//This has been moved to PathRepair.worker.js, but code kept here for testing

function pathsIntersect(pathA: BasePath, pathB: BasePath): boolean {
  if (!doAABBsIntersect(pathA.AABB, pathB.AABB)) {
    return false;
  }
  const segmentsA = getPathSegments(pathA);
  const segmentsB = getPathSegments(pathB);

  for (const segA of segmentsA) {
    for (const segB of segmentsB) {
      if (segmentsIntersect(segA, segB)) {
        return true;
      }
    }
  }
  return false;
}

function pathsEnclosed(pathA: BasePath, pathB: BasePath): boolean {
  if (pathA.closed) {
    //Check if any point in B contained in A
    const BinA = pointsInsidePath(pathA, pathB.points);
    if (BinA) {
      return true;
    }
  }
  if (pathB.closed) {
    //Check if any point in A contained in B
    const AinB = pointsInsidePath(pathB, pathA.points);
    if (AinB) {
      return true;
    }
  }
  return false;
}

function testPathPairForGrouping(pathA, pathB): boolean {
  return pathsEnclosed(pathA, pathB) || pathsIntersect(pathA, pathB);
}

// Returns an array of path arrays. Each path array should be instantiated as a separate group.
function groupPaths(paths) {
  //Initialize groupSet with array of single path arrays
  // const groupStartTime = performance.now();
  let groupSet = paths.map((p) => [p]);
  const resultGroupSet = [];

  while (groupSet.length > 1) {
    const sourceGroup = groupSet.shift();

    const mergeGroupFlags = new Array(groupSet.length).fill(false);

    // I need a label to break out of two loop levels easily
    // eslint-disable-next-line no-labels
    nextTargetGroup: for (const [
      targetIdx,
      targetGroup,
    ] of groupSet.entries()) {
      for (const targetPath of targetGroup) {
        for (const sourcePath of sourceGroup) {
          const groupedPaths = testPathPairForGrouping(sourcePath, targetPath);
          if (groupedPaths) {
            mergeGroupFlags[targetIdx] = true;
            // eslint-disable-next-line no-labels
            continue nextTargetGroup; //Break out of targetPath and sourcePath loops, test next targetGroup
          }
        }
      }
    }

    //If sourceGroup doesn't intersect or enclose any other groups, it's done.
    if (mergeGroupFlags.every((flag) => !flag)) {
      resultGroupSet.push(sourceGroup);
      continue;
    }

    //Add flagged groups to sourceGroup, remove flagged groups as separate groups from groupSet, and put sourceGroup back at the front of groupSet for further comparisons
    const flaggedGroups = groupSet
      .filter((g, idx) => mergeGroupFlags[idx])
      .flat();

    groupSet = groupSet.filter((g, idx) => !mergeGroupFlags[idx]);

    groupSet.unshift([...flaggedGroups, ...sourceGroup]);
  }

  //Add last group to results
  resultGroupSet.push(...groupSet);

  // console.log(`grouping time ${performance.now()-groupStartTime}`);
  return resultGroupSet;
}

export {
  closePaths,
  joinPaths,
  removeDuplicatePaths,
  joinPathPair,
  areCleanedPathsEquivalent,
  removeCollinearPoints,
  pathsIntersect,
  pathsEnclosed,
  testPathPairForGrouping,
  groupPaths,
};
