import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import { debugTranslations, isDevelopment } from './development';
import { addAttributeToUser } from './Utility/userflow';
import * as Sentry from '@sentry/react';

const isStaging = () => {
  return (
    isDevelopment() ||
    window.location.href.includes('staging') ||
    window.location.href.includes('herokuapp')
  );
};

const getLocalTranslations = async (url, callback) => {
  try {
    const translation = await fetch(`/locales/${url}/translation.json`);
    callback(null, {
      status: 200,
      data: translation.data,
    });
  } catch (error) {
    Sentry.captureEvent(error);
  }
};

export const i18nInit = async () => {
  const cdnURL = isStaging()
    ? `https://assets.shapertools.com/staging/studio/locales`
    : `https://assets.shapertools.com/studio/locales`;

  const i18nConfig = i18n.use(HttpBackend).use(initReactI18next);

  i18nConfig.init({
    fallbackLng: 'en',
    debug: debugTranslations(),
    whitelist: ['en', 'de', 'fr'],
    backend: {
      loadPath: `{{lng}}`,
      parse: (data) => data,
      request: async (options, url, payload, callback) => {
        if (isDevelopment()) {
          getLocalTranslations(url, callback);
        } else {
          try {
            const translation = await axios.get(
              `${cdnURL}/${url}/translation.json`
            );
            callback(null, {
              status: 200,
              data: translation.data,
            });
          } catch (e) {
            getLocalTranslations(url, callback);
          }
        }
      },
    },
    react: {
      useSuspense: false,
    },
  });
};

export const setI18nLanguage = (locale) => {
  i18n.changeLanguage(locale);
  addAttributeToUser('locale_code', locale);
};

export default i18nInit();
