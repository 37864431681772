import BaseAction from './BaseAction';

import {
  hideAllFeatures,
  toggleIconSearch,
  toggleEditSelectionProperties,
  toggleSelectionEditor,
  toggleShapeCreator,
  toggleFileImport,
} from '@/Redux/Slices/UISlice';

export default class UIFeatureAction extends BaseAction {
  // TODO: add toggles and other behaviors?

  toggleInsertShape(active) {
    this.dispatch(toggleShapeCreator(active));
  }

  toggleSelectionEditor(active) {
    this.dispatch(toggleSelectionEditor(active));
  }

  toggleEditSelectionProperties(active) {
    this.dispatch(toggleEditSelectionProperties(active));
  }

  toggleFindArt(active) {
    this.dispatch(toggleIconSearch(active));
  }

  toggleFileImport(active) {
    this.dispatch(toggleFileImport(active));
  }

  clear() {
    this.dispatch(hideAllFeatures());
  }
}
