import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// helpers
import { getEventAttributeValue, useSwitchMobileView } from '@/Utility/react';

// selectors
import { selectScreenViewMode } from '@/Redux/Slices/ViewportSlice';

// components
import CanvasActionMenuButton from './CanvasActionMenuButton';

// default floating canvas action menu
export default function CanvasActionMenu(props) {
  let { expandable, i18nKey } = props;
  const viewMode = useSelector(selectScreenViewMode);
  const isMobile = viewMode === 'mobile';
  const defaultExpanded = expandable === 'mobile' && isMobile ? false : true;

  // state
  const [expanded, setExpanded] = useState(defaultExpanded);
  const disableAll = props.disableAll ?? false;

  // toggles the menu
  function onToggleExpansion() {
    setExpanded(!expanded);
  }

  // selects an action
  function onSelectAction(event) {
    const id = getEventAttributeValue(event, 'data-action');
    const action = props.actions.find((item) => item.id === id);
    props.onSelectAction(action);
  }

  useSwitchMobileView((mode) => {
    if (mode === 'mobile' && expandable === 'mobile') {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  });

  // generate each action
  const buttons = props.actions
    .filter((item) => item.enabled !== false && item.visible !== false)
    .map(
      (action, index) =>
        action.component || (
          <CanvasActionMenuButton
            key={index}
            icon={action.icon || action.id}
            content={action.content}
            className={action.className}
            onClick={disableAll ? () => {} : onSelectAction}
            attrs={{
              'data-action': action.id,
              'data-cy': action.i18nKey || action.dataCy,
            }}
            i18nKey={action.i18nKey}
          >
            {action.label}
          </CanvasActionMenuButton>
        )
    );

  // render
  const expandLabel = expanded ? 'Cancel' : '';
  const direction = ['left', 'right'].includes(props.edge)
    ? 'vertical'
    : 'horizontal';
  const menuCx = classNames(
    'canvas-action-menu',
    props.edge,
    direction,
    props.className,
    {
      expanded,
      invert: 'invert' in props,
    }
  );

  return (
    <div className={menuCx} role='menu' data-cy={props.dataCy}>
      {expanded && buttons}

      {expandable && (
        <CanvasActionMenuButton
          icon='action-menu-toggle'
          i18nKey={i18nKey}
          onClick={onToggleExpansion}
          attrs={{
            className: 'canvas-action-menu--expander',
            'data-cy': 'mobile-menu-expander',
          }}
        >
          {expandLabel}
        </CanvasActionMenuButton>
      )}
    </div>
  );
}
