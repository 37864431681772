import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UIState from '@/UILayer/State/UIState';

import { selectSVGViewbox, selectViewport } from '@/Redux/Slices/ViewportSlice';

// state management
import DefaultState from '../State/DefaultState';
import DesignState from '../State/DesignState';

// UIs
import DefaultUI from './Default/DefaultUI';
import PlanUI from './Plan/PlanUI';
import ReviewUI from './Review/ReviewUI';

// special modes
import ShapeBuilderUI from './ShapeBuilder/ShapeBuilderUI';
import AnchorSelectionUI from './AnchorSelection/AnchorSelectionUI';
import ShapeBuilderState from '../State/ShapeBuilderState';
import ImportState from '../State/ImportState';
import ImportUI from './Import/ImportUI';
import { selectOptions } from '../../Redux/Slices/SherpaContainerSlice';

// possible UI layer modes
const MODES = {
  default: [DefaultUI, DesignState],
  'text-editor': [DefaultUI, DesignState],
  plan: [PlanUI, DesignState],
  review: [ReviewUI, DefaultState],
  import: [ImportUI, ImportState],
  'shape-builder': [ShapeBuilderUI, ShapeBuilderState],
  'anchor-selection': [AnchorSelectionUI, DefaultState],
};

export default function UILayer(props) {
  const viewport = useSelector(selectViewport);
  const svgViewbox = useSelector(selectSVGViewbox);
  const options = useSelector(selectOptions);

  // used to trigger rendering
  const [, setLastUpdate] = useState();
  UIState.setListener(setLastUpdate);

  // get the correct mode
  const [UI, mode] = MODES[props.ui] || MODES.default;

  // create the UI state
  const ui = UIState.create(mode, {
    // default params
    viewport,
    svgViewbox,

    // some canvas settings from mode menu
    options,

    // provided props
    ...props,
  });

  return <UI {...props} ui={ui} />;
}
