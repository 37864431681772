import BaseAction from './BaseAction';
import AddGeometryAction from '@/Actions/AddGeometry';
import UIModeAction from '@/Actions/UIMode';
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';
import {
  selectOptions,
  updateCustomAnchorOption,
  updateOptions,
} from '@/Redux/Slices/SherpaContainerSlice';
import { toggleFileImport } from '@/Redux/Slices/UISlice';
import {
  setImportedGroup,
  selectImportedGroup,
  setImportPosition,
  selectImportPosition,
} from '@/Redux/Slices/ImportSlice';
import {
  updateKeyAndValue,
  clone,
  createSVGGroupAndImportPositionFromSvg,
} from '@/Geometry/SvgGroupOps';
import { add } from '@/Geometry/PointOps';
import { mergeAABBArray, getSizeAndCenterFromAABB } from '@/Geometry/AABBOps';
import { convertDxfToSvg } from '@/Geometry/DxfConverter';
import { transform, updateSvgSourceTransform } from '../Geometry/BasePathOps';
import { Point } from '../Geometry/sherpa-svg-generator/Point';
import { multiplyMatrix33 } from '../Geometry/sherpa-svg-generator/Matrix33';
import {
  createTranslateMtx,
  invert,
} from '../Geometry/sherpa-svg-generator/Matrix33';

import extractShaperXmlAttributes, {
  ImportOptions,
} from '@/Geometry/extractShaperXmlAttributes';

/*
  Import flow
   dxf  --> svg
    
   SvgGroupOps.createSvgGroupFromSvg(svg) -- make group (or alternatively just paths)
    //Apply join, deduplicate, close repairs here

    Store geometry in ImportSlice

    setMode to import,
    ImportUI gets geometry from slice

    ImportUI.cancel:
      clearImportGeom() 
      setMode(default)

    ImportUI.placeGroup
      CanvasOps.addGroupsToCanvas(importedGroup)
      setMode(default)

    ImportUI.placeSeparately
      start loading spinner
      call splitAndPlaceImportedGroups{
        getImportedGroups
        start webworker to split into multiple groups -- see PathRepair.worker.js or PathRepair.js for synchronous version
      }

      worker.onmessage = this.groupPathsComplete({data})

      groupPathsComplete{
        translates each split group so relative positions of groups remains unchanged
        returns cloned groups
      }

      groups = pathSets.map(ps => SvgGroupOps.createSvgGroup(ps))
      CanvasOps.addGroupsToCanvas(groups)
      setMode(default)
*/

const isSvgImportAnOriginWorkpieceWithDefaultPosition = (svgStr) => {
  const { svgType, importOptions } = extractShaperXmlAttributes(svgStr);

  const isOriginWorkpiece = svgType === 'origin-workpiece';
  const isDefaultPosition = importOptions?.includes(
    ImportOptions.ViewBoxOriginIsGridOrigin
  );
  return isOriginWorkpiece && isDefaultPosition;
};

export default class ImportGeometryAction extends BaseAction {
  // eslint-disable-next-line no-useless-constructor
  constructor(...args) {
    super(...args);
    // since we are no longer attempting to group paths on import
    // we don't have to put Path Repair in a web worker :)
    // this.worker = new PathRepairWorker();
    // this.worker.onmessage = this.groupPathsComplete.bind(this);
  }

  getViewboxPosition = () => {
    const viewbox = this.useSelector(selectSVGViewbox);
    return getPositionFromSvgViewbox(viewbox);
  };

  getImportedGroup = () => {
    // Value from selector is immutable, but we may need to mutate to adjust position, so deep clone it.
    return JSON.parse(JSON.stringify(this.useSelector(selectImportedGroup)));
  };

  placeImportedGroups(importedGroups = [this.getImportedGroup()]) {
    //Logic for positioning: 0, 0 if position is on, otherwise center of screen.

    // Until Shawn or Adi says otherwise, Origin workpieces should ALWAYS be placed with the grid Origin at Studio's origin.
    const importPosition = this.useSelector(selectImportPosition);
    const defaultPosition = this.useSelector(selectOptions).usePosition
      ? new Point()
      : this.getViewboxPosition();

    // If the import position is defined, it suggests that the SVG is considered an Origin workpiece.
    const isOriginWorkpiece = Boolean(importPosition);

    // Assign the actual position to be used based on whether the SVG is an Origin workpiece.
    const position = isOriginWorkpiece ? importPosition : defaultPosition;

    if (isOriginWorkpiece) {
      // Turn on custom anchor point for Origin workpieces to align them correctly upon re-import.
      // Future improvement: Implement a hidden custom anchor that represents the grid origin.
      this.dispatch(updateOptions({ usePositioning: true }));
      this.dispatch(updateCustomAnchorOption(true));
    }

    importedGroups.forEach((ig) => {
      updateKeyAndValue({
        svgGroup: ig,
        key: 'relative_position',
        value: position,
      });
    });
    const addGeometryAction = this.createAction(AddGeometryAction);
    addGeometryAction.placeImportedGroups(importedGroups).then(() => {
      const uiModeAction = this.createAction(UIModeAction);
      uiModeAction.toDefault();
    });
  }

  splitAndPlaceImportedGroup(attemptGrouping = false) {
    const importedGroup = this.getImportedGroup();
    // if(attemptGrouping){
    //   this.worker.postMessage(importedGroup);
    // }
    this.groupPathsComplete({
      data: { importedGroup, splitPathSets: importedGroup.basePathSet },
    });
  }

  groupPathsComplete(msg) {
    let { importedGroup, splitPathSets } = msg.data;
    splitPathSets = splitPathSets.flat().map((x) => [x]);
    const pathSetsPositions = splitPathSets.map((pathSet) => {
      const pathsAABBs = pathSet.map((path) => path.AABB);
      const pathSetAABB = mergeAABBArray(pathsAABBs);
      return getSizeAndCenterFromAABB(pathSetAABB).centerPosition;
    });
    const splitGroups = splitPathSets.map((pathSet, idx) => {
      //Set each split group's position to be the center of the pathSet's AABB
      const position = pathSetsPositions[idx];

      //Transform each split group's path by -pathSetPosition
      const pathMtx = invert(createTranslateMtx(position));
      const transformedPathSet = pathSet
        .map((path) => transform(path, pathMtx))
        //Need to preserve this relative position transform to use later when retessellating
        .map((path) =>
          updateSvgSourceTransform(
            path,
            multiplyMatrix33(pathMtx, path.sourceSvg.sourceTransform)
          )
        );

      return clone(
        {
          ...importedGroup,
          position: add(position, importedGroup.position),
        },
        transformedPathSet
      );
    });
    this.placeImportedGroups(splitGroups);
  }

  importSVG(svg, fromTrace = false) {
    this.dispatch(toggleFileImport());

    const { svgGroup, importPosition } = createSVGGroupAndImportPositionFromSvg(
      {
        useGroupSize: true,
        svgStr: svg,
        repairPaths: true,
      }
    );

    this.dispatch(
      setImportedGroup({
        importGroup: svgGroup,
        fromTrace,
      })
    );

    // Only use the import position if the svg is an Origin workpiece, otherwise set to null and use the default positioning rules for imported SVG files
    const useImportPosition =
      isSvgImportAnOriginWorkpieceWithDefaultPosition(svg);

    this.dispatch(setImportPosition(useImportPosition ? importPosition : null));
    const uiModeAction = this.createAction(UIModeAction);
    uiModeAction.toImport();
  }

  importDXF(dxf, position = this.getViewboxPosition()) {
    const convertedSVG = convertDxfToSvg(dxf);
    this.importSVG(convertedSVG);
  }
}
