import React from 'react';

// components
import Button from './Components/Button';
import Label from './Components/Label';
import Close from './Components/Close';
import Row from './Components/Row';

export default function FooterMenu(props) {
  return <div className='footer-menu'>{props.children}</div>;
}

FooterMenu.Button = Button;
FooterMenu.Label = Label;
FooterMenu.Close = Close;
FooterMenu.Row = Row;
