import {
  comparePoints,
  distance,
  subtract,
  scalarMul,
  dot,
  add,
  norm,
} from './PointOps';
import { Point } from './sherpa-svg-generator/Point';

class Line {
  p0: Point;
  p1: Point;
  constructor({ p0, p1 }: { p0: Point; p1: Point }) {
    this.p0 = p0;
    this.p1 = p1;
  }

  distanceToPoint(point: Point, eps = 0.001) {
    //If line is 0 length, reduce to distance from point
    if (comparePoints(this.p0, this.p1, eps)) {
      return distance(this.p0, point);
    }
    //Compute norm vector of line seg
    const segVec = subtract(this.p1, this.p0);
    const segNormVec = scalarMul(segVec, 1 / norm(segVec));

    //Compute vector from p0 to point
    const pointVec = subtract(point, this.p0);

    //Dot product of these vectors is relative position of intersection point of perpendicular line from point to line seg. Length of this perpendicular line is the shortest distance between the point and line, relative to p0
    const deltaPerpIntersection = scalarMul(
      segNormVec,
      dot(segNormVec, pointVec)
    );

    //Determine absolute location of the intersection point
    const perpIntersection = add(this.p0, deltaPerpIntersection);

    //Get length of perpendicular line from perpIntersection to point
    return distance(point, perpIntersection);
  }
}

function simplify(points: Point[], tolerance = 0.01): Point[] {
  if (points.length <= 2) {
    return points;
  }

  const lineSeg = new Line({ p0: points[0], p1: points[points.length - 1] });

  //Test only the points between line segment end points
  const intermediatePoints = points.slice(1, -1);
  const ptDistances = intermediatePoints.map((p) => lineSeg.distanceToPoint(p));
  const maxDistancePt = ptDistances.reduce(
    (acc, d, idx) => (d > acc.distance ? { distance: d, idx: idx + 1 } : acc),
    { distance: Number.MIN_SAFE_INTEGER, idx: -1 }
  ); //Need + 1 because we want index into points, not intermediatePoints

  //Need to round distance to handle numerical issues
  const toleranceLog = Math.log10(tolerance);
  const toleranceMag =
    toleranceLog < 0 ? Math.floor(toleranceLog) : Math.ceil(toleranceLog);
  const roundMag = Math.pow(10, toleranceMag - 3); //Round to 1/1000th of tolerance;

  maxDistancePt.distance = Math.round(maxDistancePt.distance / roundMag);

  if (maxDistancePt.distance >= Math.round(tolerance / roundMag)) {
    const leftSet = points.slice(0, maxDistancePt.idx + 1); //Need to include maxDistancePt here. idx will never be greater than length - 2
    const rightSet = points.slice(maxDistancePt.idx); //If maxDistancePt.idx == length-2, then leftSet = 0..length-2 and rightSet is length-2..length-1

    return [
      // 0 .. idx - 1
      ...simplify(leftSet, tolerance).slice(0, -1), //Remove last point to prevent duplicate of point shared by leftSeg and rightSeg
      //idx .. end
      ...simplify(rightSet, tolerance),
    ];
  }
  return [points[0], points[points.length - 1]];
}

export { simplify };
