import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// components
import Grid from '@/UILayer/Components/Grid';
import SelectionNet from '@/UILayer/Components/SelectionNet';
import WorkspaceOrigin from '@/UILayer/Components/WorkspaceOrigin';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import Pattern from '../../Components/Pattern';
import classNames from 'classnames';
import CustomAnchor from '../../Components/CustomAnchor';

export default function DefaultUI(props) {
  const { ui } = props;
  const { showGrid, usePositioning, useCutPaths, showCustomAnchor } =
    useSelector(selectOptions);
  const { hasSelectionNet } = ui;
  const uiCx = classNames('ui-layer', { 'cut-path-display': useCutPaths });

  return (
    <div className={uiCx}>
      <Viewport ui={ui}>
        <Pattern />
        {usePositioning && showGrid && <Grid viewport={ui.viewport} />}
        {usePositioning && <WorkspaceOrigin {...props} hideGuides={true} />}
        {usePositioning && showCustomAnchor && (
          <CustomAnchor {...props} viewport={ui.viewport} />
        )}
        <SvgGroups ui={ui} />
        {hasSelectionNet && <SelectionNet {...ui.selectionNet} />}
      </Viewport>
    </div>
  );
}
