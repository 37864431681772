import { batch } from 'react-redux';
import BaseAction from './BaseAction';
import {
  clearSelection,
  updateActiveSelection,
} from '@/Redux/Slices/SelectionSlice';
import { deleteSvgGroup } from '@/CanvasContainer/CanvasActions';

export default class DeleteGroupsAction extends BaseAction {
  delete(groupIds, deleteOnly) {
    const { dispatch } = this;
    if (deleteOnly) {
      return dispatch(deleteSvgGroup(groupIds));
    }

    return batch(async () => {
      await dispatch(clearSelection());
      const deleteResult = await dispatch(deleteSvgGroup(groupIds));
      await dispatch(updateActiveSelection());
      return deleteResult;
    });
  }
}
