import math from './sherpa-svg-generator/mathjs';
import { unitsPrecision } from '@/defaults';
import { isNumber, isString } from 'lodash';

const unit = math.unit;

function validNumeric(n: any) {
  if (typeof n === 'string' || n instanceof String) {
    return isNaN(Number(n)) ? false : true;
  } else if (typeof n === 'number' || n instanceof Number) {
    return true;
  }
}

function formattedNum(num: number, displayUnit: string): string {
  const precision = (() => {
    switch (displayUnit) {
      case 'in':
        return unitsPrecision.imperialPrecision;
      case 'mm':
        return unitsPrecision.metricPrecision;
      case 'deg':
        return unitsPrecision.degreePrecision;
      default:
        return 2;
    }
  })();

  return math.format(num, { notation: 'fixed', precision });
}

function mmToUnitFormattedNum(value: string, displayUnit: string): string {
  return formattedNum(
    unit(parseFloat(value), 'mm').toNumber(displayUnit),
    displayUnit
  );
}

function unitToMMFormattedNum(value: string, displayUnit: string): string {
  return formattedNum(
    unit(parseFloat(value), displayUnit).toNumber('mm'),
    'mm'
  );
}

function unitToFormattedDisplayUnit(
  valStr: string,
  displayUnit: string
): string {
  return formattedNum(unit(valStr).toNumber(displayUnit), displayUnit);
}

function unitToMMNum(input: any, displayUnit: string) {
  const value = (() => {
    if (!displayUnit && isString(input)) {
      return input.replace(/[^0-9.-]*/g, '');
    }

    return input;
  })();

  const fromUnit = (() => {
    if (!displayUnit && isNumber(input)) {
      return 'mm';
    }
    return displayUnit;
  })();

  return unit(parseFloat(value), fromUnit).toNumber('mm');
}

function radiansToDegreesFormattedNum(value: string) {
  return formattedNum(unit(parseFloat(value), 'rad').toNumber('deg'), 'deg');
}

function normalizeDegrees(value: number) {
  let angle = value % 360;
  angle = angle < 0 ? angle + 360 : angle;
  return formattedNum(angle, 'deg');
}

function formattedStr(num: number, displayUnit: string): string {
  return `${formattedNum(num, displayUnit).replace(
    /^(\d+\.\d*?[1-9])0+$|^(\d+)\.0*$/gm,
    '$1$2'
  )}`;
}

function mmToUnitFormattedStr(value: string, displayUnit: string): string {
  return `${mmToUnitFormattedNum(value, displayUnit).replace(
    /^(\d+\.\d*?[1-9])0+$|^(\d+)\.0*$/gm,
    '$1$2'
  )}`;
}

function mmToUnitFormattedStrWLabel(
  value: string,
  displayUnit: string
): string {
  return `${mmToUnitFormattedStr(value, displayUnit)} ${displayUnit}`;
}

function convertNumBetweenUnits(
  num: number,
  oldUnits: string,
  newUnits: string
) {
  return unit(num, oldUnits).toNumber(newUnits);
}

function convertNumToMM(num: number, units: string) {
  return unit(num, units).toNumber('mm');
}

function convertUnitNumToMM(n: any, defaultUnit: string = 'mm') {
  if (typeof n === 'string' || n instanceof String) {
    const nu = unit(n);
    if (nu.units.length > 0 && nu.equalBase(unit('1mm'))) {
      try {
        return nu.toNumber('mm');
      } catch (err: any) {
        throw err?.message;
      }
    } else {
      return unit(Number(n), defaultUnit).toNumber();
    }
  } else if (typeof n === 'number' || n instanceof Number) {
    //If no unit, assume number is mm already
    return unit(n, defaultUnit).toNumber();
  }

  throw new TypeError(`Cannot convert type ${typeof n} to units`);
}

export {
  validNumeric,
  formattedNum,
  mmToUnitFormattedNum,
  unitToMMFormattedNum,
  unitToFormattedDisplayUnit,
  unitToMMNum,
  radiansToDegreesFormattedNum,
  normalizeDegrees,
  formattedStr,
  mmToUnitFormattedStr,
  mmToUnitFormattedStrWLabel,
  convertNumBetweenUnits,
  convertNumToMM,
  convertUnitNumToMM,
};
