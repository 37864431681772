import { useActionWithDispatch } from './useAction';

export default class BaseAction {
  constructor(dispatch, useSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  createAction(action, ...args) {
    return useActionWithDispatch(
      action,
      this.dispatch,
      this.useSelector,
      ...args
    );
  }
}
