import { sortGroupsByArea } from '@/Helpers/AreaDetection';

// calculates the groups using their estimated area
export function sortSelectionByArea(selection, allGroups, idsOnly = true) {
  const groups = (() => {
    if (idsOnly) {
      return selection.map(({ groupId }) =>
        allGroups.find((group) => group.id === groupId)
      );
    }
    return selection.map((s) =>
      allGroups.find((group) => group.id === s.groupId)
    );
  })();

  return sortGroupsByArea(groups).map((result) =>
    idsOnly ? result.id : result
  );
}
