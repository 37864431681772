/* eslint-disable valid-jsdoc */
import {
  setSequence,
  setSnapshot,
  setStatus,
} from '../../Redux/Slices/SyncSlice';
import { update, UpdateObject } from './../SyncThunks';
import {
  applyPatches,
  immerToJSONPatches,
  createInitialPatch,
} from './../PatchGenerator';
import { setCanvasFromSyncSnapshot } from '@/Redux/Slices/CanvasSlice';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import { downloadBlob } from '@/ShaperHub/ShaperHubThunks';
import {
  log,
  logError,
  sanitizePatches,
  sanitizeSnapshotResponse,
} from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';

/**
 * Adds an initial patch to a workspace either on create or if a workspace was
 * created without successfully applying an initial patch
 */
export const addInitialPatch = async (
  listenerApi: SyncListenerApi,
  blobId?: string
) => {
  const { dispatch, fork, getState } = listenerApi;
  const { canvas } = getState();
  const initialPatch = createInitialPatch(canvas);

  log(
    `Created an initial patch for workspace`,
    { ...context, initialPatch: sanitizePatches(initialPatch) },
    'debug'
  );

  /**
   * Fork an async task to update the workspace with the initial patch
   */
  const task = fork(async () => {
    const result = await dispatch(update(initialPatch));
    return result;
  });

  const result = await task.result;
  if (result.status === 'ok') {
    const { payload } = result.value;
    const { latestUpdateSequence } = payload as UpdateObject;
    dispatch(setSequence(latestUpdateSequence));

    const { sync } = getState();
    const { snapshot } = sync;

    const jsonPatches = immerToJSONPatches(initialPatch);
    try {
      if (jsonPatches) {
        const newSnapshot = applyPatches(snapshot, jsonPatches);
        log(
          `Able to apply initial patch for workspace`,
          {
            ...context,
            jsonPatches: jsonPatches.length,
            newSnapshot: sanitizeSnapshotResponse(newSnapshot),
          },
          'debug'
        );
        dispatch(setSnapshot(newSnapshot));
        dispatch(setCanvasFromSyncSnapshot(newSnapshot.canvas));
        dispatch(setStatus('edit'));
        dispatch(setLoading(false));

        /**
         * If we have a blob id, now is the time to import it
         */
        if (blobId) {
          dispatch(downloadBlob(blobId));
        }
      } else {
        logError('Unable to convert immer to json patches', {
          ...context,
          initialPatch: sanitizePatches(initialPatch),
        });
      }
    } catch (error) {
      logError(
        'Unable to convert immer to json patches or apply patches to snapshot',
        {
          ...context,
          error,
          initialPatch: sanitizePatches(initialPatch),
          snapshot: sanitizeSnapshotResponse(snapshot),
        }
      );
    }
  }
};
