// This file is used to calculate dimensions for DOM elements so it's
// easier to create SVG elements with appropriate sizes

// reusable container
const container = document.createElement('div');
const bounding = document.createElement('div');
container.appendChild(bounding);

// stays out of view
container.style.display = 'flex';
container.style.visibility = 'hidden';
container.style.pointerEvents = 'none';
container.style.position = 'absolute';
container.setAttribute('source', 'DomElementMeasurement.js');

// measures the text bounding for a layer
export default function measureDomElement(
  contents: string,
  className: string,
  useHtml: boolean
) {
  bounding.className = className;

  if (useHtml) {
    bounding.innerHTML = contents;
  } else {
    bounding.innerText = contents;
  }

  // measure
  document.body.appendChild(container);
  const bounds = container.getBoundingClientRect();
  return bounds;
}
