import React from 'react';
import { cancelEvent } from '@/Utility/events';

export default function PopOut(props) {
  return (
    <div
      className={`properties-panel--pop-out ${props.className || ''}`}
      onClick={cancelEvent}
      onPointerDown={cancelEvent}
    >
      {props.children}
    </div>
  );
}
