import BaseAction from './BaseAction';
import { actions as uiSliceActions } from '@/Redux/Slices/UISlice';
import { batch } from 'react-redux';
import {
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperHubFiles,
  selectWorkspaceInformation,
  selectShaperHubPath,
} from '@/Redux/Slices/ShaperHubSlice';
import {
  getShaperHubFile,
  getShaperHubExternalItemSvg,
} from '@/ShaperHub/ShaperHubThunks';
import ImportGeometryAction from '@/Actions/ImportGeometry';
import OpenSyncedWorkspaceAction from './OpenSyncedWorkspace';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { getShaperHubExternalItem } from '../ShaperHub/ShaperHubThunks';
import { setLoading } from '../Redux/Slices/SherpaContainerSlice';

export default class FileSystemAction extends BaseAction {
  selectDirectory(name) {
    this.dispatch(readShaperHubFolder(name));
  }

  clearDirectory(atRoot) {
    if (!atRoot) {
      this.dispatch(readShaperHubFolder('..'));
    } else {
      this.dispatch(readShaperHubFolder());
    }
  }

  closeFileSystem() {
    batch(() => {
      this.dispatch(uiSliceActions.toggleMoveMenu(false));
      this.dispatch(uiSliceActions.toggleOpenMenu(false));
    });
  }

  toggleMoveMenu(isShowingMoveMenu) {
    batch(() => {
      this.dispatch(uiSliceActions.toggleMoveMenu(!isShowingMoveMenu));
      this.dispatch(readShaperHubFolder());
    });
  }

  toggleOpenMenu(isShowingOpenMenu) {
    batch(() => {
      this.dispatch(uiSliceActions.toggleOpenMenu(!isShowingOpenMenu));
      this.dispatch(getShaperHubFiles());
    });
  }

  toggleMyProjects() {
    this.dispatch(readShaperHubFolder());
  }

  async openFile(file) {
    const importGeometryAction = this.createAction(ImportGeometryAction);
    const svg = await getShaperHubFile(file);
    await importGeometryAction.importSVG(svg);
  }

  async importWorkspace(file) {
    const importGeometryAction = this.createAction(ImportGeometryAction);
    const svg = await getShaperHubExternalItemSvg(file);
    await importGeometryAction.importSVG(svg);
  }

  openWorkspace(file) {
    const openSyncedWorkspaceAction = this.createAction(
      OpenSyncedWorkspaceAction
    );
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(async () => {
      this.dispatch(setLoading(true));
      await openSyncedWorkspaceAction.openSyncedWorkspace(file.externalItemId);
      this.dispatch(uiSliceActions.toggleOpenMenu(false));
      updateWorkspaceOptions.update({ showApplicationMenu: false });
    });
  }

  moveWorkspace() {
    const workspace = this.useSelector(selectWorkspaceInformation);
    const currentPath = this.useSelector(selectShaperHubPath);
    if (workspace) {
      const patch = {
        name: workspace.name,
        path: workspace.path,
        newPath: `/${currentPath.join('/')}/`.replace('//', '/'),
      };

      batch(async () => {
        await this.dispatch(updateShaperHubWorkspace(patch));
        await this.dispatch(getShaperHubExternalItem(workspace.externalItemId));
        this.dispatch(uiSliceActions.toggleMoveMenu(false));
      });
    }
  }
}
