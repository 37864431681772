/* eslint-disable no-unused-vars */
const defaultToolDiams = {
  imperial: { key: 'IN0.125', value: '0.125in', label: '0.125in' },
  metric: { key: 'MM3', units: 'mm', value: '3.0mm', label: '3mm' },
};
const defaultCutDepths = {
  imperial: '0in',
  metric: '0mm',
};
const defaultCutOffsets = {
  imperial: '0in',
  metric: '0mm',
};

/**
 * @enum {CutType} - Defines the cut type of a BasePath as followed by Origin's CutType's (online, guide, inside, pocket, outside)
 *
 * For export purposes, `Anchor` is added as a CutType here, but should not be used as an actual CutType
 */
/* eslint-disable no-unused-vars */
export enum CutType {
  ONLINE = 'online',
  GUIDE = 'guide',
  INSIDE = 'inside',
  POCKET = 'pocket',
  OUTSIDE = 'outside',
  ANCHOR = 'anchor',
}
/* eslint-enable no-unused-vars */

const defaultCutType = {
  key: 'online',
  keyEnum: CutType.ONLINE,
  label: 'On Line',
};

/**
 * @type {ICutParams} - Wrapper configuration interface for {@link CutType}
 */
export interface ICutParams {
  cutType?: CutType;
  cutDepth?: string;
  cutOffset?: string;
  toolDia?: string;
}

/**
 * @type {CutParams} - Properties that are useful in displaying properties in Plan and Review Mode and for exporting purposes
 * @property {CutType} cutType - See {@link CutType} implementation details
 * @property {string} cutDepth - A string to represent the "depth" of a cut
 * @property {string} cutOffset - A string to represent the offset of a cut
 * @property {toolDia} toolDia - A string to represent the diameter of the tool/bit being used for cutting
 */
export class CutParams {
  cutType: CutType;
  cutDepth: string;
  cutOffset: string;
  toolDia: string;

  /**
   * @constructor
   *
   * @param {ICutParams} cutParams - If provided, CutParams will be initialized with the values provided in {@link CutParams}
   *
   * @also
   * Otherwise, default constructor will provide initial values for CutParams:
   *
   *  - cutType = Outside
   *  - cutDepth = '0'
   *  - cutOffset = '0'
   *  - toolDia = '0.125in'
   */
  constructor(cutParams?: ICutParams) {
    if (cutParams) {
      this.cutType = cutParams.cutType || defaultCutType.keyEnum;
      this.cutDepth = cutParams.cutDepth || defaultCutDepths.imperial;
      this.cutOffset = cutParams.cutOffset || defaultCutOffsets.imperial;
      this.toolDia = cutParams.toolDia || defaultToolDiams.imperial.value;
    } else {
      this.cutType = defaultCutType.keyEnum;
      this.cutDepth = defaultCutDepths.imperial;
      this.cutOffset = defaultCutOffsets.imperial;
      this.toolDia = defaultToolDiams.imperial.value;
    }
  }
}
