import React from 'react';

export default function Section(props) {
  return (
    <div className='action-list-menu--section'>
      {props.label && (
        <div className='action-list-menu--section-label'>{props.label}</div>
      )}
      <div className='action-list-menu--section-children'>{props.children}</div>
    </div>
  );
}
