import { batch } from 'react-redux';
import BaseAction from './BaseAction';
import { setLoading } from '../Redux/Slices/SherpaContainerSlice';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { disconnect } from '@/Sync/SyncThunks';
import { log } from '@/Sync/SyncLog';

export default class OpenSyncedWorkspaceAction extends BaseAction {
  constructor(dispatch) {
    super(dispatch);
    this.dispatch = dispatch;
    this.updateWorkspaceOptionsAction = new UpdateWorkspaceOptionsAction(
      dispatch
    );
  }

  openSyncedWorkspace = (newWorkspaceId, newWorkspaceSeq = false) => {
    const { dispatch } = this;
    batch(async () => {
      await dispatch(setLoading(true));
      //disconnect from currently open workspace, if needed, before opening new workspace to prevent being stuck in view only when rapidly switching between workspaces
      log(`Disconnecting workspace and opening workspace ${newWorkspaceId}`, {
        syncLevel: 'action',
      });
      await dispatch(
        disconnect({
          openWorkspaceAfterDisconnect: newWorkspaceId,
        })
      );
      // await dispatch(open({ workspaceId: newWorkspaceId }));
      this.updateWorkspaceOptionsAction.update({ showApplicationMenu: false });
    });
  };
}
