import React from 'react';
import classNames from 'classnames';
import { cancelEvent } from '@/Utility/events';

// components
import Icon from '@/Styles/Icons/Icon';

export default function Check(props) {
  function onToggle(event) {
    props.onToggle(!props.checked);
    cancelEvent(event);
  }

  const icon = props.icon ?? 'check';
  const iconActive = props.checked ? 'active' : 'inactive';
  const checkCx = classNames('mode-menu--check', {
    checked: !!props.checked,
  });

  return (
    <div className={checkCx} onClick={onToggle}>
      <Icon>{`${icon}-${iconActive}`}</Icon>
    </div>
  );
}
