import React from 'react';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { mmToUnitFormattedNum, unitToMMNum } from '@/Geometry/UnitOps';

// util
import { asFloat } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import ResizeGroupsAction from '@/Actions/ResizeGroups';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { getTypeProperty } from '@/Geometry/sherpa-svg-generator/PathTypes';

export default function EllipseEditor() {
  const selectedGroups = useSelector(selectSelectedGroups);
  const displayUnits = useSelector(selectDisplayUnits);
  const selectionBounds = useSelector(selectSelectionBounds);
  const [selectedGroup] = selectedGroups;
  const disabled = getTypeProperty(
    selectedGroup.type,
    'propertyEditingDisabled'
  );

  // actions
  const resizeAction = useAction(
    ResizeGroupsAction,
    selectedGroups,
    'centroid'
  );

  // must be a circle to do this
  const displayDiameter = mmToUnitFormattedNum(
    selectionBounds.width,
    displayUnits
  );

  // handle resize updates
  function onChange(newDiameter) {
    const diameter = Math.abs(asFloat(newDiameter));

    // update
    if (isNumber(diameter)) {
      const size = unitToMMNum(diameter, displayUnits);
      resizeAction.resizeByAbsolute(size, size, true);
      resizeAction.resolve();
    }
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='diameter'>
        <TranslationText i18nKey='diameter'>Diameter</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        prefix='D'
        suffix={displayUnits}
        onCommit={onChange}
        number
        value={displayDiameter}
        calculate
        disabled={disabled}
      />
    </FloatingPanel.Group>
  );
}
