import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OpenSyncedWorkspaceAction from '@/Actions/OpenSyncedWorkspace';

// import Button from '@/Components/Button';

import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import { selectWorkspaceId } from '@/Redux/Slices/SyncSlice';

export default function Workspace() {
  const workspaceId = useSelector(selectWorkspaceId);
  const dispatch = useDispatch();

  const changeWorkspaceId = (newWorkspaceId) => {
    const openSyncedWorkspaceAction = new OpenSyncedWorkspaceAction(dispatch);
    openSyncedWorkspaceAction.openSyncedWorkspace(newWorkspaceId);
  };

  const changeWorkspaceSeq = (newWorkspaceSeq) => {
    const openSyncedWorkspaceAction = new OpenSyncedWorkspaceAction(dispatch);
    openSyncedWorkspaceAction.openSyncedWorkspace(workspaceId, newWorkspaceSeq);
  };

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='import-file'>Workspace ID</FloatingPanel.Label>
      <FloatingPanel.Input
        workspaceId
        onCommit={changeWorkspaceId}
        value={workspaceId}
      />

      <FloatingPanel.Label icon='import-file'>SEQ</FloatingPanel.Label>
      <FloatingPanel.Input
        workspaceId
        onCommit={changeWorkspaceSeq}
        value={''}
      />
    </FloatingPanel.Group>
  );
}
