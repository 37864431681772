export default class SvgPathBuilder {
  xs: number[] = [];
  ys: number[] = [];
  instructions: [string, ...number[]][] = [];
  closedPath = false;

  add(cmd: string, ...points: number[]) {
    for (let i = 0; i < points.length; i += 2) {
      this.xs.push(points[i]);
      this.ys.push(points[i + 1]);
    }
    this.instructions.push([cmd, ...points]);
  }

  closePath() {
    this.closedPath = true;
  }

  generate() {
    const { instructions } = this;

    //HUGO - centering the svg path is handled by the parser, so no need to do it here.
    // determine the bounds
    // const bounds = {
    //   left: Math.min.apply(null, xs) || 0,
    //   top: Math.min.apply(null, ys) || 0,
    //   right: Math.max.apply(null, xs) || 0,
    //   bottom: Math.max.apply(null, ys) || 0
    // };

    // generate the path -- adjust everything to be
    // centered inside of the view
    // let path = instructions.reduce(
    //   (d, [i, ...points]) =>
    //     `${d} ${i}${points.reduce((c, v, j) => `${c} ${round(v - bounds[j % 2 === 0 ? 'left' : 'top'])}`, '')}`,
    //   ''
    // );

    let path = instructions.reduce(
      (d, [i, ...points]) => `${d} ${i}${points.join(' ')} `,
      ''
    );

    if (this.closedPath === true) {
      path += ' Z';
    }

    //In theory, viewbox can be 1:1 with actual units. However, usvg parser tessellates curves with default 0.1 pixel precision. This leads to faceted arcs and splines if we don't upscale the path
    return `<path d="${path}" />`;
  }
}
