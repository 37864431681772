import {
  resize as viewportResize,
  centerTo as viewportCenterTo,
  setGridSize as viewportSetGridSize,
} from '@/Redux/Slices/ViewportSlice';
import BaseAction from './BaseAction';

export default class ViewportActions extends BaseAction {
  refresh() {
    const { innerWidth: width, innerHeight: height } = window;
    this.resize(width, height);
  }

  resize(width, height) {
    const { dispatch } = this;
    dispatch(viewportResize({ width, height }));
  }

  setGridSize(size) {
    const { dispatch } = this;
    dispatch(viewportSetGridSize(size));
  }

  centerTo(targets, options) {
    const { dispatch } = this;
    dispatch(viewportCenterTo({ targets, ...options }));
  }
}
