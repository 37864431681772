/* eslint-disable valid-jsdoc */
import {
  createListenerMiddleware,
  addListener,
  ListenerEffectAPI,
  TypedStartListening,
  TypedAddListener,
  UnknownAction,
} from '@reduxjs/toolkit';
import { setStatus } from '../Redux/Slices/SyncSlice';
import { open, create, update, getSnapshot } from './SyncThunks';

import { logError } from './SyncLog';

import { addOpenListener } from './Listeners/OpenListener';
import { addCanvasActionListener } from './Listeners/CanvasActionListener';
import { addCreateListener } from './Listeners/CreateListener';
import { addDuplicateListener } from './Listeners/DuplicateListener';
import { addQueueListener } from './Listeners/QueueListener';
import { addStatusListener } from './Listeners/StatusListener';
import { addDisconnectListener } from './Listeners/DisconnectListener';
import { addUpdateCanvasListener } from './Listeners/UpdateCanvasListener';
import { AppDispatch, AppThunkDispatch, RootState } from '@/Redux/store';
import { RejectedAction } from '@/Redux/hooks';
import { addGetSnapshotListener } from './Listeners/GetSnapshot';
import { getStatusFromError } from './SyncHelpers';

export const context = {
  syncLevel: 'listener',
};

export const syncListenerMiddleware = createListenerMiddleware({
  onError: (error, errorInfo) => {
    logError(
      `Unable to complete action in sync listener middleware, raised in ${errorInfo}: ${JSON.stringify(
        error
      )}`,
      { ...context }
    );
  },
});

export type SyncStartListening = typeof syncListenerMiddleware.startListening;

export const startSyncListening =
  syncListenerMiddleware.startListening as TypedStartListening<
    RootState,
    AppDispatch
  >;

export const addSyncListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

export type SyncListenerApi = ListenerEffectAPI<RootState, AppThunkDispatch>;

addOpenListener(syncListenerMiddleware.startListening);
addCanvasActionListener(syncListenerMiddleware.startListening);
addCreateListener(syncListenerMiddleware.startListening);
addDuplicateListener(syncListenerMiddleware.startListening);
addQueueListener(syncListenerMiddleware.startListening);
addStatusListener(syncListenerMiddleware.startListening);
addDisconnectListener(syncListenerMiddleware.startListening);
addUpdateCanvasListener(syncListenerMiddleware.startListening);
addGetSnapshotListener(syncListenerMiddleware.startListening);

startSyncListening({
  predicate: (action: UnknownAction) => {
    return (
      open.rejected.match(action) ||
      create.rejected.match(action) ||
      update.rejected.match(action) ||
      getSnapshot.rejected.match(action)
    );
  },
  effect: (action: RejectedAction, { dispatch }) => {
    const { payload } = action;

    logError(
      `Unable to fulfill action ${action.type}`,
      { ...context, action },
      payload
    );

    const newStatus = getStatusFromError(payload);
    dispatch(setStatus(newStatus));
  },
});
