import React from 'react';
import TranslationText from '../../TranslationText/TranslationText';
import FooterMenu from '../FooterMenu';

export default function TextInsertFooterMenu(props) {
  return (
    <FooterMenu>
      <FooterMenu.Row>
        <FooterMenu.Button dataCy='place-text' onClick={props.onCommit}>
          <TranslationText i18nKey='place-text'>Place Text</TranslationText>
        </FooterMenu.Button>
        <FooterMenu.Close onClick={props.onClose} />
      </FooterMenu.Row>
    </FooterMenu>
  );
}
