import React, { useRef, useState } from 'react';

import { asFloat, asInt } from '@/Utility/sanitize';

//actions
import { useAction } from '../../../Actions/useAction';
import UpdateSecretOptions from '@/Actions/UpdateSecretOptions';

// components
import InputField from '../../InputField/InputField';
import Switch from '@/Components/ModeMenu/Components/Switch';
import ClickCapture from '@/Components/ClickCapture/ClickCapture';
import AttachToElement from '@/Components/AttachToElement/AttachToElement';
import { HexColorPicker } from 'react-colorful';
import { createPortal } from '@/Utility/react';
import { MenuItem, Select } from '@material-ui/core';

export default function Input(props) {
  const inputRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [color, setColor] = useState(`#${props.value}`);
  const hasColor = props.hasColor;
  const [selectValue, setSelectValue] = useState(props.value || false);

  const sanitize =
    props['type'] === 'int' // eslint-disable-line dot-notation
      ? asInt
      : // eslint-disable-next-line dot-notation
      props['type'] === 'number'
      ? asFloat
      : props.sanitize || ((val) => val);

  // eslint-disable-next-line dot-notation
  const inputType = props['type'] === 'int' ? 'numeric' : 'text';

  const updateSecretOptionsAction = useAction(UpdateSecretOptions);

  function changeColor(event) {
    setColor(event);
    onChange(event?.substr(1));
  }

  function onExpand() {
    if (hasColor) {
      setExpanded(true);
    }
  }

  function onDismiss() {
    setExpanded(false);
  }

  function onChange(value) {
    if (props.onChange) {
      setSelectValue(value.target.value);
      props.onChange(value);
    } else {
      const val = sanitize(value);
      updateSecretOptionsAction.update({ [props.prop]: val });
    }
  }

  const colorSelector = () =>
    createPortal(
      <ClickCapture
        className='as-overlay secret-menu-panel--color-picker--container'
        onClick={onDismiss}
      >
        <AttachToElement
          className='secret-menu-panel--color-picker--container--element'
          el={inputRef}
        >
          <HexColorPicker color={color} onChange={changeColor} />
        </AttachToElement>
      </ClickCapture>
    );

  const renderInput = () => {
    switch (props.type) {
      case 'toggle':
        return <Switch active={props.value} onToggle={onChange} />;
      case 'dropdown':
        return (
          <Select value={selectValue} onChange={onChange}>
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='de'>German</MenuItem>
            <MenuItem value='fr'>French</MenuItem>
          </Select>
        );
      default:
        return (
          <>
            {expanded && colorSelector()}
            <div onClick={onExpand}>
              <InputField
                size={props.size || 'large'}
                inputMode={inputType}
                selectAllOnFocus={true}
                value={props.value}
                onBlur={onChange}
                onCommit={onChange}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div
      className={`secret-menu-panel--input ${props.className || ''}`}
      ref={inputRef}
    >
      {renderInput()}
      {hasColor && (
        <div className='secret-menu-panel--input--color' onClick={onExpand}>
          <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='10' r='10' fill={color} />
          </svg>
        </div>
      )}
    </div>
  );
}
