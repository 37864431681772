import React, { useEffect, useRef, useState } from 'react';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import { useAction } from '@/Actions/useAction';
import RenameWorkspaceAction from '@/Actions/RenameWorkspace';

//selectors
import { useSelector } from 'react-redux';
import {
  selectWorkspaceInformation,
  selectLoggedIn,
} from '@/Redux/Slices/ShaperHubSlice';

//components
import classNames from 'classnames';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '../TranslationText/TranslationText';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField/TextField';
import AlertAction from '@/Actions/Alert';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { CustomErrors } from '@/Constants/Errors';

export default function ApplicationMenuLauncher() {
  const fileNameRef = useRef();

  const { name } = useSelector(selectWorkspaceInformation);
  const isLoggedIn = useSelector(selectLoggedIn);
  const { t, i18n } = useTranslation();

  const [workspaceName, setWorkspaceName] = useState(name);
  const [newWorkspaceName, setNewWorkspaceName] = useState(name);
  const [editWorkspaceName, setEditWorkspaceName] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);
  const renameWorkspaceAction = useAction(RenameWorkspaceAction);
  const alertAction = useAction(AlertAction);

  const menuTranslation = i18n.exists('main-menu')
    ? t('main-menu')
    : 'main menu';
  const renameTranslation = i18n.exists('rename') ? t('rename') : 'rename';

  function onToggleApplicationMenu() {
    updateWorkspaceOptions.update({ showApplicationMenu: true });
  }

  function showRename() {
    setIsSuccess(false);
    setEditWorkspaceName(!editWorkspaceName);
    setTimeout(() => {
      fileNameRef.current?.select();
    }, 10);
  }

  async function rename() {
    if (newWorkspaceName !== name) {
      const renameResult = await renameWorkspaceAction.renameWorkspace(
        newWorkspaceName
      );
      if (renameResult) {
        setHasError(true);
        setIsSuccess(false);
        if (renameResult === CustomErrors.ALREADY_USED_RENAME) {
          alertAction.set({
            msg: 'Name already in use',
            i18nKey: 'name-in-use',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            icon: 'alert-warning',
            modalIcon: 'circle-warning',
            duration: 4000,
          });
        } else if (renameResult === CustomErrors.BAD_CHARACTER_RENAME) {
          alertAction.set({
            msg: 'Invalid character',
            i18nKey: 'invalid-character',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            modal: MODAL_TYPES.INVALID_CHARACTERS_MODAL,
            icon: 'alert-warning',
            duration: 4000,
          });
        }
      } else {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2500);
      }
    }
  }

  function onChangeWorkspaceName(e) {
    setNewWorkspaceName(e);
    setHasError(false);
  }

  function close() {
    setEditWorkspaceName(false);
    setHasError(false);
    setWorkspaceName(name);
    setNewWorkspaceName(name);
  }

  useEffect(() => {
    setWorkspaceName(name);
    setHasError(false);
    setEditWorkspaceName(false);
  }, [name]);

  const fileNameCx = classNames('application-menu--launcher--file-name', {
    'rename-file': editWorkspaceName,
    error: hasError,
    'error-shake-text': hasError,
    editing: workspaceName !== newWorkspaceName,
    success: isSuccess,
  });

  const fileName = () => {
    return isLoggedIn ? (
      <>
        {editWorkspaceName ? (
          <>
            <TextField
              className={fileNameCx}
              inputRef={fileNameRef}
              onChange={onChangeWorkspaceName}
              onSubmit={rename}
              onBlur={close}
              submitOnEnter
              value={workspaceName}
            />
          </>
        ) : (
          <Tooltip tip={renameTranslation} side='under'>
            <div
              data-cy='launcher-filename'
              className={fileNameCx}
              onClick={showRename}
            >
              {name} {isSuccess && <Icon icon='check' />}
            </div>
          </Tooltip>
        )}
      </>
    ) : (
      <div className={fileNameCx}>
        <TranslationText i18nKey='demo'>Demo</TranslationText>
      </div>
    );
  };

  return (
    <div className='application-menu--launcher' role='menu'>
      <Tooltip tip={menuTranslation} side='right' className='tooltip-icon'>
        <Icon
          icon='shaper-icon'
          className='application-menu--launcher--icon'
          onClick={onToggleApplicationMenu}
          data-cy='application-menu'
        />
      </Tooltip>

      {fileName()}
    </div>
  );
}
