import { CutParams } from './CutParams';

/**
 * Function that takes in {@link CutParams} and returns them as a formatted XML string
 * @param {CutParams} cutParams - the {@link CutParams} to be converted to XML for exporting
 * @param {string?} nsId - optional param that overrides the default `shaper` namespace
 * @returns {string} returns a string that is in XML format
 */
const getCutParamsXmlAttributes = (cutParams: CutParams, nsId = 'shaper') => {
  const xmlAttrs = Object.entries(cutParams).map(([name, val]) => {
    switch (name) {
      case 'cutType':
        return { name: `${nsId}:cutType`, value: val };

      case 'cutDepth':
        //Omit depth values if not explicitly set by users. This is to workaround derp on Origin.
        if (val === '0mm' || val === '0in') {
          return undefined;
        }
        return { name: `${nsId}:cutDepth`, value: val };

      case 'cutOffset':
        return { name: `${nsId}:cutOffset`, value: val };

      case 'toolDia':
        return { name: `${nsId}:toolDia`, value: val };

      default:
        return undefined;
    }
  });
  return xmlAttrs.filter((x) => x !== undefined);
};

export default getCutParamsXmlAttributes;
