/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';
import { miscLinks } from '../../../defaults';
import { batch, useSelector } from 'react-redux';
import OpenSyncedWorkspaceAction from '../../../Actions/OpenSyncedWorkspace';
import UpdateWorkspaceOptionsAction from '../../../Actions/UpdateWorkspaceOptions';
import { setLoading } from '../../../Redux/Slices/SherpaContainerSlice';
import { selectDuplicateId } from '@/Redux/Slices/SyncSlice';

export default function EnablePopupsModal() {
  const modalActions = useAction(ModalActions);
  const openSyncedWorkspaceAction = useAction(OpenSyncedWorkspaceAction);
  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);

  const duplicateId = useSelector(selectDuplicateId);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  const openWorkspace = () => {
    if (duplicateId) {
      batch(() => {
        setLoading(true);
        openSyncedWorkspaceAction.openSyncedWorkspace(duplicateId);
        updateWorkspaceOptions.update({ showApplicationMenu: false });
        modalActions.closeModal(true);
      });
    }
  };

  const openMyFiles = () => {
    window.open(miscLinks.MY_FILES, '_self');
  };

  return (
    <Modal className='enable-popups-modal'>
      <div className='enable-popups-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='alert-warning' />
            <TranslationText i18nKey='enable-popups'>
              Enable Pop-Ups
            </TranslationText>
          </Modal.Title>
          <div className='enable-popups-modal--info'>
            <div className='enable-popups-modal--info--text'>
              <TranslationText i18nKey='duplicated-in-my-files-part1'>
                Your design was successfully duplicated in
              </TranslationText>
              &nbsp;
              <a
                className='enable-popups-modal--info--text--myfiles'
                onClick={openMyFiles}
              >
                <TranslationText i18nKey='my-projects'>
                  My Files
                </TranslationText>
              </a>
              <TranslationText i18nKey='duplicated-in-my-files-part2'>
                , however Studio was unable to open it in a new tab.
              </TranslationText>
            </div>
            <div className='enable-popups-modal--info--experience'>
              <TranslationText i18nKey='popups-enable'>
                For the best Studio experience, enable pop&#8209;ups for this
                site by clicking on the pop&#8209;up icon in the url bar at the
                top of this browser window.
              </TranslationText>
            </div>
            <div className='enable-popups-modal--info--reload'>
              <TranslationText i18nKey='load-duplicate-design'>
                To load the duplicated design, click here
              </TranslationText>
            </div>
          </div>
          <div className='enable-popups-modal--open'>
            <Modal.Button onClick={openWorkspace}>
              <TranslationText i18nKey='open-duplicate'>Open</TranslationText>
            </Modal.Button>
          </div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
