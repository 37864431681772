import React from 'react';
import Icon from '@/Styles/Icons/Icon';

export default function Close(props) {
  return (
    <div className='footer-menu--close' onClick={props.onClick}>
      <Icon>close</Icon>
    </div>
  );
}
