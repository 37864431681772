import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';
import {
  doAABBsIntersect,
  mergeAABBArray,
  getAABBBounds,
} from '@/Geometry/AABBOps';
import { createRotationMtx } from '@/Geometry/sherpa-svg-generator/Matrix33';
import { transform } from '@/Geometry/PointOps';

const MINIMUM_GRID_SNAP_DISTANCE = 3 * 2;
const MINIMUM_OBJECT_SNAP_DISTANCE = MINIMUM_GRID_SNAP_DISTANCE * 2;

// handles creating a final alignment result for guides and snapping
class AlignmentResult {
  guides = [];

  constructor(viewport, area, snapX, snapY) {
    // resolve each value
    [
      ['x', snapX, viewport.canvasToScreenTransform[0][0], 'top', 'bottom'],
      ['y', snapY, viewport.canvasToScreenTransform[1][1], 'left', 'right'],
    ].forEach(([prop, source, scaleBy, minEdge, maxEdge]) => {
      if (!source.hasMatch) {
        return;
      }

      // set the initial value
      this[prop] = source.snapTo * scaleBy;

      // create a guide
      const guide = { [prop]: source.point };
      if (source.target === 'grid') {
        guide.type = 'grid';
      } else {
        guide.type = 'object';
        guide[minEdge] = Math.min(area[minEdge], source.target[minEdge]);
        guide[maxEdge] = Math.max(area[maxEdge], source.target[maxEdge]);
      }

      // save the guide
      this.guides.push(guide);
    });
  }

  // applies snapping, if any
  applyTo(x, y) {
    let targetX = x;
    let targetY = y;

    if (!isNaN(this.x)) {
      targetX = this.x;
    }

    if (!isNaN(this.y)) {
      targetY = this.y;
    }

    return [targetX, targetY];
  }
}

// temp approach for creating bounds using path objects
function establishBounds(
  groups,
  { viewport, ignoreMids, ignoreSubPaths, simplify, filterOffScreen }
) {
  const xs = [];
  const ys = [];
  const mx = []; //mx, my are intermediate points on a selection box that may be alignment targets.
  const my = [];

  // Get viewport AABB in canvas space
  const viewportAABB = viewport.canvasViewbox;

  //For each group, test transformedAABB against viewport to determine if any part is onscreen
  const visibleAABBsInCanvasMM = [];
  let hasMid = false;
  for (const group of groups) {
    hasMid = group.rotation !== 0 || hasMid;
    if (doAABBsIntersect(group.transformedAABB, viewportAABB)) {
      //Use unrotatedAABBs because we need to grab the rotated intermediate points as potential alignment targets. TransformedAABB is always axis-aligned, but unrotatedAABB can be rotated to get correct intermediate points

      visibleAABBsInCanvasMM.push({
        transformedAABB: group.transformedAABB,
        unrotatedAABB: group.unrotatedAABB,
        rotation: group.rotation,
        position: group.position,
      });
    }
  }

  if (ignoreMids) {
    hasMid = false;
  }

  //Merge all visible AABB
  const mergedAABB = mergeAABBArray(
    visibleAABBsInCanvasMM.map((params) => params.transformedAABB)
  );

  visibleAABBsInCanvasMM.forEach((params) => {
    const { unrotatedAABB, position, rotation } = params;
    const { left, right, top, bottom, points } = getAABBBounds(unrotatedAABB);
    if (hasMid) {
      const mtx = createRotationMtx(rotation, position);
      const rotatedAABBPts = points.map((pt) => transform(pt, mtx));

      mx.push(...rotatedAABBPts.map((pt) => pt.x));
      my.push(...rotatedAABBPts.map((pt) => pt.y));
    } else {
      xs.push((left + right) / 2);
      ys.push((top + bottom) / 2);
    }
    xs.push(left, right);
    ys.push(top, bottom);
  });

  //Sort and discard first and last points to get intermediate guide points
  mx.sort((left, right) => left - right);
  mx.pop();
  mx.shift();

  my.sort((left, right) => left - right);
  my.pop();
  my.shift();

  if (simplify) {
    //Get bounds from AABB
    const { left, right, bottom, top } = getAABBBounds(mergedAABB); //returns {left, right. bottom, top, points}
    return {
      left,
      right,
      top,
      bottom,
      cx: (left + right) / 2,
      cy: (top + bottom) / 2,
      hasMid,
      mx,
      my,
    };
  }

  return { xAxis: xs, yAxis: ys };
}

// handles tracking snap targets
class Snap {
  get hasMatch() {
    return !!this.target;
  }

  test(target, point, offset, relativeTo, minimumDistance) {
    const diff = relativeTo - (point + offset);
    const abs = Math.abs(diff);

    // check for equality because snap targets should be saved in priority
    if (
      abs < minimumDistance &&
      (abs <= this.distance || isNaN(this.distance))
    ) {
      this.diff = diff;
      this.distance = abs;
      this.point = relativeTo;
      this.snapTo = relativeTo - point;
      this.target = target;
    }
  }
}

export default class AlignmentHelper {
  constructor(
    canvas,
    viewport,
    options = {
      handle: null,
      isResize: false,
      selectedGroups: [],
      ignoreGroupIds: [],
    }
  ) {
    const { ignoreGroupIds, selectedGroups, handle } = options;
    this.canvas = canvas;
    this.viewport = viewport;

    this.isResize = options.isResize;

    // make sure all selections are up to date
    this.selection = selectedGroups.map((selected) =>
      canvas.svgGroupSet.find((group) => group.id === selected.id)
    );

    // get all other groups -- eventually this
    // should also check if this is in the viewport
    const alignTo = this.canvas.svgGroupSet.filter((group) => {
      return !(
        ignoreGroupIds.find((id) => id === group.id) ||
        selectedGroups.find((item) => item.id === group.id)
      );
    });

    // the possible snap targets
    this.targets = alignTo.map((group) => {
      const bounds = establishBounds([group], { viewport, simplify: true });
      bounds.id = group.id;
      return bounds;
    });

    // the selection object bounds
    this.area = establishBounds(this.selection, {
      viewport,
      ignoreMids: this.selection.length > 1,
      simplify: true,
    });

    const isLeftHandle = SelectionBoxOps.isLeftSideHandle(handle, true);
    const isRightHandle = SelectionBoxOps.isRightSideHandle(handle, true);
    const isTopHandle = SelectionBoxOps.isTopSideHandle(handle, true);
    const isBottomHandle = SelectionBoxOps.isBottomSideHandle(handle, true);

    // determine resize behavior
    if (SelectionBoxOps.isLeftSideHandle(handle)) {
      this.resizePropX = 'left';
    }
    if (SelectionBoxOps.isRightSideHandle(handle)) {
      this.resizePropX = 'right';
    }
    if (SelectionBoxOps.isTopSideHandle(handle)) {
      this.resizePropY = 'top';
    }
    if (SelectionBoxOps.isBottomSideHandle(handle)) {
      this.resizePropY = 'bottom';
    }

    // should reject pixel changes for an axis
    this.rejectResizeX = this.isResize && (isTopHandle || isBottomHandle);
    this.rejectResizeY = this.isResize && (isLeftHandle || isRightHandle);

    // when resizing, the origin is different
    this.originX = isLeftHandle
      ? this.area.left
      : isRightHandle
      ? this.area.right
      : this.area.cx;
    this.originY = isTopHandle
      ? this.area.top
      : isBottomHandle
      ? this.area.bottom
      : this.area.cy;
  }

  update = (
    pixelOffsetX,
    pixelOffsetY,
    {
      snapToGrid,
      snapToObjects,
      gridSnappingThreshold,
      smartSnappingThreshold,
    } = {}
  ) => {
    const { targets, area, isResize, viewport } = this;
    const { gridSize } = viewport;

    const screenToCanvasX = viewport.screenToCanvasTransform[0][0];
    const screenToCanvasY = viewport.screenToCanvasTransform[1][1];
    const canvasOffsetX = pixelOffsetX * screenToCanvasX;
    const canvasOffsetY = pixelOffsetY * screenToCanvasY;

    // determine the minimum distance for snapping
    const minimumGridDistance = MINIMUM_GRID_SNAP_DISTANCE * screenToCanvasX;
    const minimumObjectDistance =
      MINIMUM_OBJECT_SNAP_DISTANCE * screenToCanvasX;

    // create a new snap point
    const snapX = new Snap();
    const snapY = new Snap();

    // conditions for snapping
    const allowSnapLeft = !isResize || this.resizePropX === 'left';
    const allowSnapRight = !isResize || this.resizePropX === 'right';
    const allowSnapTop = !isResize || this.resizePropY === 'top';
    const allowSnapBottom = !isResize || this.resizePropY === 'bottom';
    const allowSnapCenterX = !isResize;
    const allowSnapCenterY = !isResize;

    // test grid snapping
    if (snapToGrid) {
      // helper for checking grid snap points
      const gridPoint = (point, offset, side) =>
        (Math.floor((point + offset) / gridSize) + side) * gridSize;
      const trySnapToGrid = ([allow, snap, prop, offset]) => {
        if (!allow) {
          return;
        }

        // try snapping to each point
        const point = this.area[prop];
        snap.test(
          'grid',
          point,
          offset,
          gridPoint(point, offset, 0),
          minimumGridDistance
        );
        snap.test(
          'grid',
          point,
          offset,
          gridPoint(point, offset, -1),
          minimumGridDistance
        );
        snap.test(
          'grid',
          point,
          offset,
          gridPoint(point, offset, 1),
          minimumGridDistance
        );
      };

      // each of the grid point checks
      [
        [allowSnapLeft, snapX, 'left', canvasOffsetX],
        [allowSnapRight, snapX, 'right', canvasOffsetX],
        [allowSnapTop, snapY, 'top', canvasOffsetY],
        [allowSnapBottom, snapY, 'bottom', canvasOffsetY],
        [allowSnapCenterX, snapX, 'cx', canvasOffsetX],
        [allowSnapCenterY, snapY, 'cy', canvasOffsetY],
      ].forEach(trySnapToGrid);
    }

    // next test other objects
    if (snapToObjects) {
      const trySnapToObject = ([
        obj,
        allow,
        snap,
        prop,
        offset,
        min,
        max,
        mid,
      ]) => {
        if (!allow) {
          return;
        }

        // try snapping to each edge
        const point = area[prop];
        snap.test(obj, point, offset, obj[min], minimumObjectDistance);
        snap.test(obj, point, offset, obj[max], minimumObjectDistance);
        snap.test(obj, point, offset, obj[mid], minimumObjectDistance);
      };

      // test each object
      for (const obj of targets) {
        [
          [
            obj,
            allowSnapLeft,
            snapX,
            'left',
            canvasOffsetX,
            'left',
            'right',
            'cx',
          ],
          [
            obj,
            allowSnapRight,
            snapX,
            'right',
            canvasOffsetX,
            'left',
            'right',
            'cx',
          ],
          [
            obj,
            allowSnapTop,
            snapY,
            'top',
            canvasOffsetY,
            'top',
            'bottom',
            'cy',
          ],
          [
            obj,
            allowSnapBottom,
            snapY,
            'bottom',
            canvasOffsetY,
            'top',
            'bottom',
            'cy',
          ],
          [
            obj,
            allowSnapCenterX,
            snapX,
            'cx',
            canvasOffsetX,
            'left',
            'right',
            'cx',
          ],
          [
            obj,
            allowSnapCenterY,
            snapY,
            'cy',
            canvasOffsetY,
            'top',
            'bottom',
            'cy',
          ],
        ].forEach(trySnapToObject);
      }
    }

    // before creating alignment, adjust the area to include any
    // current positioning changes
    const updatedArea = { ...area };
    const xProps = ['x', 'left', 'right', 'cx', 'mx'];
    const yProps = ['y', 'top', 'bottom', 'cy', 'my'];
    for (let i = 0; i < xProps.length; i++) {
      updatedArea[xProps[i]] += (snapX.diff || 0) + canvasOffsetX;
      updatedArea[yProps[i]] += (snapY.diff || 0) + canvasOffsetY;
    }

    // resolve the alignment
    return new AlignmentResult(viewport, updatedArea, snapX, snapY);
  };
}
