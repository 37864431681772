import { trim, take } from 'lodash';

export function asFloat(str) {
  return parseFloat((str || '').toString().replace(/[^0-9\-.]/g, ''));
}

export function asInt(str) {
  return parseFloat((str || '').toString().replace(/[^0-9-]/g, ''));
}

export function asIntegerString(val) {
  return trim(val).replace(/[^0-9-]/g, '');
}

export function asNumericString(val) {
  let input = trim(val).replace(/[^0-9.-]/g, '');

  // check for negatives
  if (input[0] === '-') {
    input = `-${input.substr(1).replace(/-/g, '')}`;
  }

  // assemble the decimal
  const parts = input.split(/\./g);
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 4);
  }

  return take(parts, 2).join('.');
}

export const asInteger = asInt;
