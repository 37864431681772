import { batch } from 'react-redux';
import UIModeAction from '@/Actions/UIMode';
import SetSelectionAction from '@/Actions/SetSelection';

import {
  addSVGGeometry,
  addSvgGroups,
  updateSvgGroup,
  deleteGroupIdsAndAddSimplePolygonGeometry,
} from '@/CanvasContainer/CanvasActions';
import { clearShapeBuilder } from '@/Redux/Slices/ShapeBuilderSlice';
import { selectViewportCenterCanvas } from '@/Redux/Slices/ViewportSlice';
import {
  selectMostRecentlyAddedGroup,
  clearSelection,
} from '@/Redux/Slices/SelectionSlice';
import { useActionWithDispatch } from './useAction';

export default class AddGeometryAction {
  constructor(dispatch, useSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
    this.selection = new SetSelectionAction(dispatch);
  }

  addSvg(type, svgParams, placePosition) {
    const { dispatch, useSelector } = this;
    const position = placePosition ?? useSelector(selectViewportCenterCanvas);

    let dispatchParams;
    switch (type) {
      //Add SVG geometry to canvas using size specified by the SVG file
      case 'unsized':
        dispatchParams = { ...svgParams, useGroupSize: true, position };
        break;

      //Adds Icon geometry at default size to canvas
      case 'icon':
        dispatchParams = { ...svgParams, useGroupSize: false, position };
        break;

      //Adds new text
      case 'new-text':
        dispatchParams = { ...svgParams, useGroupSize: true, position };
        break;

      default:
        throw new Error('Unknown svg type');
    }

    batch(async () => {
      await dispatch(addSVGGeometry(dispatchParams));
      this.selection.mostRecent();
    });
  }

  placeImportedGroups(groups) {
    const { dispatch } = this;

    return batch(async () => {
      await dispatch(addSvgGroups(groups));
      await dispatch(
        selectMostRecentlyAddedGroup({ duplicateCount: groups.length })
      );
    });
  }

  updateText(svgParams, selectedGroupIds) {
    const { dispatch } = this;
    const updateBatch = selectedGroupIds.map((gId) => ({
      id: gId,
      key: 'toolSvg',
      value: svgParams,
    }));

    batch(async () => {
      await dispatch(updateSvgGroup(updateBatch));
    });
  }

  addShapeBuilderCommits({ simplePolygonsParams = [], deleteGroupIds = [] }) {
    const { dispatch } = this;
    const uiModeAction = useActionWithDispatch(UIModeAction, dispatch);

    batch(async () => {
      await dispatch(clearSelection());
      await dispatch(
        deleteGroupIdsAndAddSimplePolygonGeometry({
          deleteGroupIds,
          simplePolygonsParams,
        })
      );
      //Shapeshifter used to require batching redux actions for undos. However, this is tricky to implement with workspace sync, so I've created a single action and reducer to delete shapeshifter inputs and add shapeshifter outputs. This eliminates the need for batching undos.
      //The PatchBatcher helper in ImmerPatchHelpers will batch undos locally, if we need this function elsewhere
      await dispatch(clearShapeBuilder());
      await dispatch(selectMostRecentlyAddedGroup());
      await uiModeAction.toDefault();
    });
  }
}
