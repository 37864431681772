import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { uniqBy } from 'lodash';

import { useAction } from '@/Actions/useAction';

// helpers
import { extractSvgGroup, getSvgShape, clearCache } from '@/Utility/svgCache';

// hooks
import useSelectionEditorHoverState from './useSelectionEditorHoverState';

// actions
import SetSelectionAction from '@/Actions/SetSelection';

// selectors
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';

// handles groups
export default function GroupSelector() {
  const setSelection = useAction(SetSelectionAction);
  const selectedGroups = useSelector(selectSelectedGroups);
  const { isHovering, createHoverEventHandler } =
    useSelectionEditorHoverState();

  // keep track of all selected paths, even when deselected
  const [groupSelection, setGroupSelection] = useState(() => ({
    selected: selectedGroups.map((group) => group.id),
    groups: selectedGroups,
  }));

  // checks if a group is selected or not
  function isSelected(groupId) {
    return groupSelection.selected.includes(groupId);
  }

  // handles changing the group selection
  function onToggle(groupId) {
    if (isHovering) {
      return;
    }

    const action = isSelected(groupId) ? 'remove' : 'add';
    setSelection.resolve([{ groupId }], action);
  }

  // clear when mounted
  useEffect(clearCache);

  // handle changes to the group selection
  useEffect(() => {
    setGroupSelection((current) => ({
      selected: selectedGroups.map((group) => group.id),
      groups: uniqBy(
        [...current.groups, ...selectedGroups],
        (group) => group.id
      ),
    }));
  }, [selectedGroups]);

  const items = groupSelection.groups.map((group, i) => {
    const elementId = `#sg-${group.id}`;
    const shape = getSvgShape(extractSvgGroup, elementId);
    const closed = group.basePathSet.every((x) =>
      x.outerPath ? x.outerPath.closed : x.closed
    );

    // without a shape there's nothing to render
    if (!shape) {
      return null;
    }

    // determine the state of the group
    const selected = isSelected(group.id);
    const groupCx = classNames(
      'selection-editor--selection-item',
      selected && 'is-selected',
      closed && 'closed',
      group.type
    );

    return (
      <div
        id={`selection-editor--${elementId.substr(1)}`}
        className={groupCx}
        key={`item_${i}`}
        onClick={() => onToggle(group.id)}
        {...createHoverEventHandler(group.id)}
      >
        {shape}
      </div>
    );
  });

  return <div className='selection-editor--selection-set'>{items}</div>;
}
