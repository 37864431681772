const NONE = {};

export default class Viewport {
  constructor(ui, overrides = NONE) {
    const { store } = ui;
    const { viewport, svgViewbox } = store;

    // set the default
    Object.assign(this, {
      // main viewport size
      size: {
        width: viewport.size.x,
        height: viewport.size.y,
      },

      // viewbox size
      gridSize: viewport.gridSize,
      x: svgViewbox.x,
      y: svgViewbox.y,
      width: svgViewbox.width,
      height: svgViewbox.height,
    });

    // check for extra data
    if (overrides.viewport) {
      this.x += overrides.viewport.x;
      this.y += overrides.viewport.y;

      // replace sizing, if needed
      this.width = overrides.viewport.width || this.width;
      this.height = overrides.viewport.height || this.height;
    }
  }

  // maps canvas to screen coordinates
  canvasToScreen(x, y) {
    const ratio = this.size.width / this.width;
    const relativeX = x - this.x;
    const relativeY = y - this.y;
    return { x: ratio * relativeX, y: ratio * relativeY };
  }

  screenToCanvas(x, y) {
    // TODO
  }
}
