import {
  createAction,
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';

import { save, restore } from '@/Helpers/UserPreferences';
import {
  createMatrix33,
  invert,
  Matrix33,
} from '@/Geometry/sherpa-svg-generator/Matrix33';
import { getScreenViewMode } from '@/Utility/screen';
import { DEFAULT_GRID_SIZE } from '@/defaults';
import { AABB, IAABB } from '../../Geometry/sherpa-svg-generator/AABB';
import { IPoint, Point } from '../../Geometry/sherpa-svg-generator/Point';
import { RootState } from '../store';
import {
  viewportActions,
  CenterToPayload,
  getSvgViewBox,
  ResetViewportPayload,
  SetScalePayload,
  getDefaultZoomLimits,
  SetScaleAbsolutePayload,
  GridLevel,
} from '@/Viewport/ViewportHelpers';
import { cloneDeep } from 'lodash';

export interface Viewbox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ViewportState {
  position: IPoint;
  size: IPoint;
  scale: number;
  canvasToScreenScale: number;
  canvasViewbox: IAABB;
  canvasToScreenTransform: Matrix33;
  screenToCanvasTransform: Matrix33;
  gridSize: number;
  maxSizePx: IPoint;
  minSizePx: IPoint;
  activeGrids?: GridLevel[];
  activeGridLevel?: GridLevel;
}

/**
 * Initial viewport state
 * @property {Point} position - center position of the viewport
 * @property {Point} size - size of the viewport in pixels
 * @property {number} scale - zoom factor
 * @property {number} canvasToScreenScale -
 * @property {Matrix33} canvasToScreenTransform - the SVG viewbox of the canvas, transformed to canvasSpace
 * @property {Matrix33} screenToCanvasTransform -
 * @property {gridSize} number -
 * @property {Point} maxSizePx -
 * @property {Point} minSizePx -
 */
export const defaultViewportState: ViewportState = {
  position: new Point(),
  //size of viewport in pixels
  size: new Point(),
  //Zoom factor
  scale: 1.0,
  //canvasToScreenScale computed from Canvas geometry AABB, state.scale, and state.size
  canvasToScreenScale: 1.0,
  //canvasGeometryViewbox is AABB of geometry on canvas or default AABB if canvas is empty
  //canvasViewbox is the SVG viewbox of the canvas, transformed to canvas space
  canvasViewbox: new AABB({ size: new Point(25.4, 25.4) }),
  canvasToScreenTransform: createMatrix33(),
  screenToCanvasTransform: invert(),

  ...restore<{ gridSize: number }>({
    gridSize: { type: Number, default: DEFAULT_GRID_SIZE },
  }),
  ...getDefaultZoomLimits(1.0),
};

export const resetViewport = createAction('viewport/resetViewport');
export const refresh = createAction('viewport/refresh');
export const resize = createAction<ResetViewportPayload>('viewport/resize');
export const setPosition = createAction<IPoint>('viewport/setPosition');
export const setScaleBy = createAction<SetScalePayload>('viewport/setScaleBy');
export const setScaleByAbsolute = createAction<SetScaleAbsolutePayload>(
  'viewport/setScaleByAbsolute'
);
export const centerTo = createAction<CenterToPayload>('viewport/centerTo');

const slice = createSlice({
  name: 'viewport',

  initialState: cloneDeep(defaultViewportState),

  reducers: {
    // update the grid size
    setGridSize: (state, action) => {
      state.gridSize = parseFloat(action.payload);

      if (isNaN(state.gridSize)) {
        state.gridSize = DEFAULT_GRID_SIZE;
      }

      // save grid sizing
      save({ gridSize: state.gridSize });
    },

    update: (state, action: PayloadAction<Partial<ViewportState>>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { update, setGridSize } = slice.actions;

// middleware for keeping transform up to date
export const middleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (isAction(action) && action.type.includes('viewport/')) {
      const { canvas: canvasState, sherpaContainer, ui, viewport } = getState();
      const { canvas } = canvasState;
      const { displayUnits } = sherpaContainer;
      const { mode } = ui;

      if (resetViewport.match(action)) {
        // reset the viewport
        const reset_viewport = viewportActions.resetViewport(
          viewport,
          canvas,
          displayUnits
        );

        // and center so that we aren't too zoomed in/out
        const newState = viewportActions.centerTo(
          {
            ...viewport,
            ...reset_viewport,
          },
          {
            type: 'viewport/centerTo',
            payload: {},
          },
          canvas,
          displayUnits,
          mode
        );
        dispatch(update(newState));
      }
      if (refresh.match(action)) {
        const newState = viewportActions.refresh(
          viewport,
          canvas,
          displayUnits
        );
        dispatch(update(newState));
      }
      if (resize.match(action)) {
        const newState = viewportActions.resize(
          viewport,
          action,
          canvas,
          displayUnits
        );
        dispatch(update(newState));
      }
      if (setPosition.match(action)) {
        const newState = viewportActions.setPosition(
          viewport,
          action,
          displayUnits
        );
        dispatch(update(newState));
      }
      if (setScaleBy.match(action)) {
        const newState = viewportActions.setScaleBy(
          viewport,
          action,
          canvas,
          displayUnits
        );
        if (newState) {
          dispatch(update(newState));
        }
      }
      if (setScaleByAbsolute.match(action)) {
        const newState = viewportActions.setScaleByAbsolute(
          viewport,
          action,
          canvas,
          displayUnits
        );
        if (newState) {
          dispatch(update(newState));
        }
      }
      if (centerTo.match(action)) {
        const newState = viewportActions.centerTo(
          viewport,
          action,
          canvas,
          displayUnits,
          mode
        );
        dispatch(update(newState));
      }
    }

    next(action);
  };

export const selectViewport = (state: RootState) => state.viewport;

export const selectSVGViewbox = (state: RootState) =>
  getSvgViewBox(state.viewport);

export const selectZoom = (state: RootState) => state.viewport.scale;

export const selectNonScalingPixelFactor = (state: RootState) =>
  state.viewport.screenToCanvasTransform[0][0];

export const selectActiveGrids = (state: RootState) =>
  state.viewport.activeGrids;

export const selectActiveGridLevel = (state: RootState) =>
  state.viewport.activeGridLevel;

export const selectScreenToCanvasScale = (state: RootState) =>
  state.viewport.screenToCanvasTransform[0][0] / state.viewport.scale;

export const selectScreenViewMode = () => getScreenViewMode();

export const selectViewportCenterCanvas = (state: RootState) =>
  state.viewport.position;

export const selectGridSize = (state: RootState) =>
  state.viewport.gridSize || DEFAULT_GRID_SIZE;

export const actions = slice.actions;
export default slice.reducer;
