import React from 'react';
import Icon from '@/Styles/Icons/Icon';

import { useSelector } from 'react-redux';
import { selectLocale } from '@/Redux/Slices/ShaperHubSlice';

import classNames from 'classnames';
import { FILETYPES } from '../../../defaults';
import { useAction } from '../../../Actions/useAction';
import FileSystemAction from '../../../Actions/FileSystem';
import TranslationText from '../../TranslationText/TranslationText';

export default function FileSystemRow({ file, mode, selected, onClick }) {
  const { locale } = useSelector(selectLocale);

  const fileSystemAction = useAction(FileSystemAction);

  const { name: fileName, type: fileType } = file;

  const disableClick = mode === 'move';

  function selectDirectory() {
    if (disableClick && fileType !== 'file') {
      fileSystemAction.selectDirectory(file.name);
    } else if (mode === 'open') {
      onClick(file);
    }
  }

  const onError = (event) => {
    event.currentTarget.src =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzNS43cHgiIGhlaWdodD0iMzUuN3B4IiB2aWV3Qm94PSIwIDAgMzUuNyAzNS43IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzNS43IDM1LjciIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSIjOEQ4RjkyIiBkPSJNMjcuMSwyNC44VjEwLjljMC44LTAuMiwxLjMtMC45LDEuMy0xLjhjMC0xLTAuOC0xLjgtMS44LTEuOGMtMC44LDAtMS42LDAuNi0xLjgsMS4zSDEwLjkKCUMxMC43LDcuOSwxMCw3LjMsOS4xLDcuM2MtMSwwLTEuOCwwLjgtMS44LDEuOGMwLDAuOCwwLjYsMS42LDEuMywxLjh2MTMuOWMtMC44LDAuMi0xLjMsMC45LTEuMywxLjhjMCwxLDAuOCwxLjgsMS44LDEuOAoJYzAuOCwwLDEuNi0wLjYsMS44LTEuM2gxMy45YzAuMiwwLjgsMC45LDEuMywxLjgsMS4zYzEsMCwxLjgtMC44LDEuOC0xLjhDMjguNCwyNS43LDI3LjksMjUsMjcuMSwyNC44eiBNMjYuNiw4LjMKCWMwLjUsMCwwLjgsMC40LDAuOCwwLjhTMjcuMSwxMCwyNi42LDEwcy0wLjgtMC40LTAuOC0wLjhTMjYuMSw4LjMsMjYuNiw4LjN6IE04LjMsOS4xYzAtMC41LDAuNC0wLjgsMC44LTAuOFMxMCw4LjcsMTAsOS4xCglTOS42LDEwLDkuMSwxMFM4LjMsOS42LDguMyw5LjF6IE05LjEsMjcuNGMtMC41LDAtMC44LTAuNC0wLjgtMC44czAuNC0wLjgsMC44LTAuOHMwLjgsMC40LDAuOCwwLjhTOS42LDI3LjQsOS4xLDI3LjR6IE0yNC44LDI2LjEKCUgxMC45Yy0wLjItMC42LTAuNy0xLjEtMS4zLTEuM1YxMC45YzAuNi0wLjIsMS4xLTAuNywxLjMtMS4zaDEzLjljMC4yLDAuNiwwLjcsMS4xLDEuMywxLjN2MTMuOUMyNS41LDI1LDI1LDI1LjQsMjQuOCwyNi4xegoJIE0yNi42LDI3LjRjLTAuNSwwLTAuOC0wLjQtMC44LTAuOHMwLjQtMC44LDAuOC0wLjhzMC44LDAuNCwwLjgsMC44UzI3LjEsMjcuNCwyNi42LDI3LjR6Ii8+Cjwvc3ZnPgo=';
  };

  const contentRowCx = classNames(`file-system--content-row ${fileType}`, {
    disabled: disableClick,
    selected: selected,
  });

  return (
    <div className={contentRowCx} onClick={selectDirectory}>
      <div className='file-system--file-icon'>
        {fileType === FILETYPES.FOLDER && (
          <Icon icon='file' className='file-system-file' />
        )}
        {(fileType === FILETYPES.FILE || fileType === FILETYPES.EXTERNAL) && (
          <>
            <img
              src={file.thumbnailURI}
              className='file-system--file-preview'
              onError={onError}
              alt=''
            />
          </>
        )}
        {fileType === FILETYPES.EXTERNAL && (
          <Icon icon='studio-file' className='file-system-studio-file' />
        )}
      </div>
      <div className='file-system--file-name-container'>
        <div className='file-system--file-name'>{fileName}</div>
        {fileType === FILETYPES.EXTERNAL && (
          <div className='file-system--file-info'>
            <TranslationText i18nKey='modified'>Modified</TranslationText>
            <span className='file-system--file-info-date'>
              {new Date(file.modified).toLocaleDateString(locale, {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
