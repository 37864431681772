import React from 'react';
import { useSelector } from 'react-redux';
import { selectUIMode } from '@/Redux/Slices/UISlice';

// components
import UILayer from '@/UILayer/UI/UILayer';
import InteractionsModeManager from '@/InteractionsManager/InteractionsModeManager';

export default function CanvasContainer(props) {
  const mode = useSelector(selectUIMode);

  return (
    <div className={`canvas canvas-mode--${mode}`}>
      <InteractionsModeManager mode={mode}>
        <UILayer ui={mode} {...props} />
      </InteractionsModeManager>
    </div>
  );
}
