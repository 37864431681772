import React from 'react';
import Tab from './Components/Tab';

export default function ToggleButton(props) {
  const { options, value, className } = props;

  const onClick = (opt) => {
    if (opt.onClick) {
      opt.onClick(opt);
    } else if (props.onClick) {
      props.onClick(opt);
    }
  };

  const selected = options.find((opt) =>
    value === null
      ? options[0].i18nKey === opt.i18nKey
      : opt.i18nKey === value.i18nKey
  );

  return (
    <div className={`toggle-button ${className || ''}`}>
      <div className='toggle-button-options'>
        <div className='toggle-button-options--container'>
          {options.map((opt) => (
            <Tab
              {...opt}
              key={opt.label}
              selected={opt === selected}
              onClick={() => onClick(opt)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
