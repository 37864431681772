import { UnleashClient } from 'unleash-proxy-client';

const unleash = new UnleashClient({
  appName: import.meta.env.VITE_UNLEASH_APP_NAME,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 60 * 5, // 5 minutes
  url: 'https://unleash.shapertools.com/api/frontend',
});

const FEATURE_FLAGS = [
  'studio-fonts',
  'is-shaper-employee',
  'studio-developer-settings',
  'studio-debug-logs',
] as const;

// eslint-disable-next-line no-unused-vars
type FeatureFlagsMap = { [key in (typeof FEATURE_FLAGS)[number]]: boolean };

export const getFeatureFlags = async (userId: string, email: string) => {
  return new Promise<FeatureFlagsMap>((resolve, reject) => {
    unleash.on('ready', () => {
      unleash.setContextField('userId', userId);
      unleash.setContextField('email', email);

      resolve(
        FEATURE_FLAGS.reduce((obj, item) => {
          return {
            ...obj,
            [item]: unleash.isEnabled(item),
          };
        }, {} as FeatureFlagsMap)
      );
    });

    unleash.on('error', (error: any) => {
      reject(error);
    });

    // Start the background polling
    unleash.start();
  });
};
