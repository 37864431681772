function rotatePoint(x, y, cx, cy, cos, sin) {
  return {
    x: cos * (x - cx) + sin * (y - cy) + cx,
    y: cos * (y - cy) - sin * (x - cx) + cy,
  };
}

function quickAABB(aabb, rotation) {
  const cos = Math.cos(rotation);
  const sin = Math.sin(rotation);
  const cx = (aabb.left + aabb.right) * 0.5;
  const cy = (aabb.top + aabb.bottom) * 0.5;

  const a = rotatePoint(aabb.left, aabb.top, cx, cy, cos, sin);
  const b = rotatePoint(aabb.right, aabb.bottom, cx, cy, cos, sin);
  const c = rotatePoint(aabb.right, aabb.top, cx, cy, cos, sin);
  const d = rotatePoint(aabb.left, aabb.bottom, cx, cy, cos, sin);

  aabb.left = Math.min(a.x, b.x, c.x, d.x);
  aabb.right = Math.max(a.x, b.x, c.x, d.x);
  aabb.top = Math.min(a.y, b.y, c.y, d.y);
  aabb.bottom = Math.max(a.y, b.y, c.y, d.y);
  aabb.width = aabb.right - aabb.left;
  aabb.height = aabb.bottom - aabb.top;
  aabb.hw = aabb.width * 0.5;
  aabb.hh = aabb.height * 0.5;
  aabb.cx = cx;
  aabb.cy = cy;
}

export default class Bounds {
  constructor(args) {
    let {
      cx,
      cy,
      fromCenter,
      x,
      y,
      top,
      left,
      right,
      bottom,
      width,
      height,
      rotation = 0,
    } = args;
    let hw;
    let hh;

    // assume the provided from the center axis
    if (fromCenter || !isNaN(cx)) {
      cx = cx || x;
      cy = cy || y;
      left = cx - hw;
      right = cx + hw;
      top = cy - hh;
      bottom = cy + hh;
    }

    // assume the provided top/left
    else if (!isNaN(x) || !isNaN(left)) {
      left = left || x;
      top = top || y;
      right = right || left + width;
      bottom = bottom || top + height;
      cx = left + hw;
      cy = top + hh;
    }

    // set default props
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
    this.width = right - left;
    this.height = bottom - top;
    this.cx = cx;
    this.cy = cy;
    this.hw = this.width * 0.5;
    this.hh = this.height * 0.5;

    // apply rotation, if needed
    if (rotation) {
      quickAABB(this, rotation);
    }
  }
}
