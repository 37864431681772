import React from 'react';

//selector
import { useSelector } from 'react-redux';
import { selectModal } from '@/Redux/Slices/SherpaContainerSlice';

//actions
import ModalActions from '../../Actions/Modal';
import { useAction } from '../../Actions/useAction';
import { MODAL_TYPES } from '../../defaults';

export function Content(props) {
  return <div className='modal--content--left--content'>{props.children}</div>;
}

export function Footer(props) {
  return <div className='modal--content--left--footer'>{props.children}</div>;
}

export function Panel(props) {
  return (
    <div className={`modal--content--${props.position ?? 'left'}`}>
      {props.children}
    </div>
  );
}

export function Button(props) {
  function onClick() {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <div
      className={`modal--content--button ${props.className || ''}`}
      onClick={onClick}
    >
      {props.children || props.label}
    </div>
  );
}

export function Title(props) {
  return (
    <div className='modal--content--left--content--title'>{props.children}</div>
  );
}

export default function Modal(props) {
  const modalActions = useAction(ModalActions);
  const modalType = useSelector(selectModal);

  const close = () => {
    if (modalType !== MODAL_TYPES.SIGNIN_MODAL) {
      modalActions.closeModal(props.clearURL);
    }
  };

  return (
    <div className={`modal ${props.className}`} data-cy={props.dataCy}>
      <div className='modal--content'>
        <div className={props.className}>{props.children}</div>
      </div>
      <div className='modal--overlay' onClick={close} />
    </div>
  );
}

Modal.Content = Content;
Modal.Footer = Footer;
Modal.Panel = Panel;
Modal.Title = Title;
Modal.Button = Button;
