import React, { useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// selectors
import {
  selectOptions,
  selectSecretOptions,
} from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectLoggedIn,
  selectShaperSubscriptions,
} from '@/Redux/Slices/ShaperHubSlice';
import {
  selectIsShowingOpenMenu,
  selectIsShowingMoveMenu,
  selectIsShowingRename,
  toggleRename,
  selectFeatures,
} from '@/Redux/Slices/UISlice';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import DownloadAction from '@/Actions/Download';
import CreateWorkspaceAction from '@/Actions/CreateWorkspace';
import FileSystemAction from '@/Actions/FileSystem';
import LogOutAction from '@/Actions/LogOut';

// components
import ClickCapture from '@/Components/ClickCapture/ClickCapture';
import Icon from '@/Styles/Icons/Icon';

//Temp login and workspace controls
import LoginAndWorkspaceControls from '@/Components/LoginMenuContainer/LoginMenuContainer';

// sub menu components
import Logo from './Components/Logo';
import FileName from './Components/FileName';
import Separator from './Components/Separator';
import UpgradeSubscription from './Components/Upgrade';
import DashboardLauncher from './Components/DashboardLauncher';
import FileSystem from './Components/FileSystem';
import classNames from 'classnames';
import ActionMenuList from '../ActionMenuList/ActionMenuList';
import DemoMode from './Components/DemoMode';
import { menuLinks } from '../../defaults';
import SignOut from './Components/SignOut';
import { selectIsEditMode, selectWorkspaceId } from '@/Redux/Slices/SyncSlice';
import { duplicate } from '@/Sync/SyncThunks';
import { entitlements } from '@/Helpers/Entitlements';

export default function ApplicationMenu() {
  const menuRef = useRef();
  const dispatch = useDispatch();

  // selectors
  const { showApplicationMenu } = useSelector(selectOptions);
  const isLoggedIn = useSelector(selectLoggedIn);
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const { showWorkspaceSettings } = useSelector(selectSecretOptions);

  const isShowingMoveMenu = useSelector(selectIsShowingMoveMenu);
  const isShowingOpenMenu = useSelector(selectIsShowingOpenMenu);
  const isShowingRename = useSelector(selectIsShowingRename);

  const showWorkspaceId = selectFeatures(entitlements.SHOW_WORKSPACEID);
  const workspaceId = useSelector(selectWorkspaceId);
  const isEditMode = useSelector(selectIsEditMode);

  // actions
  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);
  const download = useAction(DownloadAction);
  const createWorkspaceAction = useAction(CreateWorkspaceAction);
  const fileSystemAction = useAction(FileSystemAction);
  const logOutAction = useAction(LogOutAction);

  function onRename() {
    dispatch(toggleRename(!isShowingRename));
  }

  function onDownload() {
    download.request();
  }

  function onHideMenu() {
    batch(() => {
      updateWorkspaceOptions.update({ showApplicationMenu: false });
      dispatch(toggleRename(false));
    });
  }

  function onCreateWorkspace() {
    createWorkspaceAction.disconnectCurrentWorkspaceAndCreateNewWorkspace();
  }

  function onDuplicateWorkspace() {
    dispatch(duplicate());
  }

  /* Reads the contents of shaperHub at location <state.shaperHub.currentPath>/<pathSeg>
    If successful, 
      updates state.shaperHub.currentPath with the new path including pathSeg and updates
      updates state.shaperHub.currentFolder with array of objects describing contents of the current folder

    If pathSeg is '' or undefined, returns the contents of the currentPath without navigating to a new subfolder
    If pathSeg is '..', navigates to the parent folder, if any, and returns the folder contents
    
    Using a pathSeg that is not in the current folder, except for '..', or is not of type folder will result in an error
  */

  function onOpenWorkspace() {
    //Should not call this unless logged in
    if (isLoggedIn) {
      fileSystemAction.toggleOpenMenu(isShowingOpenMenu);
    }
  }

  function onMove() {
    //batch these
    if (isLoggedIn) {
      fileSystemAction.toggleMoveMenu(isShowingMoveMenu);
    }
  }

  function cancelFileSystem() {
    fileSystemAction.closeFileSystem();
  }

  function logout() {
    onHideMenu();
    logOutAction.logOut();
  }

  const menuCx = classNames('application-menu', 'scrollable', {
    show: showApplicationMenu,
    demo: !isLoggedIn,
  });

  const menuOverlayCx = classNames('application-menu--overlay', {
    show: showApplicationMenu,
  });

  const menuLeadingCx = classNames('application-menu--leading', {
    'application-menu--full-length':
      isShowingMoveMenu || isShowingOpenMenu || isShowingRename,
  });

  const duplicateDesignHover = isEditMode ? null : (
    <div className='action-list-menu--section-option--centered'>
      <Icon icon='spinning-arrows' className='animated-spinning-arrows' />
      <span> syncing workspace.</span>
    </div>
  );

  const actionMenu = [
    {
      label: 'Rename',
      icon: 'edit',
      action: onRename,
      visible: isLoggedIn,
      i18nKey: 'rename',
    },
    {
      label: 'Move',
      icon: 'move-to-project',
      action: onMove,
      visible: isLoggedIn,
      i18nKey: 'move-project',
    },
    {
      label: 'Download',
      icon: 'download',
      action: onDownload,
      visible: true,
      disabled: !isLoggedIn,
      i18nKey: 'download',
    },
    { separator: true, visible: isLoggedIn },
    {
      label: 'Duplicate Design',
      icon: 'duplicate-workspace',
      action: onDuplicateWorkspace,
      visible: isLoggedIn,
      disabled: !isEditMode,
      i18nKey: 'duplicate-workspace',
      tooltip: isEditMode ? 'opens in new tab' : null,
      onHover: duplicateDesignHover,
    },
    {
      label: 'New Design',
      icon: 'new-design',
      action: onCreateWorkspace,
      visible: true,
      disabled: !isLoggedIn,
      i18nKey: 'new-design',
    },
    {
      label: 'Open Recent',
      icon: 'open',
      action: onOpenWorkspace,
      visible: isLoggedIn,
      i18nKey: 'open-recent',
    },
  ];

  const shaperHubMenu = [
    {
      label: 'Trace',
      icon: 'trace',
      url: menuLinks.TRACE,
      visible: isLoggedIn,
      i18nKey: 'Trace',
    },
    {
      label: 'My Files',
      icon: 'projects',
      target: '_blank',
      url: menuLinks.PROJECTS,
      visible: isLoggedIn,
      i18nKey: 'my-projects',
    },
    {
      label: 'ShaperHub',
      icon: 'shaper-hub',
      target: '_blank',
      url: menuLinks.SHAPERHUB,
      visible: true,
      i18nKey: 'shaperhub',
    },
    {
      label: 'Forum',
      icon: 'shaper-origin',
      target: '_blank',
      url: menuLinks.FORUM,
      visible: true,
      i18nKey: 'forum',
    },
    {
      label: 'Shop',
      icon: 'shop',
      target: '_blank',
      url: menuLinks.SHOP,
      visible: true,
      i18nKey: 'shop',
    },
    {
      label: 'Account',
      icon: 'settings',
      target: '_blank',
      url: menuLinks.ACCOUNT,
      visible: isLoggedIn,
      i18nKey: 'account',
    },
    { separator: true, visible: isLoggedIn },
    {
      component: SignOut,
      icon: 'person',
      action: logout,
      visible: isLoggedIn,
      i18nKey: 'sign-out',
    },
  ];

  const fileSystemMode = isShowingMoveMenu
    ? 'move'
    : isShowingOpenMenu
    ? 'open'
    : null;

  const workspaceIdDisplay = showWorkspaceId ? (
    <div style={{ userSelect: 'text' }}>{`workspaceId: ${workspaceId}`}</div>
  ) : (
    ''
  );

  return (
    <>
      <ClickCapture
        onClick={onHideMenu}
        disabled={!showApplicationMenu}
        captureFor={menuRef}
      />

      <div className={menuCx} ref={menuRef}>
        <div className='application-menu--close' onClick={onHideMenu}>
          <Icon>close</Icon>
        </div>
        <div className={menuLeadingCx}>
          <Logo />
          {!isShowingOpenMenu && isLoggedIn && (
            <>
              {(!(isSubscriber && !isExpired) || isTrial) && (
                <UpgradeSubscription />
              )}
              <Separator />
              <FileName onClick={onRename} />
              {workspaceIdDisplay}
            </>
          )}
          {!isLoggedIn && <Separator />}

          {!isShowingMoveMenu && !isShowingOpenMenu && !isShowingRename && (
            <ActionMenuList>
              {actionMenu
                .filter((action) => action.visible)
                .map((m, i) => {
                  if (m.separator) {
                    return <Separator key={`${i}_action_menu`} />;
                  }

                  return (
                    <ActionMenuList.SectionOption
                      key={`${i}_action_menu`}
                      onClick={m.action}
                      {...m}
                    >
                      {m.label}
                    </ActionMenuList.SectionOption>
                  );
                })}
            </ActionMenuList>
          )}

          {!isLoggedIn && <DemoMode />}

          {showWorkspaceSettings &&
            !(isShowingMoveMenu || isShowingOpenMenu || isShowingRename) && (
              <>
                <Separator />
                <LoginAndWorkspaceControls />
              </>
            )}

          {(isShowingMoveMenu || isShowingOpenMenu) && (
            <>
              <Separator />
              <FileSystem mode={fileSystemMode} onClick={cancelFileSystem} />
            </>
          )}
        </div>

        {!isShowingMoveMenu && !isShowingOpenMenu && !isShowingRename && (
          <div className='application-menu--trailing'>
            <DashboardLauncher />
            <ActionMenuList>
              {shaperHubMenu
                .filter((action) => action.visible)
                .map((m, i) => {
                  if (m.separator) {
                    return <Separator key={`${i}_shaper_menu`} />;
                  }
                  return (
                    <ActionMenuList.SectionOption
                      key={`${i}_shaper_menu`}
                      onClick={m.action}
                      url={m.url}
                      icon={m.icon}
                      target={m.target}
                      i18nKey={m.i18nKey}
                      component={m.component}
                    >
                      {m.label}
                    </ActionMenuList.SectionOption>
                  );
                })}
            </ActionMenuList>
          </div>
        )}
      </div>
      <div className={menuOverlayCx} onClick={onHideMenu} />
    </>
  );
}
