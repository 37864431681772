import React from 'react';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';
import TranslationText from '../../TranslationText/TranslationText';

export default function Label(props) {
  const labelCx = classNames('properties-panel--label', {
    'hide-on-mobile': 'hideOnMobile' in props,
    'hide-on-desktop': 'hideOnDesktop' in props,
  });

  return (
    <div className={labelCx} onClick={props.onClick}>
      {props.lead && (
        <div className='properties-panel--label--icon'>{props.lead}</div>
      )}
      {props.icon && (
        <div className='properties-panel--label--icon'>
          <Icon>{props.icon}</Icon>
        </div>
      )}
      <div className='properties-panel--label--text'>
        <TranslationText i18nKey={props.i18nKey}>
          {props.children}
        </TranslationText>
      </div>
    </div>
  );
}
