import BaseInteraction from './BaseInteraction';
import AlignmentHelper from '@/Helpers/Alignment';

// consts
import { ROTATION_HANDLES, RESIZE_HANDLES } from '@/Constants/UI';

// actions
import TranslateGroupsAction from '@/Actions/TranslateGroups';
import ResizeGroupsAction from '@/Actions/ResizeGroups';
import RotateGroupsAction from '@/Actions/RotateGroups';
import ResolveGroupSelectionAction from '@/Actions/ResolveGroupSelection';
import SetCursorAction from '@/Actions/SetCursor';
import SetSelectionAction from '@/Actions/SetSelection';

// selectors
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';
import { selectCanvas, selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// utils
import { sortSelectionByArea } from '@/Utility/shapes';

// handles keeping track of CSS updates for hover states
// this approach is not ideal, but will make for a more
// reliable hover experience
// const hoverStateHelper = new GlobalCssHelper('default-ui-hover-state-helper');

export default class TransformGroupsInteraction extends BaseInteraction {
  interactionId = 'Transform Groups';

  createAlignmentHelper(options) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);
    const canvas = useSelector(selectCanvas);
    const svgGroupSet = useSelector(selectSvgGroupSet);
    const ids = options.selectedIds || useSelector(selectSelectedGroupIds);
    const ignoreGroupIds = ids;
    const selectedGroups = ids.map((id) =>
      svgGroupSet.find((item) => item.id === id)
    );
    return new AlignmentHelper(canvas, viewport, {
      ...options,
      selectedGroups,
      ignoreGroupIds,
    });
  }

  onMouseMove(event) {
    const { useSelector } = this;

    // don't update the cursor if currently in an action
    if (this.action) {
      return;
    }

    // update for hovers
    const selection = useSelector(selectSelectedGroupIds);
    const isTouch = !!event.sourceCapabilities?.firesTouchEvents;
    this.refreshHoverState(event.center, { isTouch });

    // don't do this if there's no selection
    if (!selection.length) {
      return;
    }

    // check the anchor
    const cursor = this.createAction(SetCursorAction);

    // if over the box, no handles to show
    const overSelectionBox = this.hitTestSelectionBox(event.center);
    if (overSelectionBox) {
      this.displayedHandle = null;
      return cursor.toDefault();
    }

    // find the handle
    const handle = this.getHandleAt(event.center);
    const ignoreHandle = this.isHandleSameAsAnchor(handle);

    // if there's a handle, update the cursor
    if (!ignoreHandle && ROTATION_HANDLES.includes(handle)) {
      cursor.toRotate(handle);
    } else if (!ignoreHandle && RESIZE_HANDLES.includes(handle)) {
      cursor.toResize(handle);
    }
    // reset the handle to default
    else {
      this.displayedHandle = null;
      return cursor.toDefault();
    }

    // save the handle
    this.displayedHandle = handle;
  }

  onPointerDown(event) {
    this.lastKnownHandle = this.displayedHandle;

    if (this.lastKnownHandle) {
      this.setActive();
    }

    // is a starting over the selection box (no handles considered)
    this.startedOverSelectionBox = this.hitTestSelectionBox(event.center);

    // handle touch events and get a larger handle
    if (event.isTouch) {
      this.mobileHandle = this.getHandleAt(event.center, {
        extendSelection: true,
      });
    }
    this.pointerMoved = false;
  }

  onPointerUp(event, manager) {
    // if for some reason there's an action in progress (which shouldn't happen
    // just skip resolving the selection)
    const { isDragging } = manager;
    if (!this.action && !this.pointerMoved && !isDragging) {
      this.origin = event;

      // check what the pointer is over
      const svgGroupSet = this.useSelector(selectSvgGroupSet);
      let over = this.getGroupsAt({
        ...event.center,
        ...{
          hitDetectEdge: true,
          hitDetectFill: true,
        },
      });
      over = sortSelectionByArea(over, svgGroupSet);

      // check if over the existing selection box
      const overSelectionBox = this.hitTestSelectionBox(event.center);

      // they're the same so it's just a click so void click on the canvas
      if (!overSelectionBox && !over.length) {
        this.refreshMenuState({ reason: 'void-click' });
        setTimeout(() =>
          this.refreshHoverState(event.center, { isTouch: event.isTouch })
        );
        return true;
      }

      // determine what to do with the selection
      const selection = this.createAction(ResolveGroupSelectionAction);
      const appendToSelection = this.shouldAppendToSelection(event);
      this.selection = selection.resolve(over, {
        appendToSelection,
      });

      // updates the menu state
      this.refreshMenuState({
        reason: 'selection',
        selection: this.selection,
      });

      // update the hover state
      // wait a moment for the state to update
      // TODO: ideally, we just pass the new selection state into this function
      setTimeout(() =>
        this.refreshHoverState(event.center, { isTouch: event.isTouch })
      );
    }
    if (isDragging) {
      manager.isDragging = false;
    }
  }

  // checks for events that should append the targeted layer
  // onto the current selection
  shouldAppendToSelection(event) {
    return event.shiftKey || /touch/i.test(event.pointerType);
  }

  onPointerMoveStart(event, manager) {
    this.pointerMoved = true;

    const { useSelector } = this;
    let overSelectionBox = this.startedOverSelectionBox; // || this.hitTestSelectionBox(event.center);

    const selectedIds = useSelector(selectSelectedGroupIds);
    const svgGroupSet = useSelector(selectSvgGroupSet);
    const selectionType = this.getSelectionType();

    // check the handle
    let handle;
    if (!this.startedOverSelectionBox) {
      handle =
        this.mobileHandle ||
        this.lastKnownHandle ||
        this.displayedHandle ||
        this.getHandleAt(event.center, {
          extendSelection: event.isTouch,
        });
      if (handle === 'centroid') {
        handle = null;
      }
    }

    // check if ignoring resize behavior
    const ignoreHandle = this.isHandleSameAsAnchor(handle);

    // use the current selection
    this.selection = [...selectedIds];

    // not over the selection box, try and resolve the selection again
    if (!overSelectionBox && !handle) {
      // for touch events, don't try and grab new groups
      if (event.isTouch) {
        return;
      }

      let over = this.getGroupsAt({
        ...event.center,
        ...{
          hitDetectEdge: true,
          hitDetectFill: true,
          multiSelection: true,
        },
      });

      // sort by area -- in this selection, the path ID is unimportant and can be ignored
      over = sortSelectionByArea(over, svgGroupSet, false)
        .map((group) => {
          return group.basePathSet.map((bps) => ({
            groupId: group.id,
            pathId: bps.id,
          }));
        })
        .flat();

      const overGroupIds = over.map((o) => o.groupId);
      const hasIdsInCommon = over.some((item) => overGroupIds.includes(item));
      const select = this.createAction(SetSelectionAction);

      // completely different groups are selected now...we should update the selection to reflect that
      if (!hasIdsInCommon && overGroupIds?.length) {
        // only use the top most layer
        this.selection = [overGroupIds[0]];
        select.set([over[0]]);
      }
      //otherwise, resolve the selection!
      else {
        const selection = this.createAction(ResolveGroupSelectionAction);
        const appendToSelection = this.shouldAppendToSelection(event);
        this.selection = selection.resolve(over, { appendToSelection });
      }
    }

    // make sure there's something to transform
    const hasSelection = !!this.selection?.length;
    if (!hasSelection) {
      return;
    }

    const selectable = selectionType ? selectionType?.selectability : true;

    // check what actions are allowed
    const allowTranslate = selectable && hasSelection;
    const allowRotate =
      selectable &&
      !overSelectionBox &&
      RotateGroupsAction.isAllowedHandle(handle);
    const allowResize =
      selectable &&
      !overSelectionBox &&
      ResizeGroupsAction.isAllowedHandle(handle);

    // resizing groups
    if (allowResize && !ignoreHandle) {
      const groups = this.getGroupsFromIds(this.selection);
      const alignmentHelper = this.createAlignmentHelper({
        isResize: true,
        handle,
      });
      this.action = this.createAction(ResizeGroupsAction, groups, handle, {
        alignmentHelper,
      });
    }
    // rotating groups
    else if (allowRotate && !ignoreHandle) {
      const groups = this.getGroupsFromIds(this.selection);
      this.action = this.createAction(
        RotateGroupsAction,
        groups,
        event.center,
        handle
      );
    }
    // translating groups
    else if (allowTranslate) {
      const alignmentHelper = this.createAlignmentHelper({});
      this.action = this.createAction(TranslateGroupsAction, this.selection, {
        alignmentHelper,
      });
    }
    // just ignore this
    else {
      return;
    }

    // also make this active
    this.setActive();
    // manager.activeInteraction = 'TransformGroups';
  }

  onActivePointerMove(event) {
    const resizeFromCenter = event.altKey;
    let {
      alignToGrid: snapToGrid,
      useSnapping: snapToObjects,
      usePositioning,
    } = this.useSelector(selectOptions);

    // override key
    if (event.ctrlKey) {
      snapToObjects = snapToGrid = false;
    }

    if (!usePositioning) {
      snapToGrid = false;
    }

    const deltaX = event.movement[0];
    const deltaY = event.movement[1];

    if (this.action instanceof TranslateGroupsAction) {
      this.action.translateBy(deltaX, deltaY, {
        snapToGrid,
        snapToObjects,
      });
    } else if (this.action instanceof ResizeGroupsAction) {
      this.action.resizeBy(deltaX, deltaY, {
        snapToGrid,
        snapToObjects,
        resizeFromCenter,
      });
    } else if (this.action instanceof RotateGroupsAction) {
      this.action.rotateBy(event.center, snapToObjects);
    }
  }

  async onActivePointerMoveEnd(_, manager) {
    // resolvable actions
    if (
      this.action instanceof TranslateGroupsAction ||
      this.action instanceof ResizeGroupsAction ||
      this.action instanceof RotateGroupsAction
    ) {
      await this.action.resolve();

      // update the selection box
      const selection = this.createAction(SetSelectionAction);
      await selection.refresh();

      // update selection logic
      this.refreshMenuState({
        currentSelection: this.selection,
        newSelection: selection,
      });
    }

    delete this.action;
    this.setActive(false);
    manager.activeInteraction = null;
  }
}
