import {
  FIT_TO_VIEW_PADDING,
  MAX_SCALE_MULTIPLE,
  MIN_SCALE_MULTIPLE,
} from '@/Constants/UI';
import { inGridParams, mmGridParams } from '@/defaults';
import { getAABBSize } from '@/Geometry/AABBOps';
import { scalarMul, subtract } from '@/Geometry/PointOps';
import { AABB } from '@/Geometry/sherpa-svg-generator/AABB';
import { BasePath } from '@/Geometry/sherpa-svg-generator/BasePath';
import { Canvas } from '@/Geometry/sherpa-svg-generator/Canvas';
import {
  createMatrix33,
  invert,
} from '@/Geometry/sherpa-svg-generator/Matrix33';
import { PATH_TYPE } from '@/Geometry/sherpa-svg-generator/Path';
import {
  IPoint,
  Point,
  transform,
} from '@/Geometry/sherpa-svg-generator/Point';
import { SvgGroup } from '@/Geometry/sherpa-svg-generator/SvgGroup';
import { DisplayUnits } from '@/@types/shaper-types';
import {
  defaultViewportState,
  Viewbox,
  ViewportState,
} from '@/Redux/Slices/ViewportSlice';
import { PayloadAction } from '@reduxjs/toolkit';

export interface Targets {
  group: SvgGroup;
  path: BasePath;
}

export interface ResetViewportPayload {
  width: number;
  height: number;
}

export interface SetScalePayload {
  scale: number;
  scaleOriginScreen: Point;
}

export interface SetScaleAbsolutePayload {
  scale: number;
  scaleOriginScreen?: Point;
}

export interface CenterToPayload {
  targets?: Targets[];
  includeCutPaths?: boolean;
  anchorLeftPercentage?: number;
  anchorTopPercentage?: number;
}

export const getDefaultZoomLimits = (canvasToScreenScale: number) => {
  const defaultSize = new Point(25.4, 25.4);
  const maxSizePx = scalarMul(
    defaultSize,
    MAX_SCALE_MULTIPLE * canvasToScreenScale
  );

  const minSizePx = scalarMul(
    defaultSize,
    MIN_SCALE_MULTIPLE * canvasToScreenScale
  );
  return { maxSizePx, minSizePx };
};

export const getSvgViewBox = (state: ViewportState): Viewbox => {
  const { canvasViewbox } = state;

  const viewboxSize = getAABBSize(canvasViewbox as AABB);
  return {
    x: (canvasViewbox as AABB).minPoint.x,
    y: (canvasViewbox as AABB).minPoint.y,
    width: viewboxSize.x,
    height: viewboxSize.y,
  };
};

export const setScaleFcn = (
  state: ViewportState,
  { scale }: { scale: number } = { scale: 1.0 },
  canvas: Canvas
) => {
  let maxSizePx, minSizePx;
  if (canvas.svgGroupSet.length > 0) {
    const canvasAABBSize = getAABBSize(canvas.AABB);
    const minGroupIdx = canvas.svgGroupSet
      .map((g) => getAABBSize(g.transformedAABB))
      .reduce(
        (acc, groupSize, idx) => {
          const groupArea = groupSize.x * groupSize.y;
          return acc.area < groupArea ? acc : { idx, area: groupArea };
        },
        { idx: -1, area: Number.POSITIVE_INFINITY }
      );

    const minGroup = canvas.svgGroupSet[minGroupIdx.idx];

    //Convert canvasAABB to pixels, if 10x canvasAABBPx is smaller than screen size, then we've zoomed out too far
    //Convert minGroupAABB to pixels, if 0.1x minGroupAABBPx is larger than screen size, then we've zoomed in too far
    maxSizePx = scalarMul(
      canvasAABBSize,
      MAX_SCALE_MULTIPLE * state.canvasToScreenScale
    );

    minSizePx =
      minGroup !== undefined
        ? scalarMul(
            getAABBSize(minGroup.transformedAABB),
            MIN_SCALE_MULTIPLE * state.canvasToScreenScale
          )
        : scalarMul(
            canvasAABBSize,
            MIN_SCALE_MULTIPLE * state.canvasToScreenScale
          );
  } else {
    ({ maxSizePx, minSizePx } = getDefaultZoomLimits(
      state.canvasToScreenScale
    ));
  }

  const zoomOutLimit = maxSizePx.x < state.size.x && maxSizePx.y < state.size.y;
  const zoomInLimit = minSizePx.x > state.size.x || minSizePx.y > state.size.y;

  const canvasHalfWidth = (state.size.x / 2) * scale;
  const canvasHalfHeight = (state.size.y / 2) * scale;

  const minPoint = new Point(
    state.position.x - canvasHalfWidth,
    state.position.y - canvasHalfHeight
  );

  const maxPoint = new Point(
    state.position.x + canvasHalfWidth,
    state.position.y + canvasHalfHeight
  );

  const nextCanvasViewbox = new AABB({ minPoint, maxPoint });
  const width = nextCanvasViewbox.maxPoint.x - nextCanvasViewbox.minPoint.x;
  const height = nextCanvasViewbox.maxPoint.y - nextCanvasViewbox.minPoint.y;

  if (
    width > 0 &&
    height > 0 &&
    ((!zoomInLimit && !zoomOutLimit) ||
      (zoomInLimit && state.scale <= scale) ||
      (zoomOutLimit && state.scale >= scale))
  ) {
    return scale;
  }
  return false;
};

//Compute CanvasToScreenTransform and ScreenToCanvasTransform from:
// viewport center position on canvas, viewport size in pixels, and canvasToScreenScale
export const refreshTransforms = (
  size: IPoint,
  position: IPoint,
  canvasToScreenScale: number
) => {
  //Center of viewport in pixels is half the viewport size
  const { x: vpCenterScreenX, y: vpCenterScreenY } = scalarMul(
    new Point(size.x, size.y),
    0.5
  );

  //Center of viewport in screen space corresponds to viewport position in canvas space (position)
  const { x: vpCenterCanvasX, y: vpCenterCanvasY } = position;

  //These two points are the same after transformation into the same space (either canvas or screen) so canvasToScreenTransform is found by:
  // vpCenterScreen = M * vpCenterCanvas,
  //   where M is a standard transformation matrix with canvasToScreenScale as the scaling factor.
  //	Solve this equation for the translation terms T in M gives:
  // T = vpCenterScreen - S * vpCenterCanvas

  const canvasToScreenTransform = createMatrix33([
    [
      canvasToScreenScale,
      0,
      vpCenterScreenX - canvasToScreenScale * vpCenterCanvasX,
    ],
    [
      0,
      canvasToScreenScale,
      vpCenterScreenY - canvasToScreenScale * vpCenterCanvasY,
    ],
    [0, 0, 1],
  ]);

  const screenToCanvasTransform = invert(canvasToScreenTransform);
  return { canvasToScreenTransform, screenToCanvasTransform };
};

// Update the canvasToScreenScale after a resize event, then set transforms using updateTransforms
export const refreshViewportScaleAndTransforms = (
  size: IPoint,
  scale: number,
  position: IPoint
) => {
  const { x: widthPx, y: heightPx } = size;

  const scaleX = (widthPx / (25.4 * scale)) * 0.5;
  const scaleY = (heightPx / (25.4 * scale)) * 0.5;

  const canvasToScreenScale = Math.min(scaleX, scaleY);

  //Refresh transforms using the new state.canvasToScreenScale
  const { canvasToScreenTransform, screenToCanvasTransform } =
    refreshTransforms(size, position, canvasToScreenScale);

  return {
    canvasToScreenScale,
    canvasToScreenTransform,
    screenToCanvasTransform,
  };
};

export const refreshScaleTransformsViewbox = (
  size: IPoint,
  scale: number,
  position: IPoint,
  canvas: Canvas,
  displayUnits: DisplayUnits
) => {
  const {
    canvasToScreenScale,
    canvasToScreenTransform,
    screenToCanvasTransform,
  } = refreshViewportScaleAndTransforms(size, scale, position);

  const nspf = screenToCanvasTransform[0][0];

  //must update viewbox
  const { canvasViewbox, activeGrids, activeGridLevel } = refreshViewbox(
    nspf,
    size,
    position,
    canvasToScreenScale,
    displayUnits
  );

  return {
    canvasToScreenScale,
    canvasToScreenTransform,
    screenToCanvasTransform,
    canvasViewbox,
    activeGrids,
    activeGridLevel,
  };
};

export interface GridLevelParams {
  scale: number;
  nonScalingPixelFactor: number;
  minScale: number;
  maxScale: number;
  level: number;
  fill: string;
  displayUnits: DisplayUnits;
  opacityFcn: Function;
  levelLabel?: string;
}

export interface GridLevel {
  fill: string;
  level: string | number;
  opacity: number;
  maxScale: number;
  minScale: number;
  point: number;
  block: number;
  size: number;
}

export const generateGridLevel = ({
  scale,
  nonScalingPixelFactor,
  minScale,
  maxScale,
  level,
  fill,
  displayUnits,
  opacityFcn,
  levelLabel,
}: GridLevelParams): GridLevel => {
  //Grid has maximum opacity midway between minScale and maxScale
  //Using nice exponential functions for the opacity value
  const opacity = opacityFcn({ scale, maxScale, minScale });

  //Converts level value to mm
  const scaleFactor = displayUnits === 'in' ? 25.4 : 1;

  //1 inch = 96px; 1mm = 3.77953px
  const gridBlockSize = displayUnits === 'in' ? 96 : 3.77953;
  const thisLevelLabel = levelLabel === undefined ? level : levelLabel;
  return {
    fill: '#AFAEAD',
    // fill, // helpful for debugging
    level: thisLevelLabel,
    opacity,
    maxScale,
    minScale,
    point: 5 * nonScalingPixelFactor,
    block: gridBlockSize * level,
    size: scaleFactor * level,
  };
};

export const generateAllGridLevels = (
  scale: number,
  nonScalingPixelFactor: number,
  displayUnits: DisplayUnits
) => {
  const gridParams = displayUnits === 'in' ? inGridParams : mmGridParams;

  return gridParams
    .map((gp) =>
      generateGridLevel({
        ...gp,
        scale,
        nonScalingPixelFactor,
        displayUnits,
      })
    )
    .filter((grid) => scale > grid.minScale && scale < grid.maxScale);
};

export const refreshViewbox = (
  nonScalingPixelFactor: number,
  size: IPoint,
  position: IPoint,
  canvasToScreenScale: number,
  displayUnits: DisplayUnits
) => {
  //transform viewport size from screen space to canvas

  const canvasHalfWidth = (size.x / 2) * nonScalingPixelFactor;
  const canvasHalfHeight = (size.y / 2) * nonScalingPixelFactor;

  const minPoint = new Point(
    position.x - canvasHalfWidth,
    position.y - canvasHalfHeight
  );

  const maxPoint = new Point(
    position.x + canvasHalfWidth,
    position.y + canvasHalfHeight
  );

  const canvasViewbox = new AABB({ minPoint, maxPoint });

  //For tuning grid transitions
  // console.log(`state.scale ${state.scale}, canvasToScreenScale ${state.canvasToScreenScale}`);

  const activeGrids = generateAllGridLevels(
    canvasToScreenScale,
    nonScalingPixelFactor,
    displayUnits
  );
  // state.activeGrids.forEach(g => console.log(`level ${g.level}, opacity ${g.opacity}`));

  const activeGridLevel = activeGrids[activeGrids.length - 1];

  return { canvasViewbox, activeGrids, activeGridLevel };
};

export const resizeViewport = (
  scale: number,
  position: IPoint,
  width: number,
  height: number,
  canvas: Canvas,
  displayUnits: DisplayUnits
) => {
  // clamp sizes
  const newWidth = Math.max(0, Math.min(window.innerWidth, width));
  const newHeight = Math.max(0, Math.min(window.innerHeight, height));
  const size = { x: newWidth, y: newHeight };

  const {
    canvasToScreenScale,
    canvasToScreenTransform,
    screenToCanvasTransform,
    canvasViewbox,
    activeGrids,
    activeGridLevel,
  } = refreshScaleTransformsViewbox(
    size,
    scale,
    position,
    canvas,
    displayUnits
  );

  return {
    size,
    canvasToScreenScale,
    canvasToScreenTransform,
    screenToCanvasTransform,
    canvasViewbox,
    activeGrids,
    activeGridLevel,
  };
};

export const viewportActions = {
  resetViewport: (
    state: ViewportState,
    canvas: Canvas,
    displayUnits: DisplayUnits
  ) => {
    const position = defaultViewportState.position;
    const scale = defaultViewportState.scale;

    let { x: width, y: height } = state.size;

    const { canvasViewbox, activeGrids, activeGridLevel } = resizeViewport(
      scale,
      position,
      width,
      height,
      canvas,
      displayUnits
    );

    return {
      position,
      size: new Point(width, height),
      scale,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    };
  },
  refresh: (
    state: ViewportState,
    canvas: Canvas,
    displayUnits: DisplayUnits
  ) => {
    const {
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    } = refreshScaleTransformsViewbox(
      state.size,
      state.scale,
      state.position,
      canvas,
      displayUnits
    );
    return {
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    };
  },
  resize: (
    state: ViewportState,
    action: PayloadAction<ResetViewportPayload>,
    canvas: Canvas,
    displayUnits: DisplayUnits
  ) => {
    const { width, height } = action.payload;

    // clamp sizes
    const newWidth = Math.max(0, Math.min(window.innerWidth, width));
    const newHeight = Math.max(0, Math.min(window.innerHeight, height));
    const size = new Point(newWidth, newHeight);

    const {
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    } = refreshScaleTransformsViewbox(
      size,
      state.scale,
      state.position,
      canvas,
      displayUnits
    );

    return {
      size,
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    };
  },
  setPosition: (
    state: ViewportState,
    action: PayloadAction<IPoint>,
    displayUnits: DisplayUnits
  ) => {
    const position = new Point(action.payload.x, action.payload.y);
    const { canvasToScreenTransform, screenToCanvasTransform } =
      refreshTransforms(state.size, position, state.canvasToScreenScale);

    const nspf = screenToCanvasTransform[0][0];
    //must update viewbox
    const { canvasViewbox, activeGrids, activeGridLevel } = refreshViewbox(
      nspf,
      state.size,
      position,
      state.canvasToScreenScale,
      displayUnits
    );
    return {
      position,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    };
  },
  setScaleBy: (
    state: ViewportState,
    action: PayloadAction<SetScalePayload>,
    canvas: Canvas,
    displayUnits: DisplayUnits
  ) => {
    //Used by mouse
    const { scale, scaleOriginScreen } = action.payload;
    const oldScaleOriginCanvas = transform(
      scaleOriginScreen,
      state.screenToCanvasTransform
    );

    const newScale = setScaleFcn(
      state,
      { scale: state.scale * (1 + scale) },
      canvas
    );
    if (newScale) {
      let { screenToCanvasTransform } = refreshViewportScaleAndTransforms(
        state.size,
        newScale,
        state.position
      );
      const newScaleOriginCanvas = transform(
        scaleOriginScreen,
        screenToCanvasTransform
      );
      //From old to new
      const deltaPos = subtract(newScaleOriginCanvas, oldScaleOriginCanvas);
      const position = subtract(
        new Point(state.position.x, state.position.y),
        deltaPos
      );

      //Recompute transforms
      let {
        canvasToScreenScale: c2sScale,
        canvasToScreenTransform: c2sTransform,
        screenToCanvasTransform: s2cTransform,
      } = refreshViewportScaleAndTransforms(state.size, newScale, position);
      //must update viewbox
      const nspf = s2cTransform[0][0];
      const { canvasViewbox, activeGrids, activeGridLevel } = refreshViewbox(
        nspf,
        state.size,
        position,
        c2sScale,
        displayUnits
      );
      return {
        scale: newScale,
        position,
        canvasToScreenScale: c2sScale,
        canvasToScreenTransform: c2sTransform,
        screenToCanvasTransform: s2cTransform,
        canvasViewbox,
        activeGrids,
        activeGridLevel,
      };
    }
  },
  setScaleByAbsolute: (
    state: ViewportState,
    action: PayloadAction<SetScaleAbsolutePayload>,
    canvas: Canvas,
    displayUnits: DisplayUnits
  ) => {
    const { scale, scaleOriginScreen } = action.payload;
    const oldScaleOriginCanvas = transform(
      scaleOriginScreen,
      state.screenToCanvasTransform
    );

    const newScale = setScaleFcn(state, { scale }, canvas);
    if (newScale) {
      const {
        canvasToScreenScale,
        canvasToScreenTransform,
        screenToCanvasTransform,
        canvasViewbox,
        activeGrids,
        activeGridLevel,
      } = refreshScaleTransformsViewbox(
        state.size,
        scale,
        state.position,
        canvas,
        displayUnits
      );

      if (scaleOriginScreen) {
        const newScaleOriginCanvas = transform(
          scaleOriginScreen,
          screenToCanvasTransform
        );

        const deltaPos = subtract(newScaleOriginCanvas, oldScaleOriginCanvas);

        const newPosition = subtract(state.position as Point, deltaPos);

        const {
          canvasToScreenScale: newCanvasToScreenScale,
          canvasToScreenTransform: newCanvasToScreenTransform,
          screenToCanvasTransform: newScreenToCanvasTransform,
          canvasViewbox: newCanvasViewbox,
          activeGrids: newActiveGrids,
          activeGridLevel: newActiveGridLevel,
        } = refreshScaleTransformsViewbox(
          state.size,
          scale,
          newPosition,
          canvas,
          displayUnits
        );

        return {
          scale: newScale,
          position: newPosition,
          canvasToScreenScale: newCanvasToScreenScale,
          canvasToScreenTransform: newCanvasToScreenTransform,
          screenToCanvasTransform: newScreenToCanvasTransform,
          canvasViewbox: newCanvasViewbox,
          activeGrids: newActiveGrids,
          activeGridLevel: newActiveGridLevel,
        };
      }
      return {
        scale: newScale,
        canvasToScreenScale,
        canvasToScreenTransform,
        screenToCanvasTransform,
        canvasViewbox,
        activeGrids,
        activeGridLevel,
      };
    }
  },
  centerTo: (
    state: ViewportState,
    action: PayloadAction<CenterToPayload>,
    canvas: Canvas,
    displayUnits: DisplayUnits,
    mode: string
  ) => {
    const {
      targets,
      includeCutPaths = false,
      anchorLeftPercentage = 1,
      anchorTopPercentage = 1,
    } = action.payload;

    // determine a selection source
    const selection = [...(targets || [])];

    // without a selection, just use everything
    if (!selection.length) {
      for (const group of canvas.svgGroupSet) {
        for (const path of group.basePathSet) {
          // Don't add reference paths when centering in plan mode
          if (mode !== 'plan' || path.type !== PATH_TYPE.REFERENCE) {
            selection.push({ group, path });
          }
        }
      }
    }

    let position: Point;
    let numberToScaleTo: number | false;
    if (selection.length > 0) {
      const xs = [];
      const ys = [];
      for (const { group, path: targetPath } of selection) {
        let { x: startX, y: startY } = group.position;
        let scaleX = group.stretchMtx[0][0];
        let scaleY = group.stretchMtx[1][1];
        let {
          minPoint: { x: minX, y: minY },
          maxPoint: { x: maxX, y: maxY },
        } = targetPath.AABB;
        minX *= scaleX;
        maxX *= scaleX;
        minY *= scaleY;
        maxY *= scaleY;

        // if we're including cut paths, extend the bounds to include the offsets
        if (includeCutPaths) {
          let cutPathExtend = 0;
          for (const path of group.basePathSet) {
            const cutType = path.cutParams?.cutType;

            let cutValue = parseFloat(path.cutParams?.toolDia) || 0;

            // if it's on the line, only use half
            if (cutType === 'online') {
              cutValue /= 2;
            }
            // if it's usable, update the expansion
            if (['outside', 'online'].includes(cutType)) {
              cutPathExtend = Math.max(cutPathExtend, cutValue);
            }
          }
          // update the sizing
          minX -= cutPathExtend;
          minY -= cutPathExtend;
          maxX += cutPathExtend;
          maxY += cutPathExtend;
        }
        minX += startX;
        maxX += startX;
        minY += startY;
        maxY += startY;

        xs.push(minX, maxX);
        ys.push(minY, maxY);
      }
      // gather all bounds
      const left = Math.min.apply(Math, xs);
      const right = Math.max.apply(Math, xs);
      const top = Math.min.apply(Math, ys);
      const bottom = Math.max.apply(Math, ys);

      // get the center
      const cx = (left + right) / 2;
      const cy = (top + bottom) / 2;
      const selectionWidth = Math.abs(right - left);
      const selectionHeight = Math.abs(bottom - top);
      const { scale } = state;
      const viewbox = getSvgViewBox(state);
      const viewportWidth = viewbox.width / scale;
      const viewportHeight = viewbox.height / scale;

      // calculate viewable area including some padding
      const viewportPadding = 1 - FIT_TO_VIEW_PADDING * 2;
      const viewableWidth = viewportWidth * viewportPadding;
      const viewableHeight = viewportHeight * viewportPadding;
      const viewableWidthWOffset = viewableWidth * anchorLeftPercentage;
      const viewableHeightWOffset = viewableHeight * anchorTopPercentage;
      const offsetWidth = (viewableWidth - viewableWidthWOffset) / 2;
      const offsetHeight = (viewableHeight - viewableHeightWOffset) / 2;

      // calculate the scale required to fit
      const newScale = Math.max(
        selectionWidth / viewableWidthWOffset,
        selectionHeight / viewableHeightWOffset
      );
      const offsetX = offsetWidth * newScale;
      const offsetY = offsetHeight * newScale;

      position = new Point(cx + offsetX, cy + offsetY);
      numberToScaleTo = setScaleFcn(state, { scale: newScale }, canvas);
    } else {
      position = new Point();
      numberToScaleTo = setScaleFcn(state, { scale: 1.0 }, canvas);
    }
    // refresh the view

    const viewportScale = numberToScaleTo ? numberToScaleTo : state.scale;

    const {
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    } = refreshScaleTransformsViewbox(
      state.size,
      viewportScale,
      position,
      canvas,
      displayUnits
    );
    return {
      ...(numberToScaleTo && { scale: numberToScaleTo }),
      canvasToScreenScale,
      canvasToScreenTransform,
      screenToCanvasTransform,
      canvasViewbox,
      activeGrids,
      activeGridLevel,
    };
  },
};
