import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

function SherpaTextField(props) {
  const { value, inputRef } = props;
  const [currentValue, setCurrentValue] = useState(value);

  // https://stackoverflow.com/questions/35791074/reactjs-how-can-i-set-a-value-for-textfield-from-material-ui

  const updateValue = (e) => {
    setCurrentValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  // check for blurred controls
  const onBlur = () => {
    if (props.submitOnBlur && props.onSubmit) {
      props.onSubmit(currentValue);
    } else if (props.onBlur) {
      props.onBlur();
    } else if (props.onChange) {
      props.onChange(currentValue);
    }
  };

  // check for enter key submissions
  const onKeyPress = (e) => {
    if (props.submitOnEnter && props.onSubmit && e.keyCode === 13) {
      props.onSubmit(currentValue);
    }
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };

  const onKeyUp = (e) => {
    if (props.onKeyUp) {
      props.onKeyUp();
    }
  };

  // text inputs
  const type = props.numericOnly ? 'number' : props.type || 'text';

  return (
    <TextField
      key={props.parentkey + props.value}
      type={type}
      inputRef={inputRef}
      className={`textField ${props.className}`}
      onChange={updateValue}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
      onKeyUp={onKeyUp}
      placeholder={props.placeholder}
      value={currentValue}
      InputProps={props.inputProps}
    />
  );
}

export default SherpaTextField;
