import math from './sherpa-svg-generator/mathjs';
import { CutParams, CutType } from './sherpa-svg-generator/CutParams';

function getToolOffset(cutParams: CutParams = new CutParams()) {
  const cutOffset = math.unit(cutParams.cutOffset).toNumber('mm');

  switch (cutParams.cutType) {
    case CutType.ONLINE:
    case CutType.GUIDE:
      return 0;

    case CutType.INSIDE:
      return -0.5 * math.unit(cutParams.toolDia).toNumber('mm') - cutOffset;

    case CutType.POCKET:
      return -0.5 * math.unit(cutParams.toolDia).toNumber('mm') - cutOffset;

    case CutType.OUTSIDE:
      return 0.5 * math.unit(cutParams.toolDia).toNumber('mm') + cutOffset;

    default:
      throw Error('Unknown cut type');
  }
}

function getReviewPathOffset(cutParams: CutParams) {
  switch (cutParams.cutType) {
    case CutType.GUIDE:
      return 0;

    // return -0.5 * math.unit(cutParams.toolDia).toNumber('mm') - cutOffset;

    case CutType.POCKET:
    case CutType.INSIDE:
    case CutType.ONLINE:
    case CutType.OUTSIDE:
      return 0.5 * math.unit(cutParams.toolDia).toNumber('mm');

    default:
      throw Error('Unknown cut type');
  }
}

function getCutDepthMM(cutParams: CutParams) {
  return math.unit(cutParams.cutDepth).toNumber('mm');
}

function getCutShadowCssClass(toolDia: string, cutDepth: string) {
  const cutDepthIn = math.round(math.unit(cutDepth).toNumber('in'), 3);

  const cssClasses = [];

  switch (true) {
    case cutDepthIn < 0.001:
      cssClasses.push('cut-depth--0');
      break;
    case cutDepthIn < 0.125:
      cssClasses.push('cut-depth--1');
      break;
    case cutDepthIn < 0.25:
      cssClasses.push('cut-depth--2');
      break;
    case cutDepthIn < 0.375:
      cssClasses.push('cut-depth--3');
      break;
    case cutDepthIn < 0.5:
      cssClasses.push('cut-depth--4');
      break;
    case cutDepthIn < 1.0:
      cssClasses.push('cut-depth--5');
      break;
    case cutDepthIn < 1.5:
      cssClasses.push('cut-depth--6');
      break;
    default:
      cssClasses.push('cut-depth--7');
      break;
  }

  return cssClasses;
}

function getSvgPathCssClass(
  pathTypeKey: string,
  toolDia = '0in',
  cutDepth = '0in'
) {
  switch (pathTypeKey) {
    case 'fragment':
      return [];
    case 'base':
      return ['basePath'];

    case 'selection':
      return ['selectionPath'];

    case 'online':
      return ['cutPath', 'online'];

    case 'guide':
      return ['cutPath', 'guide'];

    case 'inside':
      return ['cutPath', 'inside'];

    case 'pocket':
      return ['cutPath', 'pocketPath'];

    case 'outside':
      return ['cutPath', 'outside'];

    case 'toolWidth':
      return ['toolWidth'];

    case 'toolWidthReviewOutline':
      return [
        'toolWidthReviewOutline',
        ...getCutShadowCssClass(toolDia, cutDepth),
      ];

    case 'toolWidthReviewPocket':
      return [
        'toolWidthReviewPocket',
        ...getCutShadowCssClass(toolDia, cutDepth),
      ];

    default:
      throw Error('Unknown cut type');
  }
}

function compareCutParams(cp0: CutParams, cp1: CutParams): boolean {
  return (
    cp0.cutType === cp1.cutType &&
    cp0.cutDepth === cp1.cutDepth &&
    cp0.cutOffset === cp1.cutOffset &&
    cp0.toolDia === cp1.toolDia
  );
}

export {
  getToolOffset,
  getReviewPathOffset,
  getCutDepthMM,
  getSvgPathCssClass,
  getCutShadowCssClass,
  compareCutParams,
};
