import { SvgOps } from '@/Geometry/SvgParser';

export interface FontStyle {
  displayStyle: string;
  fontFamily: string;
  fontStyle: string;
  url: string;
  forceOpenPaths?: boolean;
  isSingleLine?: boolean;
}

export interface Font {
  fontCategories: [string, ...string[]];
  fontDisplayName: string;
  fontStyles: [FontStyle, ...FontStyle[]];
  isPremium?: boolean;
  fontDisplaySizeOverride?: number;
}
export const fontLibrary: Font[] = [
  {
    fontCategories: ['Sans-Serif', 'Non-Latin'],
    fontDisplayName: 'IBM Plex Sans',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/IBM_Plex_Sans/IBMPlexSans-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Handwriting', 'Non-Latin'],
    fontDisplayName: 'Amatic SC',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Amatic SC',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Amatic_SC/AmaticSC-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Amatic SC Bold',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Amatic_SC/AmaticSCBold-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Handwriting'],
    fontDisplayName: 'Architects Daughter',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Architects Daughter',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Architects_Daughter/ArchitectsDaughter-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Handwriting'],
    fontDisplayName: 'Caveat',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Caveat',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Caveat/Caveat-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Caveat Bold',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Caveat/CaveatBold-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Handwriting', 'Novelty'],
    fontDisplayName: 'Knewave',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Knewave',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Knewave/Knewave-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Komika Title',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Komika Title',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Komika-Title/KomikaTitle.ttf',
      },
    ],
    isPremium: true,
  },
  /*
    Omitting Meddon, because it's ugly and messes up shapeshifter, and did I mention that it's ugly?
  {
    fontCategories: ['Handwriting'],
    fontDisplayName: 'Meddon', 
    fontStyles: [
      { displayStyle: 'Regular', fontFamily: 'Meddon', fontStyle: 'Regular', url: '/fonts/Handwriting/Meddon/Meddon-Regular.ttf'}
    ],
    isPremium: true
  },
  */
  {
    fontCategories: ['Handwriting', 'Script'],
    fontDisplayName: 'Meow Script',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Meow Script',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Meow_Script/MeowScript-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Handwriting'],
    fontDisplayName: 'Sacramento',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Sacramento',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Sacramento/Sacramento-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Handwriting', 'Script'],
    fontDisplayName: 'Vibur',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Vibur',
        fontStyle: 'Regular',
        url: '/fonts/Handwriting/Vibur/Vibur-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Novelty', 'Sans-Serif'],
    fontDisplayName: 'Bungee',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Bungee',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Bungee/Bungee-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Creepster',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Creepster',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Creepster/Creepster-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Germania One',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Germania One',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Germania_One/GermaniaOne-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Limelight',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Limelight',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Limelight/Limelight-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty', 'Sans-Serif'],
    fontDisplayName: 'Megrim',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Megrim',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Megrim/Megrim-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Oi',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Oi',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Oi/Oi-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Plaster',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Plaster',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Plaster/Plaster-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty'],
    fontDisplayName: 'Sancreek',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Sancreek',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Sancreek/Sancreek-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty', 'Serif'],
    fontDisplayName: 'Shrikhand',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Shrikhand',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Shrikhand/Shrikhand-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Novelty', 'Serif'],
    fontDisplayName: 'Uncial Antiqua',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Uncial Antiqua',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Uncial_Antiqua/UncialAntiqua-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Bebas Neue',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Bebas Neue',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Bebas_Neue/BebasNeue-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Inter',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Inter',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Inter/Inter-Regular.ttf',
      },
      {
        displayStyle: 'Black',
        fontFamily: 'Inter Black',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Inter/Inter-Black.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Jost',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Jost',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Jost/Jost-Regular.ttf',
      },
      {
        displayStyle: 'Black',
        fontFamily: 'Jost Black',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Jost/Jost-Black.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Jura',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Jura',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Jura/Jura-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Jura Bold',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Jura/JuraBold-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'National Park',
    fontStyles: [
      //This font is currently the fallback font for the parser, so there's no need to load it again. However, it's still needed by the CSS, so we have to load it anyways.
      {
        displayStyle: 'Regular',
        fontFamily: 'National Park',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/NationalPark/NationalPark-Regular.otf',
      },
      {
        displayStyle: 'Heavy',
        fontFamily: 'National Park Heavy',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/NationalPark/NationalParkHeavy-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Sans-Serif', 'Novelty'],
    fontDisplayName: 'Nova Cut',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Nova Cut',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Nova_Cut/NovaCut-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Quicksand',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Quicksand',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Quicksand/Quicksand-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Quicksand Bold',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Quicksand/QuicksandBold-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Sans-Serif'],
    fontDisplayName: 'Space Mono',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Space Mono',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Space_Mono/SpaceMono-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Space Mono Bold',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Space_Mono/SpaceMonoBold-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Swell Braille',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Swell Braille',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Swell_Braille/Swell-Braille.ttf',
      },
    ],
    fontDisplaySizeOverride: 14,
  },
  {
    fontCategories: ['Script'],
    fontDisplayName: 'Berkshire Swash',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Berkshire Swash',
        fontStyle: 'Regular',
        url: '/fonts/Script/Berkshire_Swash/BerkshireSwash-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Script'],
    fontDisplayName: 'Birthstone',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Birthstone',
        fontStyle: 'Regular',
        url: '/fonts/Script/Birthstone/Birthstone-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Script'],
    fontDisplayName: 'Grand Hotel',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Grand Hotel',
        fontStyle: 'Regular',
        url: '/fonts/Script/Grand_Hotel/GrandHotel-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Script', 'Handwriting'],
    fontDisplayName: 'Great Vibes',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Great Vibes',
        fontStyle: 'Regular',
        url: '/fonts/Script/Great_Vibes/GreatVibes-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Script'],
    fontDisplayName: 'Lobster Two',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Lobster Two',
        fontStyle: 'Regular',
        url: '/fonts/Script/Lobster_Two/LobsterTwo-Regular.ttf',
      },
      {
        displayStyle: 'Bold',
        fontFamily: 'Lobster Two Bold',
        fontStyle: 'Regular',
        url: '/fonts/Script/Lobster_Two/LobsterTwoBold-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Handwriting', 'Script'],
    fontDisplayName: 'Pacifico',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Pacifico',
        fontStyle: 'Regular',
        url: '/fonts/Script/Pacifico/Pacifico-Regular.ttf',
      },
    ],
  },
  {
    fontCategories: ['Script'],
    fontDisplayName: 'Petit Formal Script',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Petit Formal Script',
        fontStyle: 'Regular',
        url: '/fonts/Script/Petit_Formal_Script/PetitFormalScript-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Serif'],
    fontDisplayName: 'Abril Fatface',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Abril Fatface',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Abril_Fatface/AbrilFatface-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Serif'],
    fontDisplayName: 'Alegreya',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Alegreya',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Alegreya/Alegreya-Regular.ttf',
      },
      {
        displayStyle: 'Black',
        fontFamily: 'Alegreya Black',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Alegreya/Alegreya-Black.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Serif', 'Novelty'],
    fontDisplayName: 'Cinzel',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Cinzel',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Cinzel/Cinzel-Regular.ttf',
      },
      {
        displayStyle: 'Black',
        fontFamily: 'Cinzel Black',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Cinzel/Cinzel-Black.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Serif', 'Novelty'],
    fontDisplayName: 'Graduate',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Graduate',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Graduate/Graduate-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Serif'],
    fontDisplayName: 'Playfair Display',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Playfair Display',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Playfair_Display/PlayfairDisplay-Regular.ttf',
      },
      {
        displayStyle: 'Black',
        fontFamily: 'Playfair Display Black',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Playfair_Display/PlayfairDisplay-Black.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Serif'],
    fontDisplayName: 'Yeseva One',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Yeseva One',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Yeseva_One/YesevaOne-Regular.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Single Line', 'Handwriting', 'Script'],
    fontDisplayName: 'Big Tomato SLF',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Big Tomato SLF',
        fontStyle: 'Regular',
        url: '/fonts/SingleLine/BigTomatoSLF.opf',
        forceOpenPaths: true,
        isSingleLine: true,
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Single Line', 'Sans-Serif'],
    fontDisplayName: 'Grand Teton SLF',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Grand Teton SLF',
        fontStyle: 'Regular',
        url: '/fonts/SingleLine/GrandTetonSLF.opf',
        forceOpenPaths: true,
        isSingleLine: true,
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Single Line', 'Sans-Serif'],
    fontDisplayName: 'Origin SLF',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Origin SLF',
        fontStyle: 'Regular',
        url: '/fonts/SingleLine/OriginSLF.opf',
        forceOpenPaths: true,
        isSingleLine: true,
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSans-VariableFont_wdth,wght.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans KR   한국인',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans KR Thin',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansKR.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans SC  中国人',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans SC Thin',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansSC.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans JP  日本語',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans JP Thin',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansJP.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans Thai',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans Thai',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansThai-VariableFont_wdth,wght.ttf',
      },
    ],
    isPremium: true,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans AR   العربية',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans Arabic',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansArabic-VariableFont_wdth,wght.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Sans-Serif'],
    fontDisplayName: 'Noto Sans HE   עִברִית',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Noto Sans Hebrew',
        fontStyle: 'Regular',
        url: '/fonts/SansSerif/Noto Sans/NotoSansHebrew-VariableFont_wdth,wght.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Novelty'],
    fontDisplayName: 'Dongle 한국인',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Dongle',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Dongle/Dongle-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 20,
  },
  {
    fontCategories: ['Non-Latin', 'Novelty'],
    fontDisplayName: 'Cherry Bomb One にっぽん',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Cherry Bomb One',
        fontStyle: 'Regular',
        url: '/fonts/Novelty/Cherry Bomb One/CherryBombOne-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Serif'],
    fontDisplayName: 'Rozha One  देवनागरी',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Rozha One',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Rozha One/RozhaOne-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Non-Latin', 'Serif'],
    fontDisplayName: 'Chonburi แบบไทย',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Chonburi',
        fontStyle: 'Regular',
        url: '/fonts/Serif/Chonburi/Chonburi-Regular.ttf',
      },
    ],
    isPremium: true,
    fontDisplaySizeOverride: 12,
  },
  {
    fontCategories: ['Script', 'Handwriting', 'Novelty'],
    fontDisplayName: 'Birthstone Bounce',
    fontStyles: [
      {
        displayStyle: 'Regular',
        fontFamily: 'Birthstone Bounce',
        fontStyle: 'Regular',
        url: '/fonts/Script/Birthstone Bounce/BirthstoneBounce-Regular.ttf',
      },
    ],
    isPremium: true,
  },
];

//Retrieve font file at fontUrl and convert to ArrayBuffer
//Use this to initialize CSS and wasm SVG parser
const getFontData = function (fontUrl: string) {
  return fetch(`https://assets.shapertools.com/studio${fontUrl}`)
    .then((response) => response.blob())
    .then((blob) => blob.arrayBuffer());
};

//Adds font with fontFamily name and fontData ArrayBuffer to document
const addFontFaceToDocument = async function (
  fontFamily: string,
  fontData: BinaryData
) {
  const fontFace = new FontFace(fontFamily, fontData);
  await fontFace.load();
  return document.fonts.add(fontFace);
};

//Converts fontData from ArrayBuffer to UInt8Array and passes to wasm
const addFontToSvgParser = function (
  fontArrayBuffer: ArrayBuffer,
  isSingleLine: boolean
) {
  const fontUInt8 = new Uint8Array(fontArrayBuffer);
  SvgOps.addFontToSvgParser(fontUInt8, isSingleLine);
};

export const initializeFonts = async function () {
  async function addFont(fontStyle: FontStyle) {
    try {
      const fontData = await getFontData(fontStyle.url);
      await addFontFaceToDocument(fontStyle.fontFamily, fontData);
      addFontToSvgParser(fontData, fontStyle.isSingleLine ?? false);
    } catch (error: unknown) {
      console.error('Error adding font', {
        fontFamily: fontStyle.fontFamily,
        error,
      });
    }
  }

  const fontStyles = fontLibrary.flatMap((entry) => entry.fontStyles);
  await Promise.allSettled(fontStyles.map(addFont));
};
