// Point
import { immerable } from 'immer';
import { Matrix33, initialMatrix33 } from './Matrix33';

/**
 * @type {PointEps} - default tolerance for point equality -- 0.1
 */
const PointEps = 0.1;

/**
 * Function to transform a {@link Point} by transforms defined in a {@link Matrix33}
 * @param {Point} pt - point to be transformed
 * @param {Matrix33} mtx - matrix containining the transforms
 * @returns {Point} returns the transformed Point as a new {@link Point}
 */
export const transform = (
  pt: Point = new Point(),
  mtx: Matrix33 = initialMatrix33
): Point => {
  return new Point(
    mtx[0][0] * pt.x + mtx[0][1] * pt.y + mtx[0][2],
    mtx[1][0] * pt.x + mtx[1][1] * pt.y + mtx[1][2]
  );
};

/**
 * Utility function to calculate the distance between two points
 * @param {Point} p0 - the first {@link Point} to calculate the distance
 * @param {Point} p1 - the second {@link Point} to calculate the distance
 * @returns {number} - the resulting distance between p0, p1 as a number
 */
export const distance2 = (p0: Point, p1: Point): number => {
  const dx = p0.x - p1.x;
  const dy = p0.y - p1.y;
  return Math.pow(dx, 2) + Math.pow(dy, 2);
};

/**
 * Wrapper function that calls {@link distance2}.
 * @param {Point} p0 - the first {@link Point} to calculate the distance
 * @param {Point} p1 - the second {@link Point} to calculate the distance
 * @returns {number} - the resulting distance between p0, p1 as a number
 */
export const distance = (p0: Point, p1: Point): number => {
  return Math.sqrt(distance2(p0, p1));
};

/**
 * A function to compare to points with a tolerance for point "equality"
 *
 * i.e. If p0 = 1, p1 = 1.2 and the tolerance = 0.3, the resulting value will be true since `0.2 < 0.3`
 * @param {Point} p0 - the first {@link Point} to compare
 * @param {Point} p1 - the second {@link Point} to compare
 * @param {number} tolerance - optional parameter to define the tolerance. If not given, will default to {@link PointEps}
 * @returns {boolean} returns a boolean if the points are 'equal'
 */
export const comparePoints = (
  p0: Point,
  p1: Point,
  tolerance: number = PointEps
): boolean => {
  return distance(p0, p1) < tolerance;
};

export interface IPoint {
  x: number;
  y: number;
}

/**
 * @type {Point}
 * @property {number} x - x value for a point
 * @property {number} y - y value for a point
 */
export class Point implements IPoint {
  [immerable] = true;
  x: number;
  y: number;

  /**
   * @constructor
   * @param {number} x - x value
   * @param {number} y - y value
   */
  constructor(x?: number, y?: number) {
    this.x = x ?? 0;
    this.y = y ?? 0;
  }
}

export const defaultMinPt = new Point(
  Number.MAX_SAFE_INTEGER,
  Number.MAX_SAFE_INTEGER
);
export const defaultMaxPt = new Point(
  Number.MIN_SAFE_INTEGER,
  Number.MIN_SAFE_INTEGER
);
