export function normalizeRotationDeg(rawAngle) {
  const angleRemainder = rawAngle % 360;
  return angleRemainder < 0 ? 360 + angleRemainder : angleRemainder;
}

export function createRotationDegStr(rotationDegNum) {
  return `${normalizeRotationDeg(rotationDegNum)}˚`;
}

export function degreesToRadians(degrees) {
  return (degrees % 360) * (Math.PI / 180);
}

export function rotateAroundPoint(centerX, centerY, pointX, pointY, radians) {
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const newX = cos * (pointX - centerX) + sin * (pointY - centerY) + centerX;
  const newY = cos * (pointY - centerY) - sin * (pointX - centerX) + centerY;
  return [newX, newY];
}
