import { v4 as uuidv4 } from 'uuid';
import { AABB } from './AABB';
import { Point, comparePoints } from './Point';
import { Matrix33 } from './Matrix33';
import { immerable } from 'immer';

/**
 * @enum {PATH_TYPE} - enum to help define the type of Path being created
 *
 * @also
 *    - `DESIGN` - the default Path type, is used in Design Mode
 *    - `REFERENCE` - a "locked" version of a Path type, does not appear in Plan and Review Mode.
 */
/* eslint-disable no-unused-vars */
export enum PATH_TYPE {
  DESIGN = 'Design',
  REFERENCE = 'Reference',
}
/* eslint-enable no-unused-vars */

/**
 * @type {IPath} - a configuration wrapper around {@link Path} to pass through the constructor. See {@link Path} for more impletation details
 * @property {boolean} testClosed - an optional parameter not included in {@link Path} that allows
 * the Path to be tested for "closedness" and make the path `closed` if it is not already
 */
export interface IPath {
  type?: PATH_TYPE;
  id?: string;
  closed?: boolean;
  points?: Point[];
  testClosed?: boolean;
  sourceSvg?:
    | {
        svg: string;
        sourceTransform: Matrix33;
      }
    | boolean;
}

/**
 * @type {Path}
 * @property {PATH_TYPE} type - see more implementation at {@link PATH_TYPE}
 * @property {string} id - the unique ID for the Path
 * @property {boolean} closed - a boolean to represent if the Path is closed or open
 * @property {Point[]} points - an array of points that the Path is made up of. Can be an empty array if extended from a BasePath and is a Polygon
 * @property {object|boolean?} sourceSvg - An optional property that can either be false to represent that there is not a svg string/transform that represents
 * the path or an object that contains the original Svg string and the transforms as a {@link Matrix33} that were applied to that svg
 * @property {AABB} AABB - the AABB of the Path
 * @property {object} pathArray - an optional property of Path that is not instantialized in the constructor but is represented as a `number[][]`
 */
export class Path {
  [immerable] = true;
  type: PATH_TYPE;
  id: string;
  closed: boolean;
  points: Point[];
  sourceSvg?:
    | {
        svg: string;
        sourceTransform: Matrix33;
      }
    | boolean;
  AABB: AABB;
  pathArray?: number[][];

  /**
   * @constructor
   * @param {IPath} path - an optional configuration option to create a Path with values that have been passed in
   *
   * @also
   * If path is not not provided, the Path will default to values:
   *  - id: a newly generated unique UUID
   *  - type: `Design` {@link PATH_TYPE}
   *  - points: an empty array
   *  - AABB: default AABB
   *  - sourceSvg: false
   */
  constructor(path?: IPath) {
    if (path) {
      this.id = path.id || uuidv4();
      this.type = path.type || PATH_TYPE.DESIGN;
      if (path.testClosed && path.points) {
        const p0 = path.points[0];
        const p1 = path.points[path.points.length - 1];
        const closed = path.points.length >= 4 && comparePoints(p0, p1);
        const pathPoints = closed ? path.points.slice(0, -1) : path.points;
        this.points = pathPoints;
        this.closed = closed;
      } else {
        this.points = path.points || [];
        this.closed = path.closed || false;
      }
      this.AABB = new AABB({ points: this.points });
      this.sourceSvg = path?.sourceSvg || false;
    } else {
      this.id = uuidv4();
      this.type = PATH_TYPE.DESIGN;
      this.closed = false;
      this.points = [];
      this.AABB = new AABB();
      this.sourceSvg = false;
    }
  }
}
