/* eslint-disable valid-jsdoc */
import { UnknownAction } from '@reduxjs/toolkit';
import { duplicate } from './../SyncThunks';
import { context, SyncListenerApi } from '../SyncListener';
import { setModal, updateOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { disconnect } from '../SyncThunks';
import { log } from '../SyncLog';
import { FulfilledAction } from '@/Redux/hooks';

const openDuplicateWorkspace = (workspaceId: string) => {
  return window.open(
    `${location.protocol}//${location.host}/?workspaceId=${workspaceId}&duplicate=true`,
    '_blank'
  );
};

export const addDuplicateListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => {
      return duplicate.fulfilled.match(action);
    },
    effect: (
      action: FulfilledAction,
      { dispatch, getState }: SyncListenerApi
    ) => {
      const { id } = action.payload;
      const atSequence = (() => {
        return 'arg' in action.meta ? action.meta.arg : false;
      })();

      const { sync } = getState();
      const { workspace } = sync;

      if (atSequence) {
        log(`Opening duplicated workspace ${id} in new tab`, {
          ...context,
          oldWorkspace: workspace,
        });
        const opened = openDuplicateWorkspace(id);
        if (!opened) {
          log(
            `Unable to open duplicated workspace ${id} in new tab because pop ups are not enabled`,
            {
              ...context,
              oldWorkspace: workspace,
            },
            'debug'
          );
          dispatch(setModal('enable-popups-modal', 'no-trigger'));
          dispatch(updateOptions({ showApplicationMenu: false }));
        }
      } else {
        log(
          `Disconnecting current workspace and opening duplicated workspace ${id}`,
          { ...context, oldWorkspace: workspace }
        );
        dispatch(
          disconnect({
            openWorkspaceAfterDisconnect: id,
          })
        );
      }
    },
  });
};
