import produce, {
  createDraft,
  current,
  Draft,
  enablePatches,
  finishDraft,
  Patch,
  produceWithPatches,
  setAutoFreeze,
} from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { CANVAS_DATA_VERSION, undoBufferSize } from '@/defaults';
import { WritableDraft } from 'immer/dist/internal.js';
import { CanvasState } from '@/Redux/Slices/CanvasSlice';
import jsonpatch, { JsonPatchError, Operation } from 'fast-json-patch';
import { Snapshot } from './SyncConstants';

enablePatches();
setAutoFreeze(true);

export interface PatchSet {
  id: string;
  patches: Patch[];
  inversePatches: Patch[];
  stateVersion: string;
}

export interface UpdatePatch {
  version?: string;
  patches: Patch[];
}

const addIdsToPatchArray = (patchArr: Patch[]) =>
  patchArr.map((p) => ({ ...p, id: uuidv4() }));

export const createPatchSet = (
  patches: Patch[],
  inversePatches: Patch[],
  stateVersion: string
) => ({
  id: uuidv4(),
  patches: addIdsToPatchArray(patches),
  inversePatches: addIdsToPatchArray(inversePatches),
  stateVersion,
});

export const mergePatchSets = (
  oldPatchSet: PatchSet,
  newPatchSet: PatchSet
) => {
  return createPatchSet(
    [...oldPatchSet.patches, ...newPatchSet.patches],
    [...oldPatchSet.inversePatches, ...newPatchSet.inversePatches],
    newPatchSet.stateVersion
  );
};

const handlePatchValidationError = (e: JsonPatchError): boolean => {
  //Immer regression produces patches that change array.length property. Legal JS, but illegal JSONPatch
  //Ignoring these errors, but log everthing else
  if (
    e.name === 'OPERATION_PATH_ILLEGAL_ARRAY_INDEX' &&
    e.operation.op === 'replace' &&
    e.operation.path.endsWith('/length')
  ) {
    return true;
  }
  console.error(e);
  return false;
};

export const validatePatches = (snapshot: Snapshot, patches: any): boolean => {
  const errors = jsonpatch.validate(patches, snapshot);

  if (Array.isArray(errors) && errors.length === 0) {
    return true;
  }

  if (Array.isArray(errors)) {
    if (
      errors
        .map((e) => handlePatchValidationError(e))
        .some((valid) => valid === false)
    ) {
      return false;
    }
  }
  if (errors !== undefined) {
    const stillAnError = handlePatchValidationError(errors);
    return stillAnError;
  }
  return true;
};

export const validateUndoOperation = (
  oldState: CanvasState,
  patches: Patch[],
  nextState: CanvasState
) => {
  const oldStateHistoryLength =
    oldState.undo.past.length + oldState.undo.future.length;

  const newStateHistoryLength =
    nextState.undo.past.length + nextState.undo.future.length;

  if (oldStateHistoryLength !== newStateHistoryLength) {
    return false;
  }

  const undoPatches = patches.filter((p) => p.path[0] === 'undo');

  /* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
  let direction = 0;
  for (const p of undoPatches) {
    if (p.path[1] === 'past') {
      direction--;
    } else {
      direction++;
    }
  }
  /* eslint-enable @typescript-eslint/no-unused-vars, no-unused-vars */

  return true;
};

export const generateHistory = (
  draft: Draft<CanvasState>,
  newPatchSet: PatchSet
): [WritableDraft<CanvasState>, Patch[], Patch[]] => {
  const { past } = draft.undo;
  const patches = [
    { op: 'add', path: ['undo', 'past', '-'], value: newPatchSet },
  ] as Patch[];

  const inversePatches = [
    { op: 'remove', path: ['undo', 'past', `${past.length}`] },
  ] as Patch[];

  past.push(newPatchSet);

  const newUndoBufferSize =
    JSON.stringify(current(draft)).length > 100000000 ? 5 : undoBufferSize;

  //Limit length of undo buffer to undoBufferSize
  if (past.length > newUndoBufferSize) {
    const removeCount = past.length - newUndoBufferSize;

    for (let i = 0; i < removeCount; i++) {
      patches.push({ op: 'remove', path: ['undo', 'past', '0'] });

      //Need to add patches in same order they originally appeared, hence the i in the path.
      inversePatches.push({
        op: 'add',
        path: ['undo', 'past', `${i}`],
        value: current(past[i]),
      });
    }
    //Now remove excess items
    past.splice(0, removeCount);
  }

  //Clear future buffer if adding new action to past
  if (draft.undo.future.length > 0) {
    //First add patches for this operation too
    patches.push({ op: 'replace', path: ['undo', 'future'], value: [] });
    inversePatches.push({
      op: 'replace',
      path: ['undo', 'future'],
      value: current(draft.undo.future),
    }); //Need to call current to get real value of state from the draft proxy object

    //Now update state to match patches
    draft.undo.future = [];
  }

  return [draft, patches, inversePatches];
};

export const generatePatchAndHistory = (
  state: CanvasState,
  stateMutator: Function,
  addUndoPatches: boolean = true,
  manualPatchGenerator: Function | undefined
): [PatchSet, CanvasState] => {
  const [nextCanvasState, canvasPatches, canvasInversePatches] = (() => {
    if (manualPatchGenerator) {
      const next = produce(state, (draft) => {
        stateMutator(draft);
      });
      const [patches, inversePatches] = manualPatchGenerator(state);

      return [next, patches, inversePatches];
    }
    return produceWithPatches(state, (draft) => {
      stateMutator(draft);
    });
  })();

  if (nextCanvasState === undefined) {
    throw new Error(
      `Error while generating patches and next state. Next canvas state is undefined.`,
      {
        cause: {
          state,
          stateMutator,
          addUndoPatches,
          manualPatchGenerator,
        },
      }
    );
  }

  const newCanvasPatchSet = createPatchSet(
    canvasPatches,
    canvasInversePatches,
    state.version
  );

  Object.freeze(newCanvasPatchSet);

  const [syncPatches, finalState] = (() => {
    if (addUndoPatches) {
      const [nextUndoState, undoPatches, undoInversePatches] = generateHistory(
        createDraft(nextCanvasState),
        newCanvasPatchSet
      );

      const undoPatchSet = createPatchSet(
        undoPatches,
        undoInversePatches,
        state.version
      );

      Object.freeze(undoPatchSet);

      const mergedPatchSet = mergePatchSets(newCanvasPatchSet, undoPatchSet);
      const finalUndoState = finishDraft(nextUndoState);
      return [mergedPatchSet, finalUndoState];
    }
    const valid = validateUndoOperation(
      state,
      newCanvasPatchSet.patches,
      nextCanvasState
    );
    if (valid) {
      return [newCanvasPatchSet, nextCanvasState];
    }

    throw new Error(`Unable to validate the undo operation.`);
  })();

  return [syncPatches, finalState];
};

export const applyPatches = (document: Snapshot, patches: Operation[]) => {
  const newDocument = jsonpatch.applyPatch(
    document,
    patches,
    false,
    false
  ).newDocument;

  return newDocument;
};

export const createInitialPatch = (canvas: CanvasState): UpdatePatch[] => {
  return [
    {
      version: CANVAS_DATA_VERSION,
      patches: [
        {
          op: 'replace',
          path: [],
          value: {
            canvas: canvas,
          },
        },
      ],
    },
  ];
};

const convertPatchPathAndCloneValue = (patch: Patch): Operation => {
  const pathString = patch.path.length === 0 ? '' : `/${patch.path.join('/')}`;
  //Cloning values, because read-only values from redux store seem to be slipping into patches.
  //Patch value may be null or undefined, e.g. a delete op
  if (patch.value !== undefined) {
    const value = JSON.parse(JSON.stringify(patch.value));
    return { ...patch, value, path: pathString };
  }
  return { ...patch, path: pathString } as Operation;
};

export const addSliceNameToPatches = (
  patches: PatchSet[],
  sliceName: string = 'canvas'
): UpdatePatch[] => {
  return patches.map((patch) => {
    return {
      patches: patch.patches.map((p) => {
        const pathCopy = [sliceName, ...p.path];
        return { ...p, path: pathCopy };
      }),
    };
  });
};

export const immerToJSONPatches = (
  patches: UpdatePatch[]
): Operation[] | false => {
  try {
    return patches
      .map((po) => po.patches)
      .flat()
      .map((p) => convertPatchPathAndCloneValue(p));
  } catch (err) {
    const patchErrData = {
      message: 'Error converting immerToJSONPatches',
      patchErrDataErr: err,
      t_patches: JSON.stringify(patches),
    };
    console.error(patchErrData);
    return false;
  }
};

export const isInvalidCanvasVersion = (canvas: CanvasState) => {
  const clientCanvasVersion = Number(CANVAS_DATA_VERSION);
  const snapshotCanvasVersion = Number(canvas.version);
  return (
    isNaN(snapshotCanvasVersion) || snapshotCanvasVersion > clientCanvasVersion
  );
};
