import { createAction, Draft, PayloadAction } from '@reduxjs/toolkit';
import { CanvasState } from '../Redux/Slices/CanvasSlice';
import {
  duplicateGroupsById,
  createSvgGroupOnCanvasFromSvg,
  createSvgGroupOnCanvasFromSimplePolygon,
  deleteSvgGroup as deleteSvgGroupOnCanvas,
  updateSvgGroup as updateSvgGroupOnCanvas,
  addSvgGroups as addSvgGroupsOnCanvas,
  updatePathsCutParams as updatePathsCutParamsOnCanvas,
  UpdatePaths,
} from '@/Geometry/CanvasOps';
import { produceDeletePatches } from '@/Sync/DeletePatch';
import { Canvas } from '@/Geometry/sherpa-svg-generator/Canvas';
import { difference } from '@/Geometry/Helpers';
import { applyPatches } from 'immer';
import { Point } from '@/Geometry/sherpa-svg-generator/Point';
import { SvgGroup, Tool } from '@/Geometry/sherpa-svg-generator/SvgGroup';
import { BasePath } from '@/Geometry/sherpa-svg-generator/BasePath';

export interface AddSVGGeometryPayload {
  useGroupSize: boolean;
  position: Point;
  rawSVG: string;
  tool: Tool;
}

export interface AddSimplePolygonGeometryPayload {
  position: Point;
  simplePolygon: BasePath[];
  tool: Tool;
}

export type DeleteSvgGroupPayload =
  | {
      id: string;
    }
  | string[];

export interface UpdateSvgGroupPayload {
  id: string;
  key: string;
  value: any;
}

interface SimplePolygonsParams {
  position: Point;
  simplePolygon: BasePath[];
  tool: Tool;
}

export interface DeleteAndAddPayload {
  deleteGroupIds: string | string[];
  simplePolygonsParams: SimplePolygonsParams | SimplePolygonsParams[];
}

export interface ClearCanvasPayload {
  undoable: boolean;
}

export interface DuplicateSelectedPayload {
  groupIds: string[];
  offset?: Point;
}

export interface SetGroupAnchorPayload {
  id?: string;
  ids: string[];
  anchor?: any;
}

export interface UpdateVersionPayload {
  canvas: CanvasState;
}

export type ActionLookupFunction = {
  stateMutator: Function;
  addUndoPatches: boolean;
  manualPatchGenerator?: Function;
  payloadLog?: Function;
};

export const actionLookup: {
  [key: string]: (...args: any) => ActionLookupFunction;
} = {
  addSVGGeometry: ({ payload }: PayloadAction<AddSVGGeometryPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = createSvgGroupOnCanvasFromSvg(
        draft.canvas,
        payload.useGroupSize,
        payload.position,
        payload.rawSVG,
        payload.tool
      );
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: AddSVGGeometryPayload) => {
      const rawSvgLength = actionPayload.rawSVG.length;

      // TODO: would be nice to upload the rawSVG somewhere to see what users are uploading.
      return {
        tool: actionPayload.tool,
        rawSvg: actionPayload.rawSVG.substring(
          0,
          rawSvgLength > 500 ? 500 : rawSvgLength
        ),
      };
    },
  }),
  addSimplePolygonGeometry: ({
    payload,
  }: PayloadAction<
    AddSimplePolygonGeometryPayload | AddSimplePolygonGeometryPayload[]
  >) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (Array.isArray(payload)) {
        draft.canvas = payload.reduce(
          (canvasAcc, simplePolyParams) =>
            createSvgGroupOnCanvasFromSimplePolygon(
              canvasAcc,
              simplePolyParams.position,
              simplePolyParams.simplePolygon,
              simplePolyParams.tool
            ),
          draft.canvas
        );
      } else {
        // create the new layer
        draft.canvas = createSvgGroupOnCanvasFromSimplePolygon(
          draft.canvas,
          payload.position,
          payload.simplePolygon,
          payload.tool
        );
      }
    },
    addUndoPatches: true,
    payloadLog: (
      actionPayload:
        | AddSimplePolygonGeometryPayload
        | AddSimplePolygonGeometryPayload[]
    ) => {
      if (Array.isArray(actionPayload)) {
        return {
          tool: actionPayload.map((p) => p.tool),
        };
      }
      return {
        tool: actionPayload.tool,
      };
    },
  }),
  deleteSvgGroup: ({ payload }: PayloadAction<DeleteSvgGroupPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (Array.isArray(payload)) {
        draft.canvas = payload.reduce(
          (canvasAcc, deleteId) => deleteSvgGroupOnCanvas(canvasAcc, deleteId),
          draft.canvas
        );
      } else {
        draft.canvas = deleteSvgGroupOnCanvas(draft.canvas, payload.id);
      }
    },
    addUndoPatches: true,
    manualPatchGenerator: (draft: Draft<CanvasState>) => {
      // Draft is a read-only object, want to be able to edit the svgGroup to keep track of
      // index of element being deleted
      const editableDraft = JSON.parse(JSON.stringify(draft));

      //Figure out patches for deleting SvgGroups here and return canvasPatches, canvasInversePatches
      return produceDeletePatches(
        Array.isArray(payload) ? payload : payload.id,
        editableDraft.canvas.svgGroupSet,
        ['canvas', 'svgGroupSet']
      );
    },
    payloadLog: (actionPayload: DeleteSvgGroupPayload) => {
      return actionPayload;
    },
  }),
  updateSvgGroup: ({
    payload,
  }: PayloadAction<UpdateSvgGroupPayload | UpdateSvgGroupPayload[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const updatesArray = Array.isArray(payload) ? payload : [payload];
      draft.canvas = updatesArray.reduce(
        (canvasAcc, updateParams) =>
          updateSvgGroupOnCanvas(
            canvasAcc,
            updateParams.id,
            updateParams.key,
            updateParams.value
          ),
        draft.canvas
      );
    },
    addUndoPatches: true,
    payloadLog: (
      actionPayload: UpdateSvgGroupPayload | UpdateSvgGroupPayload[]
    ) => {
      if (Array.isArray(actionPayload)) {
        return actionPayload.map((p) => ({
          id: p.id,
          key: p.key,
        }));
      }
      return {
        id: actionPayload.id,
        key: actionPayload.key,
      };
    },
  }),
  addSvgGroups: ({ payload }: PayloadAction<SvgGroup[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = addSvgGroupsOnCanvas(draft.canvas, payload);
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: SvgGroup[]) => {
      return actionPayload.map((s) => ({
        tool: s.tool,
      }));
    },
  }),
  deleteGroupIdsAndAddSimplePolygonGeometry: ({
    payload,
  }: PayloadAction<DeleteAndAddPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const { deleteGroupIds, simplePolygonsParams } = payload;
      if (Array.isArray(deleteGroupIds)) {
        draft.canvas = deleteGroupIds.reduce(
          (canvasAcc, deleteId) => deleteSvgGroupOnCanvas(canvasAcc, deleteId),
          draft.canvas
        );
      } else {
        draft.canvas = deleteSvgGroupOnCanvas(draft.canvas, deleteGroupIds);
      }
      if (Array.isArray(simplePolygonsParams)) {
        draft.canvas = simplePolygonsParams.reduce(
          (canvasAcc, simplePolyParams) =>
            createSvgGroupOnCanvasFromSimplePolygon(
              canvasAcc,
              simplePolyParams.position,
              simplePolyParams.simplePolygon,
              simplePolyParams.tool
            ),
          draft.canvas
        );
      } else {
        // create the new layer
        draft.canvas = createSvgGroupOnCanvasFromSimplePolygon(
          draft.canvas,
          simplePolygonsParams.position,
          simplePolygonsParams.simplePolygon,
          simplePolygonsParams.tool
        );
      }
    },
    addUndoPatches: true,
  }),
  undoCanvas: () => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (draft.undo.past.length > 0) {
        const undoPatchSets = draft.undo.past.pop();
        if (undoPatchSets !== undefined) {
          const { inversePatches } = undoPatchSets;
          const newState = applyPatches(draft, inversePatches);
          newState.undo.future.unshift(undoPatchSets);

          return newState;
        }
      }
    },
    addUndoPatches: false,
  }),
  redoCanvas: () => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      if (draft.undo.future.length > 0) {
        const redoPatchSets = draft.undo.future.shift();
        if (redoPatchSets !== undefined) {
          const { patches } = redoPatchSets;
          const newState = applyPatches(draft, patches);
          newState.undo.past.push(redoPatchSets);

          return newState;
        }
      }
    },
    addUndoPatches: false,
  }),
  clearCanvas: ({ payload }: PayloadAction<ClearCanvasPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = new Canvas();
    },
    addUndoPatches: payload.undoable || false,
  }),
  updatePathsCutParams: ({ payload }: PayloadAction<UpdatePaths[]>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas = updatePathsCutParamsOnCanvas(draft.canvas, payload);
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: UpdatePaths[]) => {
      return actionPayload;
    },
  }),
  duplicateSelectedGroups: ({
    payload,
  }: PayloadAction<DuplicateSelectedPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const { canvas: newCanvas } = duplicateGroupsById(
        JSON.parse(JSON.stringify(draft.canvas)),
        payload.groupIds,
        payload.offset
      );

      difference(draft.canvas, newCanvas);

      draft.canvas = newCanvas;
    },
    addUndoPatches: true,
    payloadLog: (actionPayload: DuplicateSelectedPayload) => {
      return actionPayload.groupIds;
    },
  }),
  setSvgGroupAnchor: ({ payload }: PayloadAction<SetGroupAnchorPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      // find the IDs to update
      const ids = (() => {
        if (payload.ids) {
          return payload.ids;
        } else if (payload.id) {
          return [payload.id];
        }
        return [];
      })();

      draft.canvas = ids.reduce(
        (canvas: Canvas, id: string) =>
          updateSvgGroupOnCanvas(canvas, id, 'anchor', payload.anchor),
        draft.canvas
      );
    },
    addUndoPatches: true,
  }),
  setCanvasCustomAnchor: ({ payload }: PayloadAction<boolean>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      draft.canvas.showCustomAnchor = payload;
    },
    addUndoPatches: false,
    payloadLog: (actionPayload: boolean) => {
      return actionPayload;
    },
  }),
  updateVersion: ({ payload }: PayloadAction<UpdateVersionPayload>) => ({
    stateMutator: (draft: Draft<CanvasState>) => {
      const newCanvas = payload.canvas;
      draft.version = newCanvas.version;
      draft.canvas = newCanvas.canvas;
    },
    addUndoPatches: false,
    payloadLog: (actionPayload: UpdateVersionPayload) => {
      return actionPayload.canvas.version;
    },
  }),
};

export type ActionLookup = keyof typeof actionLookup;

export const getActionLookup = (key: ActionLookup) => {
  return actionLookup[key];
};

export const addSVGGeometry = createAction<AddSVGGeometryPayload>(
  'canvas/addSVGGeometry'
);

export const addSimplePolygonGeometry =
  createAction<AddSimplePolygonGeometryPayload>(
    'canvas/addSimplePolygonGeometry'
  );

export const deleteSvgGroup = createAction<DeleteSvgGroupPayload>(
  'canvas/deleteSvgGroup'
);

export const updateSvgGroup = createAction<UpdateSvgGroupPayload>(
  'canvas/updateSvgGroup'
);

export const addSvgGroups = createAction<SvgGroup[]>('canvas/addSvgGroups');

export const deleteGroupIdsAndAddSimplePolygonGeometry =
  createAction<DeleteAndAddPayload>(
    'canvas/deleteGroupIdsAndAddSimplePolygonGeometry'
  );

export const undoCanvas = createAction<void>('canvas/undoCanvas');

export const redoCanvas = createAction<void>('canvas/redoCanvas');

export const clearCanvas =
  createAction<ClearCanvasPayload>('canvas/clearCanvas');

export const updatePathsCutParams = createAction<UpdatePaths[]>(
  'canvas/updatePathsCutParams'
);

export const duplicateSelectedGroups = createAction<DuplicateSelectedPayload>(
  'canvas/duplicateSelectedGroups'
);

export const setSvgGroupAnchor = createAction<SetGroupAnchorPayload>(
  'canvas/setSvgGroupAnchor'
);

export const setCanvasCustomAnchor = createAction<boolean>(
  'canvas/setCanvasCustomAnchor'
);

export const updateVersion = createAction<UpdateVersionPayload>(
  'canvas/updateVersion'
);
