import { localhostDomainRegex } from '@/Utility/token-storage';
import { AxiosResponse } from 'axios';
import jsLogger from 'loggly-jslogger';
import { Snapshot } from './SyncConstants';
import { ErrorTypes } from './SyncError';
import { PatchSet, UpdatePatch } from './PatchGenerator';
import { Operation } from 'fast-json-patch';
import { CanvasState } from '@/Redux/Slices/CanvasSlice';

export type LogLevelType = 'debug' | 'error' | 'log' | 'warn';

let initialized = false;
let enableVerboseLogs = false;
let user: { id: string; email: string } | null;
let logContext: {
  [key: string]: any;
} = {};

const trySanitize = (sanitizeFunction: Function, ...args: unknown[]) => {
  try {
    return sanitizeFunction(args);
  } catch {
    return {};
  }
};

export const sanitizeSnapshotResponse = (s: Snapshot) =>
  trySanitize((snapshot: Snapshot) => {
    const canvasSnapshot = snapshot.canvas ?? null;
    return {
      version: canvasSnapshot?.version,
      canvas: {
        svgGroupSet: canvasSnapshot?.canvas.svgGroupSet.length,
        AABB: canvasSnapshot?.canvas.AABB.size,
        showCustomAnchor: canvasSnapshot?.canvas.showCustomAnchor,
      },
      undo: {
        future: canvasSnapshot?.undo?.future?.length,
        past: canvasSnapshot?.undo?.past?.length,
      },
    };
  }, s);

export const sanitizePatches = (patchesToSanitize: UpdatePatch[]) =>
  trySanitize((patches: UpdatePatch[]) => {
    const patchesWithoutValues = patches.map((p) => ({
      version: p.version,
      patches: p.patches.map((patch) => ({
        op: patch.op,
        path: patch.path.join('/'),
      })),
    }));
    return patchesWithoutValues;
  }, patchesToSanitize);

export const sanitizeOperations = (operations: Operation[]) =>
  trySanitize((ops: Operation[]) => {
    const opsWithoutValues = ops.map((o) => ({
      op: o.op,
      path: o.path,
    }));
    return opsWithoutValues;
  }, operations);

export const sanitizeResponse = (
  httpResponse: AxiosResponse,
  includeExtraData = true
) =>
  trySanitize(
    (response: AxiosResponse, includeData: boolean) => {
      return {
        response: {
          status: {
            status: response.status,
            text: response.statusText,
          },
          ...(includeData
            ? {
                data: response.data,
              }
            : {}),
        },
      };
    },
    httpResponse,
    includeExtraData
  );

export const sanitizeCanvasState = (canvas: CanvasState) =>
  trySanitize((canvasState: CanvasState) => {
    const snapshot = sanitizeSnapshotResponse({
      canvas: canvasState,
    });

    return snapshot;
  }, canvas);

export const sanitizePatchState = (patchSet: PatchSet) =>
  trySanitize((p: PatchSet) => {
    const cleanedPatches = {
      id: p.id,
      stateVersion: p.stateVersion,
      patches: p.patches.map((patch) => ({
        op: patch.op,
        path: patch.path.join('/'),
      })),
      inversePatches: p.inversePatches.map((patch) => ({
        op: patch.op,
        path: patch.path.join('/'),
      })),
    };

    return cleanedPatches;
  }, patchSet);

export const init = (token: string) => {
  const isDevelopment = localhostDomainRegex.test(window.location.host);
  const isStaging = window.location.host.includes('staging.');

  if (token && jsLogger._LTracker) {
    const tag = `studio-sync,${
      isDevelopment ? 'development' : isStaging ? 'staging' : 'production'
    }`;
    jsLogger._LTracker.push({
      logglyKey: token,
      sendConsoleErrors: true,
      tag: tag,
    });
    initialized = true;
  }
};

export const log = (
  message: string,
  context: object,
  level: LogLevelType = 'log'
) => {
  const hasCorrectLevelForUser = () => {
    if (level === 'log' || level === 'error') {
      return true;
    }
    return enableVerboseLogs;
  };
  if (initialized && message && hasCorrectLevelForUser()) {
    const fullContext = {
      level,
      timestamp: `${new Date(Date.now()).toISOString()}`,
      context: {
        message: message,
        ...context,
        ...logContext,
      },
      message,
      user: user ?? {},
    };
    jsLogger._LTracker.push(fullContext);
  }
};

export const logError = (
  message: string,
  context: object,
  error?: ErrorTypes
) => {
  if (initialized) {
    if (message) {
      const fullContext = {
        timestamp: `${new Date(Date.now()).toISOString()}`,
        context: {
          ...context,
          ...logContext,
        },
        error,
      };
      jsLogger._LTracker.push(fullContext);
    }
  }
};

export const setUser = (u: { id: string; email: string }) => {
  if (initialized) {
    user = u;
  }
};

export const enableDebugging = () => {
  if (initialized && user) {
    enableVerboseLogs = true;
  }
};

export const setContextProperty = (context: { key: string; value: any }) => {
  if (initialized) {
    const { key, value } = context;
    logContext[key] = value;
  }
};

export const removeContextProperty = (key: string) => {
  if (initialized) {
    delete logContext[key];
  }
};
