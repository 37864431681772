import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import { useAction } from '@/Actions/useAction';
import { selectTextParams } from '@/Redux/Slices/UISlice';

// components
import { TextEditor } from './TextEditor';
import { withTranslation } from 'react-i18next';

function TextTool(props) {
  const textParams = useSelector(selectTextParams);
  const textEditorModeAction = useAction(ToggleTextEditorModeAction);
  const [text, setText] = useState();

  const addNewTextSvg = function (newTextToolOutput, isEditing) {
    props.onCommit(newTextToolOutput, isEditing);
    textEditorModeAction.exit();
  };

  const ExtendedTextEditor = withTranslation()(TextEditor);

  return (
    <ExtendedTextEditor
      onCommit={addNewTextSvg}
      onCancel={props.onCancel}
      textParams={textParams}
      activeText={text}
      onUpdate={setText}
    />
  );
}

export default TextTool;
