import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';
import SetSvgGroupAnchorAction from '@/Actions/SetSvgGroupAnchor';
import UIModeAction from '@/Actions/UIMode';

export default class SelectAnchorInteraction extends BaseInteraction {
  onPointerUp(event) {
    const isMobile = event.pointerType === 'touch';
    const handle = this.getHandleAt(event.center, {
      filterHidden: false,
      extendSelection: isMobile,
    });
    const ids = this.getSelectedGroupIds();

    // no handle was found
    if (!handle) {
      // if this is over the selected group, stay in mode
      const over = this.getGroupsAt({ ...event.center });
      if (over.find((group) => ids.includes(group.groupId))) {
        return false;
      }

      // activate the anchor editing mode
      const uiMode = this.createAction(UIModeAction);
      uiMode.toDefault();
      return false;
    }

    // replace the anchor
    const anchor = this.createAction(SetSvgGroupAnchorAction);

    // multi-select uses the temporary anchor
    if (ids.length > 1) {
      anchor.setTemporaryAnchor(handle);
    }
    // single target
    else {
      anchor.setAnchor(ids, handle);
    }
  }
}
