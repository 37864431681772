import BaseAction from '@/Actions/BaseAction';
import { setSherpaInitialized } from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeaturesByMode } from '@/Redux/Slices/UISlice';
import {
  updateServerSHA,
  initializeServerEnv,
  getLoginState,
  getShaperLicenses,
  selectShaperHubLoggedIn,
  selectSyncUrl,
  getShaperSubscriptions,
  getShaperHubExternalItem,
  getLocale,
  getShaperHubFiles,
  selectLocale,
  getUser,
  getFirstWorkspace,
} from '@/Redux/Slices/ShaperHubSlice';
import { open, create } from '@/Sync/SyncThunks';
import { checkBrowserForSavedState } from '@/Redux/localStorage';
import ModalActions from './Modal';
import { setI18nLanguage } from '../i18n';
import AlertAction from './Alert';
import { initializeFonts } from '@/Helpers/FontHelper';
import { SvgOps } from '@/Geometry/SvgParser';
import {
  setEnabled,
  setSandboxMode,
  setSyncUrl,
  setWorkspaceIdFromUrlParam,
} from '@/Redux/Slices/SyncSlice';
import { entitlements } from '@/Helpers/Entitlements';

export default class InitializeAppAction extends BaseAction {
  constructor(dispatch, useSelector) {
    super(dispatch, useSelector);
    this.modalAction = this.createAction(ModalActions);
    this.alertAction = this.createAction(AlertAction);
  }

  init = async (studioUrlParams) => {
    const { dispatch } = this;
    await SvgOps.init();
    await dispatch(updateServerSHA());
    await dispatch(initializeServerEnv());
    await dispatch(getShaperLicenses());
    await dispatch(getLocale());

    //Need to check browser for saved state before getLoginState, because reducer will clear any browser saved demo states
    const browserHasSavedState = checkBrowserForSavedState();
    await dispatch(getLoginState());
    const loggedIn = this.useSelector(selectShaperHubLoggedIn);
    const useSync = this.useSelector((state) =>
      selectFeaturesByMode(state, entitlements.SYNC)
    );
    const syncUrl = this.useSelector(selectSyncUrl);
    const locale = this.useSelector(selectLocale);
    await dispatch(getUser());
    await dispatch(getShaperSubscriptions());

    setI18nLanguage(locale.language);

    //TODO - fonts are loaded before ShaperSubscriptions for some reason. Need to fix this
    if (useSync) {
      await dispatch(setSyncUrl(syncUrl));
      if (loggedIn) {
        await dispatch(setEnabled(true));
        const newWorkspacePath = studioUrlParams.path;
        const newWorkspace = studioUrlParams.newWorkspace;
        const workspaceId =
          studioUrlParams.workspaceId ??
          (await this.getMostRecentActiveWorkspaceId());
        const blobId = studioUrlParams.blobId;
        const isDuplicate = studioUrlParams.duplicate;
        const blobImportIntoNewCanvas =
          (blobId !== undefined || blobId === null) &&
          studioUrlParams.workspaceId === undefined;

        await dispatch(getFirstWorkspace());

        //Create new workspace if:
        // 1. browser has saved state, create workspace and sync with current client state. It has already been purged from localStorage and sessionStorage at this point.
        // 2. newWorkspace flag is set
        // 3. If workspaceId is not provided and there are no recently modified workspaces to open.

        if (
          browserHasSavedState === true ||
          newWorkspace ||
          blobImportIntoNewCanvas ||
          !workspaceId
        ) {
          await dispatch(
            create({
              ...(newWorkspacePath && { path: newWorkspacePath }),
              ...(blobId && { blobId }),
            })
          );
        } else {
          await dispatch(
            open({
              workspaceId,
              ...(blobId && { blobId }),
            })
          );
          await dispatch(getShaperHubExternalItem(workspaceId));
          if (isDuplicate) {
            await this.alertAction.setDuplicateAlert();
          }
        }
      }
    }
    if (!useSync || !loggedIn) {
      //Sync disabled or if logged out, then set status to WORKSPACE_EDIT to enable sandbox mode
      await dispatch(setWorkspaceIdFromUrlParam(studioUrlParams.workspaceId));
      await dispatch(setSandboxMode());
      await this.modalAction.openSignInModal();
    }
    await dispatch(setSherpaInitialized());
    await initializeFonts();
  };

  getMostRecentActiveWorkspaceId = async () => {
    let recentWorkspaceId;

    try {
      const shaperHubPromise = this.dispatch(getShaperHubFiles()).unwrap();
      return shaperHubPromise
        .then((files) => {
          //Most recent modified is first
          const sortedFiles = files
            .filter((f) => f.externalItemType === 'studio-workspace')
            .sort((a, b) =>
              new Date(a.modified).getTime() > new Date(b.modified).getTime()
                ? -1
                : 1
            );
          recentWorkspaceId =
            sortedFiles.find((file) => file?.externalItemId)?.externalItemId ??
            null;

          if (recentWorkspaceId === null) {
            return null;
          }
          return recentWorkspaceId;
        })
        .catch(() => {
          return null;
        });
    } catch {
      return Promise.resolve(null);
    }
  };
}
