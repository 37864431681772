import React from 'react';

// components
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';

// helpers
import { getEventAttributeValue } from '@/Utility/react';
import classNames from 'classnames';

export default function ModeSelectionTab(props) {
  const {
    modes,
    name,
    icon,
    selected: isSelected,
    expanded: isExpanded,
    hasMenu,
    i18nKey,
    dataCy,
  } = props;

  // the first mode is considered to be
  // the default activation mode
  const [mode] = modes;

  // handles selecting a tab
  function onActivateMode(event) {
    const newMode = getEventAttributeValue(event, 'data-mode');

    // handles toggle/expanding of a mode
    if (isSelected) {
      props.onToggleMode(newMode);
    } else {
      props.onActivateMode(newMode);
    }
  }

  const expander = `mode-tab-${isExpanded ? 'expanded' : 'collapsed'}`;
  const tabCx = classNames('mode-selection-menu--mode-tab', {
    selected: isSelected,
  });

  return (
    <div
      className={tabCx}
      onClick={onActivateMode}
      data-mode={mode}
      data-cy={dataCy}
    >
      <Icon className='mode-selection-menu--mode-tab--icon' icon={icon} />
      <div className='mode-selection-menu--mode-tab--text'>
        <TranslationText i18nKey={i18nKey}>{name}</TranslationText>
      </div>
      {hasMenu && (
        <Icon
          className='mode-selection-menu--mode-tab--expander'
          icon={expander}
        />
      )}
    </div>
  );
}
