/* eslint-disable valid-jsdoc */
import { UnknownAction } from '@reduxjs/toolkit';

import { setWorkspace } from '../../Redux/Slices/SyncSlice';
import { updateOptions } from '@/Redux/Slices/SherpaContainerSlice';
import {
  getShaperHubExternalItem,
  setAlert,
} from '@/Redux/Slices/ShaperHubSlice';
import { ALERT_TYPES } from '@/defaults';
import { log } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { addInitialPatch } from './AddInitialPatch';
import { create } from '../SyncThunks';
import { FulfilledAction } from '@/Redux/hooks';

/**
 * Listener for the create thunk so that we can send an initial patch once we've received the
 * newly created workspace.
 */
export const addCreateListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => {
      return create.fulfilled.match(action);
    },
    effect: async (action: FulfilledAction, listenerApi: SyncListenerApi) => {
      const { dispatch } = listenerApi;
      const { blobId } = action.meta.arg;
      const workspace = action.payload;
      const { id } = workspace;
      dispatch(setWorkspace(workspace));

      log(`Fulfilled creating workspace ${id}`, {
        ...context,
        workspace,
        action,
      });

      await addInitialPatch(listenerApi, blobId);
      if (!blobId) {
        dispatch(
          setAlert({
            msg: 'New Design Created',
            i18nKey: 'new-design-created',
            type: ALERT_TYPES.DEFAULT_DISMISSIBLE,
            icon: 'alert-warning',
            duration: 3000,
          })
        );
      }
      dispatch(getShaperHubExternalItem(id));
      dispatch(updateOptions({ showCustomAnchor: false }));
    },
  });
};
