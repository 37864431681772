import * as DOM from '@/Helpers/DOM';

import { isPointInAABB, transform as AABBTransform } from '@/Geometry/AABBOps';
import { getId, getAABB } from '@/Geometry/BasePathOps';
import { transform } from '@/Geometry/PointOps';
import { Point } from '@/Geometry/sherpa-svg-generator/Point';
import { PATH_TYPE } from '@/Geometry/sherpa-svg-generator/Path';
import { Shape, SvgGroup } from '@/Geometry/sherpa-svg-generator/SvgGroup';
import { Matrix33 } from '@/Geometry/sherpa-svg-generator/Matrix33';
const INITIAL_BOUNDING_BOX_PADDING = 1;

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d', { willReadFrequently: true })!;
canvas.width = canvas.height = 1;

// // DEBUGGING HELPER
// canvas.width = window.innerWidth;
// canvas.height = window.innerHeight;
// document.body.appendChild(canvas);
// Object.assign(canvas.style, {
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	zIndex: 99999,
// 	top: '0',
// 	right: '0'
// });

interface HitTestOptions {
  extendBy?: number;
  hitDetectFill?: boolean;
  useBoundingBox?: boolean;
  useCutPath?: boolean;
}

// handles checking hit detection in a document
export default class HitDetection {
  groups: SvgGroup[];
  // registers all instances of a layer
  constructor(groups: SvgGroup[]) {
    this.groups = groups;
  }

  // performs a hit test and returns all matching layers
  hitTest(
    x: number,
    y: number,
    options: HitTestOptions = {},
    viewportState: { screenToCanvasTransform: Matrix33 }
  ) {
    //TODO - refactor out DOM

    //Transform hit point from screen space to canvas space
    const { useBoundingBox, useCutPath } = options;
    const hitPtScreenSpace = new Point(x, y);
    const { screenToCanvasTransform } = viewportState;
    const hitPtCanvasSpace = transform(
      hitPtScreenSpace,
      screenToCanvasTransform
    );
    const usePreciseHitDetection = useCutPath || useBoundingBox !== true;

    const pathHits = [];

    // start checking each group
    for (const group of this.groups) {
      //Does hitPt intersect group's AABB? If not, skip all the paths in this group.

      if (!usePreciseHitDetection) {
        if (
          !isPointInAABB(
            group.transformedAABB,
            hitPtCanvasSpace,
            INITIAL_BOUNDING_BOX_PADDING
          )
        ) {
          //No hit, so skip whole group
          continue;
        }
      }

      //Because group intersects hitPt, test all paths in group against hitPt

      // check each sub layer starting in reverse order
      for (const thisBasePath of group.basePathSet) {
        const basePathId = getId(thisBasePath);
        const isReference = group.type === PATH_TYPE.REFERENCE;

        //1)Test this cutPath's AABB against hit point.
        //2) If there is a hit, follow-up with more accurate DOM-based test.

        //Test 1:
        // ToolPaths are hidden in canvas mode, so use base path only for testing hits

        //basePath needs to be scaled, rotated, translated by parent group's transformation to get to canvas space.

        const basePathAABBCanvasSpace = AABBTransform(
          getAABB(thisBasePath),
          group.TRSMtx
        );

        if (!usePreciseHitDetection) {
          if (
            !isPointInAABB(
              basePathAABBCanvasSpace,
              hitPtCanvasSpace,
              INITIAL_BOUNDING_BOX_PADDING
            )
          ) {
            //No hit, so skip path and test 2
            continue;
          }
        }

        let isPreciseHit = false;

        //Test 2: test hitPt against actual path geometry
        const isText = group.tool?.type === Shape.TEXT;

        // TODO: for some reason, text gets captured every time - this is a
        // quick fix to just the screen bounds for now. We want to revisit this
        if (isText && !usePreciseHitDetection) {
          const el = document.getElementById(`layer-${group.id}`);
          const { left, right, top, bottom } = el!.getBoundingClientRect();

          if (x < left || x > right || y < top || y > bottom) {
            isPreciseHit = false;
          }
        }

        // check for complex shape hit detection, if required
        if (usePreciseHitDetection) {
          //NB: could also transform pathAABB to screen space
          // get the layer boundaries

          // TODO: inside and outside paths don't always get captured
          // this uses the base path and the larger toolWidth path to
          // ensure paths are captured for now
          // const paths = useCutPath ? ['cutPreviewOnline', 'basePath'] : ['basePath'];
          const paths = ['basePath'];
          for (const path of paths) {
            const el = DOM.getPathById(
              group.id,
              basePathId,
              path
            ) as SVGGraphicsElement;

            // found an element to work with
            if (el) {
              isPreciseHit = HitDetection.hitTestSVG(el, x, y, {
                stroke: options.extendBy,
                alwaysFill: isReference ? false : options.hitDetectFill,
              });
            }

            // no need to check more
            if (isPreciseHit) {
              break;
            }
          }
        }

        // include if this is a hit
        if (isPreciseHit) {
          pathHits.push({ pathId: basePathId, groupId: group.id });
        }
      }
    }

    // match SVG order
    pathHits.reverse();

    return pathHits;
  }

  // quick test for SVG hit testing
  static hitTestSVG(
    el: SVGGraphicsElement,
    x: number,
    y: number,
    { stroke = 0, alwaysFill = false } = {}
  ) {
    const { a, b, c, d, e, f } = el.getScreenCTM()!;
    canvas.width = canvas.height;
    ctx.setTransform(a, b, c, d, e - x, f - y);

    // apply the path
    const render = el.getAttribute('d');
    if (render) {
      const path = new Path2D(render);
      if (stroke) {
        ctx.lineWidth = stroke;
        ctx.stroke(path);
      }

      if (!stroke || alwaysFill) {
        ctx.lineWidth = 0;
        ctx.fill(path);
      }
    }
    // just check the box itself - for now
    // this assumes always using center point
    else {
      const { width, height } = el.getBBox();
      ctx.fillRect(width * -0.5, height * -0.5, width, height);
    }

    // stroke to increase clickable area
    // needs calculated line width
    // ctx.lineWidth = ???
    // ctx.stroke(path);

    // check if there's any opacity on this spot
    const { data } = ctx.getImageData(0, 0, 1, 1);
    const [, , , pixel] = data;
    return pixel > 0;
  }
}
