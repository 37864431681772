import React from 'react';
import { useSelector } from 'react-redux';

// slices
import { selectToFormattedDisplayUnitValue } from '@/Redux/Slices/SherpaContainerSlice';

// helpers
import { rotateAroundPoint } from '@/Utility/rotation';

// components
import OverlayElement from './OverlayElement';

export default function WorkspaceOriginLabels(props) {
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const { ui, boundingBox } = props;

  // don't show while rotating
  if (ui.isRotating || ui.isResizing) {
    return null;
  }

  // computed
  const { anchor, aabb, centroid } = boundingBox;
  const { left, right, top, bottom } = aabb;
  const [x, y] = rotateAroundPoint(
    centroid.x,
    centroid.y,
    anchor.x,
    anchor.y,
    boundingBox.rotation
  );

  // renders a label
  function renderWorkspaceOriginLabel(
    axis,
    minEdge,
    maxEdge,
    min,
    max,
    distance,
    alt
  ) {
    // determine the alt axis
    const invert = axis === 'y' ? -1 : 1;
    const value = toFormattedDisplayUnitValue(distance * invert, {
      includeUnit: true,
    });
    const display = `${axis.toUpperCase()}: ${value}`;
    const farSide = distance < 0;

    // determine the position
    const placement = `on-${farSide ? maxEdge : minEdge}`;
    const position = {
      [axis]: farSide ? max : min,
      [axis === 'x' ? 'y' : 'x']: alt,
    };

    return (
      <OverlayElement ui={ui} {...position}>
        <div className={`workspace-origin--label ${placement}`}>{display}</div>
      </OverlayElement>
    );
  }

  return (
    <>
      {renderWorkspaceOriginLabel('x', 'left', 'right', left, right, x, y)}
      {renderWorkspaceOriginLabel('y', 'top', 'bottom', top, bottom, y, x)}
    </>
  );
}
