import React from 'react';

// this will eventually do more
export default function Group(props) {
  return props.className ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <>{props.children}</>
  );
}
