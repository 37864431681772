import {
  clipperBasePathToShaperPath,
  shaperBasePathToScaledClipperPath,
} from './BasePathOps';
import { ClipperOps, ClipperOpsWorkerProxy, ClipperPath } from './ClipperOps';
import { BasePath } from './sherpa-svg-generator/BasePath';

function simplePolygonDifference(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath
) {
  const clipperPathsA = shaperBasePathToScaledClipperPath(
    simplePolygonA
  ) as ClipperPath[];
  const clipperPathsB = shaperBasePathToScaledClipperPath(
    simplePolygonB
  ) as ClipperPath[];

  const clipperPathResults = ClipperOps.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function simplePolygonIntersection(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath
) {
  const clipperPathsA = shaperBasePathToScaledClipperPath(
    simplePolygonA
  ) as ClipperPath[];
  const clipperPathsB = shaperBasePathToScaledClipperPath(
    simplePolygonB
  ) as ClipperPath[];

  const clipperPathResults = ClipperOps.clipperPathsIntersection(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function simplePolygonUnion(
  simplePolygonA: BasePath,
  simplePolygonB: BasePath,
  simplifyPolys = false
) {
  const clipperPathsA =
    simplePolygonA !== undefined
      ? (shaperBasePathToScaledClipperPath(simplePolygonA) as ClipperPath[])
      : [];
  const clipperPathsB =
    simplePolygonB !== undefined
      ? (shaperBasePathToScaledClipperPath(simplePolygonB) as ClipperPath[])
      : [];

  const clipperPathResults = ClipperOps.clipperPathsUnion(
    clipperPathsA,
    clipperPathsB,
    simplifyPolys
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function NSimplePolygonUnionsSync(simplePolygons: BasePath[]): BasePath[] {
  const clipperPaths = simplePolygons.map((sp) =>
    shaperBasePathToScaledClipperPath(sp)
  ) as ClipperPath[][];

  const clipperPathResults = clipperPaths.reduce(
    (unionAcc, cp, idx) => {
      //First pass is unionAcc unioned with itself, so skip
      if (idx === 0) {
        return unionAcc;
      }

      //Converts array of {outerClipperPath: {}, holeClipperPaths: []} to flat array of clipperPaths.
      const unionAccArr = unionAcc
        .map((csp) => [csp.outerClipperPath, ...csp.holeClipperPaths])
        .flat();

      //Returns array of {outerClipperPath: {}, holeClipperPaths: []}
      return ClipperOps.clipperPathsUnion(unionAccArr, cp, true);
    },
    [
      {
        outerClipperPath: clipperPaths[0][0],
        holeClipperPaths: clipperPaths[0].slice(1),
      },
    ]
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function NSimplePolygonUnionsAsync(
  simplePolygons: BasePath[]
): Promise<BasePath[]> {
  const clipperPaths = simplePolygons.map((sp) =>
    shaperBasePathToScaledClipperPath(sp)
  ) as ClipperPath[][];

  return clipperPaths
    .reduce((prevPromise, cp, idx) => {
      //Result of first iteration is the initial simplepolygon, restructured wrapped in a promise below
      if (idx === 0) {
        return prevPromise;
      }

      return prevPromise.then((unionAcc) => {
        const flatSPs = unionAcc
          .map((csp) => [csp.outerClipperPath, ...csp.holeClipperPaths])
          .flat();

        //Returns array of {outerClipperPath: {}, holeClipperPaths: []}
        const returnCP = Array.isArray(cp) ? cp : [cp];
        const openReturnCP = returnCP.map((r) => !r.closed);
        if (openReturnCP.length > 0) {
          return ClipperOpsWorkerProxy.clipperPathsUnion(
            returnCP,
            flatSPs,
            true
          );
        }
        return ClipperOpsWorkerProxy.clipperPathsUnion(flatSPs, returnCP, true);
      });
    }, Promise.resolve([{ outerClipperPath: clipperPaths[0][0], holeClipperPaths: clipperPaths[0].slice(1) }]))
    .then((clipperPathsResults) =>
      clipperPathsResults.map((cpr) =>
        clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
      )
    );
}

function NSimplePolygonDifferencesSync(
  simplePolygonsA: BasePath,
  simplePolygonsB: BasePath[]
): BasePath[] {
  const clipperPathsA = shaperBasePathToScaledClipperPath(
    simplePolygonsA
  ) as ClipperPath[];
  const clipperPathsB = simplePolygonsB
    .map((sp) => shaperBasePathToScaledClipperPath(sp))
    .flat();

  const clipperPathResults = ClipperOps.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  );

  return clipperPathResults.map((cpr) =>
    clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
  );
}

function NSimplePolygonDifferencesAsync(
  simplePolygonsA: BasePath,
  simplePolygonsB: BasePath[]
): Promise<BasePath[]> {
  const clipperPathsA = shaperBasePathToScaledClipperPath(
    simplePolygonsA
  ) as ClipperPath[];
  const clipperPathsB = simplePolygonsB
    .map((sp) => shaperBasePathToScaledClipperPath(sp))
    .flat();

  return ClipperOpsWorkerProxy.clipperPathsDifference(
    clipperPathsA,
    clipperPathsB
  ).then(
    (
      clipperPathsResults: {
        outerClipperPath: ClipperPath;
        holeClipperPaths: ClipperPath[];
      }[]
    ) =>
      clipperPathsResults.map((cpr) =>
        clipperBasePathToShaperPath(cpr.outerClipperPath, cpr.holeClipperPaths)
      )
  );
}

function cleanSimplePolygon(dirtySimplePoly: BasePath) {
  const clipperPaths = shaperBasePathToScaledClipperPath(
    dirtySimplePoly
  ) as ClipperPath[];
  const cleanOuterClipperPath = ClipperOps.cleanClipperPolygon(
    clipperPaths[0].points
  );
  clipperPaths.splice(0, 1);
  const cleanInnerClipperPaths = clipperPaths.map((cp) => ({
    closed: true,
    points: ClipperOps.cleanClipperPolygon(cp.points),
  }));
  return clipperBasePathToShaperPath(
    new ClipperPath(true, cleanOuterClipperPath),
    cleanInnerClipperPaths
  );
}

function toPathArray(simplePolygon: BasePath): BasePath[] {
  return [
    simplePolygon.outerPath,
    ...(simplePolygon.holePaths ?? []),
  ] as BasePath[];
}

export {
  simplePolygonDifference,
  simplePolygonIntersection,
  simplePolygonUnion,
  NSimplePolygonDifferencesSync,
  NSimplePolygonDifferencesAsync,
  NSimplePolygonUnionsSync,
  NSimplePolygonUnionsAsync,
  cleanSimplePolygon,
  toPathArray,
};
