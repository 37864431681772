import { cloneDeep } from 'lodash';
import { createSvg, getShearedRectAABB } from '@/Helpers/ShapeCreator';
import {
  SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE,
  SHAPE_TYPE_ROUNDED_RECTANGLE,
  SHAPE_TYPE_POLYGON,
} from '@/Constants/UI';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { updateSvgGroup } from '@/CanvasContainer/CanvasActions';

import { createTextSvg } from '@/Helpers/TextCreator';
import UIState from '@/UILayer/State/UIState';
import { updateKeyAndValue } from '@/Geometry/SvgGroupOps';

export default class ApplySvgChangeSetAction {
  constructor(dispatch, useSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  async apply(changeSet, { preview = false } = {}) {
    const { dispatch, useSelector } = this;
    const svgGroups = useSelector(selectSvgGroupSet);
    const updates = [];

    // process each change
    const ids = Object.keys(changeSet || {});
    for (const identity of ids) {
      const id = identity;
      const group = svgGroups.find((item) => item.id === id);
      const params = changeSet[identity];
      const change = { id, value: params };

      // identify the change being made
      const hasToolParams = 'toolParams' in params;
      const hasTextParams = 'textParams' in params;
      const hasTranslate = 'deltaPos' in params || 'deltaPosition' in params;
      const hasPosition = 'x' in params && 'y' in params;
      const hasStretch = 'stretchMtx' in params;
      const hasRotation = 'deltaRotation' in params || 'setRotation' in params;
      const mustRegenerate =
        (hasStretch &&
          SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE.includes(
            group.tool.type
          )) ||
        hasToolParams ||
        hasTextParams;

      // determine the required change
      if (mustRegenerate) {
        change.key = 'toolSvg';
        change.value = {
          ...change.value,
          ...this._regeneratePath(group, group.tool.type, params),
        };

        // couple cleanup things
        delete change.textParams;
        delete change.toolParams;
      }
      // a normal path
      else {
        change.key = hasPosition
          ? 'relative_position'
          : hasTranslate && hasRotation
          ? 'rotation:relative_position'
          : hasTranslate && hasStretch
          ? 'relative_stretch:relative_position'
          : null;
      }

      // finally, save the record
      if (!change.key) {
        console.error(
          `Change for ${id} did not generate a record in ApplySvgChangeSet`
        );
      } else {
        updates.push(change);
      }
    }

    if (preview) {
      UIState.update({
        groups: updates.reduce((obj, update) => {
          const group = svgGroups.find((item) => item.id === update.id);
          const groupClone = cloneDeep(group);
          updateKeyAndValue({
            svgGroup: groupClone,
            key: update.key,
            value: update.value,
          });
          return {
            ...obj,
            [update.id]: {
              data: groupClone,
            },
          };
        }, {}),
      }).apply();
    } else {
      // perform the update
      return dispatch(updateSvgGroup(updates));
    }
  }

  _regeneratePath(group, type, change) {
    switch (type) {
      case SHAPE_TYPE_ROUNDED_RECTANGLE:
      case SHAPE_TYPE_POLYGON:
        return this._regenerateShape(group, change);
      case 'text-insert':
        return this._regenerateText(group, change);
      default:
        console.error(`No path regeneration process found for ${type}`);
    }
  }

  _regenerateText(group, change) {
    const text = change.textParams.text || group.tool.params.text;
    const fontDisplayName =
      change.textParams.fontDisplayName || group.tool.params.fontDisplayName;
    const fontDisplayStyle =
      change.textParams.fontDisplayStyle || group.tool.params.fontDisplayStyle;
    const forceOpenPaths =
      change.textParams.fontDisplayStyle.forceOpenPaths || false;
    return createTextSvg(
      text,
      fontDisplayName,
      fontDisplayStyle,
      forceOpenPaths
    );
  }

  _regenerateShape(group, change) {
    const params = change.toolParams || group.tool.params;
    const stretchMtx = change.stretchMtx || group.stretchMtx;
    const rotateMtx = change.rotateMtx || group.rotateMtx;
    const stretchedSize =
      group.tool.type === SHAPE_TYPE_ROUNDED_RECTANGLE
        ? { x: change.toolParams.width, y: change.toolParams.height }
        : getShearedRectAABB(params, stretchMtx, rotateMtx);

    const shapeParams = {
      type: group.tool.type,
      params: {
        ...params,
        width: stretchedSize.x,
        height: stretchedSize.y,
      },
    };

    const generated = createSvg({
      type: shapeParams.type,
      ...shapeParams.params,
    });

    return {
      rawSVG: generated.svg,
      tool: shapeParams,
      deltaPos: change.deltaPos,
    };
  }
}
