import UIState from '@/UILayer/State/UIState';
import {
  selectSVGViewbox,
  selectViewport,
  setPosition,
} from '@/Redux/Slices/ViewportSlice';

export default class PanAndZoomViewportAction {
  constructor(dispatch, useSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    // setup initial state
    const viewbox = useSelector(selectSVGViewbox);

    const { x, y } = viewbox;
    this.TLCornerX = x;
    this.TLCornerY = y;
  }

  // applies translations and updated existing layers
  panBy(dxPixels, dyPixels) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);

    const scale = viewport.screenToCanvasTransform[0][0];

    const dxCanvas = scale * dxPixels;
    const dyCanvas = scale * dyPixels;
    const width =
      viewport.canvasViewbox.maxPoint.x - viewport.canvasViewbox.minPoint.x;
    const height =
      viewport.canvasViewbox.maxPoint.y - viewport.canvasViewbox.minPoint.y;

    if (width > 0 && height > 0) {
      this.dxCanvas = dxCanvas;
      this.dyCanvas = dyCanvas;

      this.lastUpdate = {
        x: dxCanvas,
        y: dyCanvas,
      };

      // notify the UI layer of the change
      UIState.apply((ui) => {
        ui.viewport = this.lastUpdate;
      });
    }
  }

  resolve() {
    const { useSelector, dispatch } = this;
    const viewport = useSelector(selectViewport);
    const svgViewBox = useSelector(selectSVGViewbox);

    UIState.reset();

    const newX = this.dxCanvas + svgViewBox.x;
    const newY = this.dyCanvas + svgViewBox.y;

    // //Second, we pan viewport by changing TL corner, but state stores viewport center, so we need to return new center point
    const width =
      viewport.canvasViewbox.maxPoint.x - viewport.canvasViewbox.minPoint.x;
    const height =
      viewport.canvasViewbox.maxPoint.y - viewport.canvasViewbox.minPoint.y;

    // apply
    const position = {
      x: newX + width / 2,
      y: newY + height / 2,
    };

    dispatch(setPosition(position));
  }
}
