import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import InsideSvg from './Styles/Icons/inside.svg?react';
import OutsideSvg from './Styles/Icons/outside.svg?react';
import GuideSvg from './Styles/Icons/guide.svg?react';
import PocketSvg from './Styles/Icons/pocket-pattern.svg?react';
import OnlineSvg from './Styles/Icons/online.svg?react';
import { CutType } from './Geometry/sherpa-svg-generator/CutParams';
import { localhostDomainRegex } from './Utility/token-storage';

//Need this to access defaults from a webworker without webpack barking at me.
self.$RefreshReg$ = () => {};
self.$RefreshSig$$ = () => () => {};

export const CANVAS_DATA_VERSION = '2.0';

const Iconizer = (iconSvg) => (
  <SvgIcon component={iconSvg} viewBox='0 0 16 16' />
);

export const DEFAULT_USER_PREFERENCES = {
  disableSmartAlignment: false,
};

export const defaultDocumentName = 'Untitled';

export const PIXELS_PER_INCH = 3.77953;
export const DEFAULT_GRID_SIZE = 25.4;

// export const mixedSelectionCutParam = {
//   key: 'mixed',
//   label: 'MIXED'
// };

// export const cutTypesIcons = {
//   'inside': Iconizer(InsideSvg),
//   'outside': Iconizer(OutsideSvg),
//   'online': Iconizer(OnlineSvg),
//   'pocket': Iconizer(PocketSvg),
//   'guide': Iconizer(GuideSvg),
//   'mixed': Iconizer(GuideSvg),
// };

export const cutTypes = [
  {
    key: 'online',
    keyEnum: CutType.ONLINE,
    label: 'On Line',
    icon: Iconizer(OnlineSvg),
  },
  {
    key: 'outside',
    keyEnum: CutType.OUTSIDE,
    label: 'Outside',
    icon: Iconizer(OutsideSvg),
  },
  {
    key: 'inside',
    keyEnum: CutType.INSIDE,
    label: 'Inside',
    icon: Iconizer(InsideSvg),
  },
  {
    key: 'pocket',
    keyEnum: CutType.POCKET,
    label: 'Pocket',
    icon: Iconizer(PocketSvg),
  },
  {
    key: 'guide',
    keyEnum: CutType.GUIDE,
    label: 'Guide',
    icon: Iconizer(GuideSvg),
  },
];

export const disabledCutTypesForOpenPaths = ['outside', 'inside', 'pocket'];

export const imperialToolDiams = [
  { key: 'IN0.125', value: '0.125in', label: '0.125in' },
  { key: 'IN0.25', value: '0.25in', label: '0.25in' },
  { key: 'IN0.002', value: '0.002in', label: 'Engrave' },
];

export const metricToolDiams = [
  { key: 'MM3', units: 'mm', value: '3.0mm', label: '3mm' },
  { key: 'MM5', units: 'mm', value: '5.0mm', label: '5mm' },
  { key: 'MM8', units: 'mm', value: '8.0mm', label: '8mm' },
  { key: 'IN0.002', value: '0.002in', label: 'Engrave' },
];

export const defaultToolDiams = {
  imperial: imperialToolDiams[0],
  metric: metricToolDiams[0],
};

export const defaultCutDepths = {
  imperial: '0in',
  metric: '0mm',
};

export const defaultCutOffsets = {
  imperial: '0in',
  metric: '0mm',
};

export const defaultCutType = cutTypes[0];

export const defaultSvgGroupSize = {
  metric: 25,
  imperial: 25.4,
};

//SVG import
export const svgTesselationTolerance = 0.1; //0.1 mm
export const maxPathSamples = 5000;
export const pathCloseTolerance = 0.1;
export const pathJoinTolerance = 0.1;

export const canvasScale = {
  minScale: 0.1,
  maxScale: 4.0,
  scaleInc: 0.1,
};

export const unitsPrecision = {
  imperialPrecision: 3,
  metricPrecision: 3,
  degreePrecision: 1,
};

//Functions for controlling opacity of grid
const constantOpacity = function () {
  return 1;
};

export const inGridParams = [
  {
    level: 0.0625,
    levelLabel: '1/16',
    minScale: 10,
    maxScale: Number.POSITIVE_INFINITY,
    fill: 'red',
    opacityFcn: constantOpacity,
  },
  {
    level: 1,
    minScale: 1,
    maxScale: 10,
    fill: 'lime',
    opacityFcn: constantOpacity,
  },
  {
    level: 12,
    minScale: 0.4,
    maxScale: 1,
    fill: 'blue',
    opacityFcn: constantOpacity,
  },
  {
    level: 36,
    minScale: 0,
    maxScale: 0.4,
    fill: 'blue',
    opacityFcn: constantOpacity,
  },
];

export const mmGridParams = [
  {
    level: 1,
    minScale: 22,
    maxScale: Number.POSITIVE_INFINITY,
    fill: 'red',
    opacityFcn: constantOpacity,
  },
  {
    level: 10,
    minScale: 2.5,
    maxScale: 22,
    fill: 'lime',
    opacityFcn: constantOpacity,
  },
  {
    level: 100,
    minScale: 0.3,
    maxScale: 2.5,
    fill: 'brown',
    opacityFcn: constantOpacity,
  },
  {
    level: 1000,
    minScale: 0,
    maxScale: 0.3,
    fill: 'purple',
    opacityFcn: constantOpacity,
  },
];

export const defaultSecretOptions = {
  gridSnappingThreshold: 3,
  smartSnappingThreshold: 5,
  guideLineWidth: 1.25,
  guideLineColor: 'FF274E',
  guideUnselectedPathWidth: 1.5,
  guideUnselectedPathColor: 'EBB52E',
  guideUnselectedHoverPathWidth: 12,
  guideUnselectedHoverPathColor: 'E89E26',
  guideGlowPathWidth: 12,
  guideGlowPathColor: 'E27718',
  guideSelectedPathWidth: 2,
  guideSelectedPathColor: 'E27718',
  guideSelectedHoverPathWidth: 2,
  guideSelectedHoverPathColor: 'E27718',
  objectUnselectedPathWidth: 1.25,
  objectUnselectedPathColor: '72757E',
  objectUnselectedHoverPathWidth: 2.25,
  objectUnselectedHoverPathColor: '6B93FF',
  objectSelectedPathWidth: 3,
  objectSelectedPathColor: '2C65FF',
  objectSelectedHoverPathWidth: 3.5,
  objectSelectedHoverPathColor: '0546F6',
  positionColor: '161514',
  positionStrokeWidth: 1.5,
  positionDashWidth: 6,
  positionGapWidth: 6,
  showWorkspaceSettings: false,
  language: 'en',
};

export const defaultSubscriptionOptions = {
  isSubscriber: false,
  isTrial: false,
  isExpired: false,
};

export const MODAL_TYPES = Object.freeze({
  NO_MODAL: 'no-modal',
  EDU_MODAL: 'edu-modal',
  SIGNIN_MODAL: 'signin-modal',
  SUCCESS_MODAL: 'success-modal',
  VIEWONLY_MODAL: 'view-only-modal',
  OFFLINE_MODAL: 'offline-modal',
  ENABLE_POPUPS_MODAL: 'enable-popups-modal',
  SYNC_MODAL: 'sync-modal',
  INVALID_MODAL: 'invalid-modal',
  REFERENCE_MODAL: 'reference-modal',
  INVALID_CHARACTERS_MODAL: 'invalid-characters-modal',
});

export const MODAL_TRIGGER_TYPES = Object.freeze({
  TRY_DEMO: 'try-demo',
  NO_TRIGGER: 'no-trigger',
});

export const defaultWorkspace = {
  name: 'Untitled',
};

export const FILETYPES = Object.freeze({
  FOLDER: 'folder',
  FILE: 'file',
  EXTERNAL: 'external',
});

export const ALERT_TYPES = Object.freeze({
  NONE: 'no-alert',
  DEFAULT: 'default',
  ERROR: 'error',
  DEFAULT_DISMISSIBLE: 'default-dismissible',
  ERROR_DISMISSIBLE: 'error-dismissible',
  OUT_OF_SYNC: 'out-of-sync',
  RENAME_BAD_CHARACTERS: 'rename-bad-characters',
  RENAME_ALREADY_USED: 'rename-alert-used',
});

export const defaultLocale = {
  locale: 'en-us',
  language: 'en',
  country: 'US',
  currency: 'USD',
};

export const localePricing = {
  USD: 99,
  CAD: 139,
  EUR: 119,
  GBP: 109,
};

export const ChargebeeSite =
  process.env.NODE_ENV === 'development'
    ? {
        Shaper: 'shaper-inc-test',
        ShaperGMBH: 'shaper-gmbh-test',
      }
    : {
        Shaper: 'shaper-inc-test',
        ShaperGMBH: 'shaper-gmbh-test',
      };

export const localeChargebeeSites = {
  'en-us': ChargebeeSite.Shaper,
  'en-ca': ChargebeeSite.Shaper,
  'fr-ca': ChargebeeSite.Shaper,
  'en-de': ChargebeeSite.ShaperGMBH,
  'de-de': ChargebeeSite.ShaperGMBH,
  'en-gb': ChargebeeSite.ShaperGMBH,
  'fr-fr': ChargebeeSite.ShaperGMBH,
};

const traceURL = () => {
  if (window.location.href.includes('.staging')) {
    return 'https://trace.staging.shapertools.com';
  }
  return 'https://trace.shapertools.com';
};

export const signupURL = () => {
  const encodedReturnLocation = encodeURIComponent(window.location.href);
  if (
    window.location.href.includes('.staging') ||
    localhostDomainRegex.test(window.location.host)
  ) {
    return `https://my.staging.shapertools.com/signup?backTo=${encodedReturnLocation}`;
  }
  return `https://my.shapertools.com/signup?backTo=${encodedReturnLocation}`;
};

export const menuLinks = {
  TRACE: traceURL(),
  PROJECTS: 'https://hub.shapertools.com/cloud',
  SHAPERHUB: 'https://hub.shapertools.com',
  FORUM: 'https://community.shapertools.com/c/shaper-studio',
  SHOP: 'https://www.shapertools.com/',
  ACCOUNT: 'https://my.shapertools.com',
  PRICING: 'https://www.shapertools.com/studio#pricing',
  LEARN_MORE: 'https://www.shapertools.com/studio',
};

export const helpMenuLinks = {
  HELP_CENTER:
    'https://support.shapertools.com/hc/categories/7992136295067-Studio',
  YOUTUBE:
    'https://www.youtube.com/watch?v=dOpR4EytCe8&list=PLEHMLsAENuk904BDNY9Gd8f1zJF-pEaw_',
  SHORTCUTS:
    'https://support.shapertools.com/hc/articles/8020308535195-Keyboard-Mouse-and-Trackpad-Controls',
  COMMUNITY: 'https://community.shapertools.com/',
  FEEDBACK: 'mailto:support@shapertools.com',
};

export const miscLinks = {
  MY_FILES: 'https://hub.shapertools.com/cloud',
  CUSTOM_ANCHORS:
    'https://support.shapertools.com/hc/en-us/articles/4402965445019-Custom-Anchors',
  REFERENCE_HELP:
    'https://support.shapertools.com/hc/articles/21945983196187-Reference-Paths-Deep-Dive',
};

export const apiTimeouts = {
  localStorage: 30000,
  instaplace: 30001,
  labs: 30002,
  shaperHub: 30003,
  sync: 120000,
};

export const unitDefaults = {
  metric: 25.4,
  imperial: 1,
};

//Maximum length of undo buffer
export const undoBufferSize = 100;

export const copyText = {
  en: 'copy',
  de: 'Kopie',
  fr: 'copie',
};

export const EPS_TOLERANCE = 0.001;

export const FLOW_IDS = {
  START_TRIAL: 'd4c66447-a600-4739-8bd1-6e73f0c372bc',
};

export const FEATURE_FLAGS = {
  SINGLE_LINE_FONTS: 'studio-fonts',
  DEVELOPER_ACCESS: 'studio-developer-settings',
};

export const fontFilterConfig = [
  {
    displayName: 'Single Line Font',
    category: 'Single Line',
    icon: 'fonts-single-line',
    i18nKey: 'single-line',
  },
  {
    category: 'Sans-Serif',
    icon: 'fonts-sans-serif',
    i18nKey: 'sans-serif',
  },
  {
    category: 'Serif',
    icon: 'fonts-serif',
    i18nKey: 'serif',
  },
  {
    category: 'Script',
    icon: 'fonts-script',
    i18nKey: 'script',
  },
  {
    category: 'Handwriting',
    icon: 'fonts-handwriting',
    i18nKey: 'handwriting',
  },
  {
    category: 'Novelty',
    icon: 'fonts-novelty',
    i18nKey: 'novelty',
  },
  {
    category: 'Non-Latin',
    icon: 'fonts-non-latin',
    i18nKey: 'nonlatin',
  },
];
