import {
  AsyncThunk,
  createSlice,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FragmentListOps } from '../../ShapeBuilder/ShapeBuilderCore.js';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { PATH_TYPES } from '@/Geometry/sherpa-svg-generator/PathTypes';
import { AABB } from '../../Geometry/sherpa-svg-generator/AABB.js';
import { BasePath } from '@/Geometry/sherpa-svg-generator/BasePath.js';
import { RootState } from '../store.js';
import { PATH_TYPE } from '@/Geometry/sherpa-svg-generator/Path.js';

interface Fragment {
  simplePolygon: BasePath;
  groupIdList: string[];
  AABB: AABB;
}

interface FragmentList {
  fragments: Fragment[];
  nextFragmentId: number;
}

interface FragmentSet {
  id: number;
  fragIds: string[];
  simplePolygons: BasePath[];
}

interface AddFragmentSetPayload {
  selectedFragmentIds: string[];
}

interface UpdateFragments {
  fragmentsSvgs: any;
  fragmentsAABB: AABB;
}

export const addSelectedGroupsToShapeBuilder = createAsyncThunk(
  'shapeBuilder/addSelectedGroupsToShapeBuilder',
  async (_, { getState }) => {
    const store = getState() as RootState;
    const selectedGroups = selectSelectedGroups(store).filter((g) =>
      g && g.type ? PATH_TYPES[g.type || PATH_TYPE.DESIGN].shapeshifter : true
    );
    /*
      this is a temporary fix to exclude single line fonts from shapeshifter
    */
    const groupsWithOutOpenText = selectedGroups.filter(
      (s) =>
        !(
          s?.tool?.type === 'text-insert' &&
          s?.basePathSet.some((b) =>
            b.outerPath ? !b.outerPath.closed : !b.closed
          )
        )
    );

    return Promise.resolve(
      FragmentListOps.generateFragmentsFromGroups(
        FragmentListOps.createFragmentList(),
        groupsWithOutOpenText
      )
    );
  }
);

export const updateShapeBuilderCanvas = createAsyncThunk(
  'shapeBuilder/updateShapeBuilderCanvas',
  async (params) => {
    return Promise.resolve(params);
  }
);

export const addFragmentSet = createAsyncThunk(
  'shapeBuilder/addFragmentSet',
  async (params: AddFragmentSetPayload) => {
    return Promise.resolve(params);
  }
);

export const clearFragmentSelections = createAsyncThunk(
  'shapeBuilder/clearFragmentSelections',
  async (params) => {
    return Promise.resolve(params);
  }
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export interface ShapeBuilderSlice {
  status: 'idle' | 'pending';
  fragmentList: FragmentList;
  selectedGroupsFragmentsSvgs: any;
  selectedGroupsFragmentsAABB: AABB;
  selectedFragmentSets: FragmentSet[];
  nextFragmentSetId: number;
}

const initialState: ShapeBuilderSlice = {
  status: 'idle',
  fragmentList: FragmentListOps.createFragmentList(),
  selectedGroupsFragmentsSvgs: [],
  selectedGroupsFragmentsAABB: new AABB(),
  selectedFragmentSets: [],
  nextFragmentSetId: 0,
};

//TODO - refactor slice to remove fragmentSet geometry. Fragments aren't used outside ShapeBuilderContainer, so state hooks are more appropriate
//May be able to get rid of this entire slice
export const slice = createSlice({
  name: 'shapeBuilder',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It doesn't actually mutate the state because it uses the immer library, which detects changes to a "draft state" and produces a brand new immutable state based off those changes
    updateFragments: (state, action: PayloadAction<UpdateFragments>) => {
      const { fragmentsSvgs, fragmentsAABB } = action.payload;
      state.selectedGroupsFragmentsSvgs = fragmentsSvgs;
      state.selectedGroupsFragmentsAABB = fragmentsAABB;
    },
    clearShapeBuilderFragments: (state) => {
      state.fragmentList = FragmentListOps.createFragmentList();
    },
    clearShapeBuilder: (state) => {
      state.fragmentList = FragmentListOps.createFragmentList();
      state.selectedGroupsFragmentsSvgs = [];
      state.selectedGroupsFragmentsAABB = new AABB();
      state.selectedFragmentSets = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSelectedGroupsToShapeBuilder.fulfilled, (state, action) => {
        return {
          ...state,
          fragmentList: action.payload.fragmentList,
          status: 'idle',
        };
      })
      .addCase(clearFragmentSelections.fulfilled, (state) => {
        state.selectedFragmentSets = [];
        state.nextFragmentSetId = 0;
      })
      .addCase(
        addFragmentSet.fulfilled,
        (state, action: PayloadAction<AddFragmentSetPayload>) => {
          const fragments: Fragment[] =
            FragmentListOps.selectFragmentsFromFragmentIds(
              state.fragmentList,
              action.payload.selectedFragmentIds
            );
          const simplePolygons =
            FragmentListOps.generateFragmentsUnionBasePathsArray(
              fragments.map((f) => f.simplePolygon)
            );

          const nextFragmentSet: FragmentSet = {
            id: state.nextFragmentSetId,
            fragIds: action.payload.selectedFragmentIds,
            simplePolygons: simplePolygons,
          };
          state.selectedFragmentSets = [
            ...state.selectedFragmentSets,
            nextFragmentSet,
          ];
          state.nextFragmentSetId++;
        }
      )
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state) => {
          state.status = 'idle';
        }
      )
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'pending';
        }
      );
  },
});

export const middleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (updateShapeBuilderCanvas.fulfilled.match(action)) {
      const { selection, shapeBuilder } = getState();
      const selectedGroupIds = selection.groupIds;
      if (selectedGroupIds.length > 0) {
        const { fragmentList } = shapeBuilder;
        const selectedGroupsFragmentSvgs =
          FragmentListOps.generateFragmentsSvgs(fragmentList, selectedGroupIds);

        const selectedGroupsFragmentsAABB =
          FragmentListOps.generateFragmentsAABB(fragmentList, selectedGroupIds);

        dispatch(
          updateFragments({
            fragmentsSvgs: selectedGroupsFragmentSvgs,
            fragmentsAABB: selectedGroupsFragmentsAABB,
          })
        );
      } else {
        updateFragments({
          fragmentsSvgs: [],
          fragmentsAABB: new AABB(),
        });
      }
    }

    next(action);
  };

export const selectGroupsFragmentsSvgs = (state: RootState) => {
  return state.shapeBuilder.selectedGroupsFragmentsSvgs;
};

export const selectGroupsFragmentsAABB = (state: RootState) => {
  return state.shapeBuilder.selectedGroupsFragmentsAABB;
};

export const selectNextFragmentSetId = (state: RootState) => {
  return state.shapeBuilder.nextFragmentSetId;
};

export const selectAllPendingShapesSimplePolygons = (state: RootState) => {
  return state.shapeBuilder.selectedFragmentSets.map((fs) => fs.simplePolygons);
};

export const {
  clearShapeBuilderFragments,
  clearShapeBuilder,
  updateFragments,
} = slice.actions;
export default slice.reducer;
