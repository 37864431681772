import { immerable } from 'immer';
import { AABB, mergeAABBArray } from './AABB';
import { CutParams, CutType } from './CutParams';
import { IPath, Path } from './Path';

/**
 * @extends {IPath}
 */
export interface IBasePath extends IPath {
  outerPath?: Path;
  holePaths?: Path[];
  cutParams?: CutParams;
}

/**
 * @extends {Path}
 * @implements {IBasePath}
 * @implements {IPath}
 */
export class BasePath extends Path implements IBasePath, IPath {
  [immerable] = true;
  /** @type {Path} - property that is used to hold the `outerPath` path for a Polygon*/
  outerPath?: Path;
  /** @type {Path} - property that is used to hold an array of `holePath` paths for a Polygon*/
  holePaths?: Path[];
  /** @type {CutParams} - property to hold cutParams for a Path. Every BasePath should have cutParams */
  cutParams: CutParams;

  /**
   * @constructor
   *
   * @param {IBasePath} basePath - BasePath extends a path but contains {@link CutParams} and two optional
   * properties, {@link outerPath} and {@link holePaths}
   *
   * @see Path for more implementation details
   */
  constructor(basePath: IBasePath) {
    super(basePath);
    this.outerPath = basePath?.outerPath
      ? new Path(basePath?.outerPath)
      : undefined;
    this.holePaths = basePath?.holePaths
      ? basePath?.holePaths?.map((hp) => new Path(hp))
      : undefined;
    this.cutParams = basePath?.cutParams || new CutParams();
    if (this.outerPath) {
      this.AABB = new AABB({ points: this.outerPath.points });
      this.closed = this.outerPath.closed;
      if (!this.outerPath.closed) {
        this.cutParams = new CutParams({
          ...this.cutParams,
          cutType: CutType.ONLINE,
        });
      }
    }
    if (!this.closed) {
      this.cutParams = new CutParams({
        ...this.cutParams,
        cutType: CutType.ONLINE,
      });
    }
  }
}

/**
 * Function to generate a Path Id to be used while rendering DOM elements
 * @param {string} svgId - the svgGroup id of the {@link Path}
 * @param {string} pgId -
 * @param {string} pathType - the type of Path that the string is being generated for (is almost always `basePath`)
 * @returns {string} returns a path Id string to be used in the DOM
 */
export function generatePathId(
  svgId: string = '0',
  pgId: string = '0',
  pathType = 'basePath'
) {
  return `${pathType}-sg-${svgId}-pg-${pgId}`;
}

/**
 * Get the AABB of a BasePath or an array of BasePaths
 * @param {BasePath|BasePath[]} basePath - either a single BasePath or an array of BasePaths to find the AABB of
 * @returns {AABB} returns a single AABB from basePath
 */
export const getAABB = (basePath: BasePath | BasePath[]): AABB => {
  if (Array.isArray(basePath)) {
    return mergeAABBArray(basePath.map((bp) => getAABB(bp)));
  }
  if (basePath.outerPath) {
    return basePath.outerPath.AABB;
  }
  return basePath.AABB;
};
