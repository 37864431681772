export class Manager {
  private _activeInteraction: any;
  private _isDragging: boolean;

  constructor() {
    this._activeInteraction = null;
    this._isDragging = false;
  }

  get isDragging(): boolean {
    return this._isDragging;
  }

  set isDragging(value: boolean) {
    this._isDragging = value;
  }
  get activeInteraction(): any {
    return this._activeInteraction;
  }

  set activeInteraction(value: any) {
    this._activeInteraction = value;
  }
}
