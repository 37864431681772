import React, { useEffect, useState } from 'react';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';
import TranslationText from '../../TranslationText/TranslationText';
import Tooltip from '@material-ui/core/Tooltip'; //using mui tooltip to prevent positioning issues

export default function SectionOption(props) {
  const {
    children,
    icon,
    i18nKey,
    tooltip,
    onHover,
    component: Component,
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (!tooltip) {
      setIsHovering(false);
    }
  }, [tooltip]);

  function onClick() {
    if (!props.disabled) {
      if (props.onClick) {
        props.onClick();
      } else if (props.url) {
        window.open(props.url, props.target || '_self');
      }
    }
  }

  const handleMouseOver = () => {
    if (onHover) {
      setIsHovering(true);
    }
  };

  const handleMouseOut = () => {
    if (onHover) {
      setIsHovering(false);
    }
  };

  const optionCx = classNames('action-list-menu--section-option', {
    disabled: !!props.disabled,
    onHover: isHovering,
  });

  const innerComponent = () => {
    return (
      <div
        className={optionCx}
        onClick={onClick}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        data-cy={i18nKey}
      >
        {isHovering ? (
          onHover
        ) : Component ? (
          <>{new Component()}</>
        ) : (
          <>
            <div className='action-list-menu--section-option--icon'>
              <Icon icon={icon} />
            </div>
            <div className='action-list-menu--section-option--label'>
              <TranslationText i18nKey={i18nKey}>
                {children || props.label}
              </TranslationText>
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!onHover) {
      setIsHovering(false);
    }
  }, [onHover]);

  return tooltip ? (
    <Tooltip
      placement='right'
      title={tooltip}
      arrow
      classes={{ popper: 'application-menu--tooltip' }}
    >
      {innerComponent()}
    </Tooltip>
  ) : (
    innerComponent()
  );
}
