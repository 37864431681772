import classNames from 'classnames';
import React from 'react';

// hovering tooltip
export function Tooltip(props) {
  const { side, align, className, tip, children, inline, allowWrap } = props;
  const containerCx = `component--tooltip--container placement-${side} alignment-${align} ${
    className || ''
  }`;
  const contentCx = classNames('component--tooltip--content', {
    wrap: allowWrap,
  });
  const content = (
    <>
      <div className={contentCx}>{tip}</div>
      {children}
    </>
  );

  return inline ? (
    <span className={containerCx}>{content}</span>
  ) : (
    <div className={containerCx}>{content}</div>
  );
}

// helper function to wrap content with tooltips
Tooltip.wrap = function (tip, side, content, align, params) {
  return tip ? (
    <Tooltip tip={tip} side={side || 'above'} align={align} {...params}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
