import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// components
import Grid from '@/UILayer/Components/Grid';
import BoundingBox from '@/UILayer/Components/BoundingBox';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';

export default function AnchorSelectionUI(props) {
  const { ui } = props;
  const options = useSelector(selectOptions);
  const { showGrid } = options;
  const { hasBounds } = ui;

  return (
    <div className='ui-layer'>
      <Viewport ui={ui}>
        {showGrid && <Grid viewport={ui.viewport} />}
        {<SvgGroups ui={ui} />}
        {hasBounds && <BoundingBox {...props} {...ui.boundingBox} hideLabels />}
      </Viewport>
    </div>
  );
}
