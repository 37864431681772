import { createAction } from '@reduxjs/toolkit';

export const amplitudeEventTypes = {
  StudioDownloads: 'studio.downloads',
  StudioIconPlaced: 'studio.icon-placed',
  StudioShapePlaced: 'studio.shape-placed',
  StudioTextPlaced: 'studio.text-placed',
  StudioShaperBuilderPlaced: 'studio.shapebuilder-placed',
  StudioShapeCopyPlaced: 'studio.shape-copy-placed',
  StudioSignIn: 'studio.sign-in',
  StudioStart: 'studio.start',
  StudioEnd: 'studio.end',
  StudioAddContent: 'studio.add-content',
  StudioSwitchMode: 'studio.switch-mode',
  StudioOpenWorkspace: 'studio.open-workspace',
  StudioEnableDemoMode: 'studio.enable-demo-mode',
  StudioAccountCreateClick: 'studio.account-create-click',
  StudioNewWorkspace: 'studio.new-workspace',
  StudioWorkspaceOutOfDate: 'studio.workspace-out-of-date',
};

export const amplitudeUserProperties = {
  StudioIsOriginUser: 'isOriginUser',
};

export const createAccountAction = createAction('analytics/create-account');
