import { performanceLogSync, performanceLogAsync } from './Helpers';
import math from './sherpa-svg-generator/mathjs';
import {
  NSimplePolygonDifferencesAsync,
  NSimplePolygonUnionsAsync,
} from './SimplePolygonOps';
import { BasePath, generatePathId } from './sherpa-svg-generator/BasePath';
import {
  generateSvgPathData,
  offsetSync,
  offsetAsync,
  setPathsWindingOrder,
  toPathArray,
} from './BasePathOps';
import {
  getCutDepthMM,
  getReviewPathOffset,
  getSvgPathCssClass,
  getToolOffset,
} from './CutParamsOps';
import { createSvgPathFromStrsWithCSSAndAttributes } from './sherpa-svg-generator/SvgGenerator';
import { CutParams, CutType } from './sherpa-svg-generator/CutParams';
import { Path } from './sherpa-svg-generator/Path';
import { Shape, Tool } from './sherpa-svg-generator/SvgGroup';

class CutPreview extends BasePath {
  depth: number;
  constructor(path: BasePath, depth: number = 0) {
    super(path);
    this.depth = depth;
  }
}

function getToolPathSvgData(scaledBasePath: BasePath) {
  //Offsetting a single path may result in 0, 1, or more offset paths
  const toolPaths = performanceLogSync(
    () =>
      offsetSync(
        scaledBasePath,
        getToolOffset(scaledBasePath.cutParams),
        false,
        true
      ),
    'Plan mode toolpath'
  );

  //BUT...
  //...toolpaths can be displayed as a single SVG path string, because they are styled and selected as a unit, so join this array into a single string
  return {
    pathSvgData: toolPaths
      .map((tp: BasePath) => generateSvgPathData(tp))
      .join(' '),
    pathData: toolPaths,
  };
}

function getToolPathSvgFromPathData(
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  pathData: string | []
) {
  if (pathData === '') {
    return '';
  }
  const toolPathId = generatePathId(svgGroupId, basePathId, 'cutPreviewOnline');

  // const toolPathCSS = CutParamsOps.getSvgPathCssClass(cutParams.cutType);
  const toolPathCSS = getSvgPathCssClass(cutParams.cutType);

  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    toolPathId,
    toolPathCSS,
    []
  );
}

function getPreviewPathSvgFromPathData(
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  pathData: string
) {
  if (pathData === '') {
    return '';
  }
  const previewPathId = generatePathId(
    svgGroupId,
    basePathId,
    'cutPreviewToolWidth'
  );

  const previewPathCSS = getSvgPathCssClass('toolWidth');

  const previewPathAttr = [
    {
      name: 'stroke-width',
      value: math.unit(cutParams.toolDia).toNumber('mm'),
    },
    { name: 'stroke-linejoin', value: 'round' },
    { name: 'stroke-linecap', value: 'round' },
  ]; //Never include cut offset, because width of cut is width of tool only. Cut offset only changes position of toolPath.

  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    previewPathId,
    previewPathCSS,
    previewPathAttr
  );
}

function generateToolpathOnlineOpenPathAsync(
  basePath: BasePath,
  cutParams: CutParams
) {
  const toolDiaOffset = getReviewPathOffset(cutParams);

  return performanceLogAsync(() => {
    return (
      offsetAsync(basePath, toolDiaOffset, true) as Promise<BasePath[]>
    ).then((offsets) => {
      if (offsets.length > 1) {
        const copiedOffsets = JSON.parse(JSON.stringify(offsets));
        const outerPath = copiedOffsets[0] as BasePath;
        return [
          new BasePath({
            outerPath: new Path({
              ...outerPath,
            }),
            holePaths: (copiedOffsets.slice(1) as BasePath[]).map(
              (o: BasePath) =>
                new Path({
                  ...o,
                })
            ),
            cutParams: cutParams,
          }),
        ];
      }
      return [
        new BasePath({
          outerPath: new Path({
            ...basePath,
          }),
          holePaths: offsets.map(
            (o: BasePath) =>
              new Path({
                ...o,
              })
          ),
          cutParams: cutParams,
        }),
      ];
    });
  }, 'Toolpath outline offset version');
}

//Review mode requires more than the toolpath. We need to generate the boundaries of the cutting range
//For guide - no path
//For inside, outside, and online - offset toolPath by +-toolRadius, convert pairs to simplePolygon, and store depth. NB cannot use basePath as one of these boundaries, because of corner rounding
//For pocket, offset by + toolRadius, may or may not be simplePoly. Use what came before.

///Returns simplePolygon with cutDepth
function generateToolpathOutlineSPAsync(
  basePath: BasePath,
  cutParams: CutParams
) {
  const toolDiaOffset = getReviewPathOffset(cutParams);

  //For simplePolygons, offset outerPath and holePaths separately
  //Need to round corners for this offset operation only, as we are determining the cut area around the toolpath
  return performanceLogAsync(async () => {
    const simplePolyPaths = await Promise.all(
      toPathArray(basePath).map(async (op: BasePath) => {
        const [outerPath] = await offsetAsync(op, 1 * toolDiaOffset, true);
        const holePaths = await offsetAsync(op, -1 * toolDiaOffset, true, true);

        const holePathsArray = Array.isArray(holePaths)
          ? holePaths
          : [holePaths];
        return {
          outerPath,
          holePaths: holePathsArray.map((hp) => hp.outerPath || hp),
        };
      })
    );

    return simplePolyPaths.map((spPaths) =>
      setPathsWindingOrder(
        new BasePath({
          ...spPaths,
          cutParams: cutParams || basePath.cutParams,
        })
      )
    );
  }, 'Toolpath outline offset version');
}

///Returns simplePolygon with cutDepth
async function generatePocketSPAsync(toolPath: BasePath, cutParams: CutParams) {
  //Because we're generating toolpath boundaries geometrically, we need to round the outside corners of the pocketing review path
  //Returns array of paths or simplePolygons
  return (
    offsetAsync(toolPath, getReviewPathOffset(cutParams), true) as Promise<any>
  ).then((offsetPaths: BasePath[]) => {
    return offsetPaths.map((op) => {
      if (op.outerPath) {
        op.cutParams = cutParams;
        return op;
      }
      return new BasePath({
        outerPath: new Path({
          points: op.points,
          closed: op.closed,
        }),
        cutParams,
        holePaths: [],
      });
    });
  });
}

function getReviewPathDataAsync(
  toolPaths: BasePath[],
  cutParams: CutParams,
  tool: Tool
) {
  //Guide isn't visible in preview
  if (cutParams.cutType === CutType.GUIDE) {
    return Promise.resolve([]);
  }
  if (tool.type === Shape.TEXT && tool.params.forceOpenPaths) {
    return Promise.all(
      toolPaths.map((tp: BasePath) =>
        generateToolpathOnlineOpenPathAsync(tp, cutParams)
      )
    ).then((previewPaths) => previewPaths.flat());
  }

  //First get toolpath geometry from basePath and cutParams
  //Offsetting a single path may result in 0, 1, or more offset paths
  const previewPathsPromises = toolPaths.map((tp: BasePath) => {
    switch (cutParams.cutType) {
      case CutType.ONLINE:
      case CutType.INSIDE:
      case CutType.OUTSIDE:
        // Offset version is well tested, but 30% slower than Minkowski sum
        return generateToolpathOutlineSPAsync(tp, cutParams);

      case CutType.POCKET:
        return generatePocketSPAsync(tp, cutParams);

      default:
        throw new Error(`cutType unknown, ${cutParams.cutType}`);
    }
  });

  // render all remaining
  return Promise.all(previewPathsPromises).then((previewPaths) =>
    previewPaths.flat()
  );
}

function getReviewPathSvg(
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  paths: BasePath[]
) {
  const pathData = paths.map((p) => generateSvgPathData(p)).flat();

  if (pathData.length === 0) {
    return '';
  }

  const reviewPathId = generatePathId(svgGroupId, basePathId, 'reviewToolPath');

  const cssKey =
    cutParams.cutType === CutType.POCKET
      ? 'toolWidthReviewPocket'
      : 'toolWidthReviewOutline';

  const reviewPathCSS = getSvgPathCssClass(
    cssKey,
    cutParams.toolDia,
    cutParams.cutDepth
  );

  // const previewPathAttr = [{name: "stroke-width", value: math.unit(cutParams.toolDia).toNumber('mm')}]; //Never include cut offset, because width of cut is width of tool only. Cut offset only changes position of toolPath.
  //No svg attributes right now
  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    reviewPathId,
    reviewPathCSS,
    []
  );
}

async function getDepthClippedReviewPaths(pathData: BasePath[]): Promise<any> {
  //Convert cutDepths to mm and depth sort pathData back to front
  const zSortedPathData = pathData
    .map(
      (path) =>
        new CutPreview(path, getCutDepthMM(path.cutParams || new CutParams()))
    )
    .sort((a, b) => (a.depth > b.depth ? -1 : 1)) as CutPreview[];

  const stackedPaths = [];

  for (const path of zSortedPathData) {
    const deeperPaths = stackedPaths.filter((sp) => sp.depth > path.depth);

    let clippedPathsWithDepth = [path];
    if (deeperPaths.length > 0) {
      //useWorker = true
      const clippedPaths = await NSimplePolygonDifferencesAsync(
        path,
        deeperPaths
      );
      clippedPathsWithDepth = clippedPaths.map((cp) => ({
        ...cp,
        depth: path.depth,
        cutParams: new CutParams({
          cutDepth: `${path.depth}mm`,
        }),
      }));
    }
    stackedPaths.push(...clippedPathsWithDepth);
  }

  //Now need to union paths at same depth
  const uniqueDepths = stackedPaths.reduce((acc, sp) => {
    const { depth } = sp;
    if (!acc.includes(depth)) {
      acc.push(depth);
    }
    return acc;
  }, [] as number[]);

  const mergedPaths = [];
  for (let d of uniqueDepths) {
    let pathSet = stackedPaths.filter((sp) => sp.depth === d);

    if (pathSet.length > 1) {
      //Union may result in 1 or more paths
      //Don't need .depth attribute anymore, so no need to preserve it
      const unionPaths = await NSimplePolygonUnionsAsync(pathSet);
      const unionPathsWithDepth = unionPaths.map((cp) => ({
        ...cp,
        depth: pathSet[0].depth,
        cutParams: new CutParams({
          cutDepth: `${pathSet[0].depth}mm`,
        }),
      }));
      mergedPaths.push(...unionPathsWithDepth);
    } else {
      mergedPaths.push(...pathSet);
    }
  }

  return mergedPaths;
}

export {
  getToolPathSvgData,
  getToolPathSvgFromPathData,
  getPreviewPathSvgFromPathData,
  generateToolpathOutlineSPAsync,
  getReviewPathDataAsync,
  getReviewPathSvg,
  getDepthClippedReviewPaths,
};
