import React from 'react';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';

export default function Button(props) {
  const { icon, children, head, tail, style, value, disabled, dataCy } = props;

  function onClick(event) {
    if (disabled) {
      return;
    }

    if (props.onClick) {
      props.onClick(value, event);
    }
  }

  const buttonCx = classNames('footer-menu--button', {
    [`footer-menu--button--${style}`]: style,
    'footer-menu--button--disabled': disabled,
  });

  return (
    <div className={buttonCx} onClick={onClick} data-cy={dataCy}>
      {!!head && head}
      {icon && <Icon>{icon}</Icon>}
      {children}
      {!!tail && tail}
    </div>
  );
}
