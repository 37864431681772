import BaseAction from './BaseAction';
import SetSelectionAction from './SetSelection';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';
import { selectGetGroupById } from '../Redux/Slices/CanvasSlice';
import { compact } from 'lodash';

export default class ResolveGroupSelectionAction extends BaseAction {
  _resolve(selection) {
    const select = this.createAction(SetSelectionAction);
    const groups = compact(selection);

    // update the selection
    select.set(groups);
    return groups;
  }

  _mapIdsToGroups = (ids) => {
    const { useSelector } = this;

    const getGroupById = useSelector(selectGetGroupById);
    return ids.map((id) => {
      const group = getGroupById(id);
      const [path] = group.basePathSet;
      return { groupId: id, pathId: path.id };
    });
  };

  // groups may be a collection of group objects or IDs
  resolve(groups, { appendToSelection } = {}) {
    const { useSelector } = this;
    let groupIds = [...useSelector(selectSelectedGroupIds)];

    // we're going to replace the selection
    if (appendToSelection) {
      // start by looping and adding the first new unique group
      for (const group of groups) {
        const appendId = group.groupId || group;
        const index = groupIds.indexOf(appendId);

        // if it's not found in the selection, add it and resolve
        if (index === -1) {
          groupIds.push(appendId);
          return this._resolve(groupIds);
        }
      }

      // since nothing new was added, check if we should remove something
      for (const group of groups) {
        const removeId = group.groupId || group;
        const index = groupIds.indexOf(removeId);

        // this was found in the selection, so it can
        // be removed
        if (index > -1) {
          groupIds.splice(index, 1);
          return this._resolve(groupIds);
        }
      }
    }

    // if there's a selection, find the index of the currently selected
    // item in the collection
    if (groupIds.length) {
      // since there's groups already selected, we're going to
      // use this to figure out where to cycle next
      for (let i = groupIds.length; i-- > 0; ) {
        const id = groupIds[i];
        const index = groups.findIndex(
          (group) => group.groupId === id || group === id
        );

        // can cycle
        if (index > -1) {
          const use = groups[(index + 1) % groups.length];
          return this._resolve([use.groupId || use]);
        }
      }
    }

    // if there's nothing at all, just resolve that
    if (!groups?.length) {
      return [];
    }

    // either we failed to cycle, or there was nothing
    // to cycle through. Just use the first group that
    // we're currently over
    return this._resolve([groups[0]]);
  }
}
