import {
  addSelectedGroupsToShapeBuilder,
  updateShapeBuilderCanvas,
} from '@/Redux/Slices/ShapeBuilderSlice';
import { setProgressIndicator } from '@/Redux/Slices/UISlice';
import BaseAction from './BaseAction';
import UIModeAction from './UIMode';

export default class BuildActiveShapeAction extends BaseAction {
  build() {
    const { dispatch } = this;
    const uiModeAction = this.createAction(UIModeAction);

    dispatch(setProgressIndicator(true));
    dispatch(addSelectedGroupsToShapeBuilder({}))
      .unwrap()
      .then(() => dispatch(updateShapeBuilderCanvas()).unwrap())
      .then(() => {
        dispatch(setProgressIndicator(false));

        uiModeAction.toShapeBuilder();
      });
  }
}
