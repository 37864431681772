import { createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';

import {
  updateServerSHA,
  initializeServerEnv,
  loginShaperHub,
  getLoginState,
  logoutShaperHub,
  uploadCanvasSvgShaperHub,
  getShaperLicenses,
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperSubscriptions,
  getShaperHubFiles,
  getShaperHubExternalItem,
  getLocale,
  getUser,
  startTrial,
  getFirstWorkspace,
  downloadBlob,
} from '../../ShaperHub/ShaperHubThunks';

import { clearDemoStorage, saveSherpaLocalState } from '@/Redux/localStorage';

import {
  ALERT_TYPES,
  defaultSubscriptionOptions,
  defaultWorkspace,
  defaultLocale,
} from '../../defaults';

import ImportGeometryAction from '../../Actions/ImportGeometry.js';
import {
  addAttributeToUser,
  addAttributesToUser,
  identifyUser,
} from '../../Utility/userflow.js';
import LogOutAction from '../../Actions/LogOut.js';
import { setUser } from '@/Sync/SyncLog';
import {
  Alert,
  AlertType,
  Environment,
  Licenses,
  LocaleResponse,
  Modal,
  Subscription,
  User,
  UserspaceExternalItemFileObject,
  UserspaceFileObject,
} from '../../@types/shaper-types';
import { RootState } from '../store';
import { getDeveloperSettings } from '@/Utility/developer-settings';

export {
  updateServerSHA,
  initializeServerEnv,
  loginShaperHub,
  logoutShaperHub,
  getLoginState,
  uploadCanvasSvgShaperHub,
  getShaperLicenses,
  readShaperHubFolder,
  updateShaperHubWorkspace,
  getShaperSubscriptions,
  getShaperHubFiles,
  getShaperHubExternalItem,
  getLocale,
  getUser,
  startTrial,
  getFirstWorkspace,
};

export interface ShaperHubState {
  loggedIn: boolean;
  status: 'idle' | 'pending';
  alert: Alert;
  decodedPayload: null;
  userId: string | null;
  user: User | null;
  username: string;
  serverEnv: Environment;
  serverSHA: string;
  shaperLicenses: Licenses;
  syncURL: string;
  paymentsURL: string;
  marketingURL: string;
  localeURL: string;
  entitlementsURL: string;
  iconsURL: string;
  currentPath: string[];
  currentFolder: string[];
  subscriptions: Subscription;
  recentFiles: UserspaceFileObject[];
  workspace:
    | UserspaceExternalItemFileObject
    | {
        name: string;
      };
  locale: LocaleResponse;
  loggingToken: string | null;
  lastUploadSuccessful?: boolean;
}

export const initialShaperHubState: ShaperHubState = {
  loggedIn: false,
  //TODO - deprecate
  status: 'idle',
  alert: {
    openAlert: false,
    i18nKey: '',
    alertMsg: '',
    alertType: ALERT_TYPES.NONE,
    alertIcon: '',
  },
  decodedPayload: null,
  userId: null,
  username: '',
  user: null,
  serverEnv: 'development',
  serverSHA: 'new_state',
  shaperLicenses: { tosVersion: '1.0', ppVersion: '1.0' },
  entitlementsURL: import.meta.env.VITE_SHAPER_URL_ENTITLEMENTS,
  syncURL: import.meta.env.VITE_SHAPER_URL_WORKSPACES,
  paymentsURL: import.meta.env.VITE_SHAPER_URL_PAYMENTS,
  marketingURL: import.meta.env.VITE_SHAPER_URL_MARKETING,
  localeURL: import.meta.env.VITE_SHAPER_URL_LOCALE,
  iconsURL: import.meta.env.VITE_SHAPER_URL_ICONS,
  currentPath: [],
  currentFolder: [],
  subscriptions: defaultSubscriptionOptions,
  recentFiles: [],
  workspace: defaultWorkspace,
  locale: defaultLocale as LocaleResponse,
  loggingToken: null,
};

export interface AlertActionPayload {
  msg: string;
  i18nKey?: string;
  type: AlertType;
  icon?: string;
  modal?: Modal;
  duration?: number;
  className?: string;
  position?: string;
  modalIcon?: string;
}

localStorage.setItem('shaperAuthURL', import.meta.env.VITE_SHAPER_URL_AUTH);
localStorage.setItem('shaperAPIURL', import.meta.env.VITE_SHAPER_URL_API);

export const slice = createSlice({
  name: 'shaperHub',
  initialState: initialShaperHubState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },

    clearAlert: (state) => {
      state.alert = {
        openAlert: false,
        alertMsg: '',
        alertType: 'no-alert',
      };
    },
    resetState: () => {
      //Nothing to do here because rootReducer handles it.
    },
    endSession: () => {
      //Log session end for analytics. Nothing to do as middleware intercepts and handles it.
    },
    setAlert: (state, action: PayloadAction<AlertActionPayload>) => {
      //Revert alert should not override offline alert
      if (state.alert?.i18nKey !== 'revert-workspace') {
        state.alert = {
          openAlert: true,
          alertMsg: action.payload.msg,
          i18nKey: action.payload.i18nKey,
          alertType: action.payload.type,
          alertIcon: action.payload.icon,
          alertModal: action.payload.modal,
          alertDuration: action.payload.duration,
          className: action.payload.className,
          alertPosition: action.payload.position,
          alertModalIcon: action.payload.modalIcon,
        };
      }
    },

    saveLocalDemoState: (state) => {
      saveSherpaLocalState(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loginShaperHub.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loggedIn = true;
          state.userId = action.payload;
          clearDemoStorage();
        }
      )
      .addCase(getLoginState.fulfilled, (state) => {
        state.loggedIn = true;
        clearDemoStorage();
      })
      .addCase(getLoginState.rejected, (state) => {
        state.loggedIn = false;
      })
      .addCase(logoutShaperHub.fulfilled, (state) => {
        state.loggedIn = false;
        state.userId = null;
        state.decodedPayload = null;
        state.subscriptions = defaultSubscriptionOptions;
      })
      .addCase(
        initializeServerEnv.fulfilled,
        (state, action: PayloadAction<{ server_env: Environment }>) => {
          state.serverEnv = action.payload.server_env;
        }
      )
      .addCase(
        updateServerSHA.fulfilled,
        (state, action: PayloadAction<{ server_sha: string }>) => {
          state.serverSHA = action.payload.server_sha;
        }
      )
      .addCase(uploadCanvasSvgShaperHub.fulfilled, (state) => {
        state.lastUploadSuccessful = true;
        state.alert = {
          openAlert: true,
          alertMsg: 'Upload Successful',
          i18nKey: 'upload-successful',
          alertType: ALERT_TYPES.DEFAULT,
        };
      })
      .addCase(uploadCanvasSvgShaperHub.rejected, (state) => {
        state.alert = {
          openAlert: true,
          alertMsg: 'Upload Failed',
          i18nKey: 'upload-failed',
          alertType: ALERT_TYPES.DEFAULT,
        };
      })
      .addCase(
        readShaperHubFolder.fulfilled,
        (
          state,
          action: PayloadAction<{
            currentFolder: string[];
            currentPath: string[];
          }>
        ) => {
          const { currentFolder, currentPath } = action.payload;
          state.currentPath = currentPath;
          state.currentFolder = currentFolder;
        }
      )
      .addCase(
        getShaperSubscriptions.fulfilled,
        (state, action: PayloadAction<{ subscriptions: Subscription }>) => {
          state.subscriptions = action.payload.subscriptions;
          const subscriptionType = action.payload.subscriptions.isSubscriber
            ? 'subscription'
            : action.payload.subscriptions.isTrial
            ? 'trial'
            : 'lite';
          addAttributesToUser({
            is_subscriber: action.payload.subscriptions.isSubscriber,
            is_trial: action.payload.subscriptions.isTrial,
            subscription_type: subscriptionType,
            is_expired: action.payload.subscriptions.isExpired,
            days_left: action.payload.subscriptions.daysLeft,
          });
        }
      )
      .addCase(
        getShaperHubFiles.fulfilled,
        (state, action: PayloadAction<UserspaceFileObject[]>) => {
          state.recentFiles = action.payload;
        }
      )
      .addCase(
        getShaperHubExternalItem.fulfilled,
        (state, action: PayloadAction<UserspaceExternalItemFileObject>) => {
          state.workspace = action.payload;
          document.title = `Shaper Studio | ${action.payload.name}`;
        }
      )
      .addCase(getShaperHubExternalItem.rejected, () => {
        document.title = `Shaper Studio`;
      })
      .addCase(
        getLocale.fulfilled,
        (state, action: PayloadAction<LocaleResponse>) => {
          state.locale = action.payload;
        }
      )
      .addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        identifyUser(action.payload);
        const { _id, email } = state.user;
        setUser({
          id: _id,
          email,
        });
      })
      .addCase(
        getFirstWorkspace.fulfilled,
        (_, action: PayloadAction<{ created: string } | null>) => {
          if (action.payload) {
            addAttributeToUser('studio_start', action.payload.created);
          } else {
            addAttributeToUser('studio_start', new Date().toISOString());
          }
        }
      );
  },
});

export const {
  setUsername,
  setAlert,
  clearAlert,
  resetState,
  endSession,
  saveLocalDemoState,
} = slice.actions;

export const selectServerEnv = (state: RootState) => state.shaperHub.serverEnv;

export const selectShaperHubLoggedIn = (state: RootState) =>
  state.shaperHub.loggedIn;

export const selectUsername = (state: RootState) => state.shaperHub.username;

export const selectServerSHA = (state: RootState) => state.shaperHub.serverSHA;

export const selectAlert = (state: RootState) => state.shaperHub.alert;

export const selectSyncUrl = (state: RootState) => state.shaperHub.syncURL;

export const selectLoggingToken = (state: RootState) =>
  state.shaperHub.loggingToken;

export const selectLoggedIn = (state: RootState) => {
  return state.shaperHub.loggedIn;
};

export const selectShaperHubStatus = (state: RootState) =>
  state.shaperHub.status;

export const selectShaperHubPath = (state: RootState) =>
  state.shaperHub.currentPath;

export const selectShaperHubFolder = (state: RootState) =>
  state.shaperHub.currentFolder;

export const selectShaperSubscriptions = (state: RootState) =>
  state.shaperHub.subscriptions;

export const selectRecentShaperHubFiles = (state: RootState) =>
  state.shaperHub.recentFiles;

export const selectWorkspaceInformation = (state: RootState) =>
  state.shaperHub.workspace;

export const selectShaperMarketingUrl = (state: RootState) =>
  state.shaperHub.marketingURL;

export const selectSherpaClientUrl = () => window.location.href;

export const selectLocale = (state: RootState) => {
  if (
    state.ui.featureFlags &&
    state.ui.featureFlags['studio-developer-access']
  ) {
    const localeOverride = getDeveloperSettings('locale');
    return {
      ...state.shaperHub.locale,
      locale: localeOverride,
    };
  }
  return state.shaperHub.locale;
};

export const selectUserIsVerified = (state: RootState) =>
  !!state.shaperHub.user?.emailIsVerified || false;

export const selectUser = (state: RootState) => state.shaperHub.user;

// updateServerSHA is called whenever app goes from Idle to Active. If SHA does not match, then reload client with up to date version and current workspace.
export const middleware: Middleware = (store) => (next) => (action) => {
  if (updateServerSHA.fulfilled.match(action)) {
    const { shaperHub, workspaceSync } = store.getState();
    const { serverSHA: oldSHA } = shaperHub;
    const newSHA = action.payload.server_sha;

    //Tab has been open a while and client has been updated, so refresh
    if (oldSHA !== 'new_state' && oldSHA !== newSHA) {
      const url = `${window.location.origin}/?workspaceId=${workspaceSync.workspaceId}`;
      window.location.replace(url);
    }
  }
  if (downloadBlob.fulfilled.match(action)) {
    const importGeometryAction = new ImportGeometryAction(store.dispatch);
    importGeometryAction.importSVG(action.payload, true);
  }
  if (getUser.rejected.match(action)) {
    const logOutAction = new LogOutAction(store.dispatch);
    logOutAction.logOut();
  }
  next(action);
};

export const actions = slice.actions;
export default slice.reducer;
