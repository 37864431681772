import { useEffect, useRef, useState } from 'react';
import { SELECTION_EDITOR_HOVER_STATE_THRESHOLD } from '@/Constants/UI';
import { cancelEvent } from '@/Utility/events';
import * as hoverStateHelper from '../../../HoverStateHelper/HoverStateHelper';

const isMouseEvent = (event) => /mouse/i.test(event.nativeEvent?.type);

export default function useSelectionEditorHoverState(getIds) {
  const hoverRef = useRef();
  const [isHovering, setIsHovering] = useState(false);

  // refreshes the current state
  function refreshHoverState(ids) {
    if (ids) {
      hoverStateHelper.register(...ids);
    } else {
      hoverStateHelper.clear();
    }
  }

  // clears and cancels a touch event
  const onReleaseTouch = () => {
    clearTimeout(hoverRef.current?.pending);
    hoverRef.current = null;
    hoverStateHelper.clear();

    // clear the hover state
    setTimeout(() => setIsHovering(false));
  };

  // creates required events to handle
  // hover and mouse over events
  const createHoverEventHandler = (...ids) => {
    function tryFocus() {
      if (hoverRef.current) {
        hoverRef.current.ids = ids;
        refreshHoverState(ids);
        setIsHovering(true);
      }
    }

    return {
      onContextMenu: cancelEvent,

      onMouseEnter: (event) => {
        if (isMouseEvent(event)) {
          hoverRef.current = { ids };
          refreshHoverState(ids);
        }
      },

      onMouseLeave: (event) => {
        if (isMouseEvent(event)) {
          onReleaseTouch();
        }
      },

      onTouchStart: () => {
        hoverRef.current = {
          pending: setTimeout(
            () => tryFocus(),
            SELECTION_EDITOR_HOVER_STATE_THRESHOLD
          ),
        };
      },
    };
  };

  useEffect(() => {
    window.addEventListener('touchend', onReleaseTouch);
    window.addEventListener('touchcancel', onReleaseTouch);
    window.addEventListener('blur', onReleaseTouch);

    return () => {
      window.removeEventListener('touchend', onReleaseTouch);
      window.removeEventListener('touchcancel', onReleaseTouch);
      window.removeEventListener('blur', onReleaseTouch);
    };
  }, []);

  return { isHovering, createHoverEventHandler };
}
