import React from 'react';
import { connect } from 'react-redux';

// slices
import {
  selectNonScalingPixelFactor,
  selectActiveGrids,
} from '@/Redux/Slices/ViewportSlice';
import { selectZoom } from '@/Redux/Slices/ViewportSlice';

// consts
import { PIXELS_PER_INCH } from '../../defaults';

class Grid extends React.PureComponent {
  // refs
  patternRef = React.createRef();
  rootRef = React.createRef();
  rectRef = React.createRef();
  topRef = React.createRef();

  state = {
    isReady: false,
  };

  renderGrids() {
    const { viewport, nonScalingPixelFactor } = this.props;

    // setup
    const dashWidth = 10 * nonScalingPixelFactor;
    const dashGap = 30 * nonScalingPixelFactor;

    const gridDash = {
      vectorEffect: 'non-scaling-size',
      strokeWidth: 3 * nonScalingPixelFactor,
      strokeDasharray: `${dashWidth}, ${dashGap}`,
    };

    // find the active displayed grid
    const { gridSize: size } = viewport;
    const block = size * PIXELS_PER_INCH;
    const point = 4 * nonScalingPixelFactor * Math.min(1, 30 / dashGap);

    return (
      <>
        <defs>
          <pattern
            id='active_grid'
            patternUnits='userSpaceOnUse'
            width={`${size}px`}
            height={`${size}px`}
            viewBox={`0 0 ${block} ${block}`}
          >
            <line
              className='workspace-grid--line'
              x1={0}
              x2={block}
              y1={0}
              y2={0}
              {...gridDash}
            />
            <line
              className='workspace-grid--line'
              x1={0}
              x2={0}
              y1={0}
              y2={block}
              {...gridDash}
            />

            <circle
              className='workspace-grid--junction'
              vectorEffect='non-scaling-size'
              r={point}
              cx={0}
              cy={0}
            />
            <circle
              className='workspace-grid--junction'
              vectorEffect='non-scaling-size'
              r={point}
              cx={block}
              cy={0}
            />
            <circle
              className='workspace-grid--junction'
              vectorEffect='non-scaling-size'
              r={point}
              cx={block}
              cy={block}
            />
            <circle
              className='workspace-grid--junction'
              vectorEffect='non-scaling-size'
              r={point}
              cx={0}
              cy={block}
            />
          </pattern>
        </defs>

        <rect
          className='workspace-grid'
          x={`${viewport.x}`}
          y={`${viewport.y}`}
          fill={`url(#active_grid)`}
          width='100%'
          height='100%'
        />
      </>
    );
  }

  // generate the view
  // SVG is rendered in canvas units and SVG viewbox is set to control pan and zoom on workspace.
  render() {
    return (
      <>
        {this.renderGrids()}
        {this.props.children}
      </>
    );
  }
}

// mapping props
export default connect((state) => ({
  zoom: selectZoom(state),
  nonScalingPixelFactor: selectNonScalingPixelFactor(state),
  grids: selectActiveGrids(state),
}))(Grid);
