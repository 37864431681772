import React from 'react';
import { useSelector } from 'react-redux';
import { selectSecretOptions } from '../../Redux/Slices/SherpaContainerSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';
import { entitlements } from '@/Helpers/Entitlements';

export default function AlignmentGuides(props) {
  const { viewport } = props;
  const {
    // object alignment guides
    guideLineWidth = 1.5,
    guideLineColor = 'FF274E',

    // grid alignment guides
    gridGuideLineColor = 'FF274E',
    gridGuideLineWidth = 1.5,
  } = useSelector(selectSecretOptions) || {};

  const canAlignToObjects = selectFeatures(entitlements.ALIGN_TO_OBJ);
  const canAlignToGrid = selectFeatures(entitlements.ALIGN_TO_GRID);

  function renderGuide(guide, index, cx, strokeColor, strokeWidth) {
    return 'x' in guide ? (
      <line
        key={`alignment_guide_${index}`}
        className={cx}
        x1={guide.x}
        x2={guide.x}
        y1={guide.top}
        y2={guide.bottom}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    ) : (
      <line
        key={`alignment_guide_${index}`}
        className={cx}
        y1={guide.y}
        y2={guide.y}
        x1={guide.left}
        x2={guide.right}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    );
  }

  function renderObjectAlignmentGuide(guide, index) {
    return renderGuide(
      guide,
      index,
      'alignment-guide alignment-guide--object',
      `#${guideLineColor}`,
      guideLineWidth
    );
  }

  function renderGridAlignmentGuide(guide, index) {
    const adjusted = { ...guide };

    if ('x' in guide) {
      adjusted.top = viewport.y;
      adjusted.bottom = viewport.y + viewport.height;
    } else {
      adjusted.left = viewport.x;
      adjusted.right = viewport.x + viewport.width;
    }

    return renderGuide(
      adjusted,
      index,
      'alignment-guide alignment-guide--grid',
      `#${gridGuideLineColor}`,
      gridGuideLineWidth
    );
  }

  return props.guides.map((guide, index) =>
    guide.type === 'object' && canAlignToObjects
      ? renderObjectAlignmentGuide(guide, index)
      : guide.type === 'grid' && canAlignToGrid
      ? renderGridAlignmentGuide(guide, index)
      : null
  );
}
