import React from 'react';
import { cancelEvent } from '@/Utility/events';

export default function Viewport(props) {
  const { ui } = props;
  const { viewport } = ui;

  return (
    <svg
      data-viewport
      xmlns='http://www.w3.org/2000/svg'
      className={`ui-viewport ${props.className || ''}`}
      width={viewport.size.width}
      height={viewport.size.height}
      viewBox={`${viewport.x} ${viewport.y} ${viewport.width} ${viewport.height}`}
      style={{ display: 'block' }}
      onContextMenu={cancelEvent}
      data-cy='ui-viewport'
    >
      <g>{props.children}</g>
    </svg>
  );
}
