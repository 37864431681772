import { isDevelopment } from '../development';
import { MODAL_TRIGGER_TYPES } from '../defaults';
import {
  amplitudeEventTypes,
  amplitudeUserProperties,
} from '../Constants/Analytics';
import * as Sentry from '@sentry/react';
import { getAABBSize } from '../Geometry/AABBOps';

class AmplitudeAnalyticsSingleton {
  constructor({ appendUserIdToEvents = true }) {
    this.instance = undefined;
    this.userId = undefined;
    this.appendUserId = appendUserIdToEvents;
    this.environmentProps = this.getEnvironmentEventProperties();
    this.hasAmplitudeEnabled = true;
  }

  getEnvironmentEventProperties() {
    const { origin } = window.location;
    const environment = isDevelopment() ? 'development' : 'production';

    return { environment, origin };
  }

  setApiKey(apiKey) {
    if ('amplitude' in window) {
      this.instance = window.amplitude.getInstance();
      this.instance.init(apiKey);
    } else {
      this.hasAmplitudeEnabled = false;
    }
  }

  //Amplitude API methods
  setUserId(userId) {
    try {
      this.userId = userId;
      this.instance.setUserId(userId);
      Sentry.setUser({ id: userId });
    } catch {
      // don't want to catch the amplitude errors
    }
  }

  setUserProperties(keyValuePairs) {
    try {
      if (this.instance) {
        const identifier = new this.instance.Identify();
        Object.keys(keyValuePairs).forEach((k) => {
          identifier.set(k, keyValuePairs[k]);
        });
        this.instance.identify(identifier);
      }
    } catch {
      // don't want to catch the amplitude errors
    }
  }

  addUserProperty(key, increment = 1) {
    try {
      if (this.instance) {
        const identifier = new this.instance.Identify();
        identifier.add(key, increment);
        this.instance.identify(identifier);
      }
    } catch {
      // don't want to catch the amplitude errors
    }
  }

  logEvent(eventName, eventProps = undefined) {
    const fullEventProps =
      this.appendUserId === true && this.userId !== undefined
        ? { ...eventProps, userID: this.userId, ...this.environmentProps }
        : { ...eventProps, ...this.environmentProps };
    this.instance?.logEvent(eventName, fullEventProps);
  }

  logAnonymousEvent(eventName, eventProps = undefined) {
    this.instance?.logEvent(eventName, eventProps);
  }

  incrementFileDownloads() {
    this.addUserProperty(amplitudeEventTypes.StudioDownloads);
  }

  incrementIconsPlaced() {
    this.addUserProperty(amplitudeEventTypes.StudioIconPlaced);
  }

  incrementShapesPlaced() {
    this.addUserProperty(amplitudeEventTypes.StudioShapePlaced);
  }

  incrementTextPlaced() {
    this.addUserProperty(amplitudeEventTypes.StudioTextPlaced);
  }

  incrementShapeBuilderPlaced() {
    this.addUserProperty(amplitudeEventTypes.StudioShaperBuilderPlaced);
  }

  incrementShapeCopyPlaced() {
    this.addUserProperty(amplitudeEventTypes.StudioShapeCopyPlaced);
  }

  signinUserEvent() {
    this.logEvent(amplitudeEventTypes.StudioSignIn);
  }

  startLabsEvent() {
    this.logEvent(amplitudeEventTypes.StudioStart);
  }

  endLabsEvent() {
    this.logEvent(amplitudeEventTypes.StudioEnd);
  }

  addContentEvent(contentParams) {
    if (this.userId) {
      this.logEvent(amplitudeEventTypes.StudioAddContent, contentParams);
    } else {
      this.logAnonymousEvent(amplitudeEventTypes.StudioAddContent, {
        ...contentParams,
        ...this.environmentProps,
      });
    }
  }

  downloadFileEvent(exportProps) {
    this.logEvent(amplitudeEventTypes.StudioDownloads, exportProps);
  }

  trackDemoMode() {
    this.logEvent(amplitudeEventTypes.StudioEnableDemoMode);
  }

  clickedAccountCreate() {
    this.logAnonymousEvent(amplitudeEventTypes.StudioAccountCreateClick);
  }

  setUserIsOriginOwner(isOriginOwner) {
    let userProperties = {};
    userProperties[amplitudeUserProperties.StudioIsOriginUser] = isOriginOwner;
    this.setUserProperties(userProperties);
  }

  openWorkspace(workspaceId) {
    this.logEvent(amplitudeEventTypes.StudioOpenWorkspace, { workspaceId });
  }

  incrementNewWorkspace() {
    this.addUserProperty(amplitudeEventTypes.StudioNewWorkspace);
  }

  incrementWorkspaceOutOfDate() {
    this.addUserProperty(amplitudeEventTypes.StudioWorkspaceOutOfDate);
  }
}

const amplitudeInstance = new AmplitudeAnalyticsSingleton({
  appendUserIdToEvents: true,
});

//TODO - write this
const getContentParamsFromPayload = function (contentPayload) {
  if (contentPayload === undefined || contentPayload.tool === undefined) {
    return { type: 'unknown' };
  }
  const { type, params } = contentPayload.tool;

  switch (type) {
    case 'circle':
    case 'ellipse':
    case 'rectangle':
    case 'rounded_rectangle':
    case 'polygon':
      return { type: 'shape', content_params: { shape_type: type } };

    case 'text-insert':
      //This is a font change event
      if (contentPayload.key === 'toolSvg') {
        return {
          type: 'text-update',
          content_params: {
            font_name: params.fontDisplayName,
            font_style: params.fontDisplayStyle,
          },
        };
      }
      //This is a new text insert event
      return {
        type: 'text',
        content_params: {
          font_name: params.fontDisplayName,
          font_style: params.fontDisplayStyle,
        },
      };

    case 'icon':
      return {
        type: 'icon',
        content_params: { icon_id: params.iconId, query: params.query },
      };

    case 'shapebuilder':
      return {
        type: 'shapebuilder',
        content_params: {
          group_count: params.group_count,
          processing_time: params.processing_time,
        },
      };

    default:
      return { type: 'unknown' };
  }
};

const getExportParamsFromPayload = function (exportPayload, exportState) {
  const size = getAABBSize(exportState.canvas.canvas.AABB);
  const group_count = exportState.canvas?.svgGroupSet?.length || 0;
  return {
    destination: exportPayload,
    group_count,
    file_width_mm: size.x,
    file_height_mm: size.y,
  };
};

class eventAPISingleton {
  constructor() {
    this.instance = amplitudeInstance;
    this.userId = undefined; //This will be set with registerUser or loginUser always before any other events are called
  }

  initializeAnalytics(apiKey) {
    this.instance?.setApiKey(apiKey);
    this.instance?.startLabsEvent();
  }

  registerUser() {
    this.instance?.registerUserEvent();
  }

  loginUser(userId) {
    this.userId = userId;
    this.instance?.setUserId(userId);
    this.instance?.signinUserEvent();
  }

  leaveSherpa() {
    if (this.userId === undefined) {
      console.log(new Error('Call to analytics event before setting userId'));
      return;
    }
    this.instance?.endLabsEvent(this.userId);
  }

  placeContent(contentPayload) {
    const contentParams = getContentParamsFromPayload(contentPayload);
    this.instance?.addContentEvent(contentParams);

    if (this.userId === undefined) {
      return;
    }

    switch (contentParams.type) {
      case 'icon':
        this.instance?.incrementIconsPlaced();
        break;

      case 'shape':
        this.instance?.incrementShapesPlaced();
        break;

      case 'text':
        this.instance?.incrementTextPlaced();
        break;

      case 'text-update':
        //Ignore this for totals
        break;

      case 'shapebuilder':
        this.instance?.incrementShapeBuilderPlaced();
        break;

      case 'shape_copy':
        this.instance?.incrementShapeCopyPlaced();
        break;

      default:
        console.log(
          new Error(`Unknown content type found in placeIcon: ${contentParams}`)
        );
    }
  }

  saveFile(exportPayload, exportState) {
    if (this.userId === undefined) {
      console.log(new Error('Call to analytics event before setting userId'));
      return;
    }
    const exportParams = getExportParamsFromPayload(exportPayload, exportState);

    this.instance?.downloadFileEvent(exportParams);

    switch (exportParams.destination) {
      case 'local':
        this.instance?.incrementFileDownloads();
        break;

      case 'shaperhub':
        this.instance?.incrementShaperHubUploads();
        break;

      default:
        console.log(
          new Error(`Unknown export type found in placeIcon: ${exportParams}`)
        );
    }
  }

  setUserIsOriginOwner(isOriginOwner) {
    this.instance?.setUserIsOriginOwner(isOriginOwner);
  }

  openWorkspace(workspaceId) {
    if (this.userId === undefined) {
      console.log(new Error('Call to analytics event before setting userId'));
      return;
    }
    this.instance?.openWorkspace(workspaceId);
  }

  changeUIMode(mode) {
    if (this.userId === undefined) {
      return;
    }
    this.instance?.logEvent(amplitudeEventTypes.StudioSwitchMode, { mode });
  }

  trackDemoMode() {
    this.instance?.trackDemoMode();
  }

  incrementNewWorkspace() {
    this.instance?.incrementNewWorkspace();
  }

  incrementWorkspaceOutOfDate() {
    this.instance?.incrementWorkspaceOutOfDate();
  }

  logOutUser() {
    this.userId = null;
    this.instance?.setUserId(null);
  }

  clickedAccountCreate() {
    this.instance.clickedAccountCreate();
  }
}

const eventAPI = new eventAPISingleton();

const DISABLE_ANALYTICS = false;
if (DISABLE_ANALYTICS || !eventAPI.instance.hasAmplitudeEnabled) {
  eventAPI.initializeAnalytics(import.meta.env.VITE_ANALYTICS_API_KEY);
}

//Middleware
export const analyticsMiddleware = (storeAPI) => {
  return (next) => (action) => {
    if (DISABLE_ANALYTICS || !eventAPI.instance.hasAmplitudeEnabled) {
      return next(action);
    }

    switch (action.type) {
      case 'shaperhub/loginShaperHub/fulfilled':
      case 'shaperhub/getLoginState/fulfilled':
        eventAPI.loginUser(action.payload);
        break;

      case 'canvas/addSVGGeometry/fulfilled':
        eventAPI.placeContent(action.payload);
        break;

      case 'shapeBuilder/addSelectedGroupsToShapeBuilder/fulfilled':
        const state = storeAPI.getState();
        // console.log(`logged process time: ${action.payload.processingTime}`);
        eventAPI.placeContent({
          tool: {
            type: 'shapebuilder',
            params: {
              group_count: state.selection.groupIds.length,
              processing_time: action.payload.processingTime,
            },
          },
        });
        break;

      //Only log updateSvgGroup event if font changes
      case 'canvas/updateSvgGroup/fulfilled':
        if (
          action.payload.map((x) => x.value?.tool?.type).includes('text-insert')
        ) {
          //updating a font means that there is only one instance of it in an updateSvgGroup payload
          const textUpdate = action.payload.find(
            (x) => x.value?.tool?.type === 'text-insert'
          );
          eventAPI.placeContent({ key: textUpdate.key, ...textUpdate.value });
        }
        break;

      case 'shaperhub/endSession':
      case 'shaperhub/logoutShaperHub':
        eventAPI.leaveSherpa();
        eventAPI.logOutUser();
        break;

      case 'canvas/exportCanvasSvg':
        eventAPI.saveFile('local', storeAPI.getState());
        break;

      case 'sherpaContainer/setModal':
        if (action?.meta?.trigger === MODAL_TRIGGER_TYPES.TRY_DEMO) {
          eventAPI.trackDemoMode();
        }
        break;

      case 'shaperhub/getUser/fulfilled':
        eventAPI.setUserIsOriginOwner(action.payload.hasOriginAccess);
        break;

      case 'sync/disconnect': {
        if (action.payload?.createNewWorkspaceAfterDisconnect) {
          eventAPI.openWorkspace(action.payload.workspaceId);
        }
        break;
      }

      case 'sync/create/settled':
        eventAPI.openWorkspace(action.payload.workspaceId);
        break;

      case 'sync/setStatus': {
        if (action.payload === 'out-of-date') {
          eventAPI.incrementWorkspaceOutOfDate();
        }
        break;
      }

      case 'analytics/create-account':
        eventAPI.clickedAccountCreate();
        break;

      case 'ui/setUIMode':
        eventAPI.changeUIMode(action.payload);
        break;

      default:
        break;
    }

    return next(action);
  };
};

export { analyticsMiddleware as default };
