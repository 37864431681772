import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../store';
import { PendingActionFromAsyncThunk } from 'node_modules/@reduxjs/toolkit/dist/matchers';
import { createAppAsyncThunk } from '../hooks';

const defaultIconQuery = { page: 1, query: '' };

export interface Icon {
  icon_url: string;
  id: string;
  license_description: string;
  permalink: string;
  tags: string[];
  term: string;
  thumbnail_url: string;
}

export interface IconQueryResponse {
  generatedAt: string;
  icons: Icon[];
  updated_at: string;
}

export interface IconRecord extends Icon {
  iconSVG: string;
  iconURI: string;
}

export interface IconSearchState {
  searchResults: IconRecord[];
  status: 'idle' | 'pending';
  searchError: unknown;
  noMoreResults: boolean;
  lastIconQuery: {
    page: number;
    query: string;
    limit?: number;
    append?: boolean;
  };
  cancelQuery: boolean;
}

const initialState: IconSearchState = {
  searchResults: [],
  status: 'idle',
  searchError: null,
  noMoreResults: false,
  lastIconQuery: defaultIconQuery,
  cancelQuery: false,
};

//Set limit to 30 so there will always be enough icons to fill grid list container and allow scroll event to be triggered to load next page, provided there are enough icons on server. Otherwise, list of results will be incomplete on large screens and next page will never be loaded.

export const newIconSearch = createAppAsyncThunk(
  'iconSearch/newIconSearch',
  async (
    {
      query,
      page = 0,
      limit = 30,
      append = false,
    }: { query: string; page: number; limit: number; append: boolean },
    { getState }
  ) => {
    // clear any existing searched
    const { iconSearch, shaperHub } = getState();
    if (iconSearch.cancelQuery) {
      return [];
    }

    // check if adding to the list
    let records: IconRecord[] = [];
    if (append) {
      const { searchResults = [] } = iconSearch;
      records = records.concat(searchResults);
    }

    // create the query
    const params = {
      iconquery: query,
      page,
      limit,
    };

    const { iconsURL } = shaperHub;

    // capture all icon requests
    const response: AxiosResponse<IconQueryResponse> = await axios.get(
      `${iconsURL}/search`,
      { params }
    );
    const resolveIcons = response.data.icons.map((icon) =>
      axios.get(icon.icon_url)
    );
    const icons = await Promise.all(resolveIcons);

    // map all icons to their respective data
    for (let i = 0; i < response.data.icons.length; i++) {
      const iconData = response.data.icons[i];
      const iconSVG = icons[i].data;
      const iconURI =
        'data:image/svg+xml;base64,' + btoa(encodeURIComponent(iconSVG));

      // save the record
      records.push({ ...iconData, iconSVG, iconURI });
    }

    // give back the result
    return records;
  }
);

export const slice = createSlice({
  name: 'iconSearch',
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.searchResults = [];
      state.searchError = null;
      state.lastIconQuery = defaultIconQuery;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        newIconSearch.fulfilled,
        (state, action: PayloadAction<IconRecord[]>) => {
          if (action.payload) {
            state.searchResults = action.payload;
          }
          state.status = 'idle';
        }
      )
      .addCase(
        newIconSearch.pending,
        (state, action: PendingActionFromAsyncThunk<typeof newIconSearch>) => {
          state.status = 'pending';
          let iconQuery = action.meta.arg;
          state.lastIconQuery = iconQuery;
        }
      )
      .addCase(
        newIconSearch.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.status = 'idle';
          state.searchError = action.payload;
        }
      );
  },
});

export const { clearSearchResults } = slice.actions;

export const selectSearchResults = (state: RootState) =>
  state.iconSearch.searchResults;

export const selectLastIconQuery = (state: RootState) =>
  state.iconSearch.lastIconQuery;

export const selectSearchStatus = (state: RootState) => state.iconSearch.status;

export default slice.reducer;
