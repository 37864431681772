import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// consts
import { OFFSET_PRESETS } from '@/Constants/UI';

// helpers
import { unitToFormattedDisplayUnit } from '@/Geometry/UnitOps';
import { uniq } from 'lodash';

// selectors
import { selectSelectedPaths } from '@/Redux/Slices/SelectionSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateSvgPathAction from '@/Actions/UpdateSvgPath';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';
import { useTranslation } from 'react-i18next';

export default function OffsetSelector() {
  const [focused, setFocused] = useState(false);

  // selectors
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const displayUnits = useSelector(selectDisplayUnits);
  const selectedPaths = useSelector(selectSelectedPaths);
  const { t, i18n } = useTranslation();

  // actions
  const updateSvgPathOperations = useAction(UpdateSvgPathAction);

  // computed
  const selectedOffsets = selectedPaths.map((sel) =>
    unitToFormattedDisplayUnit(sel.path.cutParams.cutOffset, displayUnits)
  );

  // if there are no offsets, just default to nothing
  if (!selectedOffsets.length) {
    selectedOffsets.push('0.000');
  }

  const hasOpenPath = selectedPaths.some((item) =>
    item.path.outerPath ? !item.path.outerPath.closed : !item.path.closed
  );
  const hasOnlineCutType = selectedPaths.some(
    (item) => item.path.cutParams.cutType === 'online'
  );
  const disabled = hasOpenPath && hasOnlineCutType;

  const isMixed = uniq(selectedOffsets).length > 1;
  const offset = isMixed
    ? i18n.exists('mixed')
      ? t('mixed')
      : 'mixed'
    : toFormattedDisplayUnitValue(selectedOffsets[0], { preformatted: true });

  function updateOffset(value) {
    if (!isNaN(value)) {
      //Offset is stored as a string with in or mm appended at the end. "####in" or "####mm"
      updateSvgPathOperations.setOffset(
        selectedPaths,
        `${value}${displayUnits}`
      );
    }
  }

  // handlers
  function onCommit(input) {
    //ignore characters that aren't numbers (e.g. you can't type '1in' or '5mm' to override display units right now)
    const inputFloat = parseFloat(input);
    updateOffset(inputFloat);
  }

  function onInvert() {
    updateOffset(-offset);
  }

  function onFocusInput() {
    setFocused(true);
  }

  function onBlurInput() {
    setFocused(false);
  }

  function onSelectPreset(value) {
    updateOffset(value);
  }

  //toggles the value from positive to negative
  function plusMinusValue() {
    const inputFloat = parseFloat(offset) * -1;
    onCommit(inputFloat);
  }

  function renderPresets() {
    const useMM = /mm/i.test(displayUnits);

    return OFFSET_PRESETS.map((preset, index) => {
      const { n = 1, d, in: inch, mm } = preset;
      const value = useMM ? mm : d ? n / d : inch;
      let label = useMM ? mm : d ? `1&frasl;${d}` : inch;

      // trim leading zeros, if needed
      label = label.toString().replace(/^0/, '');

      return (
        <div
          key={index}
          onClick={() => onSelectPreset(value)}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      );
    });
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='offset'>
        <TranslationText i18nKey='offset'>Offset</TranslationText>
      </FloatingPanel.Label>
      {focused && !disabled && (
        <FloatingPanel.PopOut className='pop-out--presets'>
          <div className='pop-out--presets--heading'>
            <TranslationText i18nKey='presets'>Presets</TranslationText>
          </div>
          <div className='pop-out--presets--grid'>{renderPresets()}</div>
        </FloatingPanel.PopOut>
      )}

      <FloatingPanel.Input
        disabled={disabled}
        calculate
        value={offset}
        prefix={<Icon onClick={onInvert}>swap-horizontal</Icon>}
        suffix={displayUnits}
        precision={4}
        onClick={plusMinusValue}
        onCommit={onCommit}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
      />
    </FloatingPanel.Group>
  );
}
