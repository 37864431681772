import { batch } from 'react-redux';
import { updateActiveSelection } from '@/Redux/Slices/SelectionSlice';
import { clearHistory } from '@/Redux/Slices/CanvasSlice';
import { undoCanvas, redoCanvas } from '@/CanvasContainer/CanvasActions';
import { selectHasSelection } from '../Redux/Slices/SelectionSlice';
import UIFeatureAction from './UIFeature';
import BaseAction from './BaseAction';

export default class UndoRedoAction extends BaseAction {
  activatePropertiesPanel() {
    const ui = this.createAction(UIFeatureAction);
    ui.toggleEditSelectionProperties(true);
  }

  undo = () => {
    const { dispatch } = this;
    batch(async () => {
      await dispatch(undoCanvas());
      await dispatch(updateActiveSelection());
      const hasSelection = this.useSelector(selectHasSelection);
      if (hasSelection) {
        this.activatePropertiesPanel();
      }
    });
  };

  redo = () => {
    const { dispatch } = this;
    batch(async () => {
      await dispatch(redoCanvas());
      await dispatch(updateActiveSelection());
      const hasSelection = this.useSelector(selectHasSelection);
      if (hasSelection) {
        this.activatePropertiesPanel();
      }
    });
  };

  clearHistory = () => {
    const { dispatch } = this;
    dispatch(clearHistory());
  };
}
