// import ClearCanvasAction from './ClearCanvas';
import { logoutShaperHub } from '@/Redux/Slices/ShaperHubSlice';
import BaseAction from './BaseAction';

export default class ClearStateAction extends BaseAction {
  clear = async () => {
    //Need to logout to clear tokens from cookies
    await this.dispatch(logoutShaperHub());
    localStorage.clear();
    sessionStorage.clear();
    await this.dispatch({ type: 'RESET_STATE' });
    location.reload();
  };
}
