import React from 'react';
import TranslationText from '../TranslationText/TranslationText';
import Button from '@/Components/Button';
import MaintenanceSvg from './maintenance.svg';

// loads the main scss file
import './MaintenancePage.scss';

export default function MaintenancePage() {
  return (
    <div className='maintenance'>
      <div className='maintenance-img-container'>
        <img src={MaintenanceSvg} alt='Maintenance' />
      </div>
      <div className='maintenance-container'>
        <div className='maintenance-header'>
          <TranslationText i18nKey='studio-maintenance'>
            Studio is undergoing brief maintenance
          </TranslationText>
        </div>
        <div>
          <Button
            className='maintenance-error--link-button pill error full'
            onClick={() => window.open('https://support.shapertools.com/hc/')}
          >
            <TranslationText i18nKey='learn-more'>Learn More</TranslationText>
          </Button>
        </div>
        <div className='maintenance-body-text'>
          <TranslationText i18nKey='maintenance-see-more'>
            See more information about ongoing Studio maintenance
          </TranslationText>
        </div>
      </div>
    </div>
  );
}
