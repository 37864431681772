import { isBoolean, isNumber, isObject, toNumber } from 'lodash';

const SETTINGS_KEY = 'studio:user_preferences';
let preferences: Record<string, any> = {};

// by default, try and restore the settings
try {
  const str = localStorage.getItem(SETTINGS_KEY);
  const data = JSON.parse(str!) || {};
  preferences = { ...data };
} catch (ex) {
  // nothing to do, just ignore the settings
}

// write the settings
export function save(obj: Record<string, unknown>) {
  preferences = { ...preferences, ...obj };

  // save
  const str = JSON.stringify(preferences);
  localStorage.setItem(SETTINGS_KEY, str);
}

// try and restore setting values
export function restore<T>(propMap: Record<string, any>, target?: Object) {
  const requested: Record<string, any> = {};

  // try and map each value
  Object.keys(propMap).forEach((id) => {
    const config = propMap[id];
    let { validate, parse, type, default: defaultValue } = config;

    // if a value was provided and not a config object, then
    // try to determine some information
    if (!isObject(config)) {
      defaultValue = config;

      // check if there's a validator to use
      if (isNumber(config)) {
        type = Number;
      } else if (isBoolean(config)) {
        type = Boolean;
      }
    }

    // try and parse the value
    try {
      let value = preferences[id];

      // helpers for type validation
      if (type === Number) {
        parse = toNumber;
        validate = isNumber;
      } else if (type === Boolean) {
        parse = (val: any) => /true/i.test(val);
        validate = (val: any) => [true, false].includes(val);
      }

      // perform formatting, if needed
      if (parse && !(value === null || value === undefined)) {
        value = parse(value);
      }

      // no value is assigned
      if (value === null || value === undefined) {
        requested[id] = defaultValue;
      }

      // if the setting exists, validate it, if possible
      else if (validate?.(value)) {
        requested[id] = value;
      }

      // just use it as is
      else {
        requested[id] = value;
      }
    } catch (ex) {
      // don't fail for single props
      requested[id] = defaultValue;
    }
  });

  // wants to assign to an object
  if (target) {
    Object.assign(target, requested);
  }

  return requested as T;
}
