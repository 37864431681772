import { Entitlements } from '@/@types/shaper-types';
import { FeatureList } from '@/Redux/Slices/UISlice';

export const entitlements = {
  DOWNLOAD: 'SVG Download',
  DESIGN_MODE: 'Design Mode',
  SHAPE_BUILDER: 'Shape Builder',
  FIND_ART: 'Find Art',
  FIND_ART_LITE: 'Find Art (Limited)',
  TEXT: 'Text',
  TEXT_LITE: 'Text (Limited)',
  IMPORT: 'SVG + DXF Import',
  DELETE: 'Delete',
  DUPLICATE: 'Duplicate',
  PRECISION_SIZE: 'Precision Size',
  PRECISION_ROTATION: 'Precision Rotate',
  PRECISION_POSITION: 'Precision Position',
  ANCHORS: 'Anchors',
  SHAPESHIFTER: 'Shapeshifter',
  ALIGN_TO_OBJ: 'Align to Object',
  GRID_COORDINATES: 'Grid Coordinate System (0,0)',
  GRID_VISIBILITY: 'Grid Visibility',
  ALIGN_TO_GRID: 'Align to Grid',
  CUT_PATH: 'Cut Path Display',
  PLAN_MODE: 'Plan Mode',
  REVIEW_MODE: 'Review Mode',
  CTA: 'CTA',
  INSTAPLACE: 'Instaplace',
  TRACE: 'Trace',
  SYNC: 'Sync',
  SECRET_MENU: 'Secret Menu',
  SHOW_WORKSPACEID: 'Show WorkspaceID',
  CUSTOM_ANCHOR: 'Custom Anchor',
  SELECTION_MANAGER: 'Selection Manager',
  REFERENCE_PATHS: 'Reference Paths',
} as const;

const DEMO = [
  entitlements.SYNC, //NB - Sync must be enabled in demo mode to initialize the state machine correctly. However, sync will not be active until user logs in, which means the user is no longer in demo mode
  entitlements.DESIGN_MODE,
  entitlements.SHAPE_BUILDER,
  entitlements.FIND_ART_LITE,
  entitlements.TEXT_LITE,
  entitlements.DELETE,
  entitlements.DUPLICATE,
  entitlements.PRECISION_SIZE,
  entitlements.PRECISION_ROTATION,
  entitlements.ANCHORS,
  entitlements.ALIGN_TO_OBJ,
  entitlements.CTA,
] as const;

const LITE = [
  entitlements.SYNC,
  entitlements.DOWNLOAD,
  entitlements.DESIGN_MODE,
  entitlements.SHAPE_BUILDER,
  entitlements.FIND_ART_LITE,
  entitlements.TEXT_LITE,
  entitlements.DELETE,
  entitlements.DUPLICATE,
  entitlements.PRECISION_SIZE,
  entitlements.PRECISION_ROTATION,
  entitlements.ANCHORS,
  entitlements.ALIGN_TO_OBJ,
  entitlements.CTA,
] as const;

const FULL = [
  entitlements.SYNC,
  entitlements.DOWNLOAD,
  entitlements.DESIGN_MODE,
  entitlements.SHAPE_BUILDER,
  entitlements.FIND_ART,
  entitlements.TEXT,
  entitlements.FIND_ART_LITE,
  entitlements.TEXT_LITE,
  entitlements.IMPORT,
  entitlements.DELETE,
  entitlements.DUPLICATE,
  entitlements.PRECISION_SIZE,
  entitlements.PRECISION_ROTATION,
  entitlements.PRECISION_POSITION,
  entitlements.ANCHORS,
  entitlements.SHAPESHIFTER,
  entitlements.ALIGN_TO_OBJ,
  entitlements.GRID_COORDINATES,
  entitlements.GRID_VISIBILITY,
  entitlements.ALIGN_TO_GRID,
  entitlements.CUT_PATH,
  entitlements.PLAN_MODE,
  entitlements.REVIEW_MODE,
  entitlements.CUSTOM_ANCHOR,
  entitlements.SELECTION_MANAGER,
  entitlements.REFERENCE_PATHS,
] as const;

//Disable experimental features for now
const DEVELOPMENT = Object.freeze([
  // entitlements.INSTAPLACE,
  // entitlements.TRACE,
  entitlements.SECRET_MENU,
  entitlements.SHOW_WORKSPACEID,
]);

const FEATURE_LIST = {
  demo: DEMO,
  lite: LITE,
  full: FULL,
  development: DEVELOPMENT,
} as const;

const keys = <T extends object>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[];

export const getFeatureList = (): FeatureList => {
  const featuresEnabled = keys(FEATURE_LIST).reduce((obj, key) => {
    return {
      ...obj,
      [key]: [
        ...FEATURE_LIST[key],
        ...(import.meta.env.MODE !== 'production'
          ? [...FEATURE_LIST.development]
          : []),
      ],
    };
    // eslint-disable-next-line no-unused-vars
  }, {} as { [key in keyof typeof FEATURE_LIST]: Entitlements[] });

  return { features: featuresEnabled, allFeatures: entitlements };
};
