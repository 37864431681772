import React from 'react';
import TranslationText from '../../TranslationText/TranslationText';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Redux/Slices/ShaperHubSlice';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@/Styles/Icons/Icon';

export default function SignOut() {
  const user = useSelector(selectUser);

  const formatInitials = (u) =>
    u && u?.givenName && u?.familyName
      ? `${u.givenName.charAt(0).toUpperCase()}${u.familyName
          .charAt(0)
          .toUpperCase()}`
      : '';

  const apiUrl = localStorage.getItem('shaperAPIURL');
  const avatarImgSrc =
    apiUrl && user ? `${apiUrl}/users/${user?._id}/image` : null;

  return (
    <>
      {user && user.email ? (
        <>
          <div className='action-list-menu--section-option--avatar'>
            <Avatar src={avatarImgSrc}>{formatInitials(user)}</Avatar>
          </div>
          <div className='action-list-menu--section-option--sign-out'>
            <div
              className='action-list-menu--section-option--sign-out--email'
              alt={user && user.email}
            >
              {user && <>{user.email}</>}
            </div>
            <div className='action-list-menu--section-option--sign-out--text'>
              <TranslationText i18nKey='sign-out'>Sign Out</TranslationText>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='action-list-menu--section-option--icon'>
            <Icon icon='person' />
          </div>
          <div className='action-list-menu--section-option--label'>
            <TranslationText i18nKey='sign-out'>Sign Out</TranslationText>
          </div>
        </>
      )}
    </>
  );
}
